import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { UtilsService } from '../../services/utils.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';

// Environments
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { AnalyticService } from 'src/app/services/analytic.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  environment = environment;

  credentials: {
    account: string,
  };

  done = false;

  constructor(
    public utilsService: UtilsService,
    public userService: UserService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    public titleService: Title,
    public analyticService: AnalyticService,
  ) { 
    this.credentials = {
      account: null
    };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Recupera la contraseña | Wuolads');
    this.analyticService.pageView(window.location.href, 'recover-password', 'Recover password');
    this.analyticService.screenView('Recover password');
  }

  recoverPassword(){
    if (!this.credentials.account || !this.utilsService.validateEmail(this.credentials.account)) {
      this.utilsService.presentAlertMessage({
        title: 'Email incorrecto.',
        description: 'Por favor indica una dirección de email correcta para recuperar tu cuenta.',
        error: true,
        duration: 6
      });

      return false;
    }

    this.utilsService.loading('open');

    this.userService.resetPassword(this.credentials).then(
      (response) => {
        this.utilsService.loading('close');
        this.done = true;
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
    );
  }
}
