import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class UniversityService extends RequestService {
  heavySearchUniversities(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(
          this.environment.API + "/university/search",
          params
        ),
        { headers: this.headers() }
      )
    );
  }

  searchUniversities(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/university", params),
        { headers: this.headers() }
      )
    );
  }
}
