<div class="body-container">
  <h1 class="wds-h1">Historial del cliente</h1>
  <div class="container">
    <div class="user-container">
      <p class="user-name">{{hubspotData?.contact?.body?.properties?.firstname}}</p>
      <p class="user-data"><a *ngIf="hubspotData?.contact?.body?.properties?.company">{{hubspotData?.contact?.body?.properties?.company}} · </a><a *ngIf="hubspotData?.contact?.body?.properties?.city">{{hubspotData?.contact?.body?.properties?.city}} ·
      </a><a *ngIf="hubspotData?.contact?.body?.properties?.phone">{{hubspotData?.contact?.body?.properties?.phone}} · </a>{{hubspotData?.contact?.body?.properties?.email}}</p>
        <div class="buttons-container" *ngIf="hubspotData?.contact?.body?.properties?.phone">
          <app-button-item (fireClick)="call()" [text]="'Llamar'" [background]="'#113984'" [solid]="true"
            [icon]="'phone'"></app-button-item>
          <app-button-item (fireClick)="addAction('Connected')" [text]="'Conectado'" [background]="'#FFFFFF'"
            [color]="'#113984'" [outlined]="true" [borderColor]="'#113984'" [solid]="true" [icon]="'add_ic_call'">
          </app-button-item>
          <app-button-item (fireClick)="addAction('No answer')" [text]="'Sin respuesta'" [background]="'#FFFFFF'"
            [color]="'#113984'" [outlined]="true" [borderColor]="'#113984'" [solid]="true" [icon]="'phone_missed'">
          </app-button-item>
          <app-button-item (fireClick)="addAction('Busy')" [text]="'Ocupado'" [background]="'#FFFFFF'" [color]="'#113984'"
            [outlined]="true" [borderColor]="'#113984'" [solid]="true" [icon]="'phone_locked'"></app-button-item>
        </div>
        <div class="buttons-container" *ngIf="hubspotData?.contact?.body?.properties?.phone">
          <app-button-item (fireClick)="addAction('Wrong number')" [text]="'Número incorrecto'" [background]="'#FFFFFF'"
            [color]="'#113984'" [outlined]="true" [borderColor]="'#113984'" [solid]="true" [icon]="'dangerous'">
          </app-button-item>
          <app-button-item (fireClick)="addAction('Left live message')" [text]="'Dejó un mensaje en vivo'"
            [background]="'#FFFFFF'" [color]="'#113984'" [outlined]="true" [borderColor]="'#113984'" [solid]="true"
            [icon]="'stream'"></app-button-item>
          <app-button-item (fireClick)="addAction('Left voicemail')" [text]="'Dejó un mensaje de voz'" [background]="'#FFFFFF'"
            [color]="'#113984'" [outlined]="true" [borderColor]="'#113984'" [solid]="true" [icon]="'voicemail'">
          </app-button-item>
      </div>
    </div>
    <div class="record">
      <p class="record-title">Historial de acciones</p>
      <div class="action-container" *ngIf="hubspotData?.calls.length > 0">
        <div class="action" *ngFor="let call of hubspotData?.calls">
          <p class="action-title">{{call?.title}}</p>
          <p class="action-date">{{call?.timestamp}}</p>
        </div>
      </div>
      <div class="action-container" *ngIf="hubspotData?.calls.length <= 0">
        <div class="action">
          <p class="action-title">No se han registrado acciones</p>
        </div>
      </div>
    </div>
  </div>
</div>