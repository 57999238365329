<nav>
  <div [routerLink]="'/index'" class="logo">
    <img src="https://www.wuolah.com/static/media/res/mark/logo.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <h4>Ad Manager</h4>

  <app-button-item class="back-button" [routerLink]="'/index'" [onlyIcon]="true" [background]="'#FFFFFF'" [icon]="'arrow_back'" [color]="'#102754'" [text]="'Continuar'" [solid]="true"></app-button-item>
</nav>

<div class="container">
  <div class="inner-container">
    <div class="inputs-container">
      <h5 class="wds-h5">Regístrate para obtener tu cuenta.</h5>

      <form>
        <div class="input-element">
          <label class="input-element-label">Email</label>
          <input placeholder="javier@tuempresa.com" name="mail" type="email" [(ngModel)]="credentials.mail">
        </div>
  
        <div class="input-element">
          <label class="input-element-label">Contraseña</label>
          <input placeholder="********" name="password" type="password" [(ngModel)]="credentials.password">
        </div>

        <a href="#">Al registrarme, acepto los Términos y condiciones y la Política de privacidad.</a>

        <app-button-item (fireClick)="signUp()" [background]="'#102754'" [color]="'#FFFFFF'" [text]="'Crear cuenta'" [solid]="true"></app-button-item>
      </form>
    </div>
  </div>
  
  <a class="outside" [routerLink]="'/login'">¿Ya tienes una cuenta en Wuolah?<span>&nbsp; Inicia sesión</span></a>
</div>
