import { Component, OnInit } from "@angular/core";

// Services
import { AdvertiserService } from '../../services/advertiser.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { Title } from "@angular/platform-browser";
import { AnalyticService } from "src/app/services/analytic.service";
import { ResourceService } from "src/app/services/resource.service";

@Component({
  selector: "app-dashboard-settings",
  templateUrl: "./dashboard-settings.component.html",
  styleUrls: ["./dashboard-settings.component.css"],
})
export class DashboardSettingsComponent implements OnInit {
  advertiserMe: any;

  newMail: string = '';
  password: string = '';
  newPassword: string = '';

  disabledAccessData: boolean = true;
  disabledProfileData: boolean = true;
  disabledTaxData: boolean = true;
  disabledchangePassword = false;

  accessData: any = {};
  profileData: any = {};
  taxData: any = {};

  city = {
    name: null,
  };

  cities = [];

  countries = [];

  constructor(
    public advertiserService: AdvertiserService,
    public userService: UserService,
    public utilsService: UtilsService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public resourceService: ResourceService,
  ) { }
  
  ngOnInit() {
    this.titleService.setTitle('Configuración del perfil | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/settings', 'Profile settings');
    this.analyticService.screenView('Profile settings');

    this.advertiserMe = this.storageService.read('advertiserMe');

    this.getCities();
    this.getCountries();
  }


  changePassword() {
    this.disabledchangePassword = !this.disabledchangePassword
  }

  onKeypressEvent(event, type: string) {
    if (type === "access") {
      this.disabledAccessData = false;
    } else if (type === "profile") {
      this.disabledProfileData = false;
    } else {
      this.disabledTaxData = false;
    }
  }

  async saveAccountInfo(){

    if(!this.password || !this.password.length || ((!this.newPassword || !this.newPassword.length) && (!this.newMail || !this.newMail.length))){
      this.utilsService.presentAlertMessage({
        title: 'Rellena los datos',
        description: 'Necesitas rellenar la contraseña y al menos un campo a actualizar.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (this.newMail && this.newMail && !this.utilsService.validateEmail(this.newMail)) {
      this.utilsService.presentAlertMessage({
        title: 'Email incorrecto.',
        description: 'Necesitas un email válido para registrarte.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (this.newPassword && this.newPassword.length && !this.utilsService.validatePassword(this.newPassword)) {
      this.utilsService.presentAlertMessage({
        title: 'Contraseña incorrecta.',
        description: 'La contraseña debe contener entre 6 y 18 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }
    let data: any = {
      password: this.password
    };
    if(this.newMail) data.newMail = this.newMail.trim();
    if(this.newPassword) data.newPassword = this.newPassword;

    this.utilsService.loading('open');
    try {
      await this.userService.updateAccount(data);
      this.utilsService.loading('close');
      this.utilsService.presentMessage("Se ha actualizado con éxito.");
    } catch(error) {
      this.utilsService.loading('close');
      this.userService.errorHandler(error, true);
    }
    
    await this.userService.refreshUserMe().then((response) => {
      if (response.mail === data.newMail) {
        this.utilsService.publish("newMail_data", data.newMail);
      }
    });

    this.newMail = '';
    this.password = '';
    this.newPassword = '';
    this.disabledAccessData = true;
  }

  async saveContactInfo() {
    let data: any = {};
    let currentAdvertiser: any = this.storageService.read('advertiserMe');
    for(let field of ['contactName', 'contactMail', 'contactCity', 'phoneNumber']){
      if(this.advertiserMe[field] && this.advertiserMe[field].length && currentAdvertiser[field] != this.advertiserMe[field]) data[field] = this.advertiserMe[field];
    }

    this.utilsService.loading('open');
    try {
      await this.advertiserService.updateProfile(data);
      this.utilsService.loading('close');
      this.utilsService.presentMessage("Se ha actualizado con éxito.");
    } catch(error) {
      this.utilsService.loading('close');
      this.userService.errorHandler(error, true);
    }

    this.advertiserMe = await this.advertiserService.refreshAdvertiserMe();
    this.disabledProfileData = true;
  }

  async saveTaxInfo() {
    let data: any = {};
    let currentAdvertiser: any = this.storageService.read('advertiserMe');
    for(let field of ['name', 'nif', 'address1', 'address2', 'postalCode', 'city', 'country']){
      if(this.advertiserMe[field] && this.advertiserMe[field].length && currentAdvertiser[field] != this.advertiserMe[field]) data[field] = this.advertiserMe[field];
    }
    if (!this.advertiserMe.nif.match('([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])')) {
      this.utilsService.presentAlertMessage({
        title: 'NIF incorrecto.',
        error: true,
        duration: 6
      });
    } else {
      this.utilsService.loading('open');
      try {
        await this.advertiserService.updateProfile(data);
        this.utilsService.loading('close');
        this.utilsService.presentMessage("Se ha actualizado con éxito.");
      } catch(error) {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
      
      this.advertiserMe = await this.advertiserService.refreshAdvertiserMe();
      this.disabledTaxData = true;
    }
  }

  getCity(cityId) {
    this.resourceService.getCity(cityId).then(
      (response) => {
        this.city = response;
        console.log(this.city)
      }
    ).catch(
      
    );
  }

  getCities() {
    const data = {
      offset: 0,
      limit: 5000,
      types: 'city',
      sort: 'name'
    };

    this.resourceService.getResources(data).then(
      (response) => {
        this.cities = response.results;
      }
    ).catch(
      () => this.cities = []
    );
  }

  getCountries() {
    const data = {
      offset: 0,
      limit: 5000,
      types: 'country',
      sort: 'name'
    };

    this.resourceService.getResources(data).then(
      (response) => {
        this.countries = response.results;
      }
    ).catch(
      () => this.countries = []
    );
  }
}
