<div class="container" [ngClass]="{'warning': color && color === 'warning', 'danger': color && color === 'danger'}">
  <div class="asset">
    <i *ngIf="!icon || icon === ''" class="material-icons-outlined">info</i>
    <i *ngIf="icon && icon !== ''" class="material-icons-outlined">{{icon}}</i>
  </div>

  <div class="texts">
    <div *ngIf="title && title !== ''" class="title">{{title}}</div>
    <div *ngIf="subtitle && subtitle !== ''" class="subtitle">{{subtitle}}</div>
  </div>
</div>
