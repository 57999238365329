<div class="container" [ngClass]="{'open': open}">
  <div class="overlay" (click)="close()"></div>

  <div class="inner-container">
    <div class="header-side">
      <div class="header-title">Crear anuncio</div>

      <app-button-item 
        [onlyIcon]="true" 
        [title]="'Cerrar'"
        [icon]="'close'"
        [color]="'#737373'"
        (fireClick)="close()"
      ></app-button-item>
    </div>

    <div class="container-side">
      <div *ngIf="false" class="letf-side">
        <div class="side-menu-item">
          <div class="asset">
            <img src="../assets/icon/rocket-white.svg" alt="Campaña genérica">
            <img src="../assets/icon/rocket-black.svg" alt="Campaña genérica">
          </div>

          <div class="information">
            <div class="top-line">Campaña generica</div>
            <div class="bottom-line">Campaña para proposito general</div>
          </div>
        </div>
      </div>
  
      <div class="main-side">
        <div class="main-side-title">Seleciona tu opción</div>

        <div class="items-container">
          <app-standard-campaign-item 
            *ngFor="let item of itemList"
            [title]="item.title" 
            [description]="item.description"
            [image]="item.image"
            [routerLink]="sponsorStorage ? '' : item.route"
            [backgroundColor]="item.backgroundColor"
            (onSelect)="goToSponsor(item.title)"
          ></app-standard-campaign-item>
        </div>
      </div>
    </div>
  </div>
</div>