<div class="body-container">
  <div class="period-capsule">
    <h1 class="wds-h1">Mis anuncios</h1>


  </div>

  <div class="table-container">
    <ngx-skeleton-loader *ngIf="check < 1" animation="progress" [theme]="{ 
        'border-radius': '8px',
        'height': '300px',
        'width': '100%',
        'background-color': '#f8f8f8',
        'margin': '0'
      }"></ngx-skeleton-loader>

      <table>
        <tr>
          <th></th>
          <th>Tipo</th>
          <th>Fecha</th>
          <th>Detalles</th>
          <th>Clics</th>
          <th>Impresiones</th>
          <th>CTR</th>
          <th></th>
        </tr>
        <tr *ngFor="let row of rowData">
          <td class="icon-container"><img src='../assets/img/create-pub-modal/shield-black.svg'
              alt='Patrocinar asignatura' class="icon"></td>
          <td class="type">{{row?.type}}</td>
          <td class="date">{{row?.date}}</td>
          <td class="segmentation">{{row?.segmentation}}</td>
          <td class="clicks">{{row?.clicks}}</td>
          <td class="impressions">{{row?.impressions}}</td>
          <td class="ctr">{{row?.ctr}}</td>
          <td class="link"><a href="dashboard/sponsor/report/{{row.id}}"><span style='color: #0055ff; margin-top: 9px; z-index: 4;'
                class='material-icons-outlined link-icon'>chevron_right</span></a></td>
        </tr>
      </table>
  </div>
</div>