import { Component, OnInit } from '@angular/core';


import { StorageService } from "src/app/services/storage.service";
import { AnalyticService } from 'src/app/services/analytic.service';
import { UtilsService } from "src/app/services/utils.service";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-purchased-success',
  templateUrl: './purchased-success.component.html',
  styleUrls: ['./purchased-success.component.css']
})
export class PurchasedSuccessComponent implements OnInit {

  uuid: string;
  userId: string;
  data: any;
  
  constructor(
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public userService: UserService,
    public utilsService: UtilsService,
  ) { 
    this.userId = this.storageService.read('userId');
    this.uuid = this.storageService.read('uuid');
    this.data = this.storageService.read('advertiserMe');
  }

  ngOnInit(): void {
    this.analyticService.customEvent(
      'UP2_04_0_END',
      {
        uuid: this.uuid,
        userId: this.userId,
      }
    );

    const user: any = this.storageService.read('userMe');

  }

}
