<div class="body-container">
  <h1 class="wds-h1">Asociar clientes</h1>

  <div class="table-container"  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
    <div class="table-row table-header">
      <div class="table-column">ID</div>
      <div class="table-column">Anunciante</div>
      <div class="table-column">ID Anunciante GAM</div>
      <div class="table-column"></div>
    </div>

    <div class="table-row" *ngFor="let advertiser of advertisers">
      <div class="table-column">{{advertiser.userId}}</div>
      <div class="table-column">{{advertiser.name}} - {{advertiser.contactName}}</div>
      <div class="table-column">{{advertiser.dfpId ? advertiser.dfpId : 'No asignado'}}</div>
      <div class="table-column actions">
        <app-button-item (fireClick)="toggleAssociateSlide(advertiser)" [onlyIcon]="true" [title]="'Asociar pedido'" [icon]="'link'" [color]="'#0055FF'"></app-button-item>
      </div>
    </div>
  </div>
</div>

<div *ngIf="associateSlide" class="side-slide" [ngClass]="{'open': openSlide, 'step-1': step === 1, 'step-2': step === 2}">
  <div (click)="toggleAssociateSlide(null)" class="overlay"></div>

  <div class="inner-container primary">
    <div class="inner-nav">
      <app-button-item (fireClick)="toggleAssociateSlide(null)" [onlyIcon]="true" [color]="'#111111'" [title]="'Cerrar'" [icon]="'close'"></app-button-item>
      <div class="inner-nav-text">{{selectedAdvertiser.name}} - {{selectedAdvertiser.contactName}}</div>
    </div>

    <div class="side-inner-body">
        <div class="item-input">
          <div class="labels">
            <label>ID de anunciante en Ad Manager</label>
            <small>
              Id de anunciante den Ad Manager (GAM) para asociarlo con nuestro ID.
            </small>
          </div>
  
          <div class="input-container">
            <input placeholder="DDTF1454" [(ngModel)]="newAdvertiserDfpId">
          </div>
        </div>

        <div class="item-input">
          <div class="labels">
            <label>Agentes asociados al cliente</label>
            <small>
              Agentes que pueden consultar y modificar información y campañas del cliente.
            </small>
          </div>
  
          <div class="input-container">
            <input placeholder="436362" [(ngModel)]="newAgentId">
          </div>
          <app-chip-item *ngFor="let agentId of selectedAdvertiser.agentIds" [text]="agentId" [selected]="true" (click)="removeAgentId(agentId)"></app-chip-item>
        </div>

      <div class="title-divider">Campañas del cliente</div>

      <!-- <div class="no-campaigns">No hay campañas asociadas a este cliente.</div> -->

      <div class="campaigns-table">
        <div class="campaign-row header">
          <div class="campaign-column">Nombre</div>
          <div class="campaign-column">ID Order GAM</div>
          <div class="campaign-column"></div>
        </div>

        <div class="campaign-row" *ngFor="let campaign of selectedAdvertiserCampaigns">
          <div class="campaign-column">{{campaign?.name}}</div>
          <div class="campaign-column">{{campaign?.id ? campaign?.id : 'No asignado' }}</div>
          <div class="campaign-column">
            <app-button-item [routerLink]="['/dashboard', 'campaign-details', campaign?.advertiserId, campaign?.id]" [onlyIcon]="true" [color]="'#111111'" [title]="'Asociar pedido'" [icon]="'visibility'"></app-button-item>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-footer">
      <app-button-item [size]="mobile ? 'small' : ''" (fireClick)="toggleAssociateSlide(null)" [text]="'Cerrar'" [title]="'Cerrar'" [outlined]="true" [color]="'#111111'" ></app-button-item>
      <app-button-item [size]="mobile ? 'small' : ''" [text]="'Guardar cambios'" [title]="'Guardar cambios'" [icon]="'save'" [solid]="true" (fireClick)="editAdvertiser()"></app-button-item>
    </div>
  </div>

  <!--<div class="inner-container secondary">
    <div class="inner-nav" *ngIf="selectedCampaign">
      <app-button-item (fireClick)="closeCampaignEdit()" [onlyIcon]="true" [title]="'Atrás'" [color]="'#111111'" [icon]="'keyboard_backspace'"></app-button-item>
      <div class="inner-nav-text">Detalles del pedido - {{selectedCampaign.name}}</div>
    </div>

    <div class="side-inner-body" *ngIf="selectedCampaign">
      <div class="item-input">
        <div class="labels">
          <label>ID de pedido en Ad Manager</label>
          <small>
            Id de pedido en Ad Manager (GAM) para asociarlo con nuestro ID.
          </small>
        </div>

        <div class="input-container">
          <input placeholder="DDTF1454" [(ngModel)]="newCampaignDfpId">
        </div>
      </div>

      <div class="item-input">
        <div class="labels">
          <label>URL Data Studio</label>
          <small>
            URL para asociar a esta campaña un Data Studio personalizado.
          </small>
        </div>

        <div class="input-container">
          <input placeholder="DDTF1454" [(ngModel)]="newCampaignDashboardUrl">
        </div>
      </div>
      <app-button-item [routerLink]="['/dashboard', 'campaign', selectedCampaign.id, selectedAdvertiser.userId]" [text]="'Ver campaña'" [title]="'Ver campaña'" [icon]="'remove_red_eye'" [solid]="true"></app-button-item>
    </div>

    <div class="inner-footer">
      <app-button-item (fireClick)="closeCampaignEdit()" [text]="'Atrás'" [title]="'Atrás'" [outlined]="true" [color]="'#111111'" ></app-button-item>
      <app-button-item [disabled]="(!newCampaignDfpId.length || newCampaignDfpId == selectedCampaign.dfpOrderId) &&
      (!newCampaignDashboardUrl.length || newCampaignDashboardUrl == selectedCampaign.dashboardUrl)" [text]="'Guardar cambios'" 
      [title]="'Guardar cambios'" [icon]="'save'" [solid]="true" (fireClick)="editCampaign()"></app-button-item>
    </div>
  </div>-->
</div>
