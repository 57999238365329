import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

// Services
import { UserService } from "../../services/user.service";
import { StorageService } from "src/app/services/storage.service";
import { UtilsService } from "src/app/services/utils.service";
import { AdvertiserService } from "src/app/services/advertiser.service";
import { AnalyticService } from "src/app/services/analytic.service";
import { ResourceService } from "src/app/services/resource.service";

// Environments
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {

  @ViewChild('searchCityInput') searchCityInput: ElementRef;

  environment = environment;
  userMe: any;
  advertiserMe: any;
  adminRole = false;
  clientToken: any;
  advertisersAccounts: any = [];
  openAccountChange = false;
  businessTypes = [];
  cities = [];
  cityResults = [];

  openPubCreator = false;
  openSavedSponsor = false;
  version = '0.0.0';

  showRequestInitCity = false;
  showRequestCity = false;

  showRequestInitBusiness = false;
  showRequestBusiness = false;

  uuid: string;

  toggleSideMenu = 'close';

  notifiableEmails = [
    'juancarlos@wuolah.com',
    'javier@wuolah.com',
    'cristina.mayoral@wuolah.com',
    'fernando.valdes@wuolah.com',
  ];

  screen = null;
  numberCity = 20;

  constructor(
    public userService: UserService,
    public advertiserService: AdvertiserService,
    public storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    public utilsService: UtilsService,
    public analyticService: AnalyticService,
    public resourceService: ResourceService,
    public titleService: Title,
  ) {
    this.uuid = this.storageService.read('uuid');
    this.utilsService.subscribe("create_pub_modal_action", (data: any) => {
      if (typeof data !== "undefined" && data) {
        if (data.action === 'open') {
          this.openPubCreator = true;
        }
      }
    });

    this.utilsService.subscribe("newMail_data", (data: any) => {
      this.userMe.mail = data;
    });

    this.screen = window.screen.width;
    if (this.screen < 765) {
      this.numberCity = 10;
    }
  }
  
  ngOnInit(): void {
    this.titleService.setTitle('Panel de anuncios | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard', 'Ad manager dashboard');
    this.analyticService.screenView('Ad manager dashboard');

    this.userMe = this.storageService.read("userMe");
    this.advertiserMe = this.storageService.read("advertiserMe");
    this.getCities();

    if (this.userMe && this.userMe.role !== 2 && (!this.advertiserMe.businessType || this.advertiserMe.businessType === '')) {
      
      this.showRequestInitBusiness = true;

      setTimeout(() => {
        this.showRequestBusiness = true;
      }, 150);
    }

    if (this.advertiserMe && (!this.advertiserMe.cityId || this.advertiserMe.cityId === '') && !this.showRequestInitBusiness) {
      this.showRequestInitCity = true;

      setTimeout(() => {
        this.showRequestCity = true;

        setTimeout(() => {
          this.searchCityInput?.nativeElement.focus();
        }, 200);
      }, 150);
    }

    this.version = this.utilsService.getVersion();
    this.businessTypes = this.utilsService.getBusinessTypes();

    if (this.userMe && this.userMe.role == 2) {
      this.adminRole = true;
      this.getClientAccounts();
    }

    this.initializeOneSignal();

    this.analyticService.customEvent(
      'UP1_05_0_VISIT',
      {
        uuid: this.uuid,
        userId: this.userMe.userId
      }
    );
  }

  getCities() {
    const data = {
      offset: 0,
      limit: 5000,
      types: 'city',
      countryId: 1
    };

    this.resourceService.getResources(data).then(
      (response) => {
        this.cities = response.results;
        this.cityResults = response.results;
      }
    ).catch(
      () => this.cities = []
    );
  }

  searchCity(event: any) {
    let value = event.target.value;
    value = value.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    value = value.toLowerCase();
    
    if (typeof value === 'undefined' || !value || value === '') {
      this.cityResults = this.cities;
      return false;
    }

    this.cityResults = this.search(value, this.cities);
  }

  search(nameKey, myArray) {
    let output = [];

    for (let i = 0; i < myArray.length; i++) {
      myArray[i].name = myArray[i].name.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      myArray[i].name = myArray[i].name.toLowerCase();

      if (myArray[i].name.indexOf(nameKey) > -1) {
        output.push(myArray[i]);
      }
    }

    return output;
  }

  logout() {
    this.storageService.remove("notification");
    this.storageService.remove("oneSignalInitialized");
    this.userService.logout();
  }

  async loginAsClient(userId) {
    try {
      let result: any = await this.userService.getUserToken(userId);
      this.clientToken = result.token;

      this.storageService.write('token', this.clientToken);
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
  }

  async getClientAccounts() {
    try {
      this.advertisersAccounts = await this.advertiserService.getAdvertisers();
    } catch (error) {
      // Error
    }
  }

  createCampaign() {
    this.storageService.remove('editingCampaign');
    if(this.route.snapshot && this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0] && this.route.snapshot.firstChild.url[0].path == 'create') {
      window.location.reload();
    } else {
      this.router.navigate(['dashboard/create']);
    }
  }

  async updateBusinessType(value: string) {
    this.showRequestBusiness = false;
    setTimeout(() => {
      this.showRequestInitBusiness = false;
    }, 200);

    try {
      const response = await this.advertiserService.updateProfile({businessType: value});
      this.storageService.write('advertiserMe', response);
      this.ngOnInit();

      } catch (error) {
      // Nothing to do
    }
  }

  async updateCity(cityId: number) {
    this.showRequestCity = false;

    setTimeout(() => {
      this.showRequestInitCity = false;
    }, 200);

    try {
      const response = await this.advertiserService.updateProfile({cityId: cityId});
      this.storageService.write('advertiserMe', response);
    } catch (error) {
      // Nothing to do
    }
  }

  openCreateModal() {
    this.openPubCreator = true;

    this.analyticService.customEvent(
      'UP2_00_0_CLICK',
      {
        uuid: this.uuid,
        userId: null
      }
    );
  }

  initializeOneSignal() {
    try {
      const OneSignal = window["OneSignal"] || [];

      OneSignal.push(() => {
        OneSignal.init({
          appId: this.environment.onesignal.appId,
          safari_web_id: this.environment.onesignal.appId,
          notifyButton: {
            enable: false,
          },
        });

        OneSignal.registerForPushNotifications();
        
        OneSignal.on("subscriptionChange", (isSubscribed) => {
          if (isSubscribed) {
            OneSignal.getUserId().then((deviceId) => {
              // Registrar device token en la bbdd
              this.userService.registerUserDevice({deviceId: deviceId});
              this.setupNotifications();
            });
          }
        });
      });

      OneSignal.push(() => {
        if(this.userMe?.role === 2){
          OneSignal.sendTag("direct-sales-wuolads", "1"); 
        }
        OneSignal.setEmail(this.userMe?.mail);
        OneSignal.setExternalUserId(this.userMe?.userId.toString());

        if (this.notifiableEmails.includes(this.userMe?.mail)) {
          OneSignal.sendTag("must-send-notification", "1");
        }
      });
      
    } catch (err) {
      console.log(err);
    }
  }

  setupNotifications () {
    const OneSignal = window['OneSignal'] || []

    OneSignal.push(() => {
      OneSignal.on('notificationDisplay', event => {
        // ignore onesignal welcome notification
        if (event.data.__isOneSignalWelcomeNotification) {
          return;
        }
      })
    })
  }
}
