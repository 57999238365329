import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

// Services
import { SponsorSubjectService } from "src/app/services/sponsor-subject.service";
import { UtilsService } from "src/app/services/utils.service";
import { StorageService } from "src/app/services/storage.service";

interface button {
  name: string;
  value: string;
  color: string;
  backgroundColor: string;
  iconUrl: string;
  iconType: string;
  actionUrl: string;
  id?: number;
}

interface contact {
  name: string;
  value: string;
  color: string;
  backgroundColor: string;
  iconUrl: string;
  iconType: string;
  actionUrl: string;
  id?: number;
}

interface feature {
  title: string;
  description: string;
  color: string;
  backgroundColor: string;
  data: string;
  highlight: 1 | 0;
  assetUrl: string;
  actionText: string;
  actionUrl: string;
  id?: number;
}

interface ad {
  creativeId: number;
  orderId: number;
  lineItemId: number;
  ubication: number;
  viewUrl: string;
  clickUrl: string;
  pixelUrl: string;
  files: {
    portrait: {
      imgTopUrl: string;
      imgLateralUrl: string;
    };
    landscape: {
      imgTopUrl: string;
      imgLateralUrl: string;
    };
  };
}

@Component({
  selector: "app-dashboard-admin-sponsor-subject-detail",
  templateUrl: "./dashboard-admin-sponsor-subject-detail.component.html",
  styleUrls: ["./dashboard-admin-sponsor-subject-detail.component.css"],
})
export class DashboardAdminSponsorSubjectDetailComponent implements OnInit {
  @ViewChild("mediaInput", { static: true }) mediaInput: ElementRef;
  @ViewChild("mediaInputAddons", { static: true }) mediaInputAddons: ElementRef;

  config: any;

  slidesController = {
    contacts: {
      slide: false,
      open: false,
    },
    buttons: {
      slide: false,
      open: false,
    },
    features: {
      slide: false,
      open: false,
    },
    ads: {
      slide: false,
      open: false,
    },
  };

  filters: {
    folderId: number;
    centerId: number;
    studyId: number;
    course: number;
  };

  folderName: string;

  widgets: any;
  selectedWidget = -1;
  mode: string;
  fromCopy = false;

  slideMode: string;
  slideItemIndex: number;
  slideItemObject: any;

  uploadingMode: string;
  uploadingModeAddon: string;
  deleteWidgetProperty: boolean = false;

  constructor(
    public utilsService: UtilsService,
    public sponsorSubjectService: SponsorSubjectService,
    public route: ActivatedRoute,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params) {
        this.filters = {
          folderId: null,
          centerId: null,
          studyId: null,
          course: null,
        };

        if (params.folderId) {
          this.filters.folderId = parseInt(params.folderId);
        }
        if (params.centerId) {
          this.filters.centerId = parseInt(params.centerId);
        }
        if (params.studyId) {
          this.filters.studyId = parseInt(params.studyId);
        }
        if (params.course) {
          this.filters.course = parseInt(params.course);
        }
        if (params.name) {
          this.folderName = params.name;
        }
      }
    });

    this.init();
  }

  init() {
    this.utilsService.loading("open");

    setTimeout(() => {
      this.sponsorSubjectService
        .getFolderWidgets(this.filters)
        .then((response) => {
          this.widgets = response;
          this.utilsService.loading("close");
        })
        .catch((error) => {
          this.widgets = [];
          this.utilsService.loading("close");
        });
    }, 200);
  }

  handleWidgetCreation() {
    this.config = {
      actionText: null,
      actionUrl: null,
      backgroundUrl: null,
      type: null,
      centerId: this.filters.centerId,
      course: this.filters.course,
      description: null,
      folderId: this.filters.folderId,
      name: null,
      status: 0,
      studyId: this.filters.studyId,
      subtitle: null,
      title: null,
      userId: null,
    };

    this.fromCopy = false;
    this.mode = "create";
  }

  openSlide(name: string, mode: string, index?: number) {

    this.slidesController[name].slide = true;

    this.slideMode = mode;

    switch (name) {
      case "contacts":
        this.slideItemObject = {
          name: null,
          value: null,
          color: null,
          backgroundColor: null,
          iconUrl: null,
          iconType: null,
          actionUrl: null,
        };
        break;

      case "buttons":
        this.slideItemObject = {
          name: null,
          value: null,
          color: null,
          backgroundColor: null,
          iconUrl: null,
          iconType: null,
          actionUrl: null,
        };
        break;

      case "features":
        this.slideItemObject = {
          title: null,
          description: null,
          color: null,
          backgroundColor: null,
          data: null,
          highlight: 1,
          assetUrl: null,
          actionText: null,
          actionUrl: null,
        };
        break;

      case "ads":
        this.slideItemObject = {
          creativeId: this.config.id,
          orderId: this.config.userId,
          lineItemId: this.filters.folderId,
          ubication: 32,
          viewUrl: null,
          clickUrl: null,
          pixelUrl: null,
          imgLandscapeLateralUrl: null,
          imgLandscapeTopUrl: null,
          imgLandscapeUrl: null,
          imgPortraitLateralUrl: null,
          imgPortraitTopUrl: null,
          imgPortraitUrl: null,
          imgUrl: null,
          type: null,
        };
        break;
    }

    if (mode === "edition") {
      this.slideItemIndex = index;
      this.slideItemObject = this.config[name][index];
    }

    setTimeout(() => {
      this.slidesController[name].open = true;
    }, 200);
  }

  closeSlide(name: string) {
    this.slidesController[name].open = false;

    setTimeout(() => {
      this.slidesController[name].slide = false;

      this.slideMode = null;
      this.slideItemIndex = null;
      this.slideItemObject = null;
    }, 200);
  }

  send() {
    this.utilsService.loading("open");
    this.config.userId = parseInt(this.config.userId);

    if (!this.config.userId || isNaN(this.config.userId)) {
      this.utilsService.loading("close");
      this.utilsService.presentMessage("Debes indicar un ID de usuario");
      return false;
    }

    if (this.mode === "create") {
      this.sponsorSubjectService
        .createWidget(this.config)
        .then((response) => {
          if (!this.widgets) {
            this.widgets = [];
          }

          if (this.fromCopy) {
            if (
              this.config.features.length ||
              this.config.contacts.length ||
              this.config.buttons.length ||
              this.config.ads.length
            ) {
              this.createSecondaryItems(response);
            }
          } else {
            this.widgets.push(response);
            this.openWidget(response.id, this.widgets.length - 1);
            this.utilsService.loading("close");
          }
        })
        .catch((error) => {
          this.utilsService.presentMessage("Ha ocurrido un error");
          this.utilsService.loading("close");
        });
    } else {
      this.sponsorSubjectService
        .updateWidget(this.widgets[this.selectedWidget].id, this.config)
        .then((response) => {
          this.openWidget(response.widget.id, this.selectedWidget);
        })
        .catch((error) => {
          this.utilsService.presentMessage("Ha ocurrido un error");
          this.utilsService.loading("close");
        });
    }
  }

  async createSecondaryItems(newitem: any) {
    if (this.config.contacts.length) {
      for (let i = 0; i < this.config.contacts.length; i++) {
        await this.sponsorSubjectService.addWidgetProperty(
          "contact",
          newitem.id,
          this.config.contacts[i]
        );
      }
    }

    if (this.config.buttons.length) {
      for (let i = 0; i < this.config.buttons.length; i++) {
        await this.sponsorSubjectService.addWidgetProperty(
          "button",
          newitem.id,
          this.config.buttons[i]
        );
      }
    }

    if (this.config.features.length) {
      for (let i = 0; i < this.config.features.length; i++) {
        await this.sponsorSubjectService.addWidgetProperty(
          "feature",
          newitem.id,
          this.config.features[i]
        );
      }
    }

    if (this.config.ads.length) {
      for (let i = 0; i < this.config.ads.length; i++) {
        await this.sponsorSubjectService.addWidgetProperty(
          "ad",
          newitem.id,
          this.config.ads[i]
        );
      }
    }

    this.widgets.push(newitem);
    this.openWidget(newitem.id, this.widgets.length - 1);
  }

  toggleWidgetStatus() {
    this.utilsService.loading("open");

    const data = {
      status: 0,
    };

    if (this.config.status === 0) {
      data.status = 1;
    }

    this.sponsorSubjectService
      .updateWidget(this.widgets[this.selectedWidget].id, data)
      .then((response) => {
        this.widgets[this.selectedWidget] = Object.assign({}, response.widget);
        this.config = Object.assign({}, response.widget);
        this.utilsService.loading("close");
      })
      .catch((error) => {
        this.utilsService.presentMessage("Ha ocurrido un error");
        this.utilsService.loading("close");
      });
  }

  openWidget(id: number, index: number) {
    this.utilsService.loading("open");

    this.sponsorSubjectService
      .getFolderWidget(id)
      .then((response) => {
        this.selectedWidget = index;
        this.mode = "edit";
        this.config = response;
        this.utilsService.loading("close");
      })
      .catch((error) => {
        this.utilsService.loading("close");
        this.utilsService.presentMessage("Ha ocurrido un error");
      });
  }

  async handleSend(property: string) {
    this.utilsService.loading("open");

    if (this.slideMode === "creation") {
      this.sponsorSubjectService
        .addWidgetProperty(property, this.config.id, this.slideItemObject)
        .then((response) => {
          this.config[property + "s"].push(response);
          this.closeSlide(property + "s");
          this.utilsService.loading("close");
        })
        .catch(() => {
          this.utilsService.loading("close");
          this.utilsService.presentMessage("Ha ocurrido un error");
        });
    } else {
      this.sponsorSubjectService
        .updateWidgetProperty(
          property,
          this.config.id,
          this.config[property + "s"][this.slideItemIndex].id,
          this.slideItemObject
        )
        .then((response) => {
          this.config[property + "s"][this.slideItemIndex] = response[property];
          this.closeSlide(property + "s");
          this.utilsService.loading("close");
        })
        .catch(() => {
          this.utilsService.loading("close");
          this.utilsService.presentMessage("Ha ocurrido un error");
        });
    }
  }

  handleDelete(property: string, index: number) {
    this.utilsService.loading("open");

    this.sponsorSubjectService
      .deleteWidgetProperty(
        property,
        this.config.id,
        this.config[property + "s"][index].id
      )
      .then(() => {
        this.config[property + "s"].splice(index, 1);
        this.utilsService.loading("close");
      })
      .catch(() => {
        this.utilsService.loading("close");
        this.utilsService.presentMessage("Ha ocurrido un error");
      });
  }

  copy() {
    const copy = Object.assign({}, this.config);
    delete copy.id;
    this.storageService.write("copy-subject-sponsor", copy);
    this.utilsService.presentMessage("Widget copiado.");
  }

  checkCopy() {
    const copy = this.storageService.read("copy-subject-sponsor");
    return typeof copy !== "undefined" && copy;
  }

  handleWidgetCreationFromCopy() {
    this.config = this.storageService.read("copy-subject-sponsor");
    delete this.config.id;
    this.config.status = 0;

    this.config.folderId = this.filters.folderId;
    this.config.centerId = this.filters.centerId;
    this.config.studyId = this.filters.studyId;
    this.config.course = this.filters.course;

    this.fromCopy = true;
    this.mode = "create";
  }

  openMediaInput(mode: string) {
    this.uploadingMode = mode;
    this.mediaInput.nativeElement.click();
  }

  openMediaInputAddon(mode: string) {
    this.uploadingModeAddon = mode;
    this.mediaInputAddons.nativeElement.click();
  }

  handleFile(event: any) {
    this.utilsService.loading("open");

    const data: any = {};
    const file = event.target.files[0];
    const lastDot = file.name.lastIndexOf(".");
    const extension = file.name.substring(lastDot + 1);

    data[this.uploadingMode] = {
      extension: extension,
      contentType: file.type,
    };

    this.sponsorSubjectService
      .updateWidget(this.widgets[this.selectedWidget].id, data)
      .then((response) => {
        this.uploadToS3(response[this.uploadingMode + "Url"], file.type, file);

        this.widgets[this.selectedWidget] = Object.assign({}, response.widget);
        this.config = Object.assign({}, response.widget);

        this.mediaInput.nativeElement.value = null;

        this.utilsService.loading("close");
      })
      .catch((error) => {
        this.utilsService.presentMessage("Ha ocurrido un error");
        this.utilsService.loading("close");
      });
  }

  async handleFileAddon(event: any) {

    await this.handleSend(this.uploadingModeAddon.split('_')[0]).then(() => {
      this.utilsService.loading("open");

      const modes = this.uploadingModeAddon.split("_");

      const data: any = {};
      const file = event.target.files[0];
      const lastDot = file.name.lastIndexOf(".");
      const extension = file.name.substring(lastDot + 1);

      data[modes[1]] = {
        extension: extension,
        contentType: file.type,
      };

      this.sponsorSubjectService
        .updateWidgetProperty(
          modes[0],
          this.config.id,
          this.config[modes[0] + "s"][this.slideItemIndex].id,
          data
        )
        .then((response) => {
          this.uploadToS3(response[modes[1] + "Url"], file.type, file);
          this.slideItemObject = response[modes[0]];
          this.config[modes[0] + "s"][this.slideItemIndex] = response[modes[0]];

          this.mediaInputAddons.nativeElement.value = null;

          this.utilsService.loading("close");
        })
        .catch(() => {
          this.utilsService.loading("close");
          this.utilsService.presentMessage("Ha ocurrido un error");
        });
    });
  }

  async uploadToS3(url: string, type: any, file: any) {
    const response = await this.sponsorSubjectService.uploadToS3(
      url,
      type,
      file
    );
  }

  getAdName(ubicationId: number, index: number) {
    const ubications = {
      1: "PDF A4",
      2: "PDF LATERAL",
      3: "PDF FOOTER",
      17: "PDF PORTADA",
      32: "PATROCINIO PDF",
    };

    if (ubicationId === 32) {
      return this.config.ads[index].type === 'BANNER' ? 'PATROCINIO BANNER' : 'PATROCINIO PDF';
    }

    return ubications[ubicationId];
  }

  oneActive(widget) {
    let res = false;
    for (let w of this.widgets) {
      if (
        w?.status === 1 &&
        JSON.stringify(w) !== JSON.stringify(widget) &&
        widget?.status === 0
      ) {
        res = true;
      }
    }
    return res;
  }

  deleteWidget(key?) {
    if (!key) {
      this.deleteWidgetProperty = true;
      return;
    }

    if (key === 'cancel') {
      this.deleteWidgetProperty = false;
    }

    if (key === 'delete') {
      this.sponsorSubjectService
        .deleteWidget(
          this.config.id
        )
        .then(() => {
          this.deleteWidgetProperty = false;
          this.utilsService.loading("close");
          this.selectedWidget = -1;
          this.ngOnInit();
        })
        .catch(() => {
          this.deleteWidgetProperty = false;
          this.utilsService.loading("close");
          this.utilsService.presentMessage("Ha ocurrido un error");
        });

    }
  }
}
