<div class="container">
  <div class="asset">
    <i class="material-icons-outlined">close</i>
  </div>

  <h1>Algo ha ido mal.</h1>

  <p>
    ¿puedes volver a intentarlo?, seguro que lo conseguimos.
  </p>

  <app-button-item routerLink="/" [text]="'Volver a intentar'" [solid]="true"></app-button-item>
</div>