import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from "@angular/router";
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-pixel-modal',
  templateUrl: './pixel-modal.component.html',
  styleUrls: ['./pixel-modal.component.css']
})
export class PixelModalComponent implements OnInit {

  open = false;

  @Input("code") code: any;
  @Output() onClose = new EventEmitter();

  constructor(
    public router: Router,
    public utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.open = true;
    }, 100);
  }

  close() {
    this.open = false;

    setTimeout(() => {
      this.onClose.emit();
    }, 150);
  }

  copy() {

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.utilsService.presentAlertMessage({
      title: 'Copiado.',
      description: 'Se ha copiado el código.',
      error: false,
      duration: 6
    });
  }

}
