<nav *ngIf="openModal">
  <app-pixel-modal (onClose)="openModal = false" [code]="code"></app-pixel-modal>
</nav>
<div class="body-container">
  <h1 class="wds-h1">Analytics</h1>
  <section *ngIf="pixelMe">
    <div class="period-capsule">
      <div class="period-capsule-item">
        <div (click)="setPeriod(7); openPersonalize = false" class="period-item"
          [ngClass]="{'selected': periodSelected === 7 && !openPersonalize}">7 días</div>
        <div (click)="setPeriod(14); openPersonalize = false" class="period-item"
          [ngClass]="{'selected': periodSelected === 14 && !openPersonalize}">14 días</div>
        <div (click)="setPeriod(30); openPersonalize = false" class="period-item"
          [ngClass]="{'selected': periodSelected === 30 && !openPersonalize}">30 días</div>
        <div class="period-item ngx-daterangepicker-action"
          [ngClass]="{'selected': (openPersonalize || (periodSelected !== 7 && periodSelected !== 14 && periodSelected !== 30) ) }"
          (click)="openDatepicker()">
          Personalizar
        </div>
      </div>
    </div>
    <input style="display: none;" ngxDaterangepickerMd class="input-period" [(ngModel)]="dateRange"
      [closeOnAutoApply]="true" [autoApply]="true" placeholder="Selecciona un rango" [linkedCalendars]="true"
      (datesUpdated)="setPersonalized(); openPersonalize = false"
      [locale]="{format: 'DD-MM-YYYY', separator: ' a ', firstDay: 1, daysOfWeek: days, monthNames: months}">

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{
      'width': '100%',
      'height': '92px',
      'background': '#f8f8f8',
      'border-radius': '8px',
      'margin-top': '17px',
      'margin-bottom': '41px'}">
    </ngx-skeleton-loader>

    <div class="analysis-table" *ngIf="firstReport">
      <div class="analysis-table-item">
        <div>
          <p class="analysis-table-item-title">Visitantes actuales</p>
          <div class="horizontal-line" style="background-color: #fbfbfb;"></div>
        </div>
        <p class="analysis-table-item-value">{{pixelData?.visitors?.visitors}}</p>
        <p class="negative-value"></p>
      </div>
      <div class="line"></div>
      <div class="analysis-table-item">
        <div>
          <p class="analysis-table-item-title">Vistas únicas</p>
          <div class="horizontal-line" style="background-color: #CFDDF9;"></div>
        </div>
        <p class="analysis-table-item-value">{{selected?.Uniques}}</p>
        <p [ngClass]="calculator?.positive?.uniques ? 'positive-value' : 'negative-value'">
          {{calculator?.subtract?.uniques}}</p>
      </div>
      <div class="line"></div>
      <div class="analysis-table-item">
        <div>
          <p class="analysis-table-item-title">Páginas vistas del sitio</p>
          <div class="horizontal-line" style="background-color: #6F85B2;"></div>
        </div>
        <p class="analysis-table-item-value">{{selected?.Views}}</p>
        <p [ngClass]="calculator?.positive?.views ? 'positive-value' : 'negative-value'">
          {{calculator?.subtract?.views}}
        </p>
      </div>
    </div>

    <div class="side-chart">
      <div class="chart-container">
        <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{ 
          'border-radius': '8px',
          'height': '250px',
          'width': '100%',
          'background-color': '#f8f8f8',
          'margin': '0'
        }"></ngx-skeleton-loader>

        <highcharts-chart *ngIf="firstReport" [Highcharts]="Highcharts" [options]="chartOptionsAnalysis"
          style="width: 100%; height: 300px; display: block;" [(update)]="updateFlag" [oneToOne]="oneToOneFlag">
        </highcharts-chart>
      </div>
    </div>

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{
      'width': '100%',
      'height': '200px',
      'background': '#f8f8f8',
      'border-radius': '8px',
      'margin': '40px 0 0 0'}">
    </ngx-skeleton-loader>

    <div class="tables-container" *ngIf="firstReport">
      <div class="tables-row">
        <div class="table-container">
          <div class="table-description">
            <p class="table-title"><strong>Referers</strong></p>
            <p class="table-subtitle">Muestra la dirección web desde la que se está accediendo</p>
          </div>
          <div class="table">
            <div class="table-header">

              <span>
                Referer
              </span>
              <span>
                Vistas únicas
              </span>

            </div>

            <div class="table-height">
              <table>
                <tr *ngFor="let result of pixelData?.referer">
                  <td [ngStyle]="selected===result ? {'color': '#fbfbfb'} : {}">
                    <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                      [style.width]="(result?.uniques / pixelData?.referer[0]?.uniques * 100) + '%'"></span>
                    {{(result?.referer !== null ? (result?.referer.includes('/') ? result?.referer : result?.referer |
                    titlecase) : '(not set)')}}
                  </td>
                  <td>
                    {{result?.uniques}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="table-container">
          <div class="table-description">
            <p class="table-title"><strong>Sistema Operativo</strong></p>
            <p class="table-subtitle">Muestra el sistema operativo utilizado por el usuario para entrar en el sitio web
            </p>
          </div>
          <div class="table">
            <div class="table-header">
              <span>
                Tipo de dispositivo
              </span>
              <span>
                Vistas únicas
              </span>
            </div>

            <div class="table-height">
              <table>
                <tr *ngFor="let result of pixelData?.os">
                  <td [ngStyle]="selected===result ? {'color': '#fbfbfb'} : {}">
                    <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                      [style.width]="(result?.Uniques / pixelData?.os[0]?.Uniques * 100) + '%'"></span>
                    {{result?.os !== null ? result?.os : '(not set)'}}
                  </td>
                  <td>
                    {{result?.Uniques}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="table-container">
          <div class="table-description">
            <p class="table-title"><strong>Navegador</strong></p>
            <p class="table-subtitle">Navegador desde el cual el usuario entra en su página web</p>
          </div>
          <div class="table">
            <div class="table-header">
              <span>
                Navegador
              </span>
              <span>
                Vistas únicas
              </span>

            </div>

            <div class="table-height">
              <table>
                <tr *ngFor="let result of pixelData?.browser">
                  <td [ngStyle]="selected===result ? {'color': '#fbfbfb'} : {}">
                    <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                      [style.width]="(result?.Uniques / pixelData?.browser[0]?.Uniques * 100) + '%'"></span>
                    {{result?.browser !== null ? result?.browser : '(not set)'}}
                  </td>
                  <td>
                    {{result?.Uniques}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="table-container">
          <div class="table-description">
            <p class="table-title"><strong>País</strong></p>
            <p class="table-subtitle">País desde el cual se está accediendo</p>
          </div>
          <div class="table">
            <div class="table-header">
              <span>
                País
              </span>
              <span>
                Vistas únicas
              </span>

            </div>

            <div class="table-height">
              <table>
                <tr *ngFor="let result of pixelData?.country">
                  <td [ngStyle]="selected===result ? {'color': '#fbfbfb'} : {}">
                    <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                      [style.width]="(result?.Uniques / pixelData?.country[0]?.Uniques * 100) + '%'"></span>
                    {{result?.country !== null ? result?.country : '(not set)'}}
                  </td>
                  <td>
                    {{result?.Uniques}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="third-row">
        <div class="table-container">
          <div class="table-description">
            <p class="table-title"><strong>UTMs</strong></p>
            <p class="table-subtitle">Parámetros que se añaden a tu enlace y te permiten conocer mejor el tráfico de la
              web</p>
          </div>
          <div class="table">
            <div class="header-mobile">
              <div (click)="changeTable('source')"
                [ngClass]="change==='source' ? 'selected-table' : 'unselected-table'">
                Fuente
              </div>
              <div (click)="changeTable('medium')"
                [ngClass]="change==='medium' ? 'selected-table' : 'unselected-table'">
                Medio
              </div>
              <div (click)="changeTable('campaign')"
                [ngClass]="change==='campaign' ? 'selected-table' : 'unselected-table'">
                Campaña
              </div>
            </div>
            <div class="table-header-last">
              <div class="change-table-container">
                <div (click)="changeTable('source')"
                  [ngClass]="change==='source' ? 'selected-table' : 'unselected-table'">
                  Fuente
                </div>
                <div (click)="changeTable('medium')"
                  [ngClass]="change==='medium' ? 'selected-table' : 'unselected-table'">
                  Medio
                </div>
                <div (click)="changeTable('campaign')"
                  [ngClass]="change==='campaign' ? 'selected-table' : 'unselected-table'">
                  Campaña
                </div>
              </div>

              <span>
                Páginas vistas
              </span>
              <span>
                Vistas únicas
              </span>
            </div>
            <div class="table-header-last-mobile">
              <div class="change-table-container">
                <div *ngIf="change === 'source'" class="selected-table-mobile">
                  Fuente
                </div>
                <div *ngIf="change === 'medium'" class="selected-table-mobile">
                  Medio
                </div>
                <div *ngIf="change === 'campaign'" class="selected-table-mobile">
                  Campaña
                </div>
              </div>

              <span>
                Páginas vistas
              </span>
              <span>
                Vistas únicas
              </span>
            </div>

            <div class="table-height">
              <table>
                <ng-container *ngIf="change==='source'">
                  <tr *ngFor="let result of pixelData?.source">
                    <td colspan="2" class="maxWidth" [ngStyle]="selected===result ? {'color': '#FBFBFB'} : {}">
                      <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                        [style.width]="(result?.Uniques / pixelData?.source[0]?.Uniques * 100) + '%'"></span>
                      {{result?.source}}
                    </td>
                    <td>
                      {{result?.Views}}
                    </td>
                    <td>
                      {{result?.Uniques}}
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="change==='medium'">
                  <tr *ngFor="let result of pixelData?.medium">
                    <td colspan="2" class="maxWidth" [ngStyle]="selected===result ? {'color': '#FBFBFB'} : {}">
                      <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                        [style.width]="(result?.Uniques / pixelData?.medium[0]?.Uniques * 100) + '%'"></span>
                      {{result?.medium}}
                    </td>
                    <td>
                      {{result?.Views}}
                    </td>
                    <td>
                      {{result?.Uniques}}
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="change==='campaign'">
                  <tr *ngFor="let result of pixelData?.campaign">
                    <td colspan="2" class="maxWidth" [ngStyle]="selected===result ? {'color': '#FBFBFB'} : {}">
                      <span class="row-background" [ngClass]="selected===result ? 'selected' : 'unselected'"
                        [style.width]="(result?.Uniques / pixelData?.campaign[0]?.Uniques * 100) + '%'"></span>
                      <span>{{result?.campaign}}</span>
                    </td>
                    <td>
                      {{result?.Views}}
                    </td>
                    <td>
                      {{result?.Uniques}}
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-code">
      <p>¿Necesitas volver a copiar el código?</p>
      <app-button-item [disabled]="!code" (click)="openModal = true" [text]="'Obtener el código'" [background]="'#102754'"
            [color]="'#FFFFFF'" [solid]="true"></app-button-item>
    </div>
  </section>
  <section *ngIf="pixelMe===false">
    <div class="splitted-content">
      <div class="left-side">
        <h4 class="wds-h4">¿Qué es el píxel de Wuolads?</h4>

        <p>El píxel de Wuolads es un sistema propio que nos permite trackear de
          forma directa, sin intermediarios, las visitas que se producen al sitio donde se integre.</p>

        <p>Con un seguimiento de las UTMs, las páginas de referencia, etcétera.</p>

        <p>Eliminamos la dependencia de terceros y dotamos de versatilidad y personalización a los
          informes que ofrecemos a nuestros clientes. Y por supuesto total transparencia y acceso
          a los datos que se recogen.</p>
      </div>

      <div class="right-side">
        <h4 class="wds-h4">Código del píxel</h4>

        <p>Debes añadir este código en dentro de las etiquetas <span>&lt;head&gt;&lt;/head&gt;</span> de tu sitio web.
        </p>

        <div class="code-box">
          {{code}}
        </div>

        <div class="button-row">
          <app-button-item [disabled]="!code" (click)="copy()" [text]="'Copiar código'" [background]="'#102754'"
            [color]="'#FFFFFF'" [solid]="true"></app-button-item>
        </div>
      </div>
    </div>
  </section>
</div>