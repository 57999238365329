import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MDCTextField } from '@material/textfield';

@Component({
  selector: 'app-input-item',
  templateUrl: './input-item.component.html',
  styleUrls: ['./input-item.component.css']
})
export class InputItemComponent implements OnInit {

  counter: number = 0;
  uuid: number;

  @Input() data: any;
  @Input() type: string = 'text';
  @Input() label: string;
  @Input() helpText: string;
  @Input() helpStatus: string;
  @Input() maxLength: number;
  @Input() debounce: number;
  @Input() disabled: Boolean = false;
  @Input() noMargin: Boolean;
  @Input() pattern: any = '';
  @Input() name: any = '';

  @Output() dataChange = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() enterAction = new EventEmitter();

  @ViewChild('mtfInput', {static: false}) mtfInput: any;

  constructor() {
    this.uuid = new Date().getTime() - (Math.floor(Math.random() * 20));
  }

  ngOnInit() {
    setTimeout(() => {
      const textField = new MDCTextField(this.mtfInput.nativeElement);
    }, 100);
  }

  emitData() {
    if (this.maxLength) {
      if (this.data && this.data.length > this.maxLength) {
        this.helpStatus = 'danger';
      } else {
        this.helpStatus = '';
      }
    }

    this.dataChange.emit(this.data);
  }

  onBlurEmit(){
    this.onBlur.emit(true);
  }

  onKeyEnter(){
    this.enterAction.emit(true);
  }

}
