import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UtilsService } from "src/app/services/utils.service";
import { BillingService } from "src/app/services/billing.service";
import { StorageService } from "src/app/services/storage.service";
import { AnalyticService } from 'src/app/services/analytic.service';
import { UserService } from 'src/app/services/user.service';
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';

import { FormGroup, } from "@angular/forms";

import {
  StripeService,
  StripeCardComponent,
} from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard-sponsor-payment",
  templateUrl: "./dashboard-sponsor-payment.component.html",
  styleUrls: ["./dashboard-sponsor-payment.component.css"],
})
export class DashboardSponsorPaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  paymentData = {
    payment: null,
    user: null,
  };

  errorMessages = [];
  error = false;

  paymentItems = [
    {
      name: "Pago Anual",
      price: 19.99,
      feature: 1,
      discount: 0.25,
      choosen: false,
    },
    {
      name: "Pago Mensual",
      price: 19.99,
      feature: 0,
      discount: null,
      choosen: false,
    },
  ];

  paymentItem = {
    name: null,
    price: null,
    feature: 0,
    discount: null,
    choosen: false,
  };

  user = {
    name: null,
    surname: null,
    address: null,
    postcode: null,
    city: null,
    creditCard: null,
    expiration: null,
    cvc: null,
  };

  checkMethodStatus = false;

  noCards = true;

  addNewCard = false;

  paymentMethod = {
    defaultMethod: false,
    cardToken: null,
  };

  userPaymentMethod = [];

  plans: any;

  cardToken = null;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {},
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "es",
  };

  stripeTest: FormGroup;

  userId = this.storageService.read<any>("userId");
  
  uuid: string;
  data: any;

  config = [];
  contacts = [];
  buttons = [];
  features = [];
  ads: any;

  signaturesLocal: any;
  dataGeneralInformationLocal: any;
  buttonsLocal: any;
  contactsLocal: any;
  featuresLocal: any;

  widget: any;

  constructor(
    private router: Router,
    public utilsService: UtilsService,
    public billingService: BillingService,
    public storageService: StorageService,
    private stripeService: StripeService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public userService: UserService,
    public sponsorSubjectService: SponsorSubjectService,
  ) {
    this.paymentItems[0].choosen = true;
    this.throwPaymentData();

    this.billingService.getPlans().then((response) => {
      this.plans = response;
    });

    this.billingService.getUserPaymentMethod(this.storageService.read<any>("userId")).then((response) => {
      if (response) {
        this.userPaymentMethod = response;
        if (this.userPaymentMethod.length > 0) {
          this.noCards = false;
        }
      }
    });



    this.userId = this.storageService.read('userMe');
    this.uuid = this.storageService.read('uuid');
    this.data = this.storageService.read('advertiserMe');

    this.widget = this.storageService.read('widgets');
    
  }
  
  ngOnInit() {
    
    const user: any = this.storageService.read('userMe');
    
    this.titleService.setTitle('Pago del patrocinio de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/subject/4', 'Subject sponsorship payment');
    this.analyticService.screenView('Subject sponsorship payment');

    this.titleService.setTitle('Pago del patrocinio de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/subject/4', 'Subject sponsorship payment');
    this.analyticService.screenView('Subject sponsorship payment');

      
    this.analyticService.customEvent(
      'UP2_03_2_VISIT',
      {
        uuid: this.uuid,
        userId: this.userId,
      }
    );

    let checkStorageSignatures = JSON.parse(localStorage.getItem("signatures"));
    let checkStorageGeneral = JSON.parse(
      localStorage.getItem("dataGeneralInformation")
    );
    let checkStorageButtons = JSON.parse(localStorage.getItem("buttons"));
    let checkStorageContacts = JSON.parse(localStorage.getItem("contacts"));
    let checkStorageFeatures = JSON.parse(localStorage.getItem("features"));
    

    if (checkStorageSignatures == null) {
      this.router.navigate(["dashboard/sponsor/subject/1"]);
    } else if (
      checkStorageGeneral == null ||
      checkStorageButtons == null ||
      checkStorageContacts == null ||
      checkStorageFeatures == null
    ) {
      this.router.navigate(["dashboard/sponsor/subject/2"]);
    }
  }

  updateWidgetProperty(widget) {

    let featureLength = this.features.length;
    let widgetLength = widget.features.length;
    let diff = widgetLength - featureLength;
    if (featureLength === widgetLength) {
      for (let i = 0; i < this.features.length; i++) {
        this.sponsorSubjectService.updateWidgetProperty('feature', widget.id, widget.features[i].id, this.features[i]);
      }
    }else if(featureLength < widgetLength){
    
      for (let i = 1; i <= diff; i++) {
        this.sponsorSubjectService.deleteWidgetProperty('feature', widget.id, widget.features[widgetLength - i].id);
        
      }
      for (let i = 0; i < featureLength; i++) {
        this.sponsorSubjectService.updateWidgetProperty('feature', widget.id, widget.features[i].id, this.features[i]);
        
      }

    }else {
      for (let i = 1; i <= Math.abs(diff); i++) {
        this.sponsorSubjectService.addWidgetProperty('feature', widget.id, this.features[featureLength - i]);
        
      }
      for (let i = 0; i < widgetLength; i++) {
        this.sponsorSubjectService.updateWidgetProperty('feature', widget.id, widget.features[i].id, this.features[i]);
        
      }
    }

  }

  checkCard(card) {
    let checked = false;
    if (card?.default && card?.default === true) {
      checked = true;
    }
    return checked;
  }

  setDefaultCard(card) {
    if (!card?.default || card?.default === false) {
      card.default = true;
      this.billingService.updatePaymentMethod(true, card);
      for (let c of this.userPaymentMethod.filter(x => x.default === true && x !== card)) {
        c.default = false;
      }
    }
  }

  selectImage(brand: string) {
    if (brand === "visa") {
      return "./../../../assets/img/visa.svg";
    } else if (brand === "mastercard") {
      return "./../../../assets/img/mastercard.svg";
    } else if (brand === "cartes_bancaires") {
      return "./../../../assets/img/cartes_bancaires.svg";
    } else if (brand === "amex") {
      return "./../../../assets/img/americanexpress.svg";
    }
  }

  async createToken() {
    if ((this.user?.name === null || this.user?.name === undefined) || (this.user?.surname === null || this.user?.surname === undefined)) {
      this.utilsService.presentAlertMessage({
        title: "Introduce un nombre y apellido.",
        error: true,
      });
      this.error = true;
    } else {
      const name = this.user?.name + " " + this.user?.surname;
      await this.stripeService
        .createToken(this.card?.element, { name })
        .subscribe((result) => {
          if (result?.token) {
            this.cardToken = result?.token?.id;
          } else if (result.error?.message === "El número de tu tarjeta está incompleto.") {
            this.error = true;
            this.utilsService.presentAlertMessage({
              title: "El número de tu tarjeta está incompleto",
              error: true,
            });
          } else if (result.error?.message === "La fecha de caducidad de tu tarjeta está incompleta.") {
            this.error = true;
            this.utilsService.presentAlertMessage({
              title: "La fecha de caducidad de tu tarjeta está incompleta.",
              error: true,
            });
          } else if (result.error?.message === "El código de seguridad de tu tarjeta está incompleto.") {
            this.error = true;
            this.utilsService.presentAlertMessage({
              title: "El código de seguridad de tu tarjeta está incompleto.",
              error: true,
            });
          } else if (result.error?.message === "Tu código postal está incompleto.") {
            this.error = true;
            this.utilsService.presentAlertMessage({
              title: "Tu código postal está incompleto.",
              error: true,
            });
          }
        });
    }
  }

  createPaymentMethod() {
    return new Promise((resolve, reject) => {
      this.createToken().then(() => {
        if (this.userPaymentMethod.length <= 0) {
          this.checkMethodStatus = true;
        }
        
        setTimeout(() => {
          if (!this.error) {
            this.billingService
              .createPaymentMethod(this.checkMethodStatus, this.cardToken)
              .then(() => {
                if (this.addNewCard = true) {
                  this.addNewCard = false;
                  this.billingService.getUserPaymentMethod(this.storageService.read<any>("userId")).then((response) => {
                    this.userPaymentMethod = response;
                    resolve(true);
                  });
                }
              });
          }
        }, 900);
      });
    });
  }

  async createWidget() {
    this.signaturesLocal = this.storageService.read('signatures');
    this.dataGeneralInformationLocal = this.storageService.read('dataGeneralInformation');
    this.buttonsLocal = this.storageService.read('buttons');
    this.contactsLocal = this.storageService.read('contacts');
    this.featuresLocal = this.storageService.read('features');

    for (let i = 0; i < this.featuresLocal.length; i++) {
      if (this.featuresLocal[i].highlight === 1) {
        this.features.push({
          title: this.featuresLocal[i]?.title,
          description: this.featuresLocal[i]?.description,
          color: null,
          backgroundColor: null,
          data: this.featuresLocal[i]?.data,
          highlight: 1,
          assetUrl: this.featuresLocal[i]?.assetUrl,
          actionText: this.featuresLocal[i]?.actionText,
          actionUrl: this.featuresLocal[i]?.actionUrl,
        });
      }
      
    }
    
    for (let i = 0; i < this.signaturesLocal.length; i++) {
      await this.sponsorSubjectService.getWidget(this.widget[i].id).then((response) => {
        this.updateWidgetProperty(response);
      });
    }

    setTimeout(() => {
      
      for (let i = 0; i < this.signaturesLocal.length; i++) {
        
        

        this.config[i] = {
          actionText: this.dataGeneralInformationLocal?.actionText,
          actionUrl: this.dataGeneralInformationLocal?.actionUrl,
          backgroundUrl: this.dataGeneralInformationLocal?.backgroundUrl,
          centerId: this.signaturesLocal[i]?.centerId,
          course: this.signaturesLocal[i]?.course,
          description: this.dataGeneralInformationLocal?.description,
          folderId: this.signaturesLocal[i]?.folderId,
          logoUrl: this.dataGeneralInformationLocal?.logoUrl,
          name: this.dataGeneralInformationLocal?.name,
          primaryBgColor: null,
          primaryColor: null,
          secondaryBgColor: null,
          secondaryColor: null,
          status: 1,
          studyId: this.signaturesLocal[i]?.studyId,
          subtitle: this.dataGeneralInformationLocal?.subtitle,
          title: this.dataGeneralInformationLocal?.title,
          userId: this.userId?.userId,
        };
  
        this.ads = {
          creativeId: null,
          orderId: this.userId?.userId,
          lineItemId: this.signaturesLocal[i]?.folderId,
          ubication: 32,
          viewUrl: null,
          clickUrl: null,
          pixelUrl: null,
          imgLandscapeLateralUrl: null,
          imgLandscapeTopUrl: null,
          imgLandscapeUrl: null,
          imgPortraitLateralUrl: null,
          imgPortraitTopUrl: null,
          imgPortraitUrl: null
        };

        try {

          this.sponsorSubjectService.updateWidget( this.widget[i].id,this.config[i]).then((response) => {
            for (let i = 0; i < this.buttonsLocal.length; i++) {
              
              this.sponsorSubjectService.addWidgetProperty('button', response.widget.id, this.buttonsLocal[i]);
              
            }

            for (let i = 0; i < this.contactsLocal.length; i++) {
              
              this.sponsorSubjectService.addWidgetProperty('contact', response.widget.id, this.contactsLocal[i]);
              
            }
          });
        }catch {
          this.utilsService.presentMessage('Ha ocurrido un error');
          this.utilsService.loading('close');
        }
      }
    }, 80);

    setTimeout(() => {
      this.storageService.remove('dataGeneralInformation');
      this.storageService.remove('buttons');
      this.storageService.remove('contacts');
      this.storageService.remove('features');
      this.storageService.remove('signatures');
      this.storageService.remove('dataUpload');
      this.storageService.remove('widgets');
      this.storageService.remove('croppedCreativities');
      this.utilsService.loading('close');
      this.router.navigate(['/landing/gracias']);
    }, 150);
  }

  subscribeToPlan() {
    let data = {
      priceId:
        this.paymentData?.payment?.name === "Pago Anual"
          ? this.plans[0]?.prices[0]?.id
          : this.plans[0]?.prices[1]?.id,
      trial: false,
    };
    this.billingService
      .subscribePlan(this.plans[0]?.id, data)
      .then((response) => {

        this.createWidget();

      });
  }

  pay() {
    this.utilsService.loading('open');

    if (this.userPaymentMethod.length > 0) {
      this.subscribeToPlan();
    } else {
      this.createPaymentMethod().then(() => {
        this.subscribeToPlan();
      });
    }
  }

  getFinalPrice(paymentItem) {
    let result = paymentItem?.price;
    if (paymentItem?.discount) {
      result = (
        paymentItem?.price -
        paymentItem?.price * paymentItem?.discount
      ).toFixed(2);
    }
    return result;
  }

  chooseItem(paymentItem) {
    this.paymentItems
      .filter((x) => x?.choosen === true)
      .map((X) => (X.choosen = false));
    paymentItem.choosen = true;
  }

  throwPaymentData() {
    this.paymentData = {
      payment: this.paymentItems.find((x) => x?.choosen === true),
      user: this.user,
    };
    this.utilsService.publish("paymentData", this.paymentData);
  }

  checkDisabled() {
    let result = Object.values(this.user).some((o) => o === null);
    return result;
  }
}
