import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-design-creatives-item',
  templateUrl: './design-creatives-item.component.html',
  styleUrls: ['./design-creatives-item.component.css']
})
export class DesignCreativesItemComponent implements OnInit {

  
  @ViewChild('div', {static: false}) div: ElementRef;
  @ViewChild('icon', {static: false}) icon: ElementRef;

  @Input('border') border: string = 'transparent';
  @Input('title') title: string;
  @Input('description') description: string;
  @Input('iconMaterial') iconMaterial: string;

  constructor() { }

  ngOnInit(): void {
  }

  active() {
    setTimeout (() => {
      if (this.border) {
        this.div.nativeElement.style.border = this.border;        
      }
    }, 80);
  }

}
