import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class CenterService extends RequestService {
  heavySearchCenters(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/center/search", params),
        { headers: this.headers() }
      )
    );
  }

  searchCenters(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/center", params),
        { headers: this.headers() }
      )
    );
  }
}
