import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestamp',
})
export class TimestampPipe implements PipeTransform {

  constructor() {}

  transform(timestamp: string) {
    return new Date(timestamp).toISOString().split('T')[0];
  }

  revert(dateString: string) {
    return (new Date(dateString)).getTime();
  }

  
}
