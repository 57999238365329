import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-academy-contact-item",
  templateUrl: "./academy-contact-item.component.html",
  styleUrls: ["./academy-contact-item.component.css"],
})
export class AcademyContactItemComponent implements OnInit {
  @Input("data") data: any;

  constructor() {}

  ngOnInit(): void {}
}
