import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { MDCRipple } from "@material/ripple";

@Component({
  selector: "app-button-item",
  templateUrl: "./button-item.component.html",
  styleUrls: ["./button-item.component.css"],
})
export class ButtonItemComponent implements OnInit, OnChanges {
  @ViewChild("button", { static: false }) button: ElementRef;

  @Input("disabled") disabled: boolean = false;
  @Input("outlined") outlined: boolean = false;
  @Input("solid") solid: boolean = false;
  @Input("onlyIcon") onlyIcon: boolean = false;
  @Input("background") background: string;
  @Input("color") color: string = "#FFFFFF";
  @Input("borderColor") borderColor: string;
  @Input("size") size: string;

  @Input("img") img: boolean = false;
  @Input("icon") icon: string;
  @Input("text") text: string;
  @Input("title") title: string;

  @Output("fireClick") fireClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    const buttonRipple = new MDCRipple(document.querySelector(".mdc-button"));
    if (!this.title && this.text) this.title = this.text;

    setTimeout(() => {
      if (this.solid) {
        this.button.nativeElement.style.background = this.background;
        this.button.nativeElement.style.color = this.color;
      }

      if (this.outlined) {
        this.button.nativeElement.style.color = this.color;
        this.button.nativeElement.style.borderColor = this.color;
      }

      if (this.borderColor) {
        this.button.nativeElement.style.borderColor = this.borderColor;
      }

      if (this.color) {
        this.button.nativeElement.style.color = this.color;
      }

      if (this.onlyIcon) {
        this.button.nativeElement.style.color = this.color;
      }

      if (this.size === 'small') {
        this.button.nativeElement.style.height = '32px';
        this.button.nativeElement.style.fontSize = '14px';
      }
    }, 150);
  }

  ngOnChanges() {
    const buttonRipple = new MDCRipple(document.querySelector(".mdc-button"));
    if (!this.title && this.text) this.title = this.text;

    setTimeout(() => {
      if (this.solid) {
        this.button.nativeElement.style.background = this.background;
        this.button.nativeElement.style.color = this.color;
      }

      if (this.outlined) {
        this.button.nativeElement.style.color = this.color;
        this.button.nativeElement.style.borderColor = this.color;
      }

      if (this.borderColor) {
        this.button.nativeElement.style.borderColor = this.borderColor;
      }

      if (this.color) {
        this.button.nativeElement.style.color = this.color;
      }

      if (this.onlyIcon) {
        this.button.nativeElement.style.color = this.color;
      }
    }, 150);
  }

  colorButton() {
    setTimeout(() => {
      this.button.nativeElement.style.color = this.color;
    }, 75);
  }

  throwClickEvent() {
    this.fireClick.emit();
  }
}
