import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnalyticService } from 'src/app/services/analytic.service';

// Services
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dashboard-admin-sponsor-subject',
  templateUrl: './dashboard-admin-sponsor-subject.component.html',
  styleUrls: ['./dashboard-admin-sponsor-subject.component.css']
})
export class DashboardAdminSponsorSubjectComponent implements OnInit {

  advertiserMe: any;

  searchData: any = {
    folder: null,
    folderId: null,
    centerId: null,
    studyId: null,
    course: null,
    studyType: null,
    limit: 25,
    offset: 0
  };

  folders: any;

  searchMode = 'widgets';

  constructor(
    public utilsService: UtilsService,
    public sponsorSubjectService: SponsorSubjectService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) {
    this.advertiserMe = this.storageService.read("advertiserMe");
  }
  
  ngOnInit(): void { 
    this.titleService.setTitle('Patrocinar asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'sponsor/subject', 'Subject sponsorship subject');
    this.analyticService.screenView('Subject sponsorship subject');

    this.search();
  }

  searchHandler(event) {
    let delay = 1000;
    if (typeof event === "undefined") {
      this.folders = [];
      this.search();
    } else {
      if (event?.keyCode == 13) {
        delay = 0;
      }
      if (event?.target === undefined) {
        this.folders = [];
        this.searchData.offset = 0;
        this.search();
      } else {
        this.manageInputDelay(delay, () => {
          this.folders = [];
          this.searchData.offset = 0;
          this.search();
        }, []);
      }
    }
  }

  manageInputDelay(delay, callback, args) {
    setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  }

  search() {
    this.utilsService.loading('open');
    if (this.searchMode === 'folders') {
      this.getGenericFolders();
    } else {
      setTimeout(() => {
        this.sponsorSubjectService.getFolderWidgets(this.searchData).then(
          (response) => {
            if (response.length > 0) {
              if (!this.folders) {
                this.folders = [];
              }
    
              this.folders = this.folders.concat(response);
              this.folders = this.folders.filter((object,index) => index === this.folders.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object)));

              this.utilsService.loading('close');
            } else {
              this.utilsService.loading('close');
            }
          }
        ).catch(
          (error) => {
            this.folders = [];
            this.utilsService.loading('close');
          }
        );
      }, 200);
    }
  }

  getGenericFolders() {
    this.utilsService.loading('open');
    const data = Object.assign({}, this.searchData);
    
    this.sponsorSubjectService.searchFolders(data).then(
      (response) => {
        if (response.length > 0) {
          if (!this.folders) {
            this.folders = [];
          }
          this.folders = this.folders.concat(response);
          this.folders = this.folders.filter((object,index) => index === this.folders.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object)));
        } 
        this.utilsService.loading('close');
      }
    ).catch(
      (error) => {
        this.folders = [];
        this.utilsService.loading('close');
      }
    );
    
  }

  resetSearch() {
    this.utilsService.loading('open');
    
    this.folders = null;
    this.searchData = {
      folderName: null,
      userId: null,
      folderId: null,
      id: null,
      centerId: null,
      studyId: null,
      course: null,
      limit: 25,
      offset: 0
    };

    this.search();
  }

  handleSearch() {
    this.searchData.offset = 0;
    this.folders = null;
    this.search();
  }

  toggleWidget() {
    if (this.searchData.status === 1) {
      delete this.searchData.status;
    } else {
      this.searchData.status = 1;
    }

    this.handleSearch();
  }

  onScroll() {
    this.searchData.offset = this.searchData.offset + this.searchData.limit;
    this.search();
  }
}
