<router-outlet></router-outlet>

<app-loading-item *ngIf="loading"></app-loading-item>

<app-message-item
  *ngIf="showMessage"
  [title]="message.title"
  [description]="message.description"
  [error]="message.error"
  (closeAction)="showMessage = false; message = null"
></app-message-item>

<app-contactus-modal
  *ngIf="showContactUs"
  (onClose)="showContactUs = false"
></app-contactus-modal>
