// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: 'https://api.wuolahlocal.com/wuolads',
  firebaseConfig: {
    apiKey: "AIzaSyBS8cf5QXzn_Ix-6RPS_cTkGAiuOGVUzDM",
    authDomain: "wuolah-dead4.firebaseapp.com",
    databaseURL: "https://wuolah-dead4.firebaseio.com",
    projectId: "wuolah-dead4",
    storageBucket: "wuolah-dead4.appspot.com",
    messagingSenderId: "423981129820",
    appId: "1:423981129820:web:9db092d02ef2ed6dea4699",
    measurementId: "G-QGCCPHFJHY"
  },
  stripeKey: "pk_live_mKCjwOKjBTM6lDKvGxHCbwDr",
  wdsStripeKey: 'pk_live_51IRCuyCZDAhvQ6LXlL1NKd8ctv1sZKzAA2tJOoEFyyyrDPrnosjIcMnARUsM9ZIfToUFxJ0lsoqm3gcfLquOWMfQ00AdDvuHyw',
  wdsClientsStripeKey: 'pk_live_51KlA7rKUum47S64x4CD3bGBRmVZrsbDTg4MHzFwy5IslI426U0O4PEwe2ngs1e02tH8X1VxSR2Xza3YUiOz7VIEt00mxNMSmFV',
  s3Buckets: {
    public: "https://s3-eu-west-1.amazonaws.com/wuolah-test-public",
    media: "https://s3-eu-west-1.amazonaws.com/wuolah-test",
    wuolahWeb: "https://wuolah-web.s3-eu-west-1.amazonaws.com"
  },
  onesignal: {
    appId: '6d0eec50-166d-45dd-8a15-ec7371dcf1ce',
    safari_web_id: 'web.onesignal.auto.6a13c381-7602-4e1d-b2a0-1098f769d9cc'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
