<div class="precard-title" [ngClass]="{'skeleton': !data || !data.title}">{{data.title}}</div>
<div class="academy-container">
  <div class="main-academy">
    <div class="permanent-academy">
      <img src="./../../../assets/img/index_bck.png" alt="" />
      <h6 class="academy-title">Título</h6>
      <p class="academy-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, quisquam?</p>
      <div class="academy-spans">
        <span>Whatsapp:</span>
        <a href="#">611223344</a>
      </div>
      <div class="academy-contacts">
        <a href="#">
         <div>Whatsapp</div>
        </a>
      </div>
      <p class="academy-title academy-subtitle">AL GRANO CON AEROESPACIAL: ASIGNATURAS QUE OFERTAMOS EN EL 1º CUATRIMESTRE:</p>
    </div>
    <div class="academy-features">
      <img src="./../../../assets/img/rocket.png" alt="">
      <div>
        <h6>Titulito</h6>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta, inventore.</p>
        <div class="academy-feature-action">
          <a href="#">Ir</a>
        </div>
      </div>
    </div>
    <div class="academy-features">
      <img src="./../../../assets/img/rocket.png" alt="">
      <div>
        <h6>Titulito</h6>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta, inventore.</p>
        <div class="academy-feature-action">
          <a href="#">Ir</a>
        </div>
      </div>
    </div>
    <div class="space-feature"></div>
  </div>
  <div class="footer-academy"></div>
</div>
