<div class="precard-title" [ngClass]="{ skeleton: !generalInformation || !generalInformation.name }">
  {{ generalInformation && generalInformation.name && generalInformation.name !== "" ? generalInformation.name : "" }}
</div>

<div class="academy-container">
  <div class="main-academy">
    <div class="permanent-academy">
      <img
      *ngIf="showImage"
      class="urlLogo"
      [ngClass]="{ skeleton: !generalInformation || !generalInformation.backgroundUrl }"
      [src]="generalInformation && generalInformation.backgroundUrl && generalInformation.backgroundUrl !== '' ? generalInformation.backgroundUrl : ''"
      alt="empty"
      >
      <!-- <img
        *ngIf="showImage"
        class="urlLogo"
        [ngClass]="{ skeleton: !generalInformation || !generalInformation.logoUrl }"
        [src]="generalInformation && generalInformation.logoUrl && generalInformation.logoUrl !== '' ? generalInformation.logoUrl : ''"
        alt="empty"
      /> -->

      <h6 class="academy-title" [ngClass]="{ skeleton: !generalInformation || !generalInformation.title }">
        {{ generalInformation && generalInformation.title && generalInformation.title !== "" ? generalInformation.title : "" }}
      </h6>

      <p
        class="academy-description"
        [ngClass]="{ skeleton: !generalInformation || !generalInformation.description }"
      >
        {{ generalInformation && generalInformation.description && generalInformation.description !== "" ? generalInformation.description : "" }}
      </p>
      
      <div class="contactS" *ngIf="dataContacts == []">
        <div class="skeleton"></div>
        
        <div class="skeleton"></div>
      </div>
      
      <div *ngIf="dataContacts.length > 0">
        <div class="academy-spans" *ngIf="dataContacts[0].value || dataContacts[1].value || dataContacts[2].value" >
          <div *ngFor="let item of dataContacts index as i">
            <span *ngIf="dataContacts[i].value">{{ dataContacts[i] && dataContacts[i].value && dataContacts[i].value !== "" ? dataContacts[i].name : "" }}</span>
            
            <a
              *ngIf="dataContacts[i].value"
              [href]="i === 0 ? (dataContacts[0] && dataContacts[0].actionUrl && dataContacts[0].actionUrl !== '' ? getWhatsappUrl(dataContacts[0].actionUrl) : '') : (i === 1 ? (dataContacts[1] && dataContacts[1].actionUrl && dataContacts[1].actionUrl !== '' ? 'mailto: ' + dataContacts[1].actionUrl : '') : (dataContacts[2] && dataContacts[2].actionUrl && dataContacts[2].actionUrl !== '' ? getWebUrl(dataContacts[2].actionUrl) : ''))"
              target="_blank" rel="nofollow"
              >{{ dataContacts && dataContacts[i].actionUrl && dataContacts[i].actionUrl !== "" ? dataContacts[i].actionUrl : "" }}
            </a>

          </div>
        </div>
      </div>
      
      <div class="academy-contacts">
        <a
          [ngClass]="{ skeleton: !dataButtons[0] || !dataButtons[0].actionUrl }"
          [href]="dataButtons[0] && dataButtons[0].actionUrl && dataButtons[0].actionUrl !== '' ? getWebUrl(dataButtons[0].actionUrl) : ''"
          target="_blank" rel="nofollow">
          <div
            style="min-width: 60px"
            [ngClass]="{ skeleton: !dataButtons[0] || !dataButtons[0].actionUrl }"
          >{{ dataButtons[0] && dataButtons[0].actionUrl && dataButtons[0].actionUrl !== "" ? "Web" : "" }}
          </div>
        </a>
        
        <a
          [ngClass]="{ skeleton: !dataButtons[1] || !dataButtons[1].actionUrl }"
          [href]="dataButtons[1] && dataButtons[1].actionUrl && dataButtons[1].actionUrl !== '' ? getWhatsappUrl(dataButtons[1].actionUrl) : ''"
          target="_blank" rel="nofollow">
          <div
            style="min-width: 60px"
            [ngClass]="{ skeleton: !dataButtons[1] || !dataButtons[1].actionUrl }"
          >{{ dataButtons[1] && dataButtons[1].actionUrl && dataButtons[1].actionUrl !== "" ? "Whatsapp" : "" }}
          </div>
        </a>
      </div>
      
      <p
        class="academy-title academy-subtitle"
        [ngClass]="{ skeleton: !generalInformation || !generalInformation.subtitle }"
      >{{ generalInformation && generalInformation.subtitle && generalInformation.subtitle !== "" ? generalInformation.subtitle : "" }}
      </p>
    </div>
  
    <div *ngFor="let item of dataFeatures index as i">
      <app-feature-design-item
        *ngIf="dataFeatures[i].highlight == '1'"
        [title]="dataFeatures[i].title"
        [description]="dataFeatures[i].description"
        [linkName]="dataFeatures[i].actionText"
        [link]="dataFeatures[i].actionUrl"
        [image]="dataFeatures[i].assetUrl"
        [edit]="false"
      ></app-feature-design-item>
    </div>
    
    <div *ngIf="dataFeatures.length <= 0">
      <div class="academy-features">
        <div class="skeleton imageS"></div>
        
        <div>
          <h6 class="skeleton"></h6>
          
          <p class="skeleton"></p>
          
          <div
            class="show skeleton"
          ></div>
          
          <div class="academy-feature-action">
            <a class="skeleton"></a>
          </div>
        </div>
      </div>
      <div class="academy-features">
        <div class="skeleton imageS"></div>
        
        <div>
          <h6 class="skeleton"></h6>
          
          <p class="skeleton"></p>
          
          <div
            class="show skeleton"
          ></div>
          
          <div class="academy-feature-action">
            <a class="skeleton"></a>
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="dataFeatures.length > 0">
      <div [ngClass]="{academyFeaturesNone: dataFeatures[0].highlight == '1' || dataFeatures[1].highlight == '1' || dataFeatures[2].highlight == '1' || dataFeatures[3].highlight == '1' || dataFeatures[4].highlight == '1' || dataFeatures[5].highlight == '1' }" class="academy-features">
        <div class="skeleton imageS"></div>
        
        <div>
          <h6 class="skeleton"></h6>
          
          <p class="skeleton"></p>
          
          <div
            class="show skeleton"
          ></div>
          
          <div class="academy-feature-action">
            <a class="skeleton"></a>
          </div>
        </div>
      </div>
      <div [ngClass]="{academyFeaturesNone: dataFeatures[0].highlight == '1' || dataFeatures[1].highlight == '1' || dataFeatures[2].highlight == '1' || dataFeatures[3].highlight == '1' || dataFeatures[4].highlight == '1' || dataFeatures[5].highlight == '1' }" class="academy-features">
        <div class="skeleton imageS"></div>
        
        <div>
          <h6 class="skeleton"></h6>
          
          <p class="skeleton"></p>
          
          <div
            class="show skeleton"
          ></div>
          
          <div class="academy-feature-action">
            <a class="skeleton"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="space-feature"></div>
  </div>
  <div class="footer-academy">
    <span [ngClass]="{ skeleton: !dataContacts[2] || !dataContacts[2].value }">{{ dataContacts[2] && dataContacts[2].value && dataContacts[2].value !== "" ? "Ir a la academia" : "" }}</span>
    <a [ngClass]="{ skeletonF: !dataContacts[2] || !dataContacts[2].value } " [href]="dataContacts[2] ? getWebUrl(dataContacts[2].value) : ''" target="_blank" rel="nofollow">
      <app-button-item
        [ngClass]="{ skeletonF: !dataContacts[2] || !dataContacts[2].value }"
        [onlyIcon]="true"
        [title]="'Link'"
        [icon]="'chevron_right'"
        [background]="!dataContacts[2] || !dataContacts[2].value ? '#B6B6B6' : '#0055FF'"
        [color]="'#ffffff'"
        [solid]="true"
      ></app-button-item>
    </a>
  </div>
</div>
