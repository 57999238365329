import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class SponsorSubjectService extends RequestService {
  getFolders(data?: any) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/folder", data),
        { headers: this.headersAdmin() }
      )
    );
  }

  async searchFolders(data?: any) {
    return await this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/widget/folder", data),
        { headers: this.headers() }
      )
    );;
  }

  getFolderById(folderId: number){
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/folder/" + folderId),
        { headers: this.headersAdmin() }
      )
    );
  }

  getFolderWidgets(data?: any) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/widget", data),
        { headers: this.headers() }
      )
    );
  }

  getFolderWidget(id: number, data?: any) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/widget/" + id, data),
        { headers: this.headersAdmin() }
      )
    );
  }

  getWidget(id: number, data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/' + id, data),
      { headers: this.headers() }
    ));
  }

  createWidget(data: any) {
    return this.request(
      this.httpClient.post(this.environment.API + "/widget", data, {
        headers: this.headers(),
      })
    );
  }

  updateWidget(id: number, data: any) {
    return this.request(
      this.httpClient.put(this.environment.API + "/widget/" + id, data, {
        headers: this.headers(),
      })
    );
  }

  addWidgetProperty(property: string, id: number, data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/widget/" + id + "/" + property,
        data,
        { headers: this.headers() }
      )
    );
  }

  updateWidgetProperty(
    property: string,
    widgetId: number,
    propertyId: number,
    data: any
  ) {
    return this.request(
      this.httpClient.put(
        this.environment.API +
          "/widget/" +
          widgetId +
          "/" +
          property +
          "/" +
          propertyId,
        data,
        { headers: this.headers() }
      )
    );
  }

  deleteWidgetProperty(property: string, widgetId: number, propertyId: number) {
    return this.request(
      this.httpClient.delete(
        this.environment.API +
          "/widget/" +
          widgetId +
          "/" +
          property +
          "/" +
          propertyId,
        { headers: this.headers() }
      )
    );
  }

  deleteWidget(widgetId: number) {
    return this.request(
      this.httpClient.delete(
        this.environment.API +
          "/widget/" +
          widgetId,
        { headers: this.headers() }
      )
    );
  }

  createCustomPayment(data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/payment/session",
        data,
        { headers: this.headers() }
      )
    );
  }

  createCustomPaymentUnique(data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/payment/session/unique",
        data,
        { headers: this.headers() }
      )
    );
  }

  uploadToS3(url: string, contentType: string, file: any) {
    return this.request(
      this.httpClient.put(url, file, { headers: this.s3Headers(contentType) })
    );
  }

  sponsorReport(data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/report', data),
      { headers: this.headers() }
    ));
  }

  async sponsorReportWidgetsList(data?: any) {
    return await this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/report/widgets', data),
      { headers: this.headers() }
    ));
  }

  getWidgetReportDetails(widgetId: number, data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/' + widgetId + '/report', data),
      { headers: this.headers() }
    ));
  }

  getWidgetPubInDoc(widgetId: number, data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/' + widgetId + '/report/adindoc', data),
      { headers: this.headers() }
    ));
  }

  getWidgetDocList(widgetId: number, data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/' + widgetId + '/report/listDocs', data),
      { headers: this.headers() }
    ));
  }

  getReportWidgetDetails(widgetId: number, data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/widget/' + widgetId + '/report/details', data),
      { headers: this.headers() }
    ));
  }

  addTrelloCard(folders: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/widget/trello",
        folders,
        { headers: this.headers() }
      )
    );
  }
}
