import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-close-sponsor-modal',
  templateUrl: './close-sponsor-modal.component.html',
  styleUrls: ['./close-sponsor-modal.component.css']
})
export class CloseSponsorModalComponent implements OnInit {

  open = false;

  @Output() onClose = new EventEmitter();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.open = true;
    }, 100);
  }

  close() {
    this.open = false;
    
    setTimeout(() => {
      this.onClose.emit();
    }, 150);
  }

  gotToDashboardSave() {
    this.router.navigate(["dashboard/"]);
  }

  gotToDashboard() {
    localStorage.removeItem('dataGeneralInformation');
    localStorage.removeItem('buttons');
    localStorage.removeItem('contacts');
    localStorage.removeItem('features');
    localStorage.removeItem('signatures');
    localStorage.removeItem('dataUpload');
    localStorage.removeItem('croppedCreativities');
    localStorage.removeItem('widgets');
    this.router.navigate(["dashboard/"]);
  }

}
