<nav>
  <div [routerLink]="'/login'" class="logo">
    <img src="https://www.wuolah.com/static/media/res/mark/logo.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <h4>Ad Manager</h4>

  <app-button-item class="back-button" [routerLink]="'/login'" [onlyIcon]="true" [background]="'#FFFFFF'" [icon]="'arrow_back'" [color]="'#102754'" [text]="'Continuar'" [solid]="true"></app-button-item>
</nav>

<div class="container">
    <div class="inner-container">  
      <div class="inputs-container">
        <h5 class="wds-h5">Recuperar contraseña</h5>
  
        <app-input-item *ngIf="!done"
          [data]="credentials.account"
          [label]="'Email'"
          [type]="'email'"
          (dataChange)="credentials.account = $event"
        ></app-input-item>

        <div *ngIf="done" class="done">
          <p class="wds-text">
            Te hemos enviado un correo electrónico. Sigue las instrucciones para recuperar tu cuenta.
            *Por favor, revisa tu bandeja de entrada y de correo no deseado.
          </p>
        </div>
  
        <a *ngIf="!done" [routerLink]="'/login'">Volver a inicio de sesión</a>
  
        <app-button-item *ngIf="!done" (fireClick)="recoverPassword()" [text]="'Continuar'" [background]="'#102754'" [color]="'#FFFFFF'" [solid]="true"></app-button-item>
        <app-button-item *ngIf="done" [routerLink]="'/login'"  [text]="'Continuar'" [background]="'#102754'" [color]="'#FFFFFF'" [solid]="true"></app-button-item>
      </div>
    </div>
  </div>
  
