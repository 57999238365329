<div class="body-container">
  <h1 class="wds-h1">Análisis de la campaña</h1>
  <div class="period-capsule">
    <div class="period-capsule-item">
      <div (click)="setPeriod(7); openPersonalize = false" class="period-item"
        [ngClass]="{'selected': periodSelected === 7 && !openPersonalize}">7 días</div>
      <div (click)="setPeriod(14); openPersonalize = false" class="period-item"
        [ngClass]="{'selected': periodSelected === 14 && !openPersonalize}">14 días</div>
      <div (click)="setPeriod(30); openPersonalize = false" class="period-item"
        [ngClass]="{'selected': periodSelected === 30 && !openPersonalize}">30 días</div>
      <div class="period-item ngx-daterangepicker-action" [ngClass]="{'selected': openPersonalize}" (click)="open()">
        Personalizar
      </div>
    </div>
  </div>
  <div class="date-picker" *ngIf="openPersonalize">
    <input type="text" ngxDaterangepickerMd class="input-period" [(ngModel)]="dateRange" [closeOnAutoApply]="true"
      [autoApply]="true" placeholder="Selecciona un rango" [linkedCalendars]="true"
      [locale]="{format: 'DD-MM-YYYY', separator: ' a ', firstDay: 1, daysOfWeek: days, monthNames: months}">
    <app-button-item (fireClick)="setPersonalized(); openPersonalize = false" [icon]="'search'" [onlyIcon]="true"
      [background]="'#001540'" [solid]="true"></app-button-item>
  </div>

  <div class="general-report">
    <p>Resumen general</p>
    <ngx-skeleton-loader *ngIf="!generalReport?.impressions" animation="progress" [theme]="{
      'width': '100%',
      'height': '120px',
      'background': '#f8f8f8',
      'border-radius': '8px',
      'margin-top': '17px',
      'margin-bottom': '41px'}">
    </ngx-skeleton-loader>
    <div class="general-report-container" *ngIf="generalReport?.impressions">
      <div class="card">
        <p>Impresiones</p>
        <p class="card-number">{{generalReport?.impressions}}</p>
      </div>
      <div class="card">
        <p>Clics</p>
        <p class="card-number">{{generalReport?.clicks}}</p>
      </div>
      <div class="card">
        <p>CTR</p>
        <p class="card-number">{{generalReport?.ctr}}</p>
      </div>
    </div>
  </div>
  <div class="chart">
    <p>Impresiones por día</p>

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{ 
      'border-radius': '8px',
      'height': '300px',
      'width': '100%',
      'background-color': '#f8f8f8',
      'margin': '10px'
    }"></ngx-skeleton-loader>
    <highcharts-chart *ngIf="firstReport" [Highcharts]="Highcharts" [options]="chartOptionsDayImpressions"
      style="width: 100%; height: 300px; display: block; margin: 10px; margin: 10px;" [(update)]="updateFlag" [oneToOne]="oneToOneFlag">
    </highcharts-chart>
  </div>

  <div class="chart">
    <p>Impresiones por ubicación</p>

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{ 
      'border-radius': '8px',
      'height': '300px',
      'width': '100%',
      'background-color': '#f8f8f8',
      'margin': '10px'
    }"></ngx-skeleton-loader>
    <highcharts-chart *ngIf="firstReport" [Highcharts]="Highcharts" [options]="chartOptionsUbicationImpressions"
      style="width: 100%; height: 300px; display: block; margin: 10px;" [(update)]="updateFlag" [oneToOne]="oneToOneFlag">
    </highcharts-chart>
  </div>

  <div class="chart">
    <p>Clics por día</p>

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{ 
      'border-radius': '8px',
      'height': '300px',
      'width': '100%',
      'background-color': '#f8f8f8',
      'margin': '10px'
    }"></ngx-skeleton-loader>
    <highcharts-chart *ngIf="firstReport" [Highcharts]="Highcharts" [options]="chartOptionsDayClicks"
      style="width: 100%; height: 300px; display: block; margin: 10px;" [(update)]="updateFlag" [oneToOne]="oneToOneFlag">
    </highcharts-chart>
  </div>

  <div class="chart">
    <p>Clics por ubicación</p>

    <ngx-skeleton-loader *ngIf="!firstReport" animation="progress" [theme]="{ 
      'border-radius': '8px',
      'height': '300px',
      'width': '100%',
      'background-color': '#f8f8f8',
      'margin': '10px'
    }"></ngx-skeleton-loader>
    <highcharts-chart *ngIf="firstReport" [Highcharts]="Highcharts" [options]="chartOptionsUbicationClicks"
      style="width: 100%; height: 300px; display: block; margin: 10px;" [(update)]="updateFlag" [oneToOne]="oneToOneFlag">
    </highcharts-chart>
  </div>
</div>