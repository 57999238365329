import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';

// Services
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dashboard-url-click-gen',
  templateUrl: './dashboard-url-click-gen.component.html',
  styleUrls: ['./dashboard-url-click-gen.component.css']
})
export class DashboardUrlClickGenComponent implements OnInit {

  click_url: string;
  output: string;

  constructor(
    public utilsService: UtilsService,
    public requestService: RequestService,
  ) { }

  ngOnInit(): void { }

  generateURL() {
    if (!this.click_url || this.click_url === '') {
      this.utilsService.presentAlertMessage({
        title: 'URL incorrecta.',
        description: 'Debe introducir una URL en el campo de texto',
        error: true,
        duration: 6
      });

      return false;
    }

    this.output = 'https://api.wuolah.com/pub/click';

    const data = {
      oid: '%ebuy!',
      lid: '%eaid!',
      cid: '%ecid!',
      sid: '%%PATTERN:url%%',
      sd: null,
      ub: '%%ADUNIT%%',
      cb: '%%CACHEBUSTER%%',
      uid: '%%PATTERN:entity%%'
    };

    this.output += '?url=' + encodeURIComponent(this.click_url);

    for (var prop in data) {
      if (Object.prototype.hasOwnProperty.call(data, prop)) {
        this.output += '&' + prop + '=' + data[prop];
      }
    }
  }

  copyOutput() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.output;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
