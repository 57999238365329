import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Guards
import { SessionGuard } from "./guards/session.guard";

// Pages
import { LoginComponent } from "./pages/login/login.component";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { CompleteProfileComponent } from "./pages/complete-profile/complete-profile.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { DashboardMainComponent } from "./pages/dashboard-main/dashboard-main.component";
import { CampaignDetailsComponent } from "./pages/campaign-details/campaign-details.component";
import { DashboardPendingComponent } from "./pages/dashboard-pending/dashboard-pending.component";
import { DashboardSettingsComponent } from "./pages/dashboard-settings/dashboard-settings.component";
import { RecoverPasswordComponent } from "./pages/recover-password/recover-password.component";
import { DashboardCropperComponent } from "./pages/dashboard-cropper/dashboard-cropper.component";
import { DashboardPublicsComponent } from "./pages/dashboard-publics/dashboard-publics.component";
import { DashboardAssociateComponent } from "./pages/dashboard-associate/dashboard-associate.component";
import { LandingMainComponent } from "./pages/landing/landing-main/landing-main.component";
import { SponsorSubjectsComponent } from "./pages/landing/sponsor-subjects/sponsor-subjects.component";
import { PurchasedSuccessComponent } from "./pages/landing/purchased-success/purchased-success.component";
import { PurchasedErrorComponent } from "./pages/landing/purchased-error/purchased-error.component";
import { DashboardAdminSponsorSubjectDetailComponent } from "./pages/dashboard-admin-sponsor-subject-detail/dashboard-admin-sponsor-subject-detail.component";
import { SponsorLanguagesComponent } from "./pages/landing/sponsor-languages/sponsor-languages.component";
import { CustomPaymentComponent } from "./pages/landing/custom-payment/custom-payment.component";
import { IndexComponent } from "./pages/index/index.component";
import { DashboardUrlClickGenComponent } from "./pages/dashboard-url-click-gen/dashboard-url-click-gen.component";
import { DashboardSponsorComponent } from "./pages/dashboard-sponsor/dashboard-sponsor.component";
import { DashboardSponsorSegmentationComponent } from "./pages/dashboard-sponsor-segmentation/dashboard-sponsor-segmentation.component";
import { DashboardSponsorDesignComponent } from "./pages/dashboard-sponsor-design/dashboard-sponsor-design.component";
import { DashboardSponsorCreativitiesComponent } from "./pages/dashboard-sponsor-creativities/dashboard-sponsor-creativities.component";
import { DashboardSponsorPaymentComponent } from "./pages/dashboard-sponsor-payment/dashboard-sponsor-payment.component";
import { DashboardSponsorReportComponent } from "./pages/dashboard-sponsor-report/dashboard-sponsor-report.component";
import { DashboardSponsorReportDetailsComponent } from "./pages/dashboard-sponsor-report-details/dashboard-sponsor-report-details.component";
import { DashboardPixelComponent } from "./pages/dashboard-pixel/dashboard-pixel.component";
import { DashboardAdminSponsorSubjectComponent } from "./pages/dashboard-admin-sponsor-subject/dashboard-admin-sponsor-subject.component";
import { AgentComponent } from "./pages/landing/agent/agent.component";
import { DashboardCampaignDetailsComponent } from "./pages/dashboard-campaign-details/dashboard-campaign-details.component";
import { DashboardTakeoverComponent } from "./pages/dashboard-takeover/dashboard-takeover.component";
import { CustomPaymentUniqueComponent } from "./pages/landing/custom-payment-unique/custom-payment-unique.component";
import { DashboardGenerateSegmentationComponent } from "./pages/dashboard-generate-segmentation/dashboard-generate-segmentation.component";
import { DashboardCreateComponent } from "./pages/dashboard-create/dashboard-create.component";

const routes: Routes = [
  { path: "index", component: IndexComponent, canActivate: [SessionGuard] },
  { path: "login", component: LoginComponent, canActivate: [SessionGuard] },
  {
    path: "complete-profile",
    component: CompleteProfileComponent,
    canActivate: [SessionGuard],
  },
  { path: "recover-password", component: RecoverPasswordComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [SessionGuard],
    children: [
      { path: "", component: DashboardMainComponent },
      { path: "main", component: DashboardMainComponent },
      { path: "settings", component: DashboardSettingsComponent },
      {
        path: "create",
        component: DashboardCreateComponent,
      },
      {
        path: "edit/:id",
        component: DashboardCreateComponent,
      },
      {
        path: "generate-segmentation",
        component: DashboardGenerateSegmentationComponent,
      },
      { path: "campaign/pending", component: DashboardPendingComponent },
      {
        path: "campaign/:id/:advertiserId",
        component: CampaignDetailsComponent,
      },
      { path: "cropper", component: DashboardCropperComponent },
      { path: "takeover", component: DashboardTakeoverComponent },
      { path: "publics", component: DashboardPublicsComponent },
      { path: "associate", component: DashboardAssociateComponent },
      { path: "url-click-generator", component: DashboardUrlClickGenComponent },
      { path: "sponsor/report", component: DashboardSponsorReportComponent },
      {
        path: "sponsor/report/:id",
        component: DashboardSponsorReportDetailsComponent,
      },
      { path: "pixel", component: DashboardPixelComponent },
      {
        path: "admin/sponsor/subject",
        component: DashboardAdminSponsorSubjectComponent,
      },
      {
        path: "admin/sponsor/subject/:folderId/:centerId/:studyId/:course/:name",
        component: DashboardAdminSponsorSubjectDetailComponent,
      },
      { path: "landing/agent/client/:clientId", component: AgentComponent },
      {
        path: "campaign-details/:advertiserId/:id",
        component: DashboardCampaignDetailsComponent,
      },
    ],
  },
  {
    path: "dashboard/sponsor",
    component: DashboardSponsorComponent,
    canActivate: [SessionGuard],
    children: [
      { path: "", component: DashboardSponsorSegmentationComponent },
      { path: "subject/1", component: DashboardSponsorSegmentationComponent },
      { path: "subject/2", component: DashboardSponsorDesignComponent },
      { path: "subject/3", component: DashboardSponsorCreativitiesComponent },
      { path: "subject/4", component: DashboardSponsorPaymentComponent },
    ],
  },
  {
    path: "landing",
    component: LandingMainComponent,
    children: [
      { path: "", component: LandingMainComponent },
      {
        path: "patrocinar/:quantity/asignaturas",
        component: SponsorSubjectsComponent,
      },
      {
        path: "patrocinar/:quantity/idiomas",
        component: SponsorLanguagesComponent,
      },
      {
        path: "payment/:product/:amount/:interval",
        component: CustomPaymentComponent,
      },
      {
        path: "unique/payment/:product/:amount",
        component: CustomPaymentUniqueComponent,
      },
      { path: "gracias", component: PurchasedSuccessComponent },
      { path: "error", component: PurchasedErrorComponent },
    ],
  },
  { path: "", redirectTo: "/index", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
