<div class="container" [ngClass]="{'open': open, 'error': error}">
  <div class="information">
    <div class="title">{{title}}</div>
    <div *ngIf="description && description !== ''" class="description">{{description}}</div>
  </div>

  <div (click)="close()" class="asset">
    <span *ngIf="!error" class="material-icons-outlined">
      check_circle_outline
    </span>

    <span *ngIf="error" class="material-icons-outlined">
      error_outline
    </span>
  </div>
</div>
