<div class="container">
  <div class="overlay" (click)="close()"></div>

  <div class="modal-container">
    <div class="close-modal">
      <app-button-item
       [title]="'Close'"
       [onlyIcon]="true"
       [icon]="'close'"
       [color]="'#111111'"
       (fireClick)="close()"
      ></app-button-item>
    </div>
    
    <p>Código del pixel</p>
    
    <div class="code-box">
      {{code}}
    </div>
    
    <div class="button-row">
      <app-button-item [disabled]="!code" (click)="copy()" [text]="'Copiar código'" [background]="'#102754'"
        [color]="'#FFFFFF'" [solid]="true"></app-button-item>
    </div>
  </div>
</div>
