import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable()

export class AdvertiserService extends RequestService {

  getAdvertiserData(advertiserId: any) {
    return this.request(this.httpClient.get(
        this.environment.API + '/pub/advertiser/' + advertiserId,
      { headers: this.headers() }
    ));
  }

  getAdvertiser() {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/me',
      { headers: this.headers() }
    ));
  }

  getAdvertiserMe() {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/me',
      { headers: this.headers() }
    ));
  }

  getAdvertisers(data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(
        this.environment.API + '/pub/advertiser', data),
      { headers: this.headers() }
    ));
  }

  completeProfile(data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser",
        data,
        { headers: this.headers() })
    );
  }

  updateProfile(data: any) {
    return this.request(
      this.httpClient.put(
        this.environment.API + "/pub/advertiser/me",
        data,
        { headers: this.headers() })
    );
  }

  adminUpdate(userId: Number, data: any) {
    return this.request(
      this.httpClient.put(
        this.environment.API + "/pub/advertiser/" + userId,
        data,
        { headers: this.headers() })
    );
  }

  async refreshAdvertiserMe(){
    let advertiserMe = await this.getAdvertiserMe();
    this.storageService.write('advertiserMe', advertiserMe);
    return advertiserMe;
  }

  async getGoogleAdManager(data: any){
    return await this.request(this.httpClient.get(
      this.serializeUrl(
        this.environment.API + '/pub/advertiser/gam', data),
      { headers: this.headers() }
    ));
  }

  async getAdvertiserGoogleAdManager(adManagerId: any){
    return await this.request(this.httpClient.get(
      this.serializeUrl(
        this.environment.API + '/pub/advertiser/' + adManagerId + '/campaign/gam'),
      { headers: this.headers() }
    ));
  }
}
