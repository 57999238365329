import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-advice-message-item',
  templateUrl: './advice-message-item.component.html',
  styleUrls: ['./advice-message-item.component.css']
})
export class AdviceMessageItemComponent implements OnInit {

  @Input('color') color: 'normal' | 'warning' | 'danger';
  @Input('icon') icon: string;
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;

  constructor() { }

  ngOnInit(): void { }

}
