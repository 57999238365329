import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chip-item',
  templateUrl: './chip-item.component.html',
  styleUrls: ['./chip-item.component.css']
})
export class ChipItemComponent implements OnInit {

  @Input('text') text: any;
  @Input('selected') selected: boolean = false;
  @Input('initialSelected') initialSelected: boolean;
  @Input('multiple') multiple: boolean = true;
  @Input('addition') addition: boolean = false;

  @Output() onChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void { 
    if(typeof this.initialSelected != 'undefined') this.selected = this.initialSelected;
  }

  toggle() {
    if (!this.multiple) {
      return false;
    }

    this.selected = !this.selected;
    this.onChange.emit(this.selected);
  }

}
