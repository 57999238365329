<div class="body-container" *ngIf="campaign">
  <h1 class="wds-h1">
    {{campaign.name}}
  </h1>

  <div *ngIf="!campaign.dfpOrderId" class="no-iframe-container">
      Aún no se ha gestionado tu campaña.
  </div>
  <div class="cards-container">
    <div class="campaign-info-box info-box-half info-box-half-left info-box-small">
      <div class="item-input no-margin">
        <div class="labels">
          <label>Nombre de la campaña.</label>
          <small>El nombre que le diste a tu campaña para reconocerla.</small>
        </div>
        <div class="title">{{campaign.name}}</div>
      </div>
    </div>
    
    <div id="date-info-box" class="campaign-info-box info-box-half info-box-half-right info-box-small">
      <div class="item-input no-margin">
        <div class="labels">
          <label>Fecha de duración.</label>
          <small>Duración de la campaña.</small>
          <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="changeStatus()" class="capture-payment-button" [title]="'Estado'" [text]="campaignStatusesMessages[campaign.status]"></app-button-item>
        </div>
        <div class="strong-content">
          <p><strong>Inicio: </strong><small>{{campaignAdditionalInfo.startDate ? campaignAdditionalInfo.startDate : 'Sin especificar'}}</small></p>
          <p><strong>Fin: </strong><small>{{campaignAdditionalInfo.endDate ? campaignAdditionalInfo.endDate : 'Sin especificar'}}</small></p>
        </div>
      </div>
    </div>
    
    <div class="campaign-info-box info-box-half info-box-half-left info-box-medium">
      <div class="item-input no-margin">
        <div class="labels">
          <label>Presupuesto.</label>
          <small>Las campañas que pronto estarán en distribución.</small>
        </div>
        <div class="strong-content">
          <div class="budget-box-half">
            <strong class="budget-strong-title">Tipo</strong>
            <div class="budget-shadowed-input">Total</div>
          </div>
          <div class="budget-box-half">
            <strong class="budget-strong-title">Cantidad</strong>
            <div class="budget-shadowed-input">{{campaign.finalBudget}}€</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="campaign-info-box info-box-half info-box-half-right info-box-medium">
      <div class="item-input no-margin">
        <div class="labels">
          <label>Facturación y pago.</label>
          <small>El método de pago seleccionado y el estado de la transacción.</small>
          <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="capturePayment()" class="capture-payment-button" *ngIf="adminUserMe && campaignAdditionalInfo.payment.status == 'AUTHORIZED'" [title]="'Efectuar cobro'" [text]="'Efectuar cobro'" [icon]="'payment'"></app-button-item>
        </div>
        <div class="strong-content flex-content">
          <div><strong>Usuario: </strong><small>{{advertiser?.name}}</small></div>
          <div><strong>Importe: </strong><small>{{campaignAdditionalInfo?.payment?.total}}€</small></div>
          <div><strong>Pago: </strong><small>{{paymentStatusMessages[campaignAdditionalInfo?.payment?.status]}}</small></div>
        </div>
      </div>
    </div>
  </div> 
  <div class="campaign-info-box">
    <div class="item-input no-margin">
      <div class="labels">
        <label>Audiencia.</label>
        <small>La segmentación y el público.</small>
        <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="togglePublicSlide()" class="generate-public-button" *ngIf="adminUserMe" [title]="'Público'" [text]="'Público'" [icon]="'person'"></app-button-item>
      </div>

      <div *ngIf="campaign.target.cityList.length">
        <div class="title">Ciudades</div>
        <app-chip-item *ngFor="let city of campaign.target.cityList" [text]="city.name" [selected]="true"></app-chip-item>
      </div>

      <div *ngIf="campaign.target.universityList.length">
        <div class="title">Universidades</div>
        <app-chip-item *ngFor="let university of campaign.target.universityList" [text]="university.name" [selected]="true"></app-chip-item>
      </div>

      <div *ngIf="campaign.target.centerList.length">
        <div class="title">Facultades</div>
        <app-chip-item *ngFor="let center of campaign.target.centerList" [text]="center.name" [selected]="true"></app-chip-item>
      </div>

      <div *ngIf="campaign.target.studyList.length">
        <div class="title">Estudios</div>
        <app-chip-item *ngFor="let study of campaign.target.studyList" [text]="study.name" [selected]="true"></app-chip-item>
      </div>

      <div *ngIf="campaign.target.courseList.length">
        <div class="title">Cursos</div>
        <app-chip-item *ngFor="let course of campaign.target.courseList" [text]="course.name" [selected]="true"></app-chip-item>
      </div>

    </div>
  </div>

  <div *ngIf="campaign.dfpOrderId" class="iframe-container">
    <iframe
      width="100%"
      height="900"
      [src]="'https://datastudio.google.com/embed/reporting/a2adf143-dd99-4506-8c9f-870e3e7568dc/page/FUnEB?params=%7B%22df453%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580' + campaign.dfpOrderId + '%22%7D' | safe"
      frameborder="0"
      style="border: 0; overflow: hidden;"
      scrolling="no"
      allowfullscreen
    ></iframe>
  </div>
  
  <div class="campaign-info-box">
    <div class="item-input no-margin">
      <div class="labels">
        <label>Creatividades y ubicaciones.</label>
        <small>Cómo se ve tu anuncio y los soportes donde aparece.</small>
      </div>

      <div class="strong-content">
        <div *ngIf="!campaignAdditionalInfo.numOfflineUbications && !campaignAdditionalInfo.numOnlineUbications" class="no-creatives-message">No se han añadido creatividades a esta campaña. ¡Ponte en contacto con nosotros y te las diseñamos!</div>
        <div *ngIf="campaignAdditionalInfo.numOfflineUbications || campaignAdditionalInfo.numOnlineUbications" class="creativity-list creativity-list-header">
          <strong class="creativity-title">Soportes</strong>
          <strong class="creativity-info">Ubicaciones</strong>
          <div class="creativity-info"></div>
        </div>
        <div *ngIf="campaignAdditionalInfo.numOfflineUbications" class="creativity-list" (click)="toggleCreativeSlide('offline')">
          <div class="creativity-title">Soportes de apuntes</div>
          <div class="creativity-info">{{campaignAdditionalInfo.numOfflineUbications}}</div>
          <div class="creativity-info">
            <app-button-item [size]="mobile ? 'small' : ''" [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" [text]="mobile ? '' : 'Ver soportes'" [title]="'Ver soportes'" [icon]="'remove_red_eye'" [onlyIcon]="mobile ? true : false"></app-button-item>    
          </div>          
        </div>
        <div *ngIf="campaignAdditionalInfo.numOnlineUbications" class="creativity-list" (click)="toggleCreativeSlide('online')">
          <div class="creativity-title">Soportes de apuntes</div>
          <div class="creativity-info">{{campaignAdditionalInfo.numOnlineUbications}}</div>
          <div class="creativity-info">
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" [text]="'Ver soportes'" [title]="'Ver soportes'" [icon]="'remove_red_eye'"></app-button-item>            
          </div>          
        </div>
      </div>

    </div>
  </div>
  
</div>

<div *ngIf="publicSlide" class="side-slide" [ngClass]="{'open': openPublic}">
  <div (click)="togglePublicSlide()" class="overlay"></div>

  <div class="inner-container">
    <div class="inner-nav">
      <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="togglePublicSlide()" [onlyIcon]="true" [title]="'Cerrar'" [icon]="'close'"></app-button-item>
      <div class="inner-nav-text">Público y segmentación de la campaña.</div>
    </div>

    <div class="side-inner-body">
      <div class="left-side">
        <div class="side-title">{{campaign.target.name}}</div>
        <ng-container *ngIf="campaign.target.cityList.length">
          <div class="public-label">
            Ciudades
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('city')" [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          <pre>cityIds: {{campaign.target.cityList | json}}</pre>
        </ng-container>
        <ng-container *ngIf="campaign.target.universityList.length">
          <div class="public-label">
            Universidades
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('university')" [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          <pre>universityIds: {{campaign.target.universityList | json}}</pre>
        </ng-container>
        <ng-container *ngIf="campaign.target.centerList.length">
          <div class="public-label">
            Centros
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('center')" [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          <pre>centerIds: {{campaign.target.centerList | json}}</pre>
        </ng-container>
        <ng-container *ngIf="campaign.target.studyList.length">
          <div class="public-label">
            Estudios
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('study')" [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          <pre>studyIds: {{campaign.target.studyList | json}}</pre>
        </ng-container>
        <ng-container *ngIf="campaign.target.courseList.length">
          <div class="public-label">
            Cursos
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('course')" [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          <pre>courseIds: {{campaign.target.courseList | json}}</pre>
        </ng-container>
      </div>
      <div class="right-side">
        <div class="campaign-info-box">
          <div class="item-input no-margin">
            <div class="title">Resumen del público</div>
          </div>
        </div>
        <div class="campaign-info-box no-margin">
          <div class="item-input no-margin">
            <div class="labels">
              <div class="title">Ciudades.</div>
              <small *ngFor="let city of campaign.target.cityList">{{city.name}}</small>
            </div>
          </div>
        </div>
        <div class="campaign-info-box no-margin">
          <div class="item-input no-margin">
            <div class="labels">
              <div class="title">Universidades.</div>
              <small *ngFor="let university of campaign.target.universityList">{{university.name}}</small>
              <small *ngIf="!campaign.target.universityList.length">No hay universidades seleccionadas.</small>
            </div>
          </div>
        </div>
        <div class="campaign-info-box no-margin">
          <div class="item-input no-margin">
            <div class="labels">
              <div class="title">Centros.</div>
              <small *ngFor="let center of campaign.target.centerList">{{center.name}}</small>
              <small *ngIf="!campaign.target.centerList.length">No hay centros seleccionados.</small>
            </div>
          </div>
        </div>
        <div class="campaign-info-box no-margin">
          <div class="item-input no-margin">
            <div class="labels">
              <div class="title">Estudios.</div>
              <small *ngFor="let study of campaign.target.studyList">{{study.name}}</small>
              <small *ngIf="!campaign.target.studyList.length">No hay estudios seleccionados.</small>
            </div>
          </div>
        </div>
        <div class="campaign-info-box no-margin">
          <div class="item-input no-margin">
            <div class="labels">
              <div class="title">Cursos.</div>
              <small *ngFor="let course of campaign.target.courseList">{{course.name}}</small>
              <small *ngIf="!campaign.target.courseList.length">No hay cursos seleccionadas.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="creativeSlide" class="side-slide" [ngClass]="{'open': openCreative}">
  <div class="inner-container">
    <div class="inner-nav">
      <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="toggleCreativeSlide()" [onlyIcon]="true" [title]="'Cerrar'" [icon]="'close'"></app-button-item>
      <div class="inner-nav-text">Creatividades y soportes.</div>
    </div>

    <div class="side-inner-body">
      <div class="campaign-info-box">
        <div class="item-input no-margin">
          <div class="labels">
            <label>Destino</label>
            <small>Cómo se ve tu anuncio y los soportes donde aparece.</small>
          </div>
          {{campaign.clickUrl}}
          https:www.wuolah.com
        </div>
      </div>
      <div class="campaign-info-box">
        <div class="item-input no-margin">
          <div class="labels">
              <label>Estado del anuncio</label>
              <small>Estado actual de revisión de la creatividad.</small>
          </div>
          <div *ngIf="!campaign.creativesChecked">Pendiente de revisar por un agente.</div>
          <div *ngIf="campaign.creativesChecked">Creatividades validadas por nuestros agentes.</div>
          <strong *ngIf="campaign.userFinalCreatives && !campaign.creativesChecked">Las creatividades añadidas han sido marcadas como definitivas por el anunciante.</strong>
        </div>
      </div>
      <div class="creatives-left-side">
        <div class="labels">
          <label>Soportes de apuntes</label>
        </div>

        <div class="images-wrapper gridder">
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 1}" (click)="onSelectedAdUbicationChange(1)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-1.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">A4</div>
          </div>

          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 2}" (click)="onSelectedAdUbicationChange(2)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-2.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Portada</div>
          </div>

          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 3}" (click)="onSelectedAdUbicationChange(3)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-3.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Lateral vertical</div>
          </div>

          <div class="image-with-title tail">
            <div class="img-item horizontal" [ngClass]="{'active': selectedAdUbication == 4}" (click)="onSelectedAdUbicationChange(4)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-4.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Lateral horizontal</div>
          </div>

          <div class="image-with-title tail" *ngIf="false">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 5}" (click)="onSelectedAdUbicationChange(5)">
              <div class="overlay">
                <img src="https://4c448342d6996fb20913-fd1f9dc15ff616aa7fa94219cb721c9c.ssl.cf3.rackcdn.com/0f/25/498717_55b4cf70e36649f194e0f936ad173ef3.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Footer</div>
          </div>
        </div>

        <div class="labels">
          <label>Soportes Wuolah</label>
        </div>
        <div class="images-wrapper gridder">
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 6}" (click)="onSelectedAdUbicationChange(6)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-6.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Roba</div>
          </div>
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 7}" (click)="onSelectedAdUbicationChange(7)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-7.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Roba grande</div>
          </div>
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 8}" (click)="onSelectedAdUbicationChange(8)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-8.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Skin</div>
          </div>
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 9}" (click)="onSelectedAdUbicationChange(9)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-9.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Superbanner</div>
          </div>
          <div class="image-with-title tail">
            <div class="img-item" [ngClass]="{'active': selectedAdUbication == 10}" (click)="onSelectedAdUbicationChange(10)">
              <div class="overlay">
                <img src="/assets/pub/example-ubication-10.jpg">
              </div>

              <i class="material-icons-outlined">wallpaper</i>
            </div>

            <div class="title">Megabanner</div>
          </div>
        </div>
      </div>
      <div class="creatives-right-side">
        <div class="campaign-info-box">
          <div class="item-input no-margin">
            <div class="labels">
              <label>Imágenes</label>
              <small>Las imágenes que ilustran tu campaña.</small>
              <div *ngIf="selectedAdUbicationCreatives.length == 0">
                No hay creatividades en esta ubicación.
              </div>
              <div class="images-wrapper gridder">
                <div class="image-with-title tail" *ngFor="let creative of selectedAdUbicationCreatives">
                  <div class="img-item" [ngClass]="{'active': selectedUbicationCreative == creative.fileUrl}" (click)="onSelectedAdUbicationCreativeChange(creative.fileUrl)">
                    <div class="overlay">
                      <img [src]="creative.fileUrl">
                    </div>
      
                    <i class="material-icons-outlined">wallpaper</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="labels" *ngIf="selectedAdUbicationCreatives.length > 0">
              <label>Vista previa de la creatividad</label>
              <small>La creatividad que será usada en el soporte.</small>
              <div class="image-container">
                <img [src]="selectedUbicationCreative">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

