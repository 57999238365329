import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UtilsService } from "src/app/services/utils.service";
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
} from "ngx-image-cropper";
import { v4 as uuidv4 } from 'uuid';
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard-cropper",
  templateUrl: "./dashboard-cropper.component.html",
  styleUrls: ["./dashboard-cropper.component.css"],
})
export class DashboardCropperComponent implements OnInit {

  @ViewChild("verticalAttachments") verticalAttachments: ElementRef;
  @ViewChild("horizontalAttachments") horizontalAttachments: ElementRef;

  @ViewChild(ImageCropperComponent)

  imageCropper: ImageCropperComponent;
  transform: ImageTransform = {};

  imageChangedEvent: any = "";
  imageChangedEvent_2: any = "";
  upperCroppedImage: any = "";
  sideCroppedImage: any = "";

  results = {
    vertical: {
      upperBlob: null,
      sideBlob: null,
      upper: null,
      side: null,
    },
    horizontal: {
      upperBlob: null,
      sideBlob: null,
      upper: null,
      side: null,
    }
  };

  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  toggle = true;
  mode = "vertical";

  verticalName: any;
  horizontalName: any;

  previewSRC: any;

  upper: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };
  side: CropperPosition = { x1: 0, y1: 247, x2: 170, y2: 2000 };

  upperHorizontal: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };
  sideHorizontal: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };

  upperCroppedWidth = 1414;
  upperCroppedHeight = 247;

  sideCroppedWidth = 177;
  sideCroppedHeight = 1753;
  
  file_aplication = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"];

  originals = {
    vertical: '',
    horizontal: ''
  };

  constructor(
    private utilService: UtilsService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { }
  
  ngOnInit(): void {
    this.titleService.setTitle('Recortador de imágenes | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/cropper', 'Crop image');
    this.analyticService.screenView('Crop image');
  }

  async handleFileInput(event, mode) {
    let files: FileList = event.srcElement.files;

    if (!this.file_aplication.includes(files[0].type)) {
      this.utilService.presentAlertMessage({
        title: "La imagen debe ser PNG, JPG, JPEG o PJPEG",
      });

      this.verticalAttachments.nativeElement.value = "";
      this.horizontalAttachments.nativeElement.value = "";
    } else {
      if (files[0].size > 2000000) {
        this.utilService.presentAlertMessage({
          title: "La imagen no puede ocupar más de 200 KB",
        });

        this.verticalAttachments.nativeElement.value = "";
        this.horizontalAttachments.nativeElement.value = "";
      } else {
        this.mode = mode;

        if (mode == "vertical") {
          this.imageChangedEvent = await this.resize(event.target.files[0], 1414, 2000, null, event.target.files[0].type);;
          this.imageChangedEvent_2 = await this.resize(event.target.files[0], 1414, 2000, null, event.target.files[0].type);;
          
          this.verticalName = files[0].name;
        } else {
          this.imageChangedEvent = await this.resize(event.target.files[0], 2000, 1414, null, event.target.files[0].type);;
          this.imageChangedEvent_2 = await this.resize(event.target.files[0], 2000, 1414, null, event.target.files[0].type);;

          this.horizontalName = files[0].name;
        }

        this.onSelectFile(event, mode);
      }
    }
  }

  onSelectFile(event, mode) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.originals[mode] = event.target.result;
        this.previewSRC = event.target.result;
      };
    }
  }

  imageCropped(event: ImageCroppedEvent, side: 'upper' | 'side') {
    if (side == "upper") {
      this.results[this.mode][side] = event.base64;
      this.upperCroppedImage = event.base64;
    }

    if (side == "side") {
      this.results[this.mode][side] = event.base64;
      this.sideCroppedImage = event.base64;
    }
  }

  imageLoaded() {
    this.showCropper = true;

    setTimeout(async () => {
      if (this.mode == "vertical") {
        this.upperCroppedWidth = 1414;
        this.upperCroppedHeight = 247;

        this.sideCroppedWidth = 170;
        this.sideCroppedHeight = 1753;

        this.upper = { x1:0, y1:0, x2:1414, y2:247 };
        this.side = { x1:0, y1:247, x2:170, y2:2000 };
      } else {
        this.upperCroppedWidth = 2000;
        this.upperCroppedHeight = 170;

        this.sideCroppedWidth = 247;
        this.sideCroppedHeight = 1244;

        this.upper = { x1:0, y1:0, x2:2000, y2:170 };
        this.side = { x1:0, y1:170, x2:247, y2:1414 };
      }
    }, 500);

    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.showCropper = true;

    setTimeout(() => {
      if (this.mode == "vertical") {
        this.upperCroppedWidth = 1414;
        this.upperCroppedHeight = 247;

        this.sideCroppedWidth = 170;
        this.sideCroppedHeight = 1753;

        this.upper = { x1:0, y1:0, x2:1414, y2:247 };
        this.side = { x1:0, y1:247, x2:170, y2:2000 };
      } else {
        this.upperCroppedWidth = 2000;
        this.upperCroppedHeight = 170;

        this.sideCroppedWidth = 247;
        this.sideCroppedHeight = 1244;

        this.upper = { x1:0, y1:0, x2:2000, y2:170 };
        this.side = { x1:0, y1:170, x2:247, y2:1414 };
      }
    }, 500);

    this.showCropper = true;
  }

  loadImageFailed() {
    // console.log("Load failed");
  }

  clickEvent(event) {
    this.toggle = !this.toggle;
  }

  openInput(mode: string) {
    if (mode === "vertical") {
      this.verticalAttachments.nativeElement.click();
    } else {
      this.horizontalAttachments.nativeElement.click();
    }
  }

  deleteImage(mode: string) {
    this.previewSRC = "";

    if (mode === "vertical") {
      this.verticalAttachments.nativeElement.value = "";
      this.verticalName = "";
    } else {
      this.horizontalAttachments.nativeElement.value = "";
      this.horizontalName = "";
    }

    this.results[mode].upper = null;
    this.results[mode].side = null;
    this.results[mode].upperBlob = null;
    this.results[mode].sideBlob = null;
  }

  showPreview(mode: string) {
    this.mode = mode;
    this.previewSRC = this.originals[mode];
  }

  downloadImages() {
    const uuid = uuidv4();

    if (this.results.vertical.upper) {
      this.results.vertical.upperBlob = this.dataURItoBlob(this.results.vertical.upper);
    }

    if (this.results.vertical.side) {
      this.results.vertical.sideBlob = this.dataURItoBlob(this.results.vertical.side);
    }

    if (this.results.horizontal.upper) {
      this.results.horizontal.upperBlob = this.dataURItoBlob(this.results.horizontal.upper);
    }

    if (this.results.horizontal.side) {
      this.results.horizontal.sideBlob = this.dataURItoBlob(this.results.horizontal.side);
    }

    if (this.results.vertical.upperBlob) {
      this.downloadBlob(this.results.vertical.upperBlob, uuid + 'vertical_top.jpg');
    }

    if (this.results.vertical.sideBlob) {
      this.downloadBlob(this.results.vertical.sideBlob, uuid + 'vertical_side.jpg');
    }

    if (this.results.horizontal.upperBlob) {
      this.downloadBlob(this.results.horizontal.upperBlob, uuid + 'horizontal_top.jpg');
    }

    if (this.results.horizontal.sideBlob) {
      this.downloadBlob(this.results.horizontal.sideBlob, uuid + 'horizontal_side.jpg');
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], {type : mimeString});
    return bb;
  }

  downloadBlob(blob: Blob, name: string) {
    const fileName: string = name;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }


  deleteUploads() {
    this.sideCroppedImage = null;
    this.upperCroppedImage = null;
    this.previewSRC = null;

    this.verticalAttachments.nativeElement.value = "";
    this.verticalName = "";
    
    this.horizontalAttachments.nativeElement.value = "";
    this.horizontalName = "";

    this.results = {
      vertical: {
        upperBlob: null,
        sideBlob: null,
        upper: null,
        side: null,
      },
      horizontal: {
        upperBlob: null,
        sideBlob: null,
        upper: null,
        side: null,
      }
    };
  }

  resize(file?: any, max_width?: any, max_height?: any, compression_ratio?: any, imageEncoding?: any) {
    return new Promise((resolve, reject) => {
      let fileLoader = new FileReader(),
      canvas: any = document.createElement('canvas'),
      context = null,
      imageObj: any = new Image(),
      blob: any = null;            
  
      //create a hidden canvas object we can use to create the new resized image data
      canvas.id     = "hiddenCanvas";
      canvas.width  = max_width;
      canvas.height = max_height;
      canvas.style.visibility   = "hidden";
      canvas.style.display   = "none";
      document.body.appendChild(canvas);  
  
      //get the context to use 
      context = canvas.getContext('2d');  
  
      // check for an image then
      //trigger the file loader to get the data from the image         
      if (file.type.match('image.*')) {
        fileLoader.readAsDataURL(file);
      } else {
        console.error('File is not an image');
      }
  
      // setup the file loader onload function
      // once the file loader has the data it passes it to the 
      // image object which, once the image has loaded, 
      // triggers the images onload function
      fileLoader.onload = (result) => {
        var data = result.target.result; 
        imageObj.src = data;
      };
  
      fileLoader.onabort = () => {
        console.error("The upload was aborted.");
      };
  
      fileLoader.onerror = () => {
        console.error("An error occured while reading the file.");
      };  
  
      // set up the images onload function which clears the hidden canvas context, 
      // draws the new image then gets the blob data from it
      imageObj.onload = (event) => {  
        console.log(event);
        // Check for empty images
        if (event.path[0].width == 0 || event.path[0].height == 0) {
          console.error('Image is empty');
        } else {                
          context.clearRect(0, 0, max_width, max_height);
          context.drawImage(imageObj, 0, 0, event.path[0].width, event.path[0].height, 0, 0, max_width, max_height);
  
          var blobBin = atob(canvas.toDataURL('image/png').split(',')[1]);
          var array = [];
          for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
  
          var file = new Blob([new Uint8Array(array)], {type: 'image/png'});
          resolve(file);
        }       
      };
  
      imageObj.onabort = () => {
        console.error("Image load was aborted.");
      };
  
      imageObj.onerror = (error) => {
        console.log(error);
        console.error("An error occured while loading image.");
      };
    });
  }
}
