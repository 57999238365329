<nav>
  <app-button-item
    class="nav-menu-button"
    [onlyIcon]="true"
    [title]="'Menu'"
    [icon]="toggleSideMenu === 'open' ? 'menu_open' : 'menu'"
    [color]="'#111111'"
    (fireClick)="toggleSideMenu === 'open' ? toggleSideMenu = 'close' : toggleSideMenu = 'open'"
  ></app-button-item>

  <div routerLink="main" class="image-nav">
    <img src="./../../../assets/img/isotipo_1.svg" alt="Logo wuolah" />
  </div>

  <div routerLink="main" class="trade">
    <div class="title">Ad Manager</div>
    <small>Administrador de anuncios de Wuolah</small>
  </div>
</nav>

<div class="side-menu" [ngClass]="{'menu-opened': toggleSideMenu === 'open'}">
  <app-button-item (fireClick)="openCreateModal(); toggleSideMenu = 'close';" [text]="'Crear anuncio'" [background]="'#102754'" [color]="'#FFFFFF'" [solid]="true"></app-button-item>

    <div class="line"></div>

  <div class="menu-actions">
    <div class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="main" routerLinkActive="active">Inicio</div>
    <div *ngIf="!adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="sponsor/report" routerLinkActive="active">Mis anuncios</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="cropper" routerLinkActive="active">Cortador de imágenes</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="takeover" routerLinkActive="active">Generar takeover params</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="publics" routerLinkActive="active">Campañas pendientes</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="associate" routerLinkActive="active">Asociar clientes</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="admin/sponsor/subject" routerLinkActive="active">Patrocinios</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="generate-segmentation" routerLinkActive="active">Generador segmentaciones</div>
    <div *ngIf="adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';"
      routerLink="url-click-generator" routerLinkActive="active">Generador de URLs</div>    
    <div *ngIf="!adminRole" class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="pixel" routerLinkActive="active">Analytics</div>
    <div class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close';" routerLink="settings" routerLinkActive="active">Configuración</div>
    <div class="action-item" (click)="openAccountChange = false; toggleSideMenu = 'close'; logout();">Cerrar sesión</div>
  </div>

  <div *ngIf="version" class="version">v{{version}}</div>

  <div class="line"></div>

  <div class="account-management-selector" (click)="openAccountChange = !openAccountChange">
    <div class="inner-title">Cuenta</div>
    <div class="inner-data" [ngClass]="adminRole ? 'admin' : ''">
      <div *ngIf="userMe.role === 2" class="icon">
        <i class="material-icons-outlined">keyboard_arrow_up</i>
      </div>

      <div class="text">{{userMe.mail}}</div>
    </div>
  </div>

  <div *ngIf="advertisersAccounts.length > 0" id="chengeAccountElement" class="account-change" [ngClass]="{'open': openAccountChange}">
    <div class="account" (click)="openAccountChange = !openAccountChange">
      <h5 class="wds-h5">Cuentas</h5>
      <span *ngIf="openAccountChange" class="material-icons">keyboard_arrow_down</span>
    </div>
    
    <div *ngIf="adminRole" (click)="loginAsClient(userMe?.userId)" class="account-row">
      <div class="icon">
        <i class="material-icons-outlined">
          check_circle_outline
        </i>
      </div>

      <div class="text"><strong>Admin:</strong> {{userMe.mail}}</div>
    </div>

    <div *ngFor="let account of advertisersAccounts" (click)="loginAsClient(account.userId)" class="account-row">
      <div class="icon">
        <i class="material-icons-outlined">
          check_circle_outline
        </i>
      </div>

      <div class="text" (click)="loginAsClient(account.userId)">{{account.name}}</div>
    </div>
  </div>
</div>

<div class="content">
  <app-root></app-root>
</div>

<div *ngIf="showRequestInitBusiness" class="request-user-business-type" [ngClass]="{'show-request': showRequestBusiness}">
  <div class="rubt-title">¡Hola {{advertiserMe.contactName}}!</div>
  <div class="rubt-subtitle">Nos gustaría saber que tipo de negocio gestionas, <br *ngIf="screen > 765"> para poder ofrecerte una experincia más personalizada.</div>

  <div class="chips-container">
    <div *ngFor="let type of businessTypes" (click)="updateBusinessType(type.value)" class="chip-item">{{type.name}}</div>
  </div>
</div>

<div *ngIf="showRequestInitCity && cities && cities.length > 0" class="request-user-business-type show-request" [ngClass]="{'show-request': showRequestCity}">
  <div class="rubt-title">¡Hola {{advertiserMe.contactName}}!</div>
  <div class="rubt-subtitle">Indicanos en que ciudad se encuentra tu negocio, <br *ngIf="screen > 765"> para poder ofrecerte una experincia más personalizada.</div>

  <input type="text" #searchCityInput class="search-city" placeholder="Busca tu ciudad..." (input)="searchCity($event)">

  <div class="chips-container chips-container-city">
    <div *ngFor="let city of cityResults; let i = index">
      <div *ngIf="i < numberCity" (click)="updateCity(city.id)" class="chip-item">{{city.name | titlecase}}</div>
    </div>
  </div>
</div>

<app-create-pub-modal
  *ngIf="openPubCreator"
  (onClose)="openPubCreator = $event; openSavedSponsor = $event"
></app-create-pub-modal>

<app-saved-sponsor-modal
  *ngIf="openSavedSponsor"
  (onSave)="openSavedSponsor = false;"
></app-saved-sponsor-modal>