import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '../translate.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {

  constructor(
    public translateService: TranslateService,
  ) {

  }
  transform(key: string, args?: any[], language?: string) {
    let translations = this.translateService.getTranslations();
    const keys = key.split('.');
    let res;

    if (translations) {
      if (keys.length == 1 && translations[keys[0]]) {
        res = translations[keys[0]];
      } else if (keys.length == 2 && translations[keys[0]] && translations[keys[0]][keys[1]]) {
        res = translations[keys[0]][keys[1]];
      } else if (keys.length == 3 && translations[keys[0]] && translations[keys[0]][keys[1]] && translations[keys[0]][keys[1]][keys[2]]) {
        res = translations[keys[0]][keys[1]][keys[2]];
      }

      if (res && args && args.length){
        for (var i = 0; i < args.length; i++){
          res = res.replace('%s', args[i]);
        }
      }
      return res;
    }

    console.error(`key '${key}' not found in translate`);

    return key;
  }
}
