<div class="body-container" infiniteScroll [infiniteScrollDistance]="100" [infiniteScrollContainer]="'.body-container'"
  [fromRoot]="true" (scrolled)="onScroll()">
  <h1 class="wds-h1">Patrocinar asignaturas</h1>

  <div class="search-type-container">
    <div [ngClass]="{'selected': searchMode === 'widgets'}" (click)="searchMode = 'widgets'; handleSearch()"
      class="search-type-item">Widgets</div>
    <div [ngClass]="{'selected': searchMode === 'folders'}" (click)="searchMode = 'folders'; handleSearch()"
      class="search-type-item">Asignaturas</div>
  </div>

  <div class="table-container filters" [ngClass]="{'folders': searchMode === 'folders'}">
    <div class="table-row">
      <div *ngIf="searchMode === 'widgets'" class="table-column">
        <div class="status-ball" (click)="toggleWidget()" [ngClass]="{'sponsored': searchData.status}">
          <i class="material-icons-outlined">paid</i>
        </div>
      </div>

      <div class="input-element">
        <label class="input-element-label">Nombre de la asignatura</label>
        <input 
          placeholder="Nombre de la asignatura" 
          [(ngModel)]="searchData.folder" 
          type="text"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element" *ngIf="searchMode === 'widgets'">
        <label class="input-element-label">userId</label>
        <input 
          placeholder="userId" 
          [(ngModel)]="searchData.userId" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">studyType</label>
        <input 
          placeholder="studyType"
          [(ngModel)]="searchData.studyType" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">folderId</label>
        <input 
          placeholder="folderId" 
          [(ngModel)]="searchData.folderId" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">centerId</label>
        <input 
          placeholder="centerId" 
          [(ngModel)]="searchData.centerId" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">studyId</label>
        <input 
          placeholder="studyId" 
          [(ngModel)]="searchData.studyId" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">course</label>
        <input 
          placeholder="course" 
          [(ngModel)]="searchData.course" 
          type="number"
          (input)="searchHandler($event)"
        />
      </div>
      <div class="table-column actions">
        <app-button-item (click)="handleSearch()" [onlyIcon]="true" [title]="'Ver detalles'" [icon]="'search'"
          [solid]="true" [background]="'#0055ff'" [color]="'#ffffff'"></app-button-item>
      </div>
    </div>
  </div>

  <div *ngIf="searchMode === 'widgets'" class="table-container">
    <div class="table-row table-header">
      <div class="table-column">Patr.</div>
      <div class="table-column">Nombre</div>
      <div class="table-column">userId</div>
      <div class="table-column">studyType</div>
      <div class="table-column">folderId</div>
      <div class="table-column">centerId</div>
      <div class="table-column">studyId</div>
      <div class="table-column">course</div>
      <div class="table-column"></div>
    </div>

    <div *ngIf="folders && folders.length > 0">
      <div *ngFor="let folder of folders; let i = index" class="table-row">
        <div class="table-column">
          <div class="status-ball" [ngClass]="{'sponsored': folder.status === 1}">
            <i class="material-icons-outlined">paid</i>
          </div>
        </div>
        <div class="table-column">{{folder.folderName}}</div>
        <div class="table-column">{{folder.userId}}</div>
        <div class="table-column">{{folder.studyType}}</div>
        <div class="table-column">{{folder.folderId}}</div>
        <div class="table-column">{{folder.centerId}}</div>
        <div class="table-column">{{folder.studyId}}</div>
        <div class="table-column">{{folder.course}}</div>
        <div class="table-column actions">
          <app-button-item [onlyIcon]="true" [title]="'Ver detalles'"
            [routerLink]="folder.folderId + '/' + folder.centerId + '/' + folder.studyId + '/' + folder.course + '/' + folder.folderName"
            [icon]="'keyboard_arrow_right'" [solid]="true" [background]="'#0055ff'" [color]="'#ffffff'">
          </app-button-item>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="searchMode === 'folders'" class="table-container folders">
    <div class="table-row table-header">
      <div class="table-column">Nombre</div>
      <div class="table-column">studyType</div>
      <div class="table-column">folderId</div>
      <div class="table-column">centerId</div>
      <div class="table-column">studyId</div>
      <div class="table-column">course</div>
      <div class="table-column"></div>
    </div>

    <div *ngIf="folders && folders.length > 0">
      <div *ngFor="let folder of folders; let i = index" class="table-row">
        <div class="table-column">{{folder?.folder}}</div>
        <div class="table-column">{{folder?.studyType}}</div>
        <div class="table-column">{{folder?.folderId}}</div>
        <div class="table-column">{{folder?.centerId}}</div>
        <div class="table-column">{{folder?.studyId}}</div>
        <div class="table-column">{{folder?.course}}</div>
        <div class="table-column actions">
          <app-button-item [disabled]="!folder.folderId || !folder.centerId || !folder.studyId || !folder.course"
            [ngClass]="{'disabled': !folder.folderId || !folder.centerId || !folder.studyId || !folder.course}"
            [onlyIcon]="true" [title]="'Ver detalles'"
            [routerLink]="folder.folderId + '/' + folder.centerId + '/' + folder.studyId + '/' + folder.course + '/' + folder.folder"
            [icon]="'keyboard_arrow_right'" [solid]="true" [background]="'#0055ff'" [color]="'#ffffff'">
          </app-button-item>
        </div>
      </div>
    </div>
  </div>
</div>