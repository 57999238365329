<nav>
  <div class="logo">
    <img src="https://www.wuolah.com/static/media/res/mark/logo.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <h4>Ad Manager</h4>
</nav>

<div class="container">
  <div class="inner-container">
    <div class="inputs-container">
      <h5 class="wds-h5">Completa tu perfil de empresa.</h5>

      <form>
        <div style="display: none;" class="input-element">
          <label class="input-element-label">Email</label>
          <input placeholder="javier@tuempresa.com" name="email" type="email" [(ngModel)]="data.email">
        </div>

        <div class="input-element">
          <label class="input-element-label">Nombre de contacto</label>
          <input placeholder="Juan Carlos" name="firstname" type="text" [(ngModel)]="data.firstname">
        </div>

        <div class="input-element">
          <label class="input-element-label">Número de teléfono</label>
          <input placeholder="600 100 200" name="mobilephone" type="tel" [(ngModel)]="data.mobilephone">
        </div>

        <div class="input-element">
          <label class="input-element-label">Nombre de la empresa</label>
          <input placeholder="Mi empresa S.L." name="name" type="text" [(ngModel)]="data.name">
        </div>

        <div class="input-element">
          <label class="input-element-label">Provincia</label>

          <div class="input-element-select">
            <div class="select-custom-caret">
              <i class="material-icons-outlined">arrow_drop_down</i>
            </div>
            
            <select [disabled]="!cities || cities.length <= 0" [(ngModel)]="data.cityId" name="cityId" id="cityId">
              <option disabled [value]="-1">Selecciona tu ciudad</option>
              <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
            </select>
          </div>
        </div>

        <div class="input-element" style="display: none;">
          <label class="input-element-label">Tipo de negocio</label>

          <div class="input-element-select">
            <div class="select-custom-caret">
              <i class="material-icons-outlined">arrow_drop_down</i>
            </div>
            
            <select [(ngModel)]="data.businessType" name="businessType" placeholder="Tipo de negocio">
              <option disabled [value]="'INITIAL'">Tipo de negocio</option>
              <option *ngFor="let bType of businessTypes; let i = index" [value]="bType.value">{{bType.name}}</option>
            </select>
          </div>
        </div>

        <app-button-item 
          (fireClick)="completeProfile()" 
          [background]="'#102754'" 
          [color]="'#FFFFFF'" 
          [text]="'Crear perfil'" 
          [solid]="true" 
          [disabled]="checkDisabled()"
        ></app-button-item>
      </form>
    </div>
  </div>
</div>

<ng-template #itemTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>