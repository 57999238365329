import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-saved-sponsor-modal',
  templateUrl: './saved-sponsor-modal.component.html',
  styleUrls: ['./saved-sponsor-modal.component.css']
})
export class SavedSponsorModalComponent implements OnInit {

  @Output() onSave = new EventEmitter();

  open = false;

  constructor(
    private router: Router,
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.open = true;
    }, 100);
  }

  close(){
    this.open = false;

    setTimeout(() => {
      this.onSave.emit();
    }, 150);
  }

  gotToSponsorSave() {
    this.router.navigate(["/dashboard/sponsor"]);
  }

  gotToSponsor() {
    localStorage.removeItem('dataGeneralInformation');
    localStorage.removeItem('buttons');
    localStorage.removeItem('contacts');
    localStorage.removeItem('features');
    localStorage.removeItem('signatures');
    localStorage.removeItem('dataUpload');
    localStorage.removeItem('croppedCreativities');
    localStorage.removeItem('widgets');
    this.router.navigate(["/dashboard/sponsor"]);
  }

}
