import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable()

export class ResourceService extends RequestService {

  getResources(data?: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/browser', data),
      { headers: this.headers() }
    ));
  }

  getCity(data: any) {
    return this.request(this.httpClient.get(
      this.environment.API + '/city/' + data,
      { headers: this.headers() }
    ));
  }
}