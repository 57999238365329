import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// Services
import { UtilsService } from 'src/app/services/utils.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-create-pub-modal',
  templateUrl: './create-pub-modal.component.html',
  styleUrls: ['./create-pub-modal.component.css']
})
export class CreatePubModalComponent implements OnInit {

  open = false;

  sponsorStorage = null;
  uuid: string;

  itemList = [
    {
      title: 'Campaña publicitaria',
      description: 'Dirigida para hacer promoción en masa o segmentada, según se adapte a tus necesidades como negocio.',
      route: '/dashboard/create',
      image: '../assets/img/create-pub-modal/rocket-white.svg',
      backgroundColor: '#0055FF',
    },
    {
      title: 'Patrocinar asignatura',
      description: 'Dirigido a asignaturas de formación que quieran tener un alcance con su público objetivo, segmentado a las asignaturas más demandadas.',
      route: '/dashboard/sponsor',
      image: '../assets/img/create-pub-modal/shield-black.svg',
      backgroundColor: '#cccccc',
    }
  ]

  userId: string;

  @Output() onClose = new EventEmitter();

  constructor(
    public utilsService: UtilsService,
    public analyticService: AnalyticService,
    public storageService: StorageService,
  ) { 
    this.utilsService.subscribe("create_pub_modal_action", (data: any) => {
      if (typeof data !== "undefined" && data) {
        if (data.action === 'close') {
          this.close();
        }
      }
    });

    let checkStorageInformationGeneral = JSON.parse(localStorage.getItem("dataGeneralInformation"));
    let checkStorageInformationSignature = JSON.parse(localStorage.getItem("signatures"));
    let checkStorageInformationButton = JSON.parse(localStorage.getItem("buttons"));
    let checkStorageInformationContact = JSON.parse(localStorage.getItem("contacts"));
    let checkStorageInformationFeature = JSON.parse(localStorage.getItem("features"));
    let checkStorageInformationCreativity = JSON.parse(localStorage.getItem("dataUpload"));

    let checkStorageAll = [checkStorageInformationGeneral, checkStorageInformationSignature, checkStorageInformationButton, checkStorageInformationContact, checkStorageInformationFeature, checkStorageInformationCreativity];
    
    let i = 0;

    while(i < checkStorageAll.length) {
      if (checkStorageAll[i] != null) {
        this.sponsorStorage = checkStorageAll[i];
      }
      i++;
    }
    
    this.userId = this.storageService.read('userId');
    this.uuid = this.storageService.read('uuid');

  }

  ngOnInit(): void { 
    this.uuid = this.storageService.read('uuid');

    setTimeout(() => {
      this.open = true;
    }, 100);
  }

  goToSponsor(e) {

    if (e == 'Patrocinar asignatura') {
      this.analyticService.customEvent(
        'UP2_02_2_CLICK',
        {
          uuid: this.uuid,
          userId: this.userId,
        }
      );
      if (this.sponsorStorage != null) {
        this.open = true;
        setTimeout(() => {
          this.onClose.emit(true);
        }, 150);
      }else{
        this.open = false;
  
        setTimeout(() => {
          this.onClose.emit(false);
        }, 150);
      }  
    }else{

      this.analyticService.customEvent(
        'UP2_02_1_CLICK',
        {
          uuid: this.uuid,
          userId: this.userId,
        }
      );

      this.sponsorStorage = false;
  
      setTimeout(() => {
        this.onClose.emit(false);
      }, 150);
    }
  }

  close() {
    setTimeout(() => {
      this.onClose.emit(false);
    }, 150);
  }
}
