<nav>
  <div class="logo">
    <img src="https://www.wuolah.com/static/media/res/mark/logo.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <h4>Ad Manager <small *ngIf="version" class="version">v{{version}}</small></h4>

  <div class="actions">
    <div (click)="goToLogin()" class="action-item">Inicia sesión</div>
  </div>
</nav>

<div class="main-container">
  <div class="inner-container">
    <div class="inputs-container">
      <h5 class="wds-h5">IMPACTA DURANTE HORAS</h5>

      <h1>Anúnciate en los apuntes.</h1>

      <h6>Cada día miles de estudiantes descargan apuntes de Wuolah. Anúnciate en sus apuntes.</h6>

      <form>
        <div class="input-element">
          <label class="input-element-label">Email</label>
          <input placeholder="javier@tuempresa.com" name="mail" type="email" [(ngModel)]="credentials.mail">
        </div>

        <div class="input-element">
          <label class="input-element-label">Tipo de negocio</label>

          <div class="input-element-select">
            <div class="select-custom-caret">
              <i class="material-icons-outlined">arrow_drop_down</i>
            </div>
            
            <select [(ngModel)]="businessType" name="businessType" placeholder="Tipo de negocio">
              <option disabled [value]="'INITIAL'">Tipo de negocio</option>
              <option *ngFor="let bType of businessTypes; let i = index" [value]="bType.value">{{bType.name}}</option>
            </select>
          </div>
        </div>

        <div class="input-element">
          <label class="input-element-label">Contraseña</label>
          <input placeholder="********" name="password" type="password" [(ngModel)]="credentials.password">
        </div>

        <a href="#">Al registrarme, acepto los Términos y condiciones y la Política de privacidad.</a>

        <app-button-item (fireClick)="signUp()" [background]="'#102754'" [color]="'#FFFFFF'" [text]="'Crear cuenta'" [solid]="true" [disabled]="checkDisabled()"></app-button-item>
      </form>
    </div>

    <div class="asset"></div>
  </div>
</div>

<div class="mobile-frame">
  <div class="m-logo">
    <img src="https://wuolah-web.s3-eu-west-1.amazonaws.com/wuolah-public/static/media/res/mark/logo-ffffff.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <div class="copy">
    <h1>Anúnciate en los apuntes.</h1>
    <h5 class="wds-h5">IMPACTA DURANTE HORAS</h5>
  </div>

  <div class="slider-inner-container">
    <swiper [slidesPerView]="1.5" [spaceBetween]="16">
      <ng-template *ngFor="let dummy of ' '.repeat(8).split(''), let i = index" swiperSlide>
        <div class="note-item" [style.backgroundImage]="'url(/assets/img/index-slide-' + (i + 1) + '.jpg)'"></div>
      </ng-template>
    </swiper>
  </div>

  <div class="m-buttons">
    <app-button-item [routerLink]="'/login'" [color]="'#FFFFFF'" [text]="'Iniciar sesión'" [outlined]="true"></app-button-item>
    <app-button-item (fireClick)='createAccount = true;' [background]="'#FFFFFF'" [color]="'#102754'" [text]="'Crear cuenta'" [solid]="true"></app-button-item>
  </div>
</div>

<div *ngIf="createAccount" class="inputs-container-mobile">
  <h5 class="wds-h5">IMPACTA DURANTE HORAS</h5>

  <h1>Anúnciate en los apuntes.</h1>

  <h6>Cada día miles de estudiantes descargan apuntes de Wuolah. Anúnciate en sus apuntes.</h6>

  <form>
    <div class="input-element">
      <label class="input-element-label">Email</label>
      <input placeholder="javier@tuempresa.com" name="mail" type="email" [(ngModel)]="credentials.mail">
    </div>

    <div class="input-element">
      <label class="input-element-label">Tipo de negocio</label>

      <div class="input-element-select">
        <div class="select-custom-caret">
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
        
        <select [(ngModel)]="businessType" name="businessType" placeholder="Tipo de negocio">
          <option disabled [value]="'INITIAL'">Tipo de negocio</option>
          <option *ngFor="let bType of businessTypes; let i = index" [value]="bType.value">{{bType.name}}</option>
        </select>
      </div>
    </div>

    <div class="input-element">
      <label class="input-element-label">Contraseña</label>
      <input placeholder="********" name="password" type="password" [(ngModel)]="credentials.password">
    </div>

    <a href="#">Al registrarme, acepto los Términos y condiciones y la Política de privacidad.</a>

    <app-button-item (fireClick)="signUp()" [background]="'#102754'" [color]="'#FFFFFF'" [text]="'Crear cuenta'" [solid]="true" [disabled]="checkDisabled()"></app-button-item>
  </form>
</div>

<div *ngIf="showRequestInit" class="request-user-business-type" [ngClass]="{'show-request': showRequest}">
  <div class="rubt-title">¡Hola!</div>
  <div class="rubt-subtitle">¿Qué te ha traído hasta aquí?</div>

  <div class="chips-container">
    <div (click)="isAStudent(0)" class="chip-item">Quiero crear un anuncio con Wuolads</div>
    <a href="https://wuolah.com?utm_source=wuolah&utm_medium=intralink&utm_campaign=differentiation-modal">
      <div (click)="isAStudent(1)" class="chip-item">Soy un estudiante extraviado</div>
    </a>
  </div>
</div>