import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: "app-widget-item",
  templateUrl: "./widget-item.component.html",
  styleUrls: ["./widget-item.component.css"],
})
export class WidgetItemComponent implements OnInit {
  
  @Input("generalInformation") generalInformation: any;
  @Input("buttons") dataButtons: any;
  @Input("contacts") dataContacts = [];
  @Input("features") dataFeatures = [];

  showImage: boolean = true;

  constructor(
    private router: Router,
    public utilsService: UtilsService,
    ) {
      
    // This is for reload the images

    this.utilsService.subscribe("reload_widget", (data: any) => {

      if (typeof data !== "undefined" && data) {
        this.showImage = false;
        setTimeout(() => {
          this.ngOnInit();
        }, 10);
      }
    });
  }

  ngOnInit(): void {
    this.showImage = true;
  }

  getWebUrl(web?) {
    let result = web;
    if (!web?.includes("https://")) {
      result = "https://" + web;
    }
    return result;
  }

  getWhatsappUrl(number?) {
    return "https://api.whatsapp.com/send?phone=34" + number;
  }
}
