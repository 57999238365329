import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

//Services
import { UtilsService } from "src/app/services/utils.service";
import { StorageService } from 'src/app/services/storage.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { Title } from "@angular/platform-browser";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: "app-dashboard-sponsor",
  templateUrl: "./dashboard-sponsor.component.html",
  styleUrls: ["./dashboard-sponsor.component.css"],
})
export class DashboardSponsorComponent implements OnInit {
  @Input("data") data: any;
  dataUpload: any;

  magnify = {
    portrait: false,
    landscape: false,
  };

  creativityView = true;
  widgetView = true;
  selectedSignatureView = true;

  paymentPage = false;
  creativitiesPage = false;
  segmentationPage = false;

  paymentData = {
    payment: null,
    user: null,
  };

  folders = [];
  widgetData = {
    name: null,
    imageLogo: null,
    imageBack: null,
    title: null,
    description: null,
    phone: null,
    email: null,
    web: null,
    secTitle: null,
    quantity: 0,
    data: [],
  };

  generalInformation = {};
  buttons = [];
  contacts = [];
  features = [];

  closeSponsor = false;

  checkStorageInformation = null;

  viewSignatures = 'Ver todas';

  viewPayment= 'Ver más';

  template = {
    portrait: null,
    landscape: null
  };
  
  uuid: string;
  userId: string;

  dataSend: any;

  constructor(
    public utilsService: UtilsService,
    public router: Router,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public userService: UserService,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.utilsService.publish("folders", this.folders);
      }
    });

    this.utilsService.subscribe("folders", (data: any) => {
      this.folders = data;
    });

    this.utilsService.subscribe("close_sponsor_modal", (data: any) => {
      this.closeSponsor = data;
    });

    this.utilsService.subscribe("change_widget_general_data", (data: any) => {
      this.generalInformation = data;
    });

    this.utilsService.subscribe("change_widget_buttons_data", (data: any) => {
      this.buttons = data;
    });

    this.utilsService.subscribe("change_widget_contacts_data", (data: any) => {
      this.contacts = data;
    });

    this.utilsService.subscribe("change_widget_features_data", (data: any) => {
      this.features = data;
    });

    this.utilsService.subscribe("change_creativities_data", (data: any) => {
        this.dataUpload = data;
    });

    this.utilsService.subscribe("creativity_template", (data: any) => {
      let template = data;
      this.selectTemplate(template);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.paymentPage =
          this.route.snapshot.firstChild.routeConfig.path === "subject/4";
        this.creativitiesPage =
        this.route.snapshot.firstChild.routeConfig.path === "subject/3";
        this.widgetView = !this.creativitiesPage;
        if (this.route.snapshot.firstChild.routeConfig.path === "subject/1" || this.route.snapshot.firstChild.routeConfig.path === "") {
          this.segmentationPage = true;
        }else{
          this.segmentationPage = false;
        }
      }
    });

    this.utilsService.subscribe("paymentData", (data: any) => {
      if (typeof data !== "undefined" && data) {
        this.paymentData = data;
      }
    });

    this.utilsService.publish("magnify_creativity", this.magnify);

    let checkStorageInformation = JSON.parse(localStorage.getItem("dataGeneralInformation"));

    if (checkStorageInformation) {
      this.checkStorageInformation = checkStorageInformation;
    }

    let checkSignatures = JSON.parse(localStorage.getItem("signatures"));
    if (checkSignatures) {
      this.folders = checkSignatures;
    }

    this.userId = this.storageService.read('userId');
    this.uuid = this.storageService.read('uuid');
    this.dataSend = this.storageService.read('advertiserMe');

  }

  ngOnInit(): void {
    const user: any = this.storageService.read('userMe');
    
    this.titleService.setTitle('Patrocinio de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor', 'Subject sponsorship');
    this.analyticService.screenView('Subject sponsorship');    

    const data = {
      step: 'SPONSORSUBJECT'
    };
    let notification: any = this.storageService.read("notification");
    if (!notification) {
      notification = {
        sponsor: 1,
        campaign: null,
        completeProfile: null,
        signUp: null,
      }
      this.userService.sendStepNotification(data);
    } else {
      if (notification?.sponsor && notification?.sponsor < 5) {
        this.userService.sendStepNotification(data);
        notification.sponsor = notification?.sponsor + 1;
      } else if (!notification?.sponsor) {
        notification.sponsor = 1;
        this.userService.sendStepNotification(data);
      }
    }
    this.storageService.write("notification", notification);

    this.analyticService.customEvent(
      'UP2_03_0_VISIT',
      {
        uuid: this.uuid,
        userId: this.userId,
      }
    );
  }

  close() {}

  selectTemplate(template){
    switch (template){
      case 1:
        this.template.portrait = 'assets/templates/indigo_portrait.jpg';
        this.template.landscape = 'assets/templates/indigo_landscape.jpg';
        break;
      case 2:
        this.template.portrait = 'assets/templates/mondrian_portrait.jpg';
        this.template.landscape = 'assets/templates/mondrian_landscape.jpg';
        break;
        case 3:
        this.template.portrait = 'assets/templates/light_portrait.jpg';
        this.template.landscape = 'assets/templates/light_landscape.jpg';
        break;
      case 4:
        this.template.portrait = 'assets/templates/minimal_portrait.jpg';
        this.template.landscape = 'assets/templates/minimal_landscape.jpg';
        break;
    }
  }

  activatedRoutes(): number {
    let path = this.route.snapshot.firstChild.routeConfig.path;
    if (path === "subject/1" || path === "") {
      return 1;
    } else if (path === "subject/2") {
      return 2;
    }
    if (path === "subject/3") {
      return 2;
    }
    if (path === "subject/4") {

      this.checkStorageInformation = 0;

      return 3;
    }
  }

  getTotal() {
    let result = this.paymentData?.payment?.price;
    if (this.paymentData?.payment?.discount) {
      result = (
        this.paymentData?.payment?.price -
        this.paymentData?.payment?.price * this.paymentData?.payment?.discount
      ).toFixed(2);
    }
    result=result*this.folders.length;
    if (this.paymentData?.payment?.name.includes("Trimestral")) {
      result = (3*result).toFixed(2) + "€/trimestre";
    } else if (this.paymentData?.payment?.name.includes("Anual")) {
      result = (12*result).toFixed(2) + "€/año";
    } else if (this.paymentData?.payment?.name.includes("Mensual")) {
      result = result + "€/mes";
    }

    return result;
  }

  getDiscount() {
    let result = this.paymentData?.payment?.price;
    if (this.paymentData?.payment?.discount) {
      result = (
        this.paymentData?.payment?.price * this.paymentData?.payment?.discount
      ).toFixed(2);
    }
    return result;
  }

  getDiscountPercentage() {
    return this.paymentData?.payment?.discount * 100;
  }

  renewCreativity(){
    this.utilsService.publish("renew_creativity", true);
  }
}
