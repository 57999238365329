<div class="container">
  <div class="asset">
    <div class="dummy-positioning d-flex">
  
      <div class="success-icon">
        <div class="success-icon__tip"></div>
        <div class="success-icon__long"></div>
      </div>
      
    </div>
  </div>

  <h1>¡Muchas gracias por tu compra!</h1>

  <p>
    Esperamos verte pronto por aquí. Disfruta del producto que acabas de contratar.
  </p>

  <app-button-item routerLink="/" [text]="'Volver al dashboard'" [solid]="true"></app-button-item>
</div>