import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { ResourceService } from "src/app/services/resource.service";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-filter-item",
  templateUrl: "./filter-item.component.html",
  styleUrls: ["./filter-item.component.css"],
})
export class FilterItemComponent implements OnInit {
  
  @ViewChild("input", { static: false }) input: ElementRef;
  
  @Input("placeholder") placeholder: string;
  @Input("type") type: string;
  @Input("filters") filters: any;
  
  @Output() buttonClick = new EventEmitter<void>();
  
  wasInside = true;
  objects: any;
  data: any;

  checkedObjects = {
    university: null,
    center: null,
    study: null,
    course: null,
  };

  searchData = {
    text: null,
    universityId: null,
    centerId: null,
    offset: 0,
    limit: 25,
    types: null,
    studyId: null,
  };

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }
  
  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.buttonClick.emit();
    }

    this.wasInside = false;
  }

  constructor(
    public utilsService: UtilsService,
    public resourceService: ResourceService
  ) {
    this.objects = [];
  }

  ngOnInit(): void {
    this.checkedObjects.university = this.filters?.university;
    this.checkedObjects.center = this.filters?.center;
    this.checkedObjects.study = this.filters?.study;
    this.checkedObjects.course = this.filters?.course;

    if (this.type === "course") {
      this.getCourses();
    }

    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 80);
  }

  searchHandler(event) {
    let delay = 500;

    if (event.keyCode == 13) {
      delay = 0;
    }

    this.manageInputDelay(delay, () => {
      if (event.target.value !== this.searchData.text) {
        this.searchData = {
          text: event.target.value,
          universityId: null,
          centerId: null,
          offset: 0,
          limit: 25,
          types: null,
          studyId: null,
        };

        this.objects = [];

        if (this.type === "course") {
          this.getCourses();
        } else {
          this.search();
        }
      }
    }, []);
  }

  manageInputDelay(delay, callback, args) {
    setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  }

  search() {
    this.searchData.types = this.type;
    this.searchData.universityId =
      typeof this.checkedObjects?.university?.id !== "undefined" &&
      this.type !== "university"
        ? this.checkedObjects?.university?.id
        : null;
    this.searchData.centerId =
      typeof this.checkedObjects?.center?.id !== "undefined" &&
      this.type !== "center"
        ? this.checkedObjects?.center?.id
        : null;
    this.searchData.studyId =
      typeof this.checkedObjects?.study?.studyId !== "undefined" &&
      this.type !== "study"
        ? this.checkedObjects?.study?.studyId
        : null;
    const data = Object.assign({}, this.searchData);
    
    this.resourceService.getResources(data).then(
      (response) => {
        if (response?.results.length > 0) {
          if (!this.objects) {
            this.objects = [];
          }

          this.objects = this.objects.concat(response?.results);
          this.objects = this.objects.reduce((accumalator, current) => {
            if (
              !accumalator.some((item) => item.id === current.id && item.name === current.name)
            ) {
              accumalator.push(current);
            }

            return accumalator;
          }, []);
        }
      }
    ).catch(
      (error) => {
        this.objects = [];
      }
    );
  }

  getCourses() {
    for (let i = 1; i <= this.checkedObjects.study?.courses; i++) {
      this.objects.push({ id: i, name: i });
    }
  }

  getObject() {
    if (this.type === "university") {
      return this.checkedObjects.university;
    } else if (this.type === "center") {
      return this.checkedObjects.center;
    } else if (this.type === "study") {
      return this.checkedObjects.study;
    } else if (this.type === "course") {
      return this.checkedObjects.course;
    }
  }

  checkObject(object) {
    let obj = this.getObject();
    let checked = false;
    const result = obj?.id === object?.id;
    if (typeof result === "undefined" || result) {
      checked = true;
    }
    return checked;
  }

  objectStatus(object) {
    if (
      this.type === "university" &&
      this.checkedObjects.university?.id !== object?.id
    ) {
      this.checkedObjects.university = object;
    } else if (
      this.type === "university" &&
      this.checkedObjects.university?.id === object?.id
    ) {
      this.checkedObjects.university = null;
    }
    if (
      this.type === "center" &&
      this.checkedObjects.center?.id !== object?.id
    ) {
      this.checkedObjects.center = object;
    } else if (
      this.type === "center" &&
      this.checkedObjects.center?.id === object?.id
    ) {
      this.checkedObjects.center = null;
    }
    if (this.type === "study" && this.checkedObjects.study?.id !== object?.id) {
      this.checkedObjects.study = object;
    } else if (
      this.type === "study" &&
      this.checkedObjects.study?.id === object?.id
    ) {
      this.checkedObjects.study = null;
    }
    if (
      this.type === "course" &&
      this.checkedObjects.course?.id !== object?.id
    ) {
      this.checkedObjects.course = object;
    } else if (
      this.type === "couse" &&
      this.checkedObjects.course?.id === object?.id
    ) {
      this.checkedObjects.course = null;
    }
  }

  onScroll() {
    this.searchData.offset = this.searchData.offset + this.searchData.limit;
    if (this.type !== "course") {
      this.search();
    }
  }

  throwData() {
    this.utilsService.publish("checked_data", this.checkedObjects);
  }
}
