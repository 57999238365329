<div class="body-container padding-bottom">
  <h1 class="wds-h1">Patrocinar<span *ngIf="folderName">: {{folderName}}</span></h1>

  <div class="inner-container">
    <div class="left-side">
      <div class="inner-title">Patrocinios</div>

      <div class="soponsors-container">
        <div (click)="handleWidgetCreation()" class="sponsor-item create">
          <div class="title">Crear nuevo patrocinio</div>
        </div>

        <div *ngIf="checkCopy()" (click)="handleWidgetCreationFromCopy()" class="sponsor-item create create-from">
          <div class="title">Crear nuevo patrocinio desde copia</div>
        </div>

        <div *ngIf="widgets && widgets.length">
          <div *ngFor="let widget of widgets; let i = index" (click)="openWidget(widget.id, i)" class="sponsor-item"
            [ngClass]="{'selected': selectedWidget === i}">
            <div class="title">{{widget.name}}</div>
            <div *ngIf="widget.user && widget.user.name" class="subtitle">{{widget.user.name}}</div>
            <div *ngIf="!widget.user || !widget.user.name" class="subtitle">-</div>

            <div *ngIf="widget.status === 0" class="status-label inactive">Inactivo</div>
            <div *ngIf="widget.status === 1" class="status-label">Activo</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(selectedWidget > -1 && mode) || (mode && mode === 'create')" class="right-side">
      <div class="inner-title">Detalles</div>

      <div class="inputs-container">
        <div *ngIf="(config && config.id) || fromCopy" class="status-container">
          <div class="status-text">Cambiar estado del widget (solo puede haber uno activo por asignatura)</div>

          <div (click)="oneActive(config) ? '' : toggleWidgetStatus()" class="status-ball"
            [ngClass]="{'sponsored': config.status === 1}">
            <i *ngIf="config.status === 0" class="material-icons-outlined">toggle_off</i>
            <i *ngIf="config.status === 1" class="material-icons-outlined">toggle_on</i>
          </div>
        </div>

        <div class="input-element">
          <label class="input-element-label">ID de usuario</label>
          <input
            placeholder="ID de usuario"
            [(ngModel)]="config.userId"
            type="number"
          />
        </div>

        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">Background</label>
            <input
              [disabled]="true"
              placeholder="Background"
              [(ngModel)]="config.backgroundUrl"
              type="text"
            />
          </div>
          <div *ngIf="(config && config.id)" (click)="openMediaInput('background')" class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>

        <div class="input-element">
          <label class="input-element-label">Tipo</label>
          <select [(ngModel)]="config.type">
            <option disabled [value]="-1">Selecciona tu empresa</option>
            <option value="ACADEMY">Academia</option>
            <option value="UNIVERSITY">Universidad</option>
          </select><br>
        </div>

        <div class="input-element">
          <label class="input-element-label">Nombre</label>
          <input
            placeholder="Nombre"
            [(ngModel)]="config.name"
            type="text"
          />
        </div>
        <div class="input-element">
          <label class="input-element-label">Título</label>
          <input
            placeholder="Título"
            [(ngModel)]="config.title"
            type="text"
          />
        </div>
        <div class="input-element">
          <label class="input-element-label">Descripción</label>
          <input
            placeholder="Descripción"
            [(ngModel)]="config.description"
            type="text"
          />
        </div>
        <div class="input-element">
          <label class="input-element-label">Subtítulo</label>
          <input
            placeholder="Subtítulo"
            [(ngModel)]="config.subtitle"
            type="text"
          />
        </div>
        <div class="input-element">
          <label class="input-element-label">Texto de acción</label>
          <input
            placeholder="Texto de acción"
            [(ngModel)]="config.actionText"
            type="text"
          />
        </div>
        <div class="input-element">
          <label class="input-element-label">URL de acción</label>
          <input
            placeholder="URL de acción"
            [(ngModel)]="config.actionUrl"
            type="text"
          />
        </div>

        <div *ngIf="(config && config.id) || fromCopy" class="related-content">
          <div class="inner-title">
            <span>Métodos de contacto</span>
            <app-button-item (fireClick)="openSlide('contacts', 'creation')" [onlyIcon]="true" [color]="'#0055FF'"
              [outlined]="true" [title]="'Añadir nuevo'" [icon]="'add'"></app-button-item>
          </div>

          <div *ngIf="config?.contacts && config?.contacts.length > 0" class="items-list">
            <div *ngFor="let contact of config?.contacts; let i = index" class="listed-item">
              <div class="innet-part">{{contact?.name}}</div>
              <div class="innet-part">{{contact?.value}}</div>
              <div class="innet-part-actions">
                <app-button-item class="button" (fireClick)="openSlide('contacts', 'edition', i)" [onlyIcon]="true" [title]="'Editar'"
                  [icon]="'create'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
                <app-button-item class="button" (fireClick)="handleDelete('contact', i)" [onlyIcon]="true" [title]="'Eliminar'"
                  [icon]="'delete'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
              </div>
            </div>
          </div>

          <div *ngIf="!config.contacts || config.contacts.length <= 0" class="items-list">
            <div class="listed-item no-content">
              <div class="innet-part">No has añadido métodos de contacto</div>
            </div>
          </div>

          <div class="inner-title">
            <span>Botones</span>
            <app-button-item (fireClick)="openSlide('buttons', 'creation')" [onlyIcon]="true" [color]="'#0055FF'"
              [outlined]="true" [title]="'Añadir nuevo'" [icon]="'add'"></app-button-item>
          </div>

          <div *ngIf="config.buttons && config.buttons.length > 0" class="items-list">
            <div *ngFor="let button of config.buttons; let i = index" class="listed-item">
              <div class="innet-part">{{button.name}}</div>
              <div class="innet-part">{{button.value}}</div>
              <div class="innet-part-actions">
                <app-button-item class="button" (fireClick)="openSlide('buttons', 'edition', i)" [onlyIcon]="true" [title]="'Editar'"
                  [icon]="'create'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
                <app-button-item class="button" (fireClick)="handleDelete('button', i)" [onlyIcon]="true" [title]="'Eliminar'"
                  [icon]="'delete'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
              </div>
            </div>
          </div>

          <div *ngIf="!config.buttons || config.buttons.length <= 0" class="items-list">
            <div class="listed-item no-content">
              <div class="innet-part">No has añadido botones</div>
            </div>
          </div>

          <div class="inner-title">
            <span>Productos / Servicios</span>
            <app-button-item (fireClick)="openSlide('features', 'creation')" [onlyIcon]="true" [color]="'#0055FF'"
              [outlined]="true" [title]="'Añadir nuevo'" [icon]="'add'"></app-button-item>
          </div>

          <div *ngIf="config.features && config.features.length > 0" class="items-list">
            <div *ngFor="let feature of config.features; let i = index" class="listed-item">
              <div class="innet-part">{{feature.title}}</div>
              <div class="innet-part">{{feature.description}}</div>
              <div class="innet-part-actions">
                <app-button-item class="button" (fireClick)="openSlide('features', 'edition', i)" [onlyIcon]="true" [title]="'Editar'"
                  [icon]="'create'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
                <app-button-item class="button" (fireClick)="handleDelete('feature', i)" [onlyIcon]="true" [title]="'Eliminar'"
                  [icon]="'delete'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
              </div>
            </div>
          </div>

          <div *ngIf="!config.features || config.features.length <= 0" class="items-list">
            <div class="listed-item no-content">
              <div class="innet-part">No has añadido productos / Servicios</div>
            </div>
          </div>

          <div class="inner-title">
            <span>Anuncios</span>
            <app-button-item (fireClick)="openSlide('ads', 'creation')"
              [onlyIcon]="true" [color]="'#0055FF'" [outlined]="true" [title]="'Añadir nuevo'" [icon]="'add'">
            </app-button-item>
          </div>

          <div *ngIf="config.ads && config.ads.length > 0" class="items-list">
            <div *ngFor="let ad of config.ads; let i = index" class="listed-item one-element">
              <div class="innet-part">{{getAdName(ad.ubication, i)}}</div>
              <div class="innet-part-actions">
                <app-button-item class="button" (fireClick)="openSlide('ads', 'edition', i)" [onlyIcon]="true" [title]="'Editar'"
                  [icon]="'create'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
                <app-button-item class="button" (fireClick)="handleDelete('ad', i)" [onlyIcon]="true" [title]="'Eliminar'"
                  [icon]="'delete'" [onlyIcon]="true" [color]="'#0055FF'"
                  [outlined]="true"></app-button-item>
              </div>
            </div>
          </div>

          <div *ngIf="!config.ads || config.ads.length <= 0" class="items-list">
            <div class="listed-item no-content">
              <div class="innet-part">No has añadido anuncios</div>
            </div>
          </div>
        </div>

        <div *ngIf="(config && config.id) || fromCopy" class="delete-widget">
          <div class="inner-title">
            <span>Borrar widget</span>
            <app-button-item (fireClick)="deleteWidget()"
              [onlyIcon]="true" [color]="'#0055FF'" [outlined]="true" [title]="'Eliminar'" [icon]="'delete'">
            </app-button-item>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="deleteWidgetProperty === true" class="modal-delete-widget">
    <div class="delete-modal-container">
      <p>¿Estás seguro que quieres eliminar el widget completo?</p>
      <div class="delete-modal-buttons">
        <app-button-item (fireClick)="deleteWidget('cancel')"
          [background]="'#001540'" [color]="'#ffffff'" [solid]="true" [title]="'Cancelar'" [text]="'Cancelar'">
        </app-button-item>
        
        <app-button-item (fireClick)="deleteWidget('delete')"
          [background]="'#ffffff'" [color]="'#001540'" [outlined]="true" [title]="'Borrar'" [text]="'Borrar'">
        </app-button-item>
      </div>
    </div>
  </div>

  <div class="inner-footer">
    <app-button-item *ngIf="(config && config.id) || fromCopy" (fireClick)="copy()" [text]="'Replicar'">
    </app-button-item>
    <app-button-item *ngIf="config" (fireClick)="send()" [text]="'Guardar cambios'" [solid]="true"></app-button-item>
  </div>
</div>

<div *ngIf="slidesController.contacts.slide" class="slide-container"
  [ngClass]="{'open-slide': slidesController.contacts.open}">
  <div (click)="closeSlide('contacts')" class="slide-overlay"></div>

  <div class="slide-content">
    <div class="slide-nav">
      <app-button-item [onlyIcon]="true" (fireClick)="closeSlide('contacts')" [title]="'Cerrar'" [color]="'#111111'" [icon]="'close'">
      </app-button-item>
      <span>Método de contacto</span>
    </div>

    <div class="slide-body">
      <div class="input-element">
        <label class="input-element-label">Nombre de contacto</label>
        <input
          placeholder="Nombre de contacto"
          [(ngModel)]="slideItemObject.name"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Contacto</label>
        <input
          placeholder="Contacto"
          [(ngModel)]="slideItemObject.value"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color del texto</label>
        <input
          placeholder="Color del texto"
          [(ngModel)]="slideItemObject.color"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color de fondo</label>
        <input
          placeholder="Color de fondo"
          [(ngModel)]="slideItemObject.backgroundColor"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Icono (material-icons)</label>
        <input
          placeholder="Icono (material-icons)"
          [(ngModel)]="slideItemObject.iconUrl"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">URL de contacto</label>
        <input
          placeholder="URL de contacto"
          [(ngModel)]="slideItemObject.actionUrl"
          type="text"
        />
      </div>
    </div>

    <div class="slide-footer">
      <app-button-item (fireClick)="handleSend('contact')" [text]="'Guardar'" [solid]="true"></app-button-item>
      <app-button-item (fireClick)="closeSlide('contacts')" [text]="'Cancelar'" [color]="'#111111'"
        [borderColor]="'#b6b6b6'" [outlined]="true"></app-button-item>
    </div>
  </div>
</div>

<div *ngIf="slidesController.buttons.slide" class="slide-container"
  [ngClass]="{'open-slide': slidesController.buttons.open}">
  <div (click)="closeSlide('buttons')" class="slide-overlay"></div>

  <div class="slide-content">
    <div class="slide-nav">
      <app-button-item (fireClick)="closeSlide('buttons')" [onlyIcon]="true" [title]="'Cerrar'" [color]="'#111111'" [icon]="'close'">
      </app-button-item>
      <span>Botones</span>
    </div>

    <div class="slide-body">
      <div class="input-element">
        <label class="input-element-label">Nombre del botón</label>
        <input
          placeholder="Nombre del botón"
          [(ngModel)]="slideItemObject.name"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Texto del botón</label>
        <input
          placeholder="Texto del botón"
          [(ngModel)]="slideItemObject.value"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color del texto</label>
        <input
          placeholder="Color del texto"
          [(ngModel)]="slideItemObject.color"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color de fondo</label>
        <input
          placeholder="Color de fondo"
          [(ngModel)]="slideItemObject.backgroundColor"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Icono (material-icons)</label>
        <input
          placeholder="Icono (material-icons)"
          [(ngModel)]="slideItemObject.iconUrl"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">URL del botón</label>
        <input
          placeholder="URL del botón"
          [(ngModel)]="slideItemObject.actionUrl"
          type="text"
        />
      </div>
    </div>

    <div class="slide-footer">
      <app-button-item (fireClick)="handleSend('button')" [text]="'Guardar'" [solid]="true"></app-button-item>
      <app-button-item (fireClick)="closeSlide('buttons')" [text]="'Cancelar'" [color]="'#111111'"
      [borderColor]="'#b6b6b6'" [outlined]="true"></app-button-item>
    </div>
  </div>
</div>

<div *ngIf="slidesController.features.slide" class="slide-container"
  [ngClass]="{'open-slide': slidesController.features.open}">
  <div (click)="closeSlide('features')" class="slide-overlay"></div>

  <div class="slide-content">
    <div class="slide-nav">
      <app-button-item (fireClick)="closeSlide('features')" [onlyIcon]="true" [title]="'Cerrar'" [color]="'#111111'" [icon]="'close'">
      </app-button-item>
      <span>Productos / Servicios</span>
    </div>

    <div class="slide-body">
      <div class="input-element">
        <label class="input-element-label">Título del producto/servicio</label>
        <input
          placeholder="Título del producto/servicio"
          [(ngModel)]="slideItemObject.title"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Descripción del producto/servicio</label>
        <input
          placeholder="Descripción del producto/servicio"
          [(ngModel)]="slideItemObject.description"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color del texto</label>
        <input
          placeholder="Color del texto"
          [(ngModel)]="slideItemObject.color"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Color de fondo</label>
        <input
          placeholder="Color de fondo"
          [(ngModel)]="slideItemObject.backgroundColor"
          type="text"
        />
      </div>
      <div class="with-button">
        <div class="input-element">
          <label class="input-element-label">URL del producto/servicio</label>
          <input
            placeholder="URL del producto/servicio"
            [(ngModel)]="slideItemObject.assetUrl"
            type="text"
          />
        </div>

        <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('feature_asset')"
          class="status-ball sponsored">
          <i class="material-icons-outlined">cloud_upload</i>
        </div>
      </div>

      <div class="input-element">
        <label class="input-element-label">Texto de acción del producto/servicio</label>
        <input
          placeholder="Texto de acción del producto/servicio"
          [(ngModel)]="slideItemObject.actionText"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">URL de acción del producto/servicio</label>
        <input
          placeholder="URL de acción del producto/servicio"
          [(ngModel)]="slideItemObject.actionUrl"
          type="text"
        />
      </div>
    </div>

    <div class="slide-footer">
      <app-button-item (fireClick)="handleSend('feature')" [text]="'Guardar'" [solid]="true"></app-button-item>
      <app-button-item (fireClick)="closeSlide('features')" [text]="'Cancelar'" [color]="'#111111'"
      [borderColor]="'#b6b6b6'" [outlined]="true"></app-button-item>
    </div>
  </div>
</div>

<div *ngIf="slidesController.ads.slide" class="slide-container" [ngClass]="{'open-slide': slidesController.ads.open}">
  <div (click)="closeSlide('ads')" class="slide-overlay"></div>

  <div class="slide-content">
    <div class="slide-nav">
      <app-button-item (fireClick)="closeSlide('ads')" [onlyIcon]="true" [title]="'Cerrar'" [color]="'#111111'" [icon]="'close'">
      </app-button-item>
      <span>Anuncios</span>
    </div>

    <div class="slide-body">
      <div class="input-element">
        <label class="input-element-label">View URL</label>
        <input
          placeholder="View URL"
          [(ngModel)]="slideItemObject.viewUrl"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Tipo</label>
        <select [(ngModel)]="slideItemObject.type">
          <option disabled [value]="-1">Tipo de anuncio</option>
          <option value="PDF">PDF</option>
          <option value="BANNER">BANNER</option>
        </select><br>
      </div>
      <div class="input-element">
        <label class="input-element-label">Click URL</label>
        <input
          placeholder="Click URL"
          [(ngModel)]="slideItemObject.clickUrl"
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Pixel URL</label>
        <input
          placeholder="Pixel URL"
          [(ngModel)]="slideItemObject.pixelUrl"
          type="text"
        />
      </div>

      <div *ngIf="slideItemObject.type === 'PDF'" class="creative-container">
        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">Vertical top</label>
            <input
              placeholder="Vertical top"
              [(ngModel)]="slideItemObject.imgPortraitTopUrl"
              type="text"
            />
          </div>

          <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('ad_imgPortraitTop')"
            class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>

        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">Vertical lateral</label>
            <input
              placeholder="Vertical lateral"
              [(ngModel)]="slideItemObject.imgPortraitLateralUrl"
              type="text"
            />
          </div>

          <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('ad_imgPortraitLateral')"
            class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>

        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">Horizontal top</label>
            <input
              placeholder="Horizontal top"
              [(ngModel)]="slideItemObject.imgLandscapeTopUrl"
              type="text"
            />
          </div>

          <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('ad_imgLandscapeTop')"
            class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>

        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">Horizontal lateral</label>
            <input
              placeholder="Horizontal lateral"
              [(ngModel)]="slideItemObject.imgLandscapeLateralUrl"
              type="text"
            />
          </div>

          <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('ad_imgLandscapeLateral')"
            class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>
      </div>

      <div *ngIf="slideItemObject.type === 'BANNER'" class="creative-container">
        <div class="with-button">
          <div class="input-element">
            <label class="input-element-label">image URL</label>
            <input
              placeholder="Imagen del banner"
              [(ngModel)]="slideItemObject.imgUrl"
              type="text"
            />
          </div>

          <div *ngIf="slideMode !== 'creation'" (click)="openMediaInputAddon('ad_img')"
            class="status-ball sponsored">
            <i class="material-icons-outlined">cloud_upload</i>
          </div>
        </div>
      </div>
    </div>

    <div class="slide-footer">
      <app-button-item (fireClick)="handleSend('ad')" [text]="'Guardar'" [solid]="true"></app-button-item>
      <app-button-item (fireClick)="closeSlide('ads')" [text]="'Cancelar'" [color]="'#111111'"
      [borderColor]="'#b6b6b6'" [outlined]="true"></app-button-item>
    </div>
  </div>
</div>

<input type="file" accept="image/*" #mediaInput (change)="handleFile($event)" style="display: none;">
<input type="file" accept="image/*" #mediaInputAddons (change)="handleFileAddon($event)" style="display: none;">
