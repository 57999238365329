<div class="academy-features" [ngClass]="{error: border}">
  <img *ngIf="image" [src]="image" [alt]="alt" />

  <div class="noImage" *ngIf="!image"></div>

  <div class="academy-data">
    <div class="modify">
      <h6>{{ title }}</h6>

      <app-button-item
        *ngIf="edit"
        [title]="'Modificar carta'"
        [onlyIcon]="true"
        [icon]="'edit'"
        [solid]="true"
        [color]="'#039BE5'"
        [background]="'#f0f0f0'"
        (fireClick)="open()"
      ></app-button-item>
    </div>

    <p>{{ description }}</p>

    <div class="academy-feature-action">
      <a *ngIf="link != null && link != ''" [href]="'https://www.'+link" target="_blank" rel="nofollow">{{ linkName }}</a>
      <a *ngIf="link == null || link == ''">{{ linkName }}</a>
    </div>
  </div>
</div>
