import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { UtilsService } from '../../services/utils.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { AnalyticService } from 'src/app/services/analytic.service';

// Environments
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';

declare var gtag: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @HostListener('window:keyup', ['$event'])
  keyEvent(event) {
    if (event.keyCode && event.keyCode === 13) {
      this.login();
    }
  }

  environment = environment;

  credentials: {
    mail: string,
    password: string
  };

  uuid: any;

  constructor(
    public utilsService: UtilsService,
    public userService: UserService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticService: AnalyticService,
    public titleService: Title
  ) {
    this.credentials = {
      mail: null,
      password: null
    };

    this.uuid = this.storageService.read('uuid');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Inicia sesión | Wuolads');
    this.analyticService.pageView(window.location.href, 'login', 'Login');
    this.analyticService.screenView('Login');

    this.analyticService.customEvent(
      'UP1_02_0_VISIT',
      {
        uuid: this.uuid,
        userId: null
      }
    );
  }

  login() {
    if (!this.credentials.mail || !this.utilsService.validateEmail(this.credentials.mail)) {
      this.utilsService.presentAlertMessage({
        title: 'Email incorrecto.',
        description: 'Por favor indica una dirección de mail correcta para iniciar sesión.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (!this.credentials.password || !this.utilsService.validatePassword(this.credentials.password)) {
      this.utilsService.presentAlertMessage({
        title: 'Contraseña incorrecta.',
        description: 'Por favor indica una contraseña correcta para iniciar sesión, debe tener entre 6 y 18 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }

    this.utilsService.loading('open');

    this.userService.login(this.credentials.mail, this.credentials.password).then(
      (response) => {
        this.storageService.write('token', response.token);

        window['dataLayer'].push({
          'event': 'login'
        });

        gtag('event', 'login', {
          method: 'Email'
        });

        this.router.navigate(['/dashboard'], { relativeTo: this.route }).then(()=>{
          this.utilsService.loading('close');
        });
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        if (error?.error?.message === "User not found") {
          this.utilsService.presentAlertMessage({
            title: 'Email o contraseña incorrecto.',
            description: 'Por favor indica una dirección de mail o contraseña correcta para iniciar sesión.',
            error: true,
            duration: 6
          });

          return false;
        } else if (error?.error?.message === "Invalid email or password") {
          this.utilsService.presentAlertMessage({
            title: 'Email o contraseña incorrecto.',
            description: 'Por favor indica una dirección de mail o contraseña correcta para iniciar sesión.',
            error: true,
            duration: 6
          });

          return false;
        } else {
          this.userService.errorHandler(error, true);
        }
      }
    );
  }


  loginWithGoogle() {
    this.userService.loginWithGoogle().then(
      (response: any) => {
        this.utilsService.loading('open');
        this.sendGoogleInformation(response, true);
      }
    ).catch(
      () => {
        this.utilsService.loading('close');

        const message = {
          title: 'Ha ocurrido un error al conectar con Google',
          description: 'No hemos podido comprobar la información en los servidores de Google. Por favor, vuelve a intentarlo pasados unos segundos.',
          error: true,
          duration: 5
        };

        this.utilsService.presentAlertMessage(message);
      }
    );
  }

  sendGoogleInformation(data: any, showError: boolean) {
    this.userService.sendGoogleData(data).then(
      (response) => {
        this.storageService.write('token', response.token);
        this.router.navigate(['/dashboard/home'], { relativeTo: this.route });
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
    );
  }

  goToSignUp() {
    this.router.navigate(['/sign-up']);
  }
}
