import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class HubspotService extends RequestService {
  async getHubspotData(advertiserId) {
    return await this.request(
      this.httpClient.get(
        this.environment.API + "/pub/advertiser/" + advertiserId + "/hubspot",
        { headers: this.headers() }
      )
    );
  }

  async addCallRegistry(advertiserId, disposition) {
    let data = {
      disposition: disposition
    }
    return await this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser/" + advertiserId + "/hubspot/call",
        data,
        { headers: this.headers() })
    );
  }
}