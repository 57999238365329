<div class="container">
  <div class="overlay-container" (click)="close(1)"></div>
  <div class="popup-containter" *ngIf="firstModal">
    <div class="closePopup">
      <app-button-item
        [title]="'Cerrar'"
        [onlyIcon]="true"
        [icon]="'close'"
        [color]="'#888888'"
        (click)="close()"
      ></app-button-item>
    </div>
    <p>Te lo ponemos más fácil...</p>
    <ul>
      <li>Llámanos al <strong>+34 640 77 46 71</strong> o escríbenos a <strong>publicidad@wuolah.com</strong></li>
      <li>Introduce alguna forma de ponernos en contacto contigo: </li>
    </ul>
    <div class="input-element">
      <label class="input-element-label">Nombre</label>
      <input class="inputName" (change)="isFilled()" [ngClass]="{inputFilled: errorName}" placeholder="Javier Gómez" name="mail" type="text">
    </div>
    <div class="input-element">
      <label class="input-element-label">Teléfono</label>
      <input class="inputPhone" (change)="isFilled()" [ngClass]="{inputFilled: errorPhone}" placeholder="+34 000 00 00 00" name="mail" type="text">
    </div>
    <app-button-item
      [text]="'Enviar'"
      [title]="'Enviar'"
      [solid]="true"
      [background]="'#002778'"
      (click)="send()"
    ></app-button-item>
    <p>... y te lo contamos todo.</p>
  </div>

  <div class="thankyou" *ngIf="!firstModal">
    <div class="closePopup">
      <app-button-item
        [title]="'Cerrar'"
        [onlyIcon]="true"
        [icon]="'close'"
        [color]="'#888888'"
        (click)="close()"
      ></app-button-item>
    </div>
    <p>GRACIAS</p>
    <span>Por dejarnos ayudarte.</span>
    <span>Contactaremos contigo en breves.</span>
  </div>
</div>
