import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AnalyticService } from 'src/app/services/analytic.service';
import { HubspotService } from 'src/app/services/hubspot.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {

  hubspotData: any;

  clientId: any;

  constructor(
    public titleService: Title,
    public analyticService: AnalyticService,
    public hubspotService: HubspotService,
    private activateRoute: ActivatedRoute,
    public utilsService: UtilsService,
    ) {
    this.clientId = this.activateRoute.snapshot.params['clientId'];
    this.getClientData();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Agente | Wuolads');
    this.analyticService.pageView(window.location.href, 'agent', 'Agent');
    this.analyticService.screenView('Agent');
  }

  getClientData() {
    this.utilsService.loading('open');
    this.hubspotService.getHubspotData(this.clientId).then((response) => {
      this.hubspotData = response;
      for (let data of this.hubspotData?.calls){
        data.timestamp= new Date(data?.timestamp).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
      }
      this.utilsService.loading('close');

    });
  }

  addAction(disposition) {
    this.hubspotService.addCallRegistry(this.clientId,disposition).then(() => {
      this.getClientData();
    });
  }

  call() {
    window.open('tel:' + this.hubspotData?.contact?.properties?.phone, '_system');
  }
}
