import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-feature-design-item',
  templateUrl: './feature-design-item.component.html',
  styleUrls: ['./feature-design-item.component.css']
})
export class FeatureDesignItemComponent implements OnInit {

  openModalAcademy = false;

  @Input('title') title: string;
  @Input('description') description: string;
  @Input('link') link: string;
  @Input('image') image: string;
  @Input('alt') alt: string;
  @Input('linkName') linkName: string;
  @Input('border') border: string;
  @Input('edit') edit: boolean = true;

  @Output() onOpen = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  open() {
    this.openModalAcademy = true;

    setTimeout(() => {
      this.onOpen.emit();
    }, 150);
  }

}
