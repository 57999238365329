import { Component, OnInit, Output, EventEmitter } from '@angular/core';

// Services
import { AnalyticService } from 'src/app/services/analytic.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-contactus-modal',
  templateUrl: './contactus-modal.component.html',
  styleUrls: ['./contactus-modal.component.css']
})
export class ContactusModalComponent implements OnInit {

  inputFilled = false;
  errorPhone = false;
  errorName = false;
  firstModal = true;

  userMe: any;
  uuid: string;

  @Output() onClose = new EventEmitter();

  constructor(
    public storageService: StorageService,
    public userService: UserService,
    public analyticService: AnalyticService,
  ) { 
    this.userMe = this.storageService.read('userMe');
    this.uuid = this.storageService.read('uuid');
  }

  ngOnInit(): void {
    this.analyticService.customEvent(
      'LOST_POPUP_JOURNEY',
      {
        type: 'VISIT',
        target: 'SHOW_POPUP',
        uuid: this.uuid,
        userId: this.userMe ? this.userMe.userId : null
      }
    );
  }

  async send() {
    this.analyticService.customEvent(
      'LOST_POPUP_JOURNEY',
      {
        type: 'CLICK',
        target: 'SEND_POPUP',
        uuid: this.uuid,
        userId: this.userMe ? this.userMe.userId : null
      }
    );

    let inputName = (document.querySelector('.inputName') as HTMLInputElement).value;
    let inputPhone = (document.querySelector('.inputPhone') as HTMLInputElement).value;

    if (inputName !== null && inputName !== '' && inputPhone !== null && inputPhone !== '') {
      this.errorName = false;
      this.errorPhone = false;
      this.inputFilled = true;

      this.firstModal = false;
      this.storageService.write('lost_popup_closed', 1);

      this.analyticService.customEvent(
        'LOST_POPUP_JOURNEY',
        {
          type: 'VISIT',
          target: 'SHOW_POPUP_THANKS',
          uuid: this.uuid,
          userId: this.userMe ? this.userMe.userId : null
        }
      );
    } else if (inputName !== null && inputName !== '') {
      this.errorName = false;
      this.errorPhone = true;
    } else if (inputPhone !== null && inputPhone !== '') {
      this.errorPhone = false;
      this.errorName = true;
    } else {
      this.errorPhone = true;
      this.errorName = true;
    }
  }

  isFilled() {
    let inputName = (document.querySelector('.inputName') as HTMLInputElement).value;
    let inputPhone = (document.querySelector('.inputPhone') as HTMLInputElement).value;

    if (inputName !== null && inputName !== '') {
      this.errorName = false;
    } else {
      this.errorName = true;
    }

    if (inputPhone !== null && inputPhone !== '') {
      this.errorPhone = false;
    } else {
      this.errorPhone = true;
    }
  }

  close(express?: number) {
    this.analyticService.customEvent(
      'LOST_POPUP_JOURNEY',
      {
        type: 'CLICK',
        target: 'CLOSE_POPUP',
        uuid: this.uuid,
        userId: this.userMe ? this.userMe.userId : null
      }
    );

    if (!express || express !== 1) {
      this.storageService.write('lost_popup_closed', 1);
    }
    
    this.onClose.emit();
  }

}
