import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(value: number, unit?: number): number {
    if(typeof unit == 'undefined') unit = Math.round(value).toString().length - 1;
    return Math.round(value/Math.pow(10, unit)) * Math.pow(10, unit);
  }

}
