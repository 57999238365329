<nav>
  <app-button-item
    [onlyIcon]="true"
    [title]="'Cerrar'"
    [icon]="'close'"
    [color]="'#111111'"
    (fireClick)="closeSponsor = true"
  ></app-button-item>

  <div class="image-nav">
    <img src="./../../../assets/img/isotipo_1.svg" alt="Logo wuolah" />
  </div>

  <app-close-sponsor-modal
    *ngIf="closeSponsor"
    (onClose)="closeSponsor = false"
  ></app-close-sponsor-modal>

  <app-button-item
    class="mobile-preview"
    [title]="'Ver'"
    [onlyIcon]="true"
    [icon]="selectedSignatureView ? 'visibility_on' : 'visibility_off'"
    [color]="'#0055ff'"
    (click)="selectedSignatureView ? selectedSignatureView = false : selectedSignatureView = true"
  ></app-button-item>
</nav>

<section>
  <div class="signature-sponsor">
    <h4>Patrocinar asignatura</h4>

    <div class="tracking">
      <div class="tracking-line">
        <div [ngClass]="activatedRoutes() >= 1 ? 'colored' : ''"></div>
        <div [ngClass]="activatedRoutes() >= 1 ? 'colored' : ''"></div>

        <div *ngIf="activatedRoutes() === 1" [ngClass]="activatedRoutes() > 1 ? 'colored' : ''" ></div>
        <div *ngIf="activatedRoutes() === 1" [ngClass]="activatedRoutes() > 1 ? 'colored' : ''" ></div>
        
        <div *ngIf="activatedRoutes() >= 2" [ngClass]="activatedRoutes() >= 2 ? 'colored' : ''"></div>
        <div *ngIf="activatedRoutes() >= 2"  [ngClass]="activatedRoutes() >= 2 ? 'colored' : ''"></div>
        <div *ngIf="activatedRoutes() >= 2"  [ngClass]="activatedRoutes() >= 3 ? 'colored' : ''"></div>
        <div *ngIf="activatedRoutes() >= 2"  [ngClass]="activatedRoutes() >= 3 ? 'colored' : ''"></div>
      </div>

      <div class="tracking-parts">
        <ul>
          <li
            [ngClass]="activatedRoutes() >= 1 ? 'colored-part cursor' : 'cursor'"
            routerLink="subject/1"
          >
            Segmentación
          </li>

          <li
            *ngIf="activatedRoutes() === 1" 
            [ngClass]="[
            activatedRoutes() > 1 ? 'colored-part disabled' : 'disabled'
            ]"
          >
            Siguiente
          </li>

          <li
            *ngIf="activatedRoutes() >= 2" 
            [ngClass]="[
              activatedRoutes() >= 2 ? 'colored-part cursor' : '',
              folders.length > 0 ? 'cursor' : 'disabled'
            ]"
            routerLink="subject/2"
          >
            Diseño
          </li>

          <li
            *ngIf="activatedRoutes() >= 2" 
            [ngClass]="[
              folders.length > 0 ? 'cursor' : 'disabled',
              activatedRoutes() >= 3 ? 'colored-part' : ''
            ]"
            routerLink="subject/4"
          >
            Finalizar
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div [ngClass]="{containSignature: segmentationPage}" class="contain-signature">
    <app-root></app-root>

    <div *ngIf="magnify.portrait" class="vertical-magnify">      
      <div class="magnify-overlay" (click)="magnify.portrait = false"></div>
      <div [ngClass]="{ horMagnify: !dataUpload || !dataUpload.portrait && !template || !template?.portrait }" class="verticalOption">
        <img
          [ngClass]="{ skeletonP: !dataUpload || !dataUpload.portrait && !template || !template?.portrait }"
          [src]="dataUpload && dataUpload.portrait && dataUpload.portrait !== '' ? dataUpload.portrait : template?.portrait"
          alt="empty"
        />

        <div class="verticalOptionContainer">
          <div></div>
        </div>

        <app-button-item
          class="magnifyVertical"
          [title]="'Aumentar'"
          [onlyIcon]="true"
          [icon]="'zoom_out'"
          [color]="'#0055ff'"
          (click)="magnify.portrait ? magnify.portrait = false : magnify.portrait = true"
        ></app-button-item>
      </div>
    </div>

    <div *ngIf="magnify.landscape" class="vertical-magnify">
      <div class="magnify-overlay" (click)="magnify.landscape = false"></div>
      <div  [ngClass]="{ horMagnify: !dataUpload || !dataUpload.landscape && !template || !template?.landscape }" class="horizontalOption">
        <img
          [ngClass]="{ skeletonP: !dataUpload || !dataUpload.landscape && !template || !template?.landscape }"
          [src]="dataUpload && dataUpload.landscape && dataUpload.landscape !== '' ? dataUpload.landscape : template?.landscape"
          alt="empty"
        />

        <div class="horizontalOptionContainer">
          <div></div>
        </div>

        <app-button-item
          class="magnifyHorizontal"
          [title]="'Aumentar'"
          [onlyIcon]="true"
          [icon]="'zoom_out'"
          [color]="'#0055ff'"
          (click)="magnify.landscape = false"
        ></app-button-item>
      </div>
    </div>
  </div>

  <div *ngIf="!paymentPage && !segmentationPage" class="selected-signature" [ngClass]="{selectedSignatureMobile: !selectedSignatureView}">
    <!-- ALL PAGES EXCEPT PAYMENT -->

    <div>
      <h4>Asignaturas seleccionadas</h4>

      <div class="view-signatures"  [ngClass]="{viewLess: viewSignatures == 'Ver menos'}">
        <p *ngIf="folders.length > 0" [ngClass]="{viewLess: viewSignatures == 'Ver menos'}" [ngClass]="{viewAll: viewSignatures == 'Ver todas'}">
          <span *ngFor="let folder of folders; let last = last"
            >{{ folder?.folder }}<span *ngIf="!last">, </span></span>
        </p>
        <p *ngIf="folders.length <= 0">Aquí aparecen las asignaturas seleccionadas desde el buscador</p>

        <div>
          <a 
            [ngClass]="{activeSelected: folders.length > 0 }"
            (click)="viewSignatures == 'Ver todas' ? viewSignatures = 'Ver menos' : viewSignatures = 'Ver todas'"
          >{{viewSignatures}}</a>
        </div>
      </div>
    </div>

    <div [ngClass]="{widgetOff: widgetView === false}" class="widget">
      <h4 class="widget-zone">Widget</h4>

      <app-button-item
        *ngIf="creativitiesPage"
        class="buttonView"
        [title]="'Ver'"
        [onlyIcon]="true"
        [icon]="widgetView ? 'visibility_off' : 'visibility_on'"
        [color]="'#0055ff'"
        (click)="widgetView ? widgetView = false : widgetView = true"
      ></app-button-item>

      <app-widget-item
        *ngIf="widgetView"
        [buttons]="buttons"
        [contacts]="contacts"
        [features]="features"
        [generalInformation]="generalInformation"
      ></app-widget-item>
    </div>

    <!-- CREATIVITIES PAGE -->

    <div *ngIf="creativitiesPage" class="creativities">
      <h4 class="widget-zone">Creatividades</h4>

      <app-button-item
        [title]="'Actualizar creatividades'"
        [onlyIcon]="true"
        [icon]="'autorenew'"
        [color]="'#0055ff'"
        (fireClick)="renewCreativity()"
      ></app-button-item>
      <app-button-item
        [title]="'Ver'"
        [onlyIcon]="true"
        [icon]="creativityView ? 'visibility_off' : 'visibility_on'"
        [color]="'#0055ff'"
        (click)="creativityView ? creativityView = false : creativityView = true"
      ></app-button-item>

      <div *ngIf="creativityView" class="vertical-option-container">
        <div class="vertical-option">
          <img
            [ngClass]="{ skeleton: !dataUpload || !dataUpload.portrait && !template || !template?.portrait }"
            [src]="
              dataUpload && dataUpload.portrait && dataUpload.portrait !== ''
                ? dataUpload.portrait
                : template?.portrait
            "
            alt="empty"
          />

          <div></div>
          
          <app-button-item
            class="magnify"
            [title]="'Aumentar'"
            [onlyIcon]="true"
            [icon]="'zoom_in'"
            [color]="'#0055ff'"
            (click)="magnify.portrait ? magnify.portrait = false : magnify.portrait = true"
          ></app-button-item>
        </div>
      </div>

      <div *ngIf="creativityView" class="horizontal-option-container">
        <div class="horizontal-option">
          <img
            [ngClass]="{ skeleton: !dataUpload || !dataUpload.landscape && !template || !template?.landscape }"
            [src]="
              dataUpload && dataUpload.landscape && dataUpload.landscape !== ''
                ? dataUpload.landscape
                : template?.landscape
            "
            alt="empty"
          />

          <div></div>

          <app-button-item
            class="magnify"
            [title]="'Aumentar'"
            [onlyIcon]="true"
            [icon]="'zoom_in'"
            [color]="'#0055ff'"
            (click)="magnify.landscape = true"
          ></app-button-item>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="paymentPage" class="selected-signature" [ngClass]="{selectedSignatureMobile: !selectedSignatureView}">
    <!-- PAYMENT PAGE -->
    <div>
      <h4 class="resume">Resumen de compra</h4>

      <div [ngClass]="{viewMorePayment: viewPayment == 'Ver menos'}" class="view-signatures-payment">
        <ul>
          <li *ngFor="let folder of folders">
            <p>{{ folder?.folder }}</p>

            <span>{{ folder?.study }}
              {{ folder?.center ? ', ' : ''  }}{{ folder?.center }}
              {{ folder?.university ? ', ' : ''  }}{{ folder?.university }}</span>
          </li>
        </ul>

        <div>
          <span
            (click)="viewPayment == 'Ver más' ? viewPayment = 'Ver menos' : viewPayment = 'Ver más'"
          >{{viewPayment}}</span>
        </div>
      </div>

      <div class="subtotal">
        <h4>Subtotal</h4>
        <ul>
          <li>
            <span>Precio por unidad</span>
            <span>{{ paymentData?.payment?.price }}€</span>
          </li>
          <li *ngIf="paymentData?.payment?.discount">
            <span>Descuento aplicado del {{ getDiscountPercentage() }}%</span>
            <span>-{{ getDiscount() }}€</span>
          </li>
        </ul>
        <hr />
      </div>

      <div class="total">
        <h4>Total</h4>
        <span>{{ getTotal() }}</span>
      </div>

      <p class="iva">IVA incluido</p>
    </div>
  </div>
</section>
