<div class="design-container">
  <div class="form-container">
    <h4>Diseño</h4>

    <p>
      Introduce la información y los gráficos que quieres que aparezcan en el
      widget de la asignatura y podrás ver el resultado final en la sección
      vista previa
    </p>

    <div class="forms-item">
      <div class="input-element">
        <label class="input-element-label">Nombre</label>

        <input 
          [ngClass]="{checkError: checkError && (checkInputs.inputName == null || checkInputs.inputName == '')}" 
          placeholder="Introduce el nombre de tu academia" 
          [(ngModel)]="checkInputs.inputName" 
          [(ngModel)]="dataGeneralInformation.name" 
          type="text"
        />
      </div>

      <div class="upload-items">
        <div class="input-element">
          <label class="input-element-label">Background</label>

          <div 
            class="upload-file" 
            [ngClass]="{checkError: checkError && (checkInputs.inputBackground == null || checkInputs.inputBackground == '')}" 
            (click)="openMediaInput('background')" 
            [ngClass]="{ uploadFileActive: dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' }"
          >
            <app-button-item
              [title]="'Añadir imagen de fondo'"
              [onlyIcon]="true"
              [icon]="dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' ? 'task_alt' : 'add_circle_outline'"
              [color]="dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' ? '#0055FF' : '#888888'"
            ></app-button-item>

            <span>{{ dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== "" ? "Añadido" : "Añadir imagen de fondo" }}</span>
          </div>
        </div>

        <!-- <div class="input-element">
          <label class="input-element-label">Background</label>

          <div 
            class="upload-file" 
            (click)="openMediaInput('background')" 
            [ngClass]="{ uploadFileActive: dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' }"
          >
            <app-button-item
              [title]="'Añadir logo'"
              [onlyIcon]="true"
              [icon]="dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' ? 'task_alt' : 'add_circle_outline'"
              [color]="dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== '' ? '#0055FF' : '#888888'"
            ></app-button-item>

            <span>{{ dataGeneralInformation && dataGeneralInformation.backgroundUrl && dataGeneralInformation.backgroundUrl !== "" ? "Añadido" : "Añadir imagen fondo" }}</span>
          </div>
        </div> -->
      </div>

      <div class="input-element">
        <label class="input-element-label">Título 1</label>

        <input 
          [ngClass]="{checkError: checkError && (checkInputs.inputTitle == null || checkInputs.inputTitle == '')}" 
          placeholder="Introduce el título de tu anuncio" 
          [(ngModel)]="checkInputs.inputTitle" 
          [(ngModel)]="dataGeneralInformation.title" 
          type="text" 
        />
      </div>

      <div class="input-element">
        <label class="input-element-label">Descripción</label>

        <textarea
          placeholder="Breve descripción del anuncio"
          id=""
          cols="30"
          rows="3"
          [(ngModel)]="checkInputs.inputDescription"
          [(ngModel)]="dataGeneralInformation.description"
          [ngClass]="{checkError: checkError && (checkInputs.inputDescription == null || checkInputs.inputDescription == '')}"
        ></textarea>
      </div>

      <div class="input-element">
        <label class="input-element-label">Teléfono</label>

        <input
          placeholder="Introduce el número de teléfono de contacto de la academia"
          type="text"
          [(ngModel)]="checkInputs.inputPhone"
          [(ngModel)]="buttons[1].actionUrl"
          [(ngModel)]="contacts[0].actionUrl"
          [(ngModel)]="contacts[0].value"
          [ngClass]="{checkError: checkErrorPhone}"
        />
      </div>

      <div class="input-element">
        <label class="input-element-label">Email</label>

        <input
          placeholder="Introduce el correo electrónico de la academia"
          type="email"
          [(ngModel)]="checkInputs.inputEmail"
          [(ngModel)]="contacts[1].actionUrl"
          [(ngModel)]="contacts[1].value"
          [ngClass]="{checkError: checkErrorMail}"         
        />
      </div>

      <div class="input-element">
        <label class="input-element-label">Web</label>

        <input
          placeholder="Introduce el enlace a la web de la academia"
          type="email"
          [(ngModel)]="checkInputs.inputWeb"
          [(ngModel)]="buttons[0].actionUrl"
          [(ngModel)]="contacts[2].actionUrl"
          [(ngModel)]="contacts[2].value"
          [(ngModel)]="dataGeneralInformation.actionUrl"
          [ngClass]="{checkError: checkErrorWeb}"
        />
      </div>

      <div class="input-element">
        <label class="input-element-label">Tarjetas de beneficios y ventajas</label>
        <p>Puedes seleccionar hasta 6 tarjetas de diferentes temas para tu widget y personalidar cada una de ellas</p>
      </div>

      <div class="input-element">
        <label class="input-element-label">Título 2</label>
        <input 
          [ngClass]="{checkError: checkError && (checkInputs.inputSubtitle == null || checkInputs.inputSubtitle == '')}" 
          placeholder="Introduce el nombre de tu academia" 
          [(ngModel)]="checkInputs.inputSubtitle" 
          [(ngModel)]="dataGeneralInformation.subtitle" 
          type="email"
        />
      </div>

      <div class="cards-container">
        <div class="card" *ngFor="let item of features index as i">
          <div class="card-selection-row">
            <div (click)="features[i].highlight == 1 ? features[i].highlight = 0 : features[i].highlight = 1; testFeature(); count = 1" class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': features[i].highlight == 1}">
              <i class="material-icons-outlined">done</i>
            </div>

            <span>{{ features[i].data }}</span>
          </div>

          <app-feature-design-item
            [border]="checkInputs.inputFeature"
            [title]="features[i].title"
            [description]="features[i].description"
            [linkName]="features[i].actionText"
            [link]="features[i].actionUrl"
            [image]="features[i].assetUrl"
            (onOpen)="openModalAcademy = true; ifeature = i"
          ></app-feature-design-item>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-container">
    <app-button-item
      [title]="'Atrás'"
      [color]="'#111111'"
      [borderColor]="'#b6b6b6'"
      [text]="'Atrás'"
      [outlined]="true"
      (fireClick)="goToSegmentation()"
    ></app-button-item>

    <app-button-item
      [disabled]="checkInputs.inputFeature || checkDisabled()"
      [text]="'Siguiente'"
      [background]="'#001540'"
      [solid]="true"
      (fireClick)="checkInput()"
    ></app-button-item>
  </div>

  <app-modify-academy-pub-modal
    class="modifyAcademy"
    *ngIf="openModalAcademy && ifeature > -1"
    [dataWidget]="features[ifeature]"
    (onModify)="features[ifeature] = $event"
    (onClose)="openModalAcademy = false; ifeature = -1"
  ></app-modify-academy-pub-modal>
</div>

<input type="file" accept="image/*" #mediaInput (change)="handleFile($event)" style="display: none;">