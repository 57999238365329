import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnalyticService } from 'src/app/services/analytic.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as Highcharts from 'highcharts';
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';
import { PixelService } from 'src/app/services/pixel.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-dashboard-pixel',
  templateUrl: './dashboard-pixel.component.html',
  styleUrls: ['./dashboard-pixel.component.css']
})
export class DashboardPixelComponent implements OnInit {

  openPersonalize = false;

  pixelSearch = {
    groupBy: null,
    fromDate: null,
    toDate: null,
    filter: null
  }

  pixelData = {
    sessions: null,
    raw: null,
    source: null,
    campaign: null,
    medium: null,
    browser: null,
    referer: null,
    country: null,
    date: null,
    os: null,
    city: null,
    visitors: null
  };

  change = 'source';

  calculator: any;
  selected: any;

  oneToOneFlag: boolean = true;

  firstReport: any;
  check = 0;
  rowData = [];
  periodSelected = 7;

  isFirstLoad = true;
  updateFlag = false;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptionsAnalysis: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  pixelMe: boolean;

  dateRange: any;

  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

  code = `<!-- Wuolah Tracking Pixel -->
  <script>
  var script=document.createElement("script");
  script.type="text/javascript",
  script.readyState?script.onreadystatechange=function()
  {"loaded"!==script.readyState&&"complete"!==script.readyState||(script.onreadystatechange=null,
  wpxl__init(PUBLISHERID))}:script.onload=function(){wpxl__init(PUBLISHERID)},
  script.src="https://wlh.es/pixel?v=" + Date.now(),
  document.getElementsByTagName("head")[0].appendChild(script);
  </script>
  <!-- Wuolah Tracking Pixel -->`;

  openModal = false;

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  constructor(
    public storageService: StorageService,
    public utilsService: UtilsService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public sponsorSubjectservice: SponsorSubjectService,
    public pixelService: PixelService,
  ) {
    this.titleService.setTitle('Integración del píxel | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/pixel', 'Pixel integration');
    this.analyticService.screenView('Pixel integration');

    this.utilsService.loading('open');
    setTimeout(() => {
      this.pixelService.getPixel().then((response) => {
        this.pixelMe = response;
  
        const pub: any = this.storageService.read('advertiserMe');
        const res = this.code.replace(/PUBLISHERID/g, pub.userId);
        this.code = res;
  
        if (this.pixelMe) {
          this.chartOptionsAnalysis.series = [];
  
          this.setPeriod(7);
          this.pixelSearch.groupBy = null;
          this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
            this.selected = response[0];
          });
        }
        this.utilsService.loading('close');
      });
    }, 150);
  }
  
  ngOnInit(): void { 
    this.titleService.setTitle('Integración del píxel | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/pixel', 'Pixel integration');
    this.analyticService.screenView('Pixel integration');
  }

  openDatepicker() {
    if (this.openPersonalize === true) {
      this.openPersonalize = false;
    } else {
      this.openPersonalize = true;
    }
    this.pickerDirective.open();
  }

  copy() {
    this.chartOptionsAnalysis.series = [];

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.utilsService.presentAlertMessage({
      title: 'Copiado.',
      description: 'Se ha copiado el código.',
      error: false,
      duration: 6
    });
  }

  init() {
    this.pixelService.getPixelVisitors().then((response) => {
      this.pixelData.visitors = response;
    });

    this.pixelSearch.groupBy = 'source';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.source = this.sort(response);
      for(let source of this.pixelData.source) {
        if (source?.source === null) {
          let index = this.pixelData.source.indexOf(source, 0);
          this.pixelData.source.splice(index, 1);
        }
      }
    });

    this.pixelSearch.groupBy = 'campaign';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.campaign = this.sort(response);
      for(let campaign of this.pixelData.campaign) {
        if (campaign?.campaign === null) {
          let index = this.pixelData.campaign.indexOf(campaign, 0);
          this.pixelData.campaign.splice(index, 1);
        }
      }
    });

    this.pixelSearch.groupBy = 'medium';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.medium = this.sort(response);
      for(let medium of this.pixelData.medium) {
        if (medium?.medium === null) {
          let index = this.pixelData.medium.indexOf(medium, 0);
          this.pixelData.medium.splice(index, 1);
        }
      }
    });

    this.pixelSearch.groupBy = 'country';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.country = this.sort(response);
    });

    this.pixelSearch.groupBy = 'browser';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.browser = this.sort(response);
    });

    this.pixelSearch.groupBy = 'referer';
    this.pixelData.referer = [];
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      for (let res of response){
        this.pixelData.referer.push({referer: res?.name, uniques: res?.uniques})
      }
      this.pixelService.getOwnReferers().then((response) => {
        this.pixelData.referer = this.pixelData.referer.concat(response).reduce((r, i) => 
        !r.some(j => JSON.stringify(i) === JSON.stringify(j)) ? [...r, i] : r
      , []);
        this.pixelData.referer = this.sort(this.pixelData.referer);
      });
      
    });

    this.pixelSearch.groupBy = 'os';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.os = this.sort(response);
    });

    this.pixelSearch.groupBy = 'city';
    this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
      this.pixelData.city = this.sort(response);
    });
  }

  sort(items) {
    return items.sort((a, b) => {
      if (a?.Uniques ? a?.Uniques > b?.Uniques : a?.uniques > b?.uniques) {
        return -1;
      }
      if (a?.Uniques ? a?.Uniques < b?.Uniques : a?.uniques > b?.uniques) {
        return 1;
      }
      return 0;
    });
  }

  async select(type: string, name: string) {
    this.firstReport = null;
    this.rowData = [];
    this.pixelSearch.groupBy = type;
    this.pixelSearch.filter = name;

    try {
      await this.pixelService.getPixelViews(this.pixelSearch).then((response) => {
        this.pixelData.date = response;
        let viewsSerie: any = [];
        let uniqueSerie: any = [];

        for (let i = 0; i < this.pixelData?.date.length; i++) {
          const splitted = response[i]?.date.split('-');
          for (let j = 0; j < splitted.length; j++) {
            splitted[j] = parseInt(splitted[j]);
          }

          const date = Date.UTC(splitted[0], splitted[1] - 1, splitted[2]);

          var viewsItem: any = [
            date,
            this.pixelData?.date[i]?.Views
          ];
          viewsSerie.push(viewsItem);

          var uniqueItem: any = [
            date,
            this.pixelData?.date[i]?.Uniques
          ];
          uniqueSerie.push(uniqueItem);
        }
        this.chartOptionsAnalysis.series = [];
        this.chartOptionsAnalysis.series.push({
          name: 'Páginas vistas',
          data: viewsSerie,
          type: null,
          lineColor: '#6F85B2',
          color: '#6F85B2'
        });

        this.chartOptionsAnalysis.series.push({
          name: 'Vistas únicas',
          data: uniqueSerie,
          type: null,
          lineColor: '#CFDDF9',
          color: '#CFDDF9'
        });

        this.firstReport = response;
        this.check = 1;

        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }

        if (!this.isFirstLoad) {
          this.updateFlag = true;
        }
      }).catch((error) => {
        this.pixelData.date = null;
        this.utilsService.presentAlertMessage({
          title: 'No existen datos',
          description: 'No existen datos con los filtros especificados',
          error: true,
          duration: 6
        });
      });
    } catch (error) {
      this.pixelData.date = null;
      this.utilsService.presentAlertMessage({
        title: 'No existen datos',
        description: 'No existen datos con los filtros especificados',
        error: true,
        duration: 6
      });
    }

    this.calculate();
  }

  calculate() {
    let data = Object.assign({}, this.pixelSearch);;

    data.toDate = this.pixelSearch?.fromDate;

    let d = new Date(data?.toDate);
    let date = new Date(d.setDate(d.getDate() - this.periodSelected));
    data.fromDate = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    this.pixelService.getPixelViews(data).then((response) => {
      this.calculator = {
        subtract: {
          uniques: Math.abs(this.selected?.Uniques - response[0]?.Uniques),
          views: Math.abs(this.selected?.Views - response[0]?.Views)
        },
        positive: {
          uniques: (this.selected?.Uniques - response[0]?.Uniques) > 0,
          views: (this.selected?.Views - response[0]?.Views) > 0
        },
      }
    });
  }

  changeTable(type) {
    this.change = type;
  }

  setPersonalized() {
    if (this.dateRange?.startDate && this.dateRange?.endDate) {
      let startDate = new Date(this.dateRange.startDate.$d);
      let endDate = new Date(this.dateRange.endDate.$d);

      this.periodSelected = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

      this.pixelSearch.toDate = this.formatDate(endDate);
      this.pixelSearch.fromDate = this.formatDate(startDate);

      this.init();
      this.select('date', null);
    }
  }

  setPeriod(period: number) {
    this.chartOptionsAnalysis.series = [];

    this.firstReport = null;
    this.rowData = [];

    this.periodSelected = period;

    const today = new Date();
    const base = new Date();
    const final = base.setDate(base.getDate() - this.periodSelected - 1);
    this.pixelSearch.toDate = this.formatDate(today.setDate(today.getDate() - 1));
    this.pixelSearch.fromDate = this.formatDate(final);
    this.init();
    this.select('date', null);

  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}
