import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  translations: any;

  environment = environment;

  constructor(
    public httpClient: HttpClient,
  ) { }

  init(forceLanguage?: string): Promise<any> {
    return <any>new Promise(async (resolve, reject) => {
      this.translations = null;

      let language = 'es_ES';

      let userMe: any = localStorage.getItem('userMe');

      if (userMe) {
        userMe = JSON.parse(userMe);
        language = userMe.language;
      } else if (forceLanguage && forceLanguage !== '') {
        language = forceLanguage;
      } else {
        // Try get the browser language
        language = 'es_ES';
      }

      if (localStorage.getItem('langConfig')) {
        const data = JSON.parse(localStorage.getItem('langConfig'));
        const now = new Date();
        const expires = new Date(data.expires);

        if (expires.getTime() < now.getTime()) {
          localStorage.removeItem('langConfig');
          this.translations = await this.getJson(language);
        } else {
          this.translations = data.data;
        }
      } else {
        this.translations = await this.getJson(language);
      }

      resolve(true);
    });
  }

  getJson(language: string): Promise<any> {
    return <any>new Promise((resolve, reject) => {
      this.httpClient.get(this.environment.s3Buckets.public + '/config/languages/lang/' + language + '/config.json?t=' + new Date().getTime()).toPromise().then(
        (translations) => {
          this.saveLang('langConfig', translations);
          resolve(translations);
        }
      ).catch(
        () => {
          resolve({});
        }
      );
    });
  }

  saveLang(name: string, data: any) {
    let dt = new Date();
    dt.setHours( dt.getHours() + 4 );

    const toSave = {
      data: data,
      expires: dt
    };

    localStorage.setItem(name, JSON.stringify(toSave));
  }

  getTranslations() {
    return this.translations;
  }
}
