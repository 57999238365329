import { 
  Component, 
  EventEmitter, 
  Input, 
  OnInit, 
  Output, 
  ViewChild, 
  ElementRef 
} from '@angular/core';

// SERVICES
import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';

@Component({
  selector: 'app-modify-academy-pub-modal',
  templateUrl: './modify-academy-pub-modal.component.html',
  styleUrls: ['./modify-academy-pub-modal.component.css']
})
export class ModifyAcademyPubModalComponent implements OnInit {

  @ViewChild('mediaInput', {static: true}) mediaInput: ElementRef;

  openModalAcademy = false;

  @Input() dataWidget: any;

  @Output() onClose = new EventEmitter();
  @Output() onTitle = new EventEmitter();
  @Output() onModify = new EventEmitter();

  file_aplication = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"];

  uploadingMode: string;

  dataDefaultWidget = {};

  defaultWidget = [
    this.dataDefaultWidget = {
      title: 'Un mes gratuito',
      description: 'Apúntate ahora y consigue un mes gratis',
      color: null,
      backgroundColor: null,
      data: 'Precios',
      highlight: null,
      assetUrl: './../../../assets/img/pig_icon.png',
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 0,
    },
    this.dataDefaultWidget = {
      title: 'Horarios flexibles',
      description: 'Tenemos diferentes horarios disponibles para adaptarnos a tu disponibilidad',
      color: null,
      backgroundColor: null,
      data: 'Horarios',
      highlight: null,
      assetUrl: './../../../assets/img/watch_icon.png',
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 1,
    },
    this.dataDefaultWidget = {
      title: 'Profesores expertos',
      description: 'Nuestro profesorado está cualificado y entrenado en la materia',
      color: null,
      backgroundColor: null,
      data: 'Profesorado/alumnado',
      highlight: null,
      assetUrl: './../../../assets/img/teacher_icon.png',
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 2,
    },
    this.dataDefaultWidget = {
      title: 'Equipos nuevos',
      description: 'Utilizamos las herramientas y los métodos de enseñanza más novedosos',
      color: null,
      backgroundColor: null,
      data: 'Tecnologías',
      highlight: null,
      assetUrl: './../../../assets/img/laptop_icon.png',
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 3,
    },
    this.dataDefaultWidget = {
      title: 'Certificados oficiales',
      description: 'Tenemos el certificado oficial de centro de estudios de Google',
      color: null,
      backgroundColor: null,
      data: 'Certificados/premios',
      highlight: null,
      assetUrl: './../../../assets/img/certificate_icon.png',
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 4,
    },
    this.dataDefaultWidget = {
      title: 'Título',
      description: 'Descripción del contenido de la tarjeta',
      color: null,
      backgroundColor: null,
      data: 'Créalo tu',
      highlight: null,
      assetUrl: null,
      actionText: 'Saber más',
      actionUrl: null,
      imageChanged: false,
      id: 5,
    },
  ];

  widgets: any;
  
  constructor(
    public utilsService: UtilsService,
    public storageService: StorageService,
    public sponsorSubjectService: SponsorSubjectService,
  ) { }

  ngOnInit(): void { 
    this.widgets = this.storageService.read('widgets');
  }

  openMediaInput(mode: string) {
    this.uploadingMode = mode;
    this.mediaInput.nativeElement.click();
  }

  async handleFile(event) {

    let files: FileList = event.srcElement.files;

    if (!this.file_aplication.includes(files[0].type)) {
      this.utilsService.presentAlertMessage({
        title: "La imagen debe ser PNG, JPG, JPEG o PJPEG",
      });

      this.mediaInput.nativeElement.value = "";
    } else {
      if (files[0].size > 2000000) {
        this.utilsService.presentAlertMessage({
          title: "La imagen no puede ocupar más de 2 MB",
        });

        this.mediaInput.nativeElement.value = "";
      } else {

        if (this.uploadingMode == "icon") {

          this.dataWidget.assetUrl = files[0].name;

        }

        delete this.dataWidget.id;
        this.dataWidget.assetUrl = null;

        this.widgets[0].features = [];

        this.sponsorSubjectService.addWidgetProperty('feature', this.widgets[0].id, this.dataWidget).then(
          (response) => {
            this.widgets[0].features.push(response);
            this.uploadWidget(event);
            this.utilsService.loading('close');
          }
        ).catch(
          () => {
            this.utilsService.loading('close');
            this.utilsService.presentMessage('Ha ocurrido un error');
          }
        );

        // this.onSelectFile(event, this.uploadingMode);
      }
    }
  }

  async uploadWidget(event) {
    this.utilsService.loading('open');

    const modes = this.uploadingMode.split('_');

    const data: any = {};
    const file = event.target.files[0];
    const lastDot = file.name.lastIndexOf('.');
    const extension = file.name.substring(lastDot + 1);;

    data[modes[1]] = {
      extension: extension,
      contentType: file.type
    };

    this.sponsorSubjectService.updateWidgetProperty(modes[0], this.widgets[0].id, this.widgets[0].features[0].id, data).then(
      (response) => {
        this.uploadToS3(response[modes[1] + 'Url'], file.type, file).then(() => {
          
          this.dataWidget.assetUrl = response.assetUrl.split('?')[0];
  
          this.mediaInput.nativeElement.value = null;
  
          this.utilsService.loading('close');
        });
      }
    ).catch(
      () => {
        this.utilsService.loading('close');
        this.utilsService.presentMessage('Ha ocurrido un error');
      }
    );
  }

  async uploadToS3(url: string, type: any, file: any) {
    const response = await this.sponsorSubjectService.uploadToS3(url, type, file);
  }

  onSelectFile(event, mode) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.dataWidget.assetUrl = event.target.result;

      };
    }
  }

  removeFeature() {
    this.dataWidget.title = this.defaultWidget[this.dataWidget.id].title;
    this.dataWidget.description = this.defaultWidget[this.dataWidget.id].description;
    this.dataWidget.actionUrl = this.defaultWidget[this.dataWidget.id].actionUrl;
    this.dataWidget.actionText = this.defaultWidget[this.dataWidget.id].actionText;
    this.dataWidget.assetUrl = this.defaultWidget[this.dataWidget.id].assetUrl;
  }

  close() {
    this.openModalAcademy = false;
    setTimeout(() => {
      this.onClose.emit();
    }, 150);
  }

  save() {
    this.onModify.emit(this.dataWidget);
    setTimeout(() => {
      this.close();
    }, 70);
  }

}
