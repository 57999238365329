import { Injectable } from '@angular/core';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  constructor() { }

  pageView(location: any = window.location.href, path: any, title: any) {
    gtag('event', 'page_view', {
      page_location: location,
      page_path: path,
      page_title: title
    });
  }

  screenView(name: string) {
    gtag('event', 'screen_view', {
      'screen_name': name
    });
  }

  customEvent(name: string, data: object) {
    gtag('event', name, data);
  }
}
