<div class="body-container">
  <h1 class="wds-h1">Resumen</h1>

  <div *ngIf="advertiserMe.dfpId && campaigns && campaigns.length > 0" class="iframe-container">
    <iframe *ngIf="advertiserMe.dfpId"
      width="100%"
      height="540"
      [src]="'https://datastudio.google.com/embed/reporting/f1b115b4-028d-4ff0-970f-2c2a97f99b62/page/u1fZB?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580' + advertiserMe.dfpId + '%22%7D' | safe"
      frameborder="0"
      style="border: 0; overflow: hidden;"
      scrolling="no"
      allowfullscreen
    ></iframe>
  </div>

  <div class="campaigns-container" *ngIf="campaigns && campaigns.length > 0">
    <div class="main-info-box">
      <div class="item-input no-margin">
        <div class="labels">
          <div class="title">Campañas activas.</div>
          <small>Las campañas que están funcionando en este momento.</small>
        </div>

        <div class="cp-row row-with-status">
          <div class="cp-column">CAMPAÑA</div>
          <div class="cp-column">ESTADO</div>
          <div class="cp-column">
            <div title="Impresiones" class="cp-inner-column">IMPR.</div>
            <div title="Clicks" class="cp-inner-column">CLICKS</div>
            <div title="CTR" class="cp-inner-column">CTR</div>
          </div>
          <div class="cp-column"></div>
        </div>
    
        <ng-container *ngFor="let campaign of campaigns">
          <div *ngIf="campaign.status == 'ACTIVE' || campaign.status == 'PAUSED'" class="cp-row campaign row-with-status">
            <div class="cp-column">{{campaign.name}}</div>
            <div class="cp-column">{{campaignStatusesMessages[campaign.status]}}</div>
            <div class="cp-column data">
              <iframe *ngIf="campaign.dfpOrderId"
                [src]="'https://datastudio.google.com/embed/reporting/97566823-4346-4fff-b3fb-da67d8c98550/page/FUnEB?params=%7B%22df453%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580Yump!%25EE%2580%2580' + 2709243750 + '%22%7D' | safe"
                frameborder="0"
                style="border: 0"
                allowfullscreen
              ></iframe>
              <span *ngIf="!campaign.dfpOrderId">
                Aún no se ha gestionado tu campaña.
              </span>
            </div>
            <div class="cp-column">
              <a [routerLink]="['/dashboard', 'campaign', campaign.id]">Ver campaña</a>
            </div>
          </div>
        </ng-container>
        <div class="no-campaigns-message" *ngIf="!currentCampaigns">
          No hay campañas activas.
        </div>
      </div>
    </div>
      
    <div class="main-info-box">
      <div class="item-input no-margin">
        <div class="labels">
          <div class="title">Campañas programadas.</div>
          <small>Las campañas que pronto estarán en distribución.</small>
        </div>

        <div class="cp-row row-with-status">
          <div class="cp-column">CAMPAÑA</div>
          <div class="cp-column">ESTADO</div>
          <div class="cp-column">
            <div title="Impresiones" class="cp-inner-column">IMPR.</div>
            <div title="Clicks" class="cp-inner-column">CLICKS</div>
            <div title="CTR" class="cp-inner-column">CTR</div>
          </div>
          <div class="cp-column"></div>
        </div>
    
        <ng-container *ngFor="let campaign of campaigns">
          <div *ngIf="campaign.status == 'READY' || campaign.status == 'PENDING'" class="cp-row campaign row-with-status">
            <div class="cp-column">{{campaign.name}}</div>
            <div class="cp-column">{{campaignStatusesMessages[campaign.status]}}</div>
            <div class="cp-column data">
              <iframe *ngIf="campaign.dfpOrderId"
                [src]="'https://datastudio.google.com/embed/reporting/97566823-4346-4fff-b3fb-da67d8c98550/page/FUnEB?params=%7B%22df453%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580Yump!%25EE%2580%2580' + 2709243750 + '%22%7D' | safe"
                frameborder="0"
                style="border: 0"
                allowfullscreen
              ></iframe>
              <span *ngIf="!campaign.dfpOrderId">
                Aún no se ha gestionado tu campaña.
              </span>
            </div>
            <div class="cp-column">
              <a [routerLink]="['/dashboard', 'campaign', campaign.id]">Ver campaña</a>
            </div>
          </div>
        </ng-container>
        <div class="no-campaigns-message" *ngIf="!futureCampaigns">
          No se hay campañas programadas.
        </div>
      </div>
    </div>
      
    <div class="main-info-box">
      <div class="item-input no-margin">
        <div class="labels">
          <div class="title">Campañas finalizadas.</div>
          <small>Las campañas que ya se han completado.</small>
        </div>

        <div class="cp-row">
          <div class="cp-column">CAMPAÑA</div>
          <div class="cp-column">
            <div title="Impresiones" class="cp-inner-column">IMPR.</div>
            <div title="Clicks" class="cp-inner-column">CLICKS</div>
            <div title="CTR" class="cp-inner-column">CTR</div>
          </div>
          <div class="cp-column"></div>
        </div>
    
        <ng-container *ngFor="let campaign of campaigns">
          <div *ngIf="campaign.status == 'FINISHED'" class="cp-row campaign">
            <div class="cp-column">{{campaign.name}}</div>
            <div class="cp-column data">
              <iframe *ngIf="campaign.dfpOrderId"
                [src]="'https://datastudio.google.com/embed/reporting/97566823-4346-4fff-b3fb-da67d8c98550/page/FUnEB?params=%7B%22df453%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580Yump!%25EE%2580%2580' + 2709243750 + '%22%7D' | safe"
                frameborder="0"
                style="border: 0"
                allowfullscreen
              ></iframe>
              <span *ngIf="!campaign.dfpOrderId">
                Aún no se ha gestionado tu campaña.
              </span>
            </div>
            <div class="cp-column">
              <app-button-item [routerLink]="['/dashboard', 'campaign', campaign.id]" [title]="'Ver campaña'" [text]="'Ver campaña'" [icon]="'remove_red_eye'"></app-button-item>
            </div>
          </div>
        </ng-container>
        <div class="no-campaigns-message" *ngIf="!pastCampaigns">
          No hay campañas finalizadas.
        </div>
      </div>
    </div>
  </div>

  <div class="no-campaigns">
    <div class="nc-asset">
      <i class="material-icons-outlined">inbox</i>
    </div>

    <div class="nc-title">Nada que mostrar</div>
    <div class="nc-subtitle">Crea tu primer anuncio, solo te tomará unos minutos.</div>

    <app-button-item
      (fireClick)="openCreatePubModal()" 
      [text]="'Crear anuncio'" 
      [background]="'#102754'" 
      [color]="'#FFFFFF'" 
      [solid]="true"
    ></app-button-item>
  </div>
</div>
