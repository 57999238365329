import { Component, OnInit } from '@angular/core';

// Services
import { CampaignService } from '../../services/campaign.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  
  userMe: any;
  advertiserMe: any;
  campaigns: any[];
  now: number;

  currentCampaigns = 0;
  futureCampaigns = 0;
  pastCampaigns = 0;

  campaignStatuses = [
    'CREATING_CAMPAIGN',
    'CREATING_CREATIVES',
    'CREATING_PAYMENTS',
    'PENDING',
    'READY',
    'ACTIVE',
    'PAUSED',
    'FINISHED'
  ];

  campaignStatusesMessages = {
    'PENDING': 'Revisando',
    'READY': 'Preparada',
    'ACTIVE': 'Activa',
    'PAUSED': 'Pausada',
    'FINISHED': 'Finalizada'
  };

  uuid: string;

  constructor(
    public campaignService: CampaignService,
    public storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    public utilsService: UtilsService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { }
  
  async ngOnInit() {
    this.titleService.setTitle('Panel principal | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/main', 'Dashboard main');
    this.analyticService.screenView('Dashboard main');

    this.uuid = this.storageService.read('uuid');

    this.now = (new Date()).getTime();
    this.userMe = this.storageService.read('userMe');
    this.advertiserMe = this.storageService.read('advertiserMe');
    this.campaigns = await this.campaignService.getCampaigns(this.userMe.userId);
    
    for (var i = 0; i < this.campaigns.length; i++) {
      if(this.campaigns[i].status == 'ACTIVE' || this.campaigns[i].status == 'PAUSED') this.currentCampaigns++;
      if(this.campaigns[i].status == 'READY' || this.campaigns[i].status == 'PENDING') this.futureCampaigns++;
      if(this.campaigns[i].status == 'FINISHED') this.pastCampaigns++;
    }
  }

  createCampaign() {
    this.storageService.remove('editingCampaign');
    if(this.route.snapshot && this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0] && this.route.snapshot.firstChild.url[0].path == 'create') {
      window.location.reload();
    } else {
      this.router.navigate(['dashboard/create']);
    }
  }

  openCreatePubModal() {  
    this.analyticService.customEvent(
      'UP2_01_0_CLICK',
      {
        uuid: this.uuid,
        userId: null
      }
    );

    this.utilsService.publish("create_pub_modal_action", {
      action: 'open'
    });
  }
}
