<div class="container" [ngClass]="{'warning': color && color === 'warning', 'danger': color && color === 'danger'}">
  <div *ngIf="selectable" class="label-container selectable">
    <select [(ngModel)]="selected" (change)="selectedOnChange()">
      <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
    </select>
  </div>

  <div *ngIf="!selectable" class="label-container">
    {{label}}
  </div>

  <div class="input-container">
    <input [type]="type" [(ngModel)]="amount" (keyup)="amountOnChange()" (change)="amountOnChange()" [max]="max" [min]="min">

    <div *ngIf="unity" class="unity">{{unity}}</div>
  </div>
</div>
