import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { version } from "../../../package.json";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private channels: { [key: string]: Subject<any> } = {};

  private customSubject = new Subject<any>();
  public version: string = version;

  businessTypes = [
    {
      name: "Academia de formación",
      value: "ACADEMY",
    },
    {
      name: "Escuela de negocio",
      value: "BUSINESS_SCHOOL",
    },
    {
      name: "Grandes marcas",
      value: "KEY_BRANDS",
    },
    {
      name: "Agencia de publicidad",
      value: "ADS_AGENCY",
    },
    {
      name: "Pequeña o mediana empresa",
      value: "PYMES",
    },
  ];

  constructor() {}

  removeDiacritics(str) {
    if (str !== null) {
      return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    }
  }

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      // You can also use ReplaySubject with one concequence
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }

  publish(topic: string, data?: any): void {
    const subject = this.channels[topic];
    if (!subject) {
      // Or you can create a new subject for future subscribers
      return;
    }
    subject.next(data);
  }

  publishCustomEvent(data: any) {
    this.customSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.customSubject;
  }

  loading(mode: "open" | "close") {
    if (mode === "open") {
      this.publishCustomEvent({
        name: "activete_loading",
      });
    } else {
      this.publishCustomEvent({
        name: "close_loading",
      });
    }
  }

  validateEmail(email: string) {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  }

  validatePassword(password: string) {
    const comparission = password.replace(/\s/g, "");

    if (password.length !== comparission.length) {
      return false;
    }

    if (!password || password === "") {
      return false;
    }

    if (!comparission || comparission === "") {
      return false;
    }

    if (comparission.length < 6 || comparission.length > 18) {
      return false;
    }

    return true;
  }

  validatePhoneNumber(number) {
    let pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    let valid = pattern.test(number);
    return valid;
  }

  presentMessage(message: any) {
    if (!message || message === "") {
      console.warn("You need specify a title for the message.");
      return false;
    }

    this.publishCustomEvent({
      name: "alert_message",
      data: { title: message },
    });
  }

  presentAlertMessage(message: any) {
    if (!message.title || message.title === "") {
      console.warn("You need specify a title for the alert message.");
      return false;
    }

    this.publishCustomEvent({
      name: "alert_message",
      data: message,
    });
  }

  getBusinessTypes() {
    return this.businessTypes;
  }

  getBusinessType(index: string) {
    let output = null;

    loop: for (let i = 0; i < this.businessTypes.length; i++) {
      if (this.businessTypes[i].value === index) {
        output = this.businessTypes[i].name;
        break loop;
      }
    }

    return output;
  }

  getVersion() {
    return this.version;
  }
}
