<div class="container">
  <div class="overlay" (click)="close()"></div>

  <div class="inner-container">
    <p>Tiene un patrocinio guardado en borradores.</p>
    <p>¿Desea continuar?</p>
    <div>
      <app-button-item
       [title]="'Sí'"
       [text]="'Sí'"
       [outlined]="true"
       [borderColor]="'#b6b6b6'"
       [color]="'#111111'"
       (fireClick)="gotToSponsorSave()"
      ></app-button-item>

      <app-button-item
       [title]="'No'"
       [text]="'No'"
       [solid]="true" 
       (fireClick)="gotToSponsor()"     
      ></app-button-item>
    </div>
  </div>
</div>
