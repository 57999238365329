import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ClipboardService } from "ngx-clipboard";

import { TargetService } from "../../services/target.service";
import { UniversityService } from "../../services/university.service";
import { CenterService } from "../../services/center.service";
import { StudyService } from "../../services/study.service";
import { UtilsService } from "../../services/utils.service";
import { AutocompleteComponent } from "angular-ng-autocomplete";
import { UserService } from "src/app/services/user.service";

// Environments
import { environment } from "../../../environments/environment";
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard-generate-segmentation",
  templateUrl: "./dashboard-generate-segmentation.component.html",
  styleUrls: ["./dashboard-generate-segmentation.component.css"],
})
export class DashboardGenerateSegmentationComponent
  implements OnInit, AfterViewInit
{
  environment = environment;
  @ViewChild("cityAutocomplete") cityAutocomplete: any;
  @ViewChild("zipAutocomplete") zipAutocomplete: AutocompleteComponent;
  @ViewChild("typeAutocomplete") typeAutocomplete: AutocompleteComponent;
  @ViewChild("universityAutocomplete")
  universityAutocomplete: AutocompleteComponent;
  @ViewChild("centerAutocomplete") centerAutocomplete: AutocompleteComponent;
  @ViewChild("studyAutocomplete") studyAutocomplete: AutocompleteComponent;
  @ViewChild("courseAutocomplete") courseAutocomplete: AutocompleteComponent;

  @ViewChild("newCreativityInput") newCreativityInput: ElementRef;

  targetSearchData = {
    city: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
    type: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
    zip: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
    university: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: true,
    },
    center: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
    study: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
    course: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
    },
  };

  campaign = {
    id: null,
    name: "",
    status: "CREATING_CAMPAIGN",
    target: {
      name: "",
      countryList: [],
      cityList: [],
      typeList: [],
      zipList: [],
      universityList: [],
      centerList: [],
      studyList: [],
      courseList: [],
    },
    clickUrl: "",
    creatives: [],
    payments: [],
    budget: 0,
    customBudgetPeriodicity: 0,
    customBudgetAmount: 0,
    startDate: null,
    endDate: null,
    startDateString: null,
    endDateString: null,
    reach: 0,
    impressions: 0,
  };

  constructor(
    private targetService: TargetService,
    private universityService: UniversityService,
    private centerService: CenterService,
    private studyService: StudyService,
    private utilsService: UtilsService,
    public userService: UserService,
    public analyticService: AnalyticService,
    public titleService: Title,
    private clipboardService: ClipboardService
  ) {}

  async ngOnInit() {
    for (let i = 1; i < 7; i++) {
      this.targetSearchData.course.results.push({ name: i + "º", id: i });
    }
  }

  async ngAfterViewInit() {
    this.setAutocompletesModel();
  }

  async searchStudyTypeTargetOptions() {
    if (
      this.targetSearchData.type.results.length > 0 ||
      this.targetSearchData.type.loading
    ) {
      return;
    }
    this.targetSearchData.type.loading = true;
    const results = await this.targetService.searchTypeStudy({
      countryId: 1,
      limit: 10,
      offset: 0,
    });
    this.targetSearchData.type.loading = false;
    this.targetSearchData.type.results = results;
  }

  async searchTargetOptions(event, type) {
    if (type == "course") return;
    this.targetSearchData[type].loading = true;
    let params: any = {
      limit: 30,
      text: event && event.length ? event : null,
    };

    let results = [];
    if (type === "city") {
      params.types = "city";
      params.countryId = 1;
      results = (await this.targetService.getSegmentations(params)).results;
    } else if (type === "university") {
      params.countryIds = [1];
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      results = await this.universityService.heavySearchUniversities(params);
    } else if (type === "center") {
      params.countryIds = [1];
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      if (this.campaign.target["typeList"].length)
        params.studyTypes = JSON.stringify(
          this.campaign.target["typeList"].map((x) => x.id)
        );
      if (this.campaign.target["universityList"].length)
        params.universityIds = JSON.stringify(
          this.campaign.target["universityList"].map((x) => x.id)
        );
      results = await this.centerService.heavySearchCenters(params);
    } else if (type === "study") {
      params.countryIds = [1];
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      if (this.campaign.target["typeList"].length)
        params.types = JSON.stringify(
          this.campaign.target["typeList"].map((x) => x.id)
        );
      if (this.campaign.target["universityList"].length)
        params.universityIds = JSON.stringify(
          this.campaign.target["universityList"].map((x) => x.id)
        );
      if (this.campaign.target["centerList"].length)
        params.centerIds = JSON.stringify(
          this.campaign.target["centerList"].map((x) => x.id)
        );
      results = await this.studyService.heavySearchStudies(params);
    }

    this.targetSearchData[type].results = results.length > 0 ? results : null;
    this.targetSearchData[type].loading = false;
  }

  async selectTarget(event, type) {
    let list = this.campaign.target[type + "List"];
    if (!list.find((x) => x.id == event.id))
      list.push({ name: event.name, id: event.id });
    if (type != "course") this.targetSearchData[type].results = [];
    await this.targetSearchData[type].autocomplete.clear();
    this.targetSearchData[type].text = "";
    if (this.campaign.target.typeList.find((item) => item.id === 1)) {
      this.targetSearchData.university.disabled = false;
    }
  }

  deselectTarget(id, type) {
    let delistedItem = this.campaign.target[type + "List"].find(
      (x) => x.id == id
    );
    let delistedIndex =
      this.campaign.target[type + "List"].indexOf(delistedItem);
    this.campaign.target[type + "List"].splice(delistedIndex, 1);
    if (!this.campaign.target.typeList.find((item) => item.id === 1)) {
      this.targetSearchData.university.disabled = true;
    }
  }

  /* AUXILIAR METHODS */
  setAutocompletesModel() {
    this.targetSearchData.city.autocomplete = this.cityAutocomplete;
    this.targetSearchData.zip.autocomplete = this.zipAutocomplete;
    this.targetSearchData.type.autocomplete = this.typeAutocomplete;
    this.targetSearchData.university.autocomplete = this.universityAutocomplete;
    this.targetSearchData.center.autocomplete = this.centerAutocomplete;
    this.targetSearchData.study.autocomplete = this.studyAutocomplete;
    this.targetSearchData.course.autocomplete = this.courseAutocomplete;
  }

  getSegmentationDepth(target) {
    let segmentationDepth = 0;
    if (
      (target.countryList && target.countryList.length) ||
      (target.cityList && target.cityList.length) ||
      (target.universityList && target.universityList.length) ||
      (target.centerList && target.centerList.length)
    )
      segmentationDepth++;

    if (target.studyList && target.studyList.length) segmentationDepth++;
    if (target.courseList && target.courseList.length) segmentationDepth++;

    segmentationDepth = Math.max(0, segmentationDepth - 1);

    return segmentationDepth;
  }

  copyPublic(type) {
    let copied = JSON.stringify(
      this.campaign.target[type + "List"].map((x) => x.id)
    );
    copied = copied.substring(1, copied.length - 1);
    console.log(copied);

    this.clipboardService.copy(copied);
    this.utilsService.presentAlertMessage({
      title: "Copiado.",
      description: "Se han copiado los ids en el portapapeles.",
      duration: 5,
    });
  }
}
