import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { i18nMetaToDocStmt } from "@angular/compiler/src/render3/view/i18n/meta";
import { ActivatedRoute, Router } from "@angular/router";
import { TargetService } from "../../services/target.service";
import { CampaignService } from "../../services/campaign.service";
import { UniversityService } from "../../services/university.service";
import { CenterService } from "../../services/center.service";
import { StudyService } from "../../services/study.service";
import { UtilsService } from "../../services/utils.service";
import { StorageService } from "../../services/storage.service";
import { AutocompleteComponent } from "angular-ng-autocomplete";
import { TimestampPipe } from "../../pipes/timestamp.pipe";
import { UserService } from "src/app/services/user.service";

// Environments
import { environment } from "../../../environments/environment";
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";

declare var Stripe: any;

@Component({
  selector: "app-dashboard-create",
  templateUrl: "./dashboard-create.component.html",
  styleUrls: ["./dashboard-create.component.css"],
})
export class DashboardCreateComponent implements OnInit, AfterViewInit {
  advertiserMe: any;
  userMe: any;
  uuid: string;

  step: number = 1;
  stepFulfilled = false;

  environment = environment;
  @ViewChild("cityAutocomplete") cityAutocomplete: any;
  @ViewChild("zipAutocomplete") zipAutocomplete: AutocompleteComponent;
  @ViewChild("universityAutocomplete")
  universityAutocomplete: AutocompleteComponent;
  @ViewChild("centerAutocomplete") centerAutocomplete: AutocompleteComponent;
  @ViewChild("studyAutocomplete") studyAutocomplete: AutocompleteComponent;
  @ViewChild("courseAutocomplete") courseAutocomplete: AutocompleteComponent;

  @ViewChild("newCreativityInput") newCreativityInput: ElementRef;

  segmentationCMP = [
    { impressions: 10, cmp: 3.75 },
    { impressions: 10, cmp: 6.5 },
    { impressions: 30, cmp: 12.5 },
  ];
  campaignStatuses = [
    "CREATING_CAMPAIGN",
    "CREATING_CREATIVES",
    "CREATING_PAYMENTS",
    "PENDING",
    "READY",
    "ACTIVE",
    "PAUSED",
    "FINISHED",
  ];

  targetSearchData = {
    city: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
    zip: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
    university: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
    center: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
    study: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
    course: {
      text: "",
      results: [],
      autocomplete: null,
      loading: false,
      disabled: false,
    },
  };
  creativesSlots = {
    1: 3,
    2: 3,
    3: 3,
    4: 3,
    5: 3,
    6: 3,
    7: 3,
    8: 3,
    9: 3,
    10: 3,
  };

  reachPercentage = 0;
  minimumTotalBudget = 0;
  numDaysSelected = 0;

  campaign = {
    id: null,
    name: "",
    status: "CREATING_CAMPAIGN",
    target: {
      name: "",
      countryList: [],
      cityList: [],
      zipList: [],
      universityList: [],
      centerList: [],
      studyList: [],
      courseList: [],
    },
    clickUrl: "",
    creatives: [],
    payments: [],
    budget: 0,
    customBudgetPeriodicity: 0,
    customBudgetAmount: 0,
    startDate: null,
    endDate: null,
    startDateString: null,
    endDateString: null,
    reach: 0,
    impressions: 0,
  };

  selectedAdUbication = 1;
  selectedCreative: number = null;
  selectedUbicationExample: string = "/assets/pub/example-ubication-1.jpg";
  creativityPreviewClass: any = { ubication1: true };

  stripeClient: any;
  stripeCard: any;

  editMode = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private targetService: TargetService,
    private campaignService: CampaignService,
    private universityService: UniversityService,
    private centerService: CenterService,
    private studyService: StudyService,
    private utilsService: UtilsService,
    private storageService: StorageService,
    private timestampPipe: TimestampPipe,
    public userService: UserService,
    public analyticService: AnalyticService,
    public titleService: Title
  ) {}

  async ngOnInit() {
    if (this.route.snapshot.paramMap.get("id")) {
      this.titleService.setTitle("Edita una campaña | Wuolads");
      this.analyticService.pageView(
        window.location.href,
        "dashboard/edit/" + this.route.snapshot.paramMap.get("id"),
        "Edit ad campaign"
      );
      this.analyticService.screenView("Edit ad campaign");
    } else {
      this.titleService.setTitle("Crea una campaña | Wuolads");
      this.analyticService.pageView(
        window.location.href,
        "dashboard/create",
        "Create new ad campaign"
      );
      this.analyticService.screenView("Create new ad campaign");
    }

    this.advertiserMe = this.storageService.read("advertiserMe");
    this.userMe = this.storageService.read("userMe");
    this.uuid = this.storageService.read("uuid");

    if (this.route.snapshot.paramMap.get("id")) {
      let id = this.route.snapshot.paramMap.get("id");
      let campaign = await this.campaignService.getCampaign(
        this.advertiserMe.userId,
        id
      );
      Object.assign(this.campaign, campaign);
      this.campaign.customBudgetPeriodicity = 1;
      this.campaign.customBudgetAmount = campaign.budget;
      this.setDefaultCampaignPeriod(
        this.campaign.startDate,
        this.campaign.endDate
      );
      this.checkCampaignChanges();
      this.editMode = true;
    } else if (this.storageService.read("editingCampaign")) {
      this.campaign = this.storageService.read("editingCampaign");
      this.checkCampaignChanges();
    } else {
      this.setDefaultCampaignPeriod();
      this.onTargetUpdate();
    }
    this.step = Math.min(
      3,
      this.campaignStatuses.indexOf(this.campaign.status) + 1
    );

    for (let i = 0; i < this.campaign.creatives.length; i++) {
      this.creativesSlots[this.campaign.creatives[i].ubicationId] = Math.max(
        0,
        this.creativesSlots[this.campaign.creatives[i].ubicationId] - 1
      );
    }
    for (let i = 1; i < 7; i++) {
      this.targetSearchData.course.results.push({ name: i + "º", id: i });
    }

    const data = {
      step: "CREATECAMPAIGN",
    };

    let notification: any = this.storageService.read("notification");
    if (!notification) {
      notification = {
        sponsor: null,
        campaign: 1,
        completeProfile: null,
        signUp: null,
      };
      this.userService.sendStepNotification(data);
    } else {
      if (notification?.campaign && notification?.campaign < 5) {
        this.userService.sendStepNotification(data);
        notification.campaign = notification?.campaign + 1;
      } else if (!notification?.campaign) {
        notification.campaign = 1;
        this.userService.sendStepNotification(data);
      }
    }
    this.storageService.write("notification", notification);

    setTimeout(() => {
      this.loadStripe();
    }, 1000);
  }

  async ngAfterViewInit() {
    this.setAutocompletesModel();
  }

  async nextStep() {
    if (!this.stepFulfilled) return;
    this.campaign.status = this.campaignStatuses[this.step];

    this.utilsService.loading("open");
    let campaign;

    try {
      campaign = await this.campaignService.editCampaign(this.campaign);
      this.utilsService.loading("close");
    } catch (error) {
      this.utilsService.loading("close");
      this.campaignService.errorHandler(error, true);
      return;
    }

    Object.assign(this.campaign, campaign);

    if (this.step < 3) {
      this.step++;
      this.checkCampaignChanges();
    }
  }

  previousStep() {
    if (this.step <= 1) return;
    this.step--;
    this.checkCampaignChanges();
    this.setAutocompletesModel();
  }

  async searchTargetOptions(event, type) {
    if (type == "course") return;
    this.targetSearchData[type].loading = true;
    let params: any = {
      limit: 30,
      text: event && event.length ? event : null,
    };

    let results = [];
    if (type === "city") {
      params.types = "city";
      params.countryId = 1;
      results = (await this.targetService.getSegmentations(params)).results;
    } else if (type === "university") {
      params.countryIds = [1];
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      results = await this.universityService.heavySearchUniversities(params);
    } else if (type === "center") {
      params.countryIds = [1];
      console.log(this.campaign.target);
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      if (this.campaign.target["universityList"].length)
        params.universityIds = JSON.stringify(
          this.campaign.target["universityList"].map((x) => x.id)
        );
      results = await this.centerService.heavySearchCenters(params);
    } else if (type === "study") {
      params.countryIds = [1];
      if (this.campaign.target["cityList"].length)
        params.cityIds = JSON.stringify(
          this.campaign.target["cityList"].map((x) => x.id)
        );
      if (this.campaign.target["universityList"].length)
        params.universityIds = JSON.stringify(
          this.campaign.target["universityList"].map((x) => x.id)
        );
      if (this.campaign.target["centerList"].length)
        params.centerIds = JSON.stringify(
          this.campaign.target["centerList"].map((x) => x.id)
        );
      results = await this.studyService.heavySearchStudies(params);
    }

    this.targetSearchData[type].results = results.length > 0 ? results : null;
    this.targetSearchData[type].loading = false;
  }

  async selectTarget(event, type) {
    let list = this.campaign.target[type + "List"];
    if (!list.find((x) => x.id == event.id))
      list.push({ name: event.name, id: event.id });
    if (type != "course") this.targetSearchData[type].results = [];
    await this.targetSearchData[type].autocomplete.clear();
    this.targetSearchData[type].text = "";
    this.onTargetUpdate();
  }

  deselectTarget(id, type) {
    let delistedItem = this.campaign.target[type + "List"].find(
      (x) => x.id == id
    );
    let delistedIndex =
      this.campaign.target[type + "List"].indexOf(delistedItem);
    this.campaign.target[type + "List"].splice(delistedIndex, 1);
    this.onTargetUpdate();
  }

  targetDisableChange(type) {
    this.targetSearchData[type].disabled =
      !this.targetSearchData[type].disabled;
    if (!this.targetSearchData[type].disabled) {
      this.campaign.target[type + "List"] = [];
      this.onTargetUpdate();
    }
  }

  onNameUpdate() {
    this.checkCampaignChanges();
  }

  onTargetNameUpdate() {
    this.checkCampaignChanges();
  }

  async onTargetUpdate() {
    await this.updateCampaignReach();
    this.updateInitialBudget();
    this.checkCampaignChanges();
  }

  onDateUpdate() {
    this.campaign.startDate = this.timestampPipe.revert(
      this.campaign.startDateString
    );
    this.campaign.endDate = this.timestampPipe.revert(
      this.campaign.endDateString
    );

    this.checkCampaignChanges();
  }

  onBudgetUpdate() {
    this.campaign.customBudgetAmount = Number(
      Number(this.campaign.customBudgetAmount).toFixed(2)
    );
    this.checkCampaignChanges();
  }

  /* STATE CHANGE FUNCTIONS */

  checkCampaignChanges() {
    this.updateDaysSelected();
    this.updateCampaignRealBudget();
    this.updateCampaignImpressions();
    this.updateCampaignReachPercentage();

    this.validateCampaignStep();
    this.storeEditingCampaign();
  }

  updateDaysSelected() {
    let dayTimestamp = 24 * 60 * 60 * 1000;
    this.numDaysSelected =
      (this.campaign.endDate - this.campaign.startDate) / dayTimestamp;

    this.minimumTotalBudget = this.numDaysSelected * 5;
  }

  updateCampaignRealBudget() {
    this.campaign.budget =
      this.campaign.customBudgetPeriodicity == 0
        ? Math.round(
            this.campaign.customBudgetAmount * 100 * this.numDaysSelected
          ) / 100
        : this.campaign.customBudgetAmount;
  }

  async updateCampaignImpressions() {
    if (
      !this.campaign.startDate ||
      !this.campaign.endDate ||
      !this.campaign.customBudgetAmount
    )
      return;

    let budget =
      this.campaign.customBudgetPeriodicity == 0
        ? this.campaign.customBudgetAmount * this.numDaysSelected
        : this.campaign.customBudgetAmount;
    let cmp =
      this.segmentationCMP[this.getSegmentationDepth(this.campaign.target)].cmp;

    let impressions = (budget / cmp) * 1000;
    this.campaign.impressions = impressions;
  }

  updateCampaignReachPercentage() {
    let impressionsBase =
      this.segmentationCMP[this.getSegmentationDepth(this.campaign.target)]
        .impressions;
    let reach = this.campaign.reach;
    let months = this.getExactMonthsBetween(
      this.campaign.startDate,
      this.campaign.endDate
    );

    let maxImpressions = reach * impressionsBase * 1.5;
    let currentImpressions = this.campaign.impressions / months;

    this.reachPercentage = (currentImpressions / maxImpressions) * 100.0;
  }

  validateCampaignStep() {
    let step1Fulfilled =
      this.campaign.name.length &&
      this.campaign.target.name.length &&
      this.campaign.startDate &&
      this.campaign.endDate &&
      this.campaign?.startDate !== this.campaign?.endDate &&
      this.campaign.budget >= this.minimumTotalBudget;

    let step2Fulfilled = !!(
      this.campaign.creatives.length && this.campaign.clickUrl.length
    );

    this.stepFulfilled =
      (this.step == 1 && step1Fulfilled) || (this.step == 2 && step2Fulfilled);
  }

  storeEditingCampaign() {
    if (this.editMode) return;
    this.storageService.write("editingCampaign", this.campaign);
  }

  async updateCampaignReach() {
    this.campaign.reach = await this.campaignService.getTargetReach(
      this.campaign.target
    );
  }

  updateInitialBudget() {
    let baseImpressions =
      this.campaign.reach *
      this.segmentationCMP[this.getSegmentationDepth(this.campaign.target)]
        .impressions;
    let cmp =
      this.segmentationCMP[this.getSegmentationDepth(this.campaign.target)].cmp;
    let months = this.getExactMonthsBetween(
      this.campaign.startDate,
      this.campaign.endDate
    );

    let budget = (baseImpressions / 1000) * cmp * months;

    if (this.campaign.customBudgetPeriodicity == 0) {
      this.campaign.customBudgetAmount = Math.max(
        5.0,
        Number((budget / this.numDaysSelected).toFixed(2))
      );
    } else {
      this.campaign.customBudgetAmount = Math.max(
        Number((this.numDaysSelected * 5).toFixed(2)),
        Number(budget.toFixed(2))
      );
    }
  }

  /* AUXILIAR METHODS */
  setAutocompletesModel() {
    this.targetSearchData.city.autocomplete = this.cityAutocomplete;
    this.targetSearchData.zip.autocomplete = this.zipAutocomplete;
    this.targetSearchData.university.autocomplete = this.universityAutocomplete;
    this.targetSearchData.center.autocomplete = this.centerAutocomplete;
    this.targetSearchData.study.autocomplete = this.studyAutocomplete;
    this.targetSearchData.course.autocomplete = this.courseAutocomplete;
  }

  getSegmentationDepth(target) {
    let segmentationDepth = 0;
    if (
      (target.countryList && target.countryList.length) ||
      (target.cityList && target.cityList.length) ||
      (target.universityList && target.universityList.length) ||
      (target.centerList && target.centerList.length)
    )
      segmentationDepth++;

    if (target.studyList && target.studyList.length) segmentationDepth++;
    if (target.courseList && target.courseList.length) segmentationDepth++;

    segmentationDepth = Math.max(0, segmentationDepth - 1);

    return segmentationDepth;
  }

  getExactMonthsBetween(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);

    var yearsDifference = endDate.getFullYear() - startDate.getFullYear();
    var monthsDifference = endDate.getMonth() - startDate.getMonth();
    var daysDifference = endDate.getDate() - startDate.getDate();

    monthsDifference += daysDifference / 30.5;

    return yearsDifference * 12 + monthsDifference;
  }

  setDefaultCampaignPeriod(startDate?, endDate?) {
    this.campaign.startDate = startDate
      ? startDate
      : new Date().getTime() + 24 * 60 * 60 * 1000;
    this.campaign.endDate = endDate
      ? endDate
      : new Date().getTime() + 30 * 24 * 60 * 60 * 1000;

    this.campaign.startDateString = this.timestampPipe.transform(
      this.campaign.startDate
    );
    this.campaign.endDateString = this.timestampPipe.transform(
      this.campaign.endDate
    );

    this.updateDaysSelected();
  }

  openAddCreativityInput() {
    this.newCreativityInput.nativeElement.click();
  }

  onClickUrlChange() {
    this.checkCampaignChanges();
  }

  async onNewCreativity(event) {
    let data;
    if (!event.srcElement.files.length) return;
    var file = event.srcElement.files[0];

    let contentType = file.type;
    if (
      ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"].indexOf(
        contentType
      ) == -1
    ) {
      this.utilsService.presentAlertMessage({
        title: "El formato del archivo seleccionado no está permitido.",
      });
      return;
    }

    if (file && file.name && file.type) {
      let nameParts = file.name.split(".");
      let name = nameParts.slice(0, nameParts.length - 1).join(".");
      let extension = nameParts[nameParts.length - 1];
      data = {
        extension: extension,
        mimeType: contentType,
        name: name + ".original." + extension,
      };
    }

    if (contentType === "image/jpg") {
      contentType = "image/jpeg";
    }

    this.utilsService.loading("open");
    let s3Url;
    try {
      s3Url = await this.campaignService.prepareCreativityUpload(
        this.campaign.id,
        data
      );
      await this.campaignService.uploadCreativityToS3(
        file,
        contentType,
        s3Url.pictureUrl
      );
      this.utilsService.loading("close");
    } catch (error) {
      this.utilsService.loading("close");
      this.campaignService.errorHandler(error, true);
      return;
    }

    this.campaign.creatives.push({
      ubicationId: this.selectedAdUbication,
      fileUrl: s3Url.pictureUrl.split("?")[0],
    });

    if (this.creativesSlots[this.selectedAdUbication])
      this.creativesSlots[this.selectedAdUbication]--;
    this.onSelectCreativeChange(this.campaign.creatives.length - 1);
    this.checkCampaignChanges();
  }

  onSelectedAdUbicationChange(ubication) {
    this.selectedAdUbication = ubication;
    this.selectedUbicationExample =
      "/assets/pub/example-ubication-" + this.selectedAdUbication + ".jpg";
    let newClass = {};
    newClass["ubication" + ubication] = true;
    this.creativityPreviewClass = newClass;

    let creative = this.campaign.creatives.find(
      (x) => x.ubicationId == ubication
    );
    if (creative)
      this.selectedCreative = this.campaign.creatives.indexOf(creative);
    else this.selectedCreative = null;
  }

  onSelectCreativeChange(index) {
    this.selectedCreative = index;
  }

  async loadStripe() {
    // Create a Stripe client.
    this.stripeClient = Stripe(this.environment.stripeKey);

    // Create an instance of Elements.
    const elements = this.stripeClient.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: "#444444",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#888888",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    // Create an instance of the card Element.
    this.stripeCard = elements.create("card", { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    this.stripeCard.mount("#card-element");

    // Handle real-time validation errors from the card Element.
    this.stripeCard.on("change", (event) => {
      var displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });
  }

  async submitPaymentForm() {
    this.utilsService.loading("open");
    try {
      let campaign = await this.campaignService.editCampaign(this.campaign);
      Object.assign(this.campaign, campaign);
      this.stripeClient
        .createPaymentMethod({
          type: "card",
          card: this.stripeCard,
        })
        .then((result) => {
          this.stripeTokenHandler(result.paymentMethod);
        });
    } catch (error) {
      this.utilsService.loading("close");
      this.campaignService.errorHandler(error, true);
    }
  }

  async stripeTokenHandler(paymentMethod?: any, paymentIntent?: any) {
    const data: any = {};
    if (paymentMethod) data.paymentMethodId = paymentMethod.id;
    if (paymentIntent) data.paymentIntentId = paymentIntent.id;

    let response;
    try {
      response = await this.campaignService.payCampaign(
        this.campaign.id,
        this.campaign.payments[this.campaign.payments.length - 1].id,
        data
      );
    } catch (error) {
      this.utilsService.loading("close");
      this.campaignService.errorHandler(error, true);
      return;
    }

    if (response.requires_action) {
      this.stripeClient
        .handleCardAction(response.payment_intent_client_secret)
        .then((result) => {
          this.stripeTokenHandler(result.paymentIntent);
        });
    } else {
      this.utilsService.loading("close");
      this.router.navigate(["dashboard/main"]);
    }
  }
}
