import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class BillingService extends RequestService {
  async getPlans() {
    return await this.request(
      this.httpClient.get(this.environment.API + "/billing/plan", {
        headers: this.headers(),
      })
    );
  }

  async createPaymentMethod(defaultMethod: boolean, cardToken: string) {
    const data = {
      defaultMethod: defaultMethod,
      cardToken: cardToken,
    };
    return await this.request(
      this.httpClient.post(
        this.environment.API + "/billing/paymentMethod",
        data,
        { headers: this.headers() }
      )
    );
  }

  updatePaymentMethod(defaultMethod: boolean, card: any) {
    const data = {
      defaultMethod: defaultMethod,
    };
    return this.request(
      this.httpClient.put(
        this.environment.API + "/billing/paymentMethod/" + card?.id,
        data,
        { headers: this.headers() }
      )
    );
  }

  async getUserPaymentMethod(userId: Number) {
    return await this.request(
      this.httpClient.get(
        this.environment.API + "/billing/user/" + userId + "/paymentMethod",
        { headers: this.headers() }
      )
    );
  }

  subscribePlan(productId: string, data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/billing/plan/" + productId + "/subscribe",
        data,
        { headers: this.headers() }
      )
    );
  }
}
