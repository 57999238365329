import { Component, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";

// Services
import { UtilsService } from 'src/app/services/utils.service';
import { CampaignService } from '../../services/campaign.service';
import { AdvertiserService } from '../../services/advertiser.service';
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard-publics",
  templateUrl: "./dashboard-publics.component.html",
  styleUrls: ["./dashboard-publics.component.css"],
})
export class DashboardPublicsComponent implements OnInit {
  advertisers: any[] = [];
  allPendingCampaigns: any[] = [];
  selectedTargets: any[] = [];

  campaignStatuses = [
    'CREATING_CAMPAIGN',
    'CREATING_CREATIVES',
    'CREATING_PAYMENTS',
    'PENDING',
    'READY',
    'ACTIVE',
    'PAUSED',
    'FINISHED'
  ];

  publicSlide = false;
  openPublic = false;

  constructor(
    private clipboardService: ClipboardService,
    public utilsService: UtilsService,
    public advertiserService: AdvertiserService,
    public campaignService: CampaignService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { }
  
  async ngOnInit() {
    this.titleService.setTitle('Campañas pendientes | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/publics', 'Pending campaigns');
    this.analyticService.screenView('Pending campaigns');

    await this.loadAdvertisers();
    await this.loadPendingCampaigns();
  }

  async loadAdvertisers(){
    let newAdvertisers = await this.advertiserService.getAdvertisers()
    this.initAdvertiserCampaigns(newAdvertisers);
    this.advertisers = this.advertisers.concat(newAdvertisers);
  }

  async loadPendingCampaigns(){
    let newCampaigns = await this.campaignService.getAgentPendingCampaigns()
    this.setPendingReason(newCampaigns);
    this.allPendingCampaigns = this.allPendingCampaigns.concat(newCampaigns);
    this.mapAdvertiserCampaigns(newCampaigns);
  }

  async initAdvertiserCampaigns(advertisers){
    for(var i = 0; i < advertisers.length; i++){
      advertisers[i].campaigns = [];
    }
  }

  async setPendingReason(campaigns){
    const now = (new Date()).getTime();
    for(var i = 0; i < campaigns.length; i++){
      if(this.campaignStatuses.indexOf(campaigns[i].status) < 3) campaigns[i].pendingReason = 'Campaña por completar.';
      else if(!campaigns[i].dfpOrderId || this.campaignStatuses.indexOf(campaigns[i].status) == 3) campaigns[i].pendingReason = 'Campaña por revisar.';
      else if(campaigns[i].payments.length && campaigns[i].payments[campaigns[i].payments.length - 1].status != 'PAID') campaigns[i].pendingReason = 'Campaña por pagar.';
      else if(this.campaignStatuses.indexOf(campaigns[i].status) == 4) campaigns[i].pendingReason = 'Campaña pendiente de empezar.';
      else if(campaigns[i].endDate == 'undefined' || campaigns[i].endDate > now) campaigns[i].pendingReason = 'Campaña por finalizar.';
    }
  }

  async mapAdvertiserCampaigns(newCampaigns){
    for(var i = 0; i < this.advertisers.length; i++){
      this.advertisers[i].campaigns = this.advertisers[i].campaigns.concat(newCampaigns.filter(x => x.userId == this.advertisers[i].userId));
    }
  }

  togglePublicSlide(advertiserId?, targets?) {
    if (this.publicSlide) {
      this.openPublic = false;

      setTimeout(() => {
        this.publicSlide = false;
      }, 210);
    } else {
      this.publicSlide = true;
      let advertiser = this.advertisers.find(x => x.userId == advertiserId);
      
      this.selectedTargets = targets.map((x) =>{
        return advertiser.targets.find(y => y.id == x);
      });

      setTimeout(() => {
        this.openPublic = true;
      }, 80);
    }
  }

  copyPublic(target?) {
    let copied = target? target : this.selectedTargets;
    this.clipboardService.copy(JSON.stringify(copied));
    this.utilsService.presentAlertMessage({
      title: 'Copiado.',
      description: 'Se ha copiado el objeto en el portapapeles.',
      duration: 5
    });
  }
}
