import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { CampaignService } from '../../services/campaign.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-dashboard-campaign-details',
  templateUrl: './dashboard-campaign-details.component.html',
  styleUrls: ['./dashboard-campaign-details.component.css']
})
export class DashboardCampaignDetailsComponent implements OnInit {

  campaignId: any;
  advertiserId: any;

  generalReport: any;
  reportByDayImpressions: any;
  reportByDayClicks: any;
  reportByUbication: any;

  Highcharts: typeof Highcharts = Highcharts;
  firstReport: any;
  check = 0;
  isFirstLoad = true;
  updateFlag = false;
  oneToOneFlag: boolean = true;

  chartOptionsDayImpressions: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsDayClicks: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsUbicationImpressions: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      },
    },
    xAxis: {
      type: 'category',
      title: {
        text: ''
      },
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.y}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsUbicationClicks: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'category',
      title: {
        text: ''
      },
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.y}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
      },
    },
    series: []
  };

  dateRange: any;
  days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  today = new Date();
  base = new Date();
  final = this.base.setDate(this.base.getDate() - 7);
  toDate = this.formatDate(this.today.setDate(this.today.getDate() - 1));;
  fromDate = this.formatDate(this.final);
  openPersonalize = false;
  periodSelected = 7;

  constructor(
    public utilsService: UtilsService,
    private route: ActivatedRoute,
    public campaignService: CampaignService,
    public storageService: StorageService,
  ) {
    this.campaignId = this.route.snapshot.paramMap.get("id");
    this.advertiserId = this.route.snapshot.paramMap.get("advertiserId");

    this.getInitialData().then(() => {
      this.init();
    })
  }

  ngOnInit() { }

  setPersonalized() {
    if (this.dateRange?.startDate?.$d && this.dateRange?.endDate?.$d) {
      this.utilsService.loading('open');
      let startDate = new Date(this.dateRange?.startDate?.$d);
      let endDate = new Date(this.dateRange?.endDate?.$d);
      this.periodSelected = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
      this.toDate = this.formatDate(endDate);
      this.fromDate = this.formatDate(startDate);
      this.getInitialData().then(() => {
        this.init();
        this.utilsService.loading('close');
      });
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  setPeriod(period: number) {
    this.utilsService.loading('open');

    this.firstReport = null;

    this.periodSelected = period;

    const today = new Date();
    const base = new Date();
    const final = base.setDate(base.getDate() - this.periodSelected - 1);
    this.toDate = this.formatDate(today.setDate(today.getDate() - 1));
    this.fromDate = this.formatDate(final);
    this.getInitialData().then(() => {
      this.init();
      this.utilsService.loading('close');
    });
  }

  open() {
    if (this.openPersonalize === true) {
      this.openPersonalize = false;
    } else {
      this.openPersonalize = true;
    }
  }

  async getInitialData() {
    await this.campaignService.getGeneralReport(this.advertiserId, this.campaignId, { toDate: this.toDate, fromDate: this.fromDate }).then((response) => {
      this.generalReport = response;
      this.generalReport.impressions = this.generalReport?.impressions.toLocaleString();
      this.generalReport.clicks = this.generalReport?.clicks.toLocaleString();
      this.generalReport.ctr = (this.generalReport?.ctr * 100).toFixed(2).toLocaleString() + '%'
    });
    await this.campaignService.getReportByDay(this.advertiserId, this.campaignId, { table: 'impression', toDate: this.toDate, fromDate: this.fromDate }).then((response) => {
      this.reportByDayImpressions = response;
    });
    await this.campaignService.getReportByDay(this.advertiserId, this.campaignId, { table: 'click', toDate: this.toDate, fromDate: this.fromDate }).then((response) => {
      this.reportByDayClicks = response;
    });
    await this.campaignService.getReportByUbication(this.advertiserId, this.campaignId, { toDate: this.toDate, fromDate: this.fromDate }).then((response) => {
      this.reportByUbication = response;
    });
  }

  init() {
    let impressionDaySerie: any = [];
    let clickDaySerie: any = [];
    let impressionUbicationSerie: any = [];
    let clickUbicationSerie: any = [];

    for (let i = 0; i < this.reportByDayImpressions.length; i++) {
      const splitted = this.reportByDayImpressions[i]?.date1.split('-');
      for (let j = 0; j < splitted.length; j++) {
        splitted[j] = parseInt(splitted[j]);
      }

      const date = Date.UTC(splitted[0], splitted[1] - 1, splitted[2]);

      var impressionItem: any = [
        date,
        this.reportByDayImpressions[i]?.impression
      ];
      impressionDaySerie.push(impressionItem);
    }
    this.chartOptionsDayImpressions.series = [];
    this.chartOptionsDayImpressions.series.push({
      name: 'Impresiones',
      data: impressionDaySerie,
      type: null,
      lineColor: '#002266',
      color: '#002266',
    });

    for (let i = 0; i < this.reportByDayClicks.length; i++) {
      const splitted = this.reportByDayClicks[i]?.date1.split('-');
      for (let j = 0; j < splitted.length; j++) {
        splitted[j] = parseInt(splitted[j]);
      }

      const date = Date.UTC(splitted[0], splitted[1] - 1, splitted[2]);

      var clickItem: any = [
        date,
        this.reportByDayClicks[i]?.click
      ];
      clickDaySerie.push(clickItem);
    }
    this.chartOptionsDayClicks.series = [];
    this.chartOptionsDayClicks.series.push({
      name: 'Clicks',
      data: clickDaySerie,
      type: null,
      lineColor: '#0055FF',
      color: '#0055FF'
    });

    for (let i = 0; i < this.reportByUbication?.impressions.length; i++) {
      var impressionItem: any = {
        name: this.reportByUbication?.impressions[i]?.ubication,
        y: this.reportByUbication?.impressions[i]?.impressions
      };
      impressionUbicationSerie.push(impressionItem);
    }

    this.chartOptionsUbicationImpressions.series = [];
    this.chartOptionsUbicationImpressions.series.push({
      name: 'Impresiones',
      data: impressionUbicationSerie.sort((obj1, obj2) => {
        if (obj1.name > obj2.name) {
          return 1;
        } else if (obj1.name < obj2.name) {
          return -1;
        }
        return 0;
      }),
      type: 'column',
      color: '#0044CC',
    });

    for (let i = 0; i < this.reportByUbication?.clicks.length; i++) {
      var clickItem: any = {
        name: this.reportByUbication?.clicks[i]?.ubication,
        y: this.reportByUbication?.clicks[i]?.clicks
      };
      clickUbicationSerie.push(clickItem);
    }
    this.chartOptionsUbicationClicks.series = [];
    this.chartOptionsUbicationClicks.series.push({
      name: 'Clicks',
      data: clickUbicationSerie.sort((obj1, obj2) => {
        if (obj1.name > obj2.name) {
          return 1;
        } else if (obj1.name < obj2.name) {
          return -1;
        }
        return 0;
      }),
      type: 'column',
      color: '#6699FF'
    });

    this.firstReport = this.reportByDayImpressions;
    this.check = 1;

    if (this.isFirstLoad) {
      this.isFirstLoad = false;
    }

    if (!this.isFirstLoad) {
      this.updateFlag = true;
    }
  }
}
