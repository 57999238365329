<div class="body-container">
  <h1 class="wds-h1">Campañas pendientes</h1>

  <div class="table-container">
    <div class="table-row table-header">
      <div class="table-column">Anunciante</div>
      <div class="table-column">Campaña</div>
      <div class="table-column">Pendiente</div>
      <div class="table-column"></div>
    </div>

    <ng-container *ngFor="let advertiser of advertisers">
      <div class="table-row" *ngFor="let campaign of advertiser.campaigns">
        <div class="table-column">{{advertiser.name}}</div>
        <div class="table-column">{{campaign.name}}</div>
        <div class="table-column">{{campaign.pendingReason}}</div>
        <div class="table-column actions">
            <app-button-item [routerLink]="['/dashboard', 'campaign', campaign.id]" [onlyIcon]="true" [title]="'Ver campaña'" [icon]="'remove_red_eye'" [solid]="false" [color]="'#0055FF'"></app-button-item>
        </div>  
      </div>
    </ng-container>
  </div>
</div>
