import { Injectable } from "@angular/core";

// Services
import { RequestService } from "./request.service";

@Injectable()
export class UserService extends RequestService {

  login(email: string, password: string) {

    const data = {
      email: email,
      password: password,
    };

    return this.request(
      this.httpClient.post(this.environment.API + "/login", data)
    );
  }

  async loginWithGoogle() {
    return await new Promise((resolve, reject) => {
      this.afAuth.idTokenResult.subscribe(
        (res) => resolve(res),
        (error) => reject(error)
      );
    });
  }

  getUserToken(userId: number) {
    return this.request(this.httpClient.get(
      this.environment.API + `/login/${userId}/auth`,
      { headers: this.headers() }
    ));
  }

  sendGoogleData(data: any) {
    return this.request(
      this.httpClient.post(this.environment.API + "/login/google", data)
    );
  }

  userMe() {
    return this.request(this.httpClient.get(
      this.environment.API + '/user/me',
      { headers: this.headers() }
    ));
  }

  async refreshUserMe(){
    let userMe = await this.userMe();
    this.storageService.write('userMe', userMe);
    return userMe;
  }

  logout() {
    this.storageService.clear();
    this.navigateToExit();
  }

  updateAccount(data:any) {
    return this.request(
      this.httpClient.put(this.environment.API + "/user", data, { headers: this.headers() })
    );
  }

  singUp(data:any) {
    return this.request(
      this.httpClient.post(this.environment.API + "/signup", data)
    );
  }

  resetPassword(data:any) {
      return this.request(this.httpClient.get(
        this.serializeUrl(this.environment.API + '/user/reset-password', data),
        { headers: this.headers() }
      ));
  }

  async sendStepNotification(data: any) {
    return await this.request(
      this.httpClient.post(
        this.environment.API + "/pub/notification",
        data,
        { headers: this.headers() })
    );
  }

  registerUserDevice(data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/user/device",
        data,
        { headers: this.headers() })
    );
  }
}
