import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AnalyticService } from "src/app/services/analytic.service";
import { CenterService } from "src/app/services/center.service";
import { ResourceService } from "src/app/services/resource.service";
import { SponsorSubjectService } from "src/app/services/sponsor-subject.service";
import { StorageService } from "src/app/services/storage.service";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-dashboard-sponsor-segmentation",
  templateUrl: "./dashboard-sponsor-segmentation.component.html",
  styleUrls: ["./dashboard-sponsor-segmentation.component.css"],
})
export class DashboardSponsorSegmentationComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild("zindex1", { static: false }) zindex1: ElementRef;
  @ViewChild("zindex2", { static: false }) zindex2: ElementRef;
  @ViewChild("zindex3", { static: false }) zindex3: ElementRef;
  @ViewChild("zindex4", { static: false }) zindex4: ElementRef;

  clicked = [false, false, false, false];
  allChecked = false;
  centers = [];
  folders = [];
  checkedFolders = [];

  advertiserMe: any;

  searchData = {
    folder: null,
    universityId: null,
    centerId: null,
    studyId: null,
    course: null,
    cityId: 11,
    countryId: 1,
    studyType: 1,
    limit: 25,
    offset: 0,
    widgetId: null,
  };

  filterData = {
    university: null,
    center: null,
    study: null,
    course: null,
  };

  searchMode = "widgets";

  searchInputData: string;

  openRemember = false;

  mobile = false;

  constructor(
    private router: Router,
    public utilsService: UtilsService,
    public centerService: CenterService,
    public sponsorSubjectService: SponsorSubjectService,
    public resourceService: ResourceService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) {
    this.folders = [];

    this.utilsService.subscribe("checked_data", (data: any) => {
      if (typeof data !== "undefined" && data) {
        this.filterData = {
          university: data?.university,
          center: data?.center,
          study: data?.study,
          course: data?.course,
        };

        this.searchData.universityId =
          typeof data?.university?.id !== "undefined"
            ? data?.university?.id
            : null;
        this.searchData.centerId =
          typeof data?.center?.id !== "undefined" ? data?.center?.id : null;
        this.searchData.studyId =
          typeof data?.study?.id !== "undefined" ? data?.study?.studyId : null;
        this.searchData.course =
          typeof data?.course?.id !== "undefined" ? data?.course?.id : null;

      }

      this.searchHandler(this.searchInputData);
    });

    if (this.storageService.read("signatures")) {
      this.checkedFolders = this.storageService.read("signatures");
      this.throwEvent();
    }

    this.advertiserMe = this.storageService.read("advertiserMe");
    this.search();

    if (window.screen.width < 767) {
      this.mobile = true;
    }
  }

  ngOnInit(): void { 
    this.titleService.setTitle('Segmentación de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/subject/1', 'Subject segmentation');
    this.analyticService.screenView('Subject segmentation');
  }

  searchHandler(event) {
    let delay = 1000;
    if (typeof event === "undefined") {
      this.folders = [];
      this.search();
    } else {
      if (event?.keyCode == 13) {
        delay = 0;
      }
      if (event?.target === undefined) {
        this.folders = [];
        this.searchData.folder = event ? event : null;
        this.searchData.offset = 0;
        if (this.searchData?.folder === '' || this.searchData?.folder === null) {
        } else {
          this.search();
        }
      } else {
        this.manageInputDelay(delay, () => {
          if (event?.target?.value !== this.searchData?.folder) {
            this.folders = [];
            this.searchData.folder = event?.target?.value ? event?.target?.value : null;
            this.searchData.offset = 0;
            if (this.searchData?.folder === '' || this.searchData?.folder === null) {
            } else {
              this.search();
            }
          }
        }, []);
      }
    }
  }

  manageInputDelay(delay, callback, args) {
    setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  }

  search() {
    this.utilsService.loading('open');
    if (this.searchData?.universityId || this.searchData?.centerId || this.searchData?.studyId){
      this.searchData.cityId = null;
    }else{
      this.searchData.cityId = this.advertiserMe?.cityId;
    }
    const data = Object.assign({}, this.searchData);

    this.sponsorSubjectService.searchFolders(data).then(
      (response) => {
        if (response.length > 0) {
          if (!this.folders) {
            this.folders = [];
          }
          this.folders = this.folders.concat(response);
          this.folders = this.folders.filter(x => x?.widgetStatus !== 1 && x?.widgetId === null);
        }
        this.utilsService.loading('close');
      }
    ).catch(
      (error) => {
        this.folders = [];
        this.utilsService.loading('close');

      }
    );
  }

  onScroll() {
    this.searchData.offset = this.searchData?.offset + this.searchData?.limit;
    this.search();
  }

  checkAll() {
    if (this.allChecked) {
      this.allChecked = false;
      this.checkedFolders = [];
    } else {
      this.allChecked = true;
      this.checkedFolders = this.folders;
    }
  }

  goToDesign() {
    this.sponsorSubjectService.addTrelloCard(this.checkedFolders).then(() => {
      this.router.navigate(["dashboard/sponsor/subject/2"]);
    });
  }

  openFilter(index) {
    if (this.clicked[index] === true) {
      this.clicked[index] = false;
    } else {
      this.clicked[index] = true;

      for (let i = 0; i < this.clicked.length; i++) {
        if (i != index && this.clicked[i] === true) {
          this.clicked.splice(i, 1, false);
        }
      }
    }

    this.throwFilterData();
  }

  changeZ(version) {

    if (this.mobile) {
      this.zindex1.nativeElement.style.zIndex = '4';
      this.zindex2.nativeElement.style.zIndex = '4';
      this.zindex3.nativeElement.style.zIndex = '4';
      this.zindex4.nativeElement.style.zIndex = '4';

      if (version === 1) {
        this.zindex1.nativeElement.style.zIndex = '100';
      }else if(version === 2) {
        this.zindex2.nativeElement.style.zIndex = '100';
      }else if(version === 3) {
        this.zindex3.nativeElement.style.zIndex = '100';
      }else if(version === 4) {
        this.zindex4.nativeElement.style.zIndex = '100';
      }
    }
  }

  checkFolder(folder) {
    let checked = true;
    const result = this.checkedFolders.some(x => JSON.stringify(x) === JSON.stringify(folder));
    if (typeof result === "undefined" || !result) {
      checked = false;
    }
    return checked;
  }

  folderStatus(folder) {
    const result = this.checkedFolders.some(x => JSON.stringify(x) === JSON.stringify(folder));

    if (result) {
      this.checkedFolders.splice(
        this.checkedFolders
          .indexOf(folder),
        1
      );
    } else {
      this.checkedFolders.push(folder);
    }
  }

  throwEvent() {
    this.utilsService.publish("folders", this.checkedFolders);
    localStorage.setItem("signatures", JSON.stringify(this.checkedFolders));
  }

  throwFilterData() {
    return this.filterData;
  }

  cancel() {
    this.utilsService.publish("close_sponsor_modal", true);
  }

  goToLanding() {
    this.sponsorSubjectService.addTrelloCard(this.checkedFolders).then(() => {
      this.router.navigate(['/landing/gracias']);
    });
  }
}
