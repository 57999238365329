import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CampaignService } from "src/app/services/campaign.service";
import { UtilsService } from "src/app/services/utils.service";
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
} from "ngx-image-cropper";
import { StorageService } from "src/app/services/storage.service";
import { Subscription } from "rxjs";
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";
import { UserService } from 'src/app/services/user.service';
import { SponsorSubjectService } from "src/app/services/sponsor-subject.service";

@Component({
  selector: "app-dashboard-sponsor-creativities",
  templateUrl: "./dashboard-sponsor-creativities.component.html",
  styleUrls: ["./dashboard-sponsor-creativities.component.css"],
})
export class DashboardSponsorCreativitiesComponent implements OnInit {
  @ViewChild("mediaInput", { static: true }) mediaInput: ElementRef;

  @ViewChild("downloadTemplateHor", { static: true })
  downloadTemplateHor: ElementRef;
  @ViewChild("downloadTemplateVer", { static: true })
  downloadTemplateVer: ElementRef;

  uploadCreativities = false;
  theme = [1, 0, 0, 0];
  dataUpload = {
    portrait: null,
    landscape: null,
  };

  creativityData = {
    topLine: null,
    bottomLine: null,
    subtitle: null,
    web: null,
    phone: null,
    plantilla: 1,
    cta: null,
    logo: null,
  };

  uploadingMode: string;

  file_aplication = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"];

  @Input("renew") renew;

  @ViewChild(ImageCropperComponent)
  imageCropper: ImageCropperComponent;

  imageChangedEventPortrait: any = "";
  imageChangedEventPortrait_2: any = "";

  imageChangedEventLandscape: any = "";
  imageChangedEventLandscape_2: any = "";

  results = {
    vertical: {
      upper: null,
      side: null,
    },
    horizontal: {
      upper: null,
      side: null,
    },
  };

  creativitiesImages = {
    vertical: {
      upper: null,
      side: null,
    },
    horizontal: {
      upper: null,
      side: null,
    },
  };

  widget: any;

  showCropper = false;

  upperPortrait: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };
  sidePortrait: CropperPosition = { x1: 0, y1: 247, x2: 170, y2: 2000 };

  upperLandscape: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };
  sideLandscape: CropperPosition = { x1: 0, y1: 0, x2: 1414, y2: 247 };

  upperCroppedWidthPortrait = 1414;
  upperCroppedHeightPortrait = 247;

  sideCroppedWidthPortrait = 177;
  sideCroppedHeightPortrait = 1753;

  sideCroppedWidthLandscape = 1414;
  sideCroppedHeightLandscape = 247;

  upperCroppedWidthLandscape = 177;
  upperCroppedHeightLandscape = 1753;

  subscription:Subscription;

  uuid: string;
  userId: string;
  data: any;

  constructor(
    private router: Router,
    public utilsService: UtilsService,
    public campaignService: CampaignService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public userService: UserService,
    public sponsorSubjectService: SponsorSubjectService,
  ) {
    let checkStorageCreativities = JSON.parse(
      localStorage.getItem("dataUpload")
    );

    const widget = this.storageService.read('widgets');
    this.widget = widget[0];
    this.widget.ads = [{
      creativeId: this.widget.id,
      orderId: this.widget.userId,
      ubication: 32,
      viewUrl: null,
      clickUrl: null,
      pixelUrl: null,
      imgLandscapeLateralUrl: null,
      imgLandscapeTopUrl: null,
      imgLandscapeUrl: null,
      imgPortraitLateralUrl: null,
      imgPortraitTopUrl: null,
      imgPortraitUrl: null
    }];

    if (checkStorageCreativities) {
      this.dataUpload = checkStorageCreativities;
    }
    this.utilsService.publish("change_creativities_data", this.dataUpload);

    this.subscription = this.utilsService.subscribe("renew_creativity", (data: any) => {
      if (typeof data !== "undefined" && data && data === true) {
        this.getCreativity();
      }
    });

    if (this.dataUpload?.portrait === null && this.dataUpload?.landscape === null) {
      this.utilsService.publish("creativity_template", this.creativityData?.plantilla);
    }

    this.userId = this.storageService.read('userId');
    this.uuid = this.storageService.read('uuid');
    this.data = this.storageService.read('advertiserMe');
  }

  ngOnInit(): void { 
    const user: any = this.storageService.read('userMe');
    
    this.titleService.setTitle('Creatividad del patrocinio de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/subject/3', 'Subject sponsorship creativity');
    this.analyticService.screenView('Subject sponsorship creativity');
    
    let checkStorageSignatures = JSON.parse(localStorage.getItem("signatures"));
    let checkStorageGeneral = JSON.parse(localStorage.getItem("dataGeneralInformation"));
    let checkStorageButtons = JSON.parse(localStorage.getItem("buttons"));
    let checkStorageContacts = JSON.parse(localStorage.getItem("contacts"));
    let checkStorageFeatures = JSON.parse(localStorage.getItem("features"));

    if (checkStorageSignatures == null) {
      this.router.navigate(["dashboard/sponsor/subject/1"]);
    } else if (checkStorageGeneral == null || checkStorageButtons == null || checkStorageContacts == null || checkStorageFeatures == null){
      this.router.navigate(["dashboard/sponsor/subject/2"]);
    }
  }

  openMediaInput(mode: string) {
    this.uploadingMode = mode;
    this.mediaInput.nativeElement.click();
  }

  async handleFile(event) {
    let files: FileList = event.srcElement.files;
    if (!this.file_aplication.includes(files[0]?.type)) {
      if (files[0]?.type) {
        this.utilsService.presentAlertMessage({
          title: "La imagen debe ser PNG, JPG, JPEG o PJPEG",
        });
      }

      this.mediaInput.nativeElement.value = "";
    } else {
      if (files[0].size > 2000000) {
        this.utilsService.presentAlertMessage({
          title: "La imagen no puede ocupar más de 2 MB",
        });

        this.mediaInput.nativeElement.value = "";
      } else {
        if (this.uploadingMode == "portrait") {
          this.dataUpload.portrait = files[0].name;
        } else if (this.uploadingMode == "landscape") {
          this.dataUpload.landscape = files[0].name;
        } else if (this.uploadingMode == "logo") {
          this.creativityData.logo = files[0].name;
        }

        this.onSelectFile(event, this.uploadingMode);
      }
    }
  }

  onSelectFile(event, mode) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        if (this.uploadingMode === "portrait") {
          this.dataUpload.portrait = event.target.result;
        } else if (this.uploadingMode === "landscape") {
          this.dataUpload.landscape = event.target.result;
        } else if (this.uploadingMode === "logo") {
          this.creativityData.logo = event.target.result;
        }
        if (this.dataUpload?.portrait && this.dataUpload?.landscape) {
          this.handleFileInput();
        }
      };
    }
  }

  designCreativities(e) {
    if (e === false) {
      this.uploadCreativities = false;
    } else {
      this.uploadCreativities = true;
    }
  }

  chosenTheme(e) {
    this.theme = [0, 0, 0, 0];
    this.theme[e] = 1;
    this.creativityData.plantilla = e + 1;
    if (this.dataUpload?.portrait === null && this.dataUpload?.landscape === null) {
      this.utilsService.publish("creativity_template", this.creativityData?.plantilla);
    }
  }

  downloadPSD() {
    this.downloadTemplateHor.nativeElement.click();
    this.downloadTemplateVer.nativeElement.click();
  }

  async goToPayment(from) {
    this.subscription.unsubscribe();
    if (from === "design") {
      await this.getCreativity().then(() => {
        setTimeout(() => {
          this.saveCropped();
          this.router.navigate(["dashboard/sponsor/subject/4"]);
        }, 800);
      });
    } else if (from === "upload") {
      this.saveCropped();
      this.router.navigate(["dashboard/sponsor/subject/4"]);
    }
  }

  designLater() {
    this.router.navigate(["dashboard/sponsor/subject/4"]);
  }

  async goToDesign() {
    this.subscription.unsubscribe();
    await this.getCreativity().then(() => {
      setTimeout(() => {
        this.saveCropped();
        this.router.navigate(["dashboard/sponsor/subject/2"]);
      }, 800);
    });
  }

  async getCreativity() {
    this.utilsService.loading('open');
    try {
      if (this.creativityData?.plantilla !== null) {
        let data =
          this.creativityData?.logo === null
            ? {
              topLine: this.creativityData?.topLine,
              bottomLine: this.creativityData?.bottomLine,
              subtitle: this.creativityData?.subtitle,
              web: this.creativityData?.web,
              phone: this.creativityData?.phone,
              plantilla: this.creativityData?.plantilla,
              cta: this.creativityData?.cta,
            }
            : this.creativityData;
        await this.campaignService.createCreativity(data).then((response) => {
          this.dataUpload.portrait = response?.vertical;
          this.dataUpload.landscape = response?.horizontal;
        });
      }
      this.handleFileInput();
      this.utilsService.loading('close');
    } catch (error) {
      console.log(error);
    }
  }

  dataUrlToFileList(): FileList {
    let fileArray: File[] = [];
    let blobObject = this.dataURItoBlob(this.dataUpload?.portrait);
    let file = new File([blobObject], "portrait", { type: "image/png" });
    fileArray.push(file);
    blobObject = this.dataURItoBlob(this.dataUpload?.landscape);
    file = new File([blobObject], "landscape", { type: "image/png" });
    fileArray.push(file);
    return fileArray as unknown as FileList;
  }

  async handleFileInput() {
    if (this.dataUpload?.portrait && this.dataUpload?.landscape) {
      let files: FileList = this.dataUrlToFileList();
      this.imageChangedEventPortrait = await this.resize(
        files[0],
        1414,
        2000,
        null,
        files[0].type
      );
      this.imageChangedEventPortrait_2 = await this.resize(
        files[0],
        1414,
        2000,
        null,
        files[0].type
      );
      this.imageChangedEventLandscape = await this.resize(
        files[1],
        2000,
        1414,
        null,
        files[1].type
      );
      this.imageChangedEventLandscape_2 = await this.resize(
        files[1],
        2000,
        1414,
        null,
        files[1].type
      );
    }
  }

  imageCropped(event: ImageCroppedEvent, side: "upper" | "side", mode) {
    if (side == "upper") {
      this.results[mode][side] = event.base64;
    }

    if (side == "side") {
      this.results[mode][side] = event.base64;
    }
  }

  imageLoaded(mode) {
    this.showCropper = true;

    setTimeout(async () => {
      if (mode == "vertical") {
        this.upperCroppedWidthPortrait = 1414;
        this.upperCroppedHeightPortrait = 105;

        this.sideCroppedWidthPortrait = 70;
        this.sideCroppedHeightPortrait = 1753;

        this.upperPortrait = { x1: 0, y1: 0, x2: 1414, y2: 105 };
        this.sidePortrait = { x1: 0, y1: 105, x2: 70, y2: 2000 };
      } else {
        this.upperCroppedWidthLandscape = 2000;
        this.upperCroppedHeightLandscape = 50;

        this.sideCroppedWidthLandscape = 70;
        this.sideCroppedHeightLandscape = 1244;

        this.upperLandscape = { x1: 0, y1: 0, x2: 2000, y2: 50 };
        this.sideLandscape = { x1: 0, y1: 50, x2: 70, y2: 1414 };
      }
    }, 500);

    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions, mode) {
    this.showCropper = true;

    setTimeout(() => {
      if (mode == "vertical") {
        this.upperCroppedWidthPortrait = 1414;
        this.upperCroppedHeightPortrait = 105;

        this.sideCroppedWidthPortrait = 70;
        this.sideCroppedHeightPortrait = 1753;

        this.upperPortrait = { x1: 0, y1: 0, x2: 1414, y2: 105 };
        this.sidePortrait = { x1: 0, y1: 105, x2: 70, y2: 2000 };
      } else {
        this.upperCroppedWidthLandscape = 2000;
        this.upperCroppedHeightLandscape = 50;

        this.sideCroppedWidthLandscape = 70;
        this.sideCroppedHeightLandscape = 1244;

        this.upperLandscape = { x1: 0, y1: 0, x2: 2000, y2: 50 };
        this.sideLandscape = { x1: 0, y1: 50, x2: 70, y2: 1414 };
      }
    }, 500);

    this.showCropper = true;
  }

  loadImageFailed() {
    console.log("Load failed");
  }

  saveCropped() {
    this.storageService.write(
      "dataUpload",
      JSON.parse(JSON.stringify(this.dataUpload))
    );
    this.storageService.write("croppedCreativities", this.results);
    setTimeout(() => {
      this.creativitiesImages = {
        vertical: {
          upper: new File([this.dataURItoBlob(this.results?.vertical?.upper)], 'vertical-upper.jpg', { type: 'image/jpg', lastModified: Date.now() }),
          side: new File([this.dataURItoBlob(this.results?.vertical?.side)], 'vertical-side.jpg', { type: 'image/jpg', lastModified: Date.now() }),
        },
        horizontal: {
          upper: new File([this.dataURItoBlob(this.results?.horizontal?.upper)], 'horizontal-upper.jpg', { type: 'image/jpg', lastModified: Date.now() }),
          side: new File([this.dataURItoBlob(this.results?.horizontal?.side)], 'horizontal-side.jpg', { type: 'image/jpg', lastModified: Date.now() }),
        },
      }
      this.addWidgetProperty();
    }, 300);

  }

  getExtension(file) {
    const lastDot = file.name.lastIndexOf('.');
    const extension = file.name.substring(lastDot + 1);
    return extension;
  }

  async addWidgetProperty() {
    const data = {
      imgPortraitTop: {
        extension: this.getExtension(this.creativitiesImages?.vertical?.upper),
        contentType: this.creativitiesImages?.vertical?.upper.type
      },
      imgPortraitLateral: {
        extension: this.getExtension(this.creativitiesImages?.vertical?.side),
        contentType: this.creativitiesImages?.vertical?.side.type
      },
      imgLandscapeTop: {
        extension: this.getExtension(this.creativitiesImages?.horizontal?.upper),
        contentType: this.creativitiesImages?.horizontal?.upper.type
      },
      imgLandscapeLateral: {
        extension: this.getExtension(this.creativitiesImages?.horizontal?.side),
        contentType: this.creativitiesImages?.horizontal?.side.type
      },
    }
    await this.sponsorSubjectService.addWidgetProperty('ad', this.widget.id, this.widget.ads[0]).then((response) => {
      this.widget.ads[0] = response;
      this.sponsorSubjectService.updateWidgetProperty('ad', this.widget.id, this.widget['ads'][0].id, data).then(
        (response) => {
          this.widget.ads[0] = response;
          this.uploadImages();
        }
      ).catch(
        () => {
          this.utilsService.loading('close');
          this.utilsService.presentMessage('Ha ocurrido un error');
        }
      );
    });
  }

  uploadImages() {
    this.uploadToS3(this.widget.ads[0]['imgPortraitTop' + 'Url'], this.creativitiesImages?.vertical?.upper.type, this.creativitiesImages?.vertical?.upper);
    this.uploadToS3(this.widget.ads[0]['imgPortraitLateral' + 'Url'], this.creativitiesImages?.vertical?.side.type, this.creativitiesImages?.vertical?.side);
    this.uploadToS3(this.widget.ads[0]['imgLandscapeTop' + 'Url'], this.creativitiesImages?.horizontal?.upper.type, this.creativitiesImages?.horizontal?.upper);
    this.uploadToS3(this.widget.ads[0]['imgLandscapeLateral' + 'Url'], this.creativitiesImages?.horizontal?.side.type, this.creativitiesImages?.horizontal?.side);
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], { type: mimeString });
    return bb;
  }

  resize(
    file?: any,
    max_width?: any,
    max_height?: any,
    compression_ratio?: any,
    imageEncoding?: any
  ) {
    return new Promise((resolve, reject) => {
      let fileLoader = new FileReader(),
        canvas: any = document.createElement("canvas"),
        context = null,
        imageObj: any = new Image(),
        blob: any = null;

      //create a hidden canvas object we can use to create the new resized image data
      canvas.id = "hiddenCanvas";
      canvas.width = max_width;
      canvas.height = max_height;
      canvas.style.visibility = "hidden";
      canvas.style.display = "none";
      document.body.appendChild(canvas);

      //get the context to use
      context = canvas.getContext("2d");

      // check for an image then
      //trigger the file loader to get the data from the image
      if (file.type.match("image.*")) {
        fileLoader.readAsDataURL(file);
      } else {
        console.error("File is not an image");
      }

      // setup the file loader onload function
      // once the file loader has the data it passes it to the
      // image object which, once the image has loaded,
      // triggers the images onload function
      fileLoader.onload = (result) => {
        var data = result.target.result;
        imageObj.src = data;
      };

      fileLoader.onabort = () => {
        console.error("The upload was aborted.");
      };

      fileLoader.onerror = () => {
        console.error("An error occured while reading the file.");
      };

      // set up the images onload function which clears the hidden canvas context,
      // draws the new image then gets the blob data from it
      imageObj.onload = (event) => {
        // Check for empty images
        if (event.path[0].width == 0 || event.path[0].height == 0) {
          console.error("Image is empty");
        } else {
          context.clearRect(0, 0, max_width, max_height);
          context.drawImage(
            imageObj,
            0,
            0,
            event.path[0].width,
            event.path[0].height,
            0,
            0,
            max_width,
            max_height
          );

          var blobBin = atob(canvas.toDataURL("image/png").split(",")[1]);
          var array = [];
          for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }

          var file = new Blob([new Uint8Array(array)], { type: "image/png" });
          resolve(file);
        }
      };

      imageObj.onabort = () => {
        console.error("Image load was aborted.");
      };

      imageObj.onerror = (error) => {
        console.error("An error occured while loading image.");
      };
    });
  }

  async uploadToS3(url: string, type: any, file: any) {
    const response = await this.sponsorSubjectService.uploadToS3(url, type, file);
  }
}
