import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { CommonModule, TitleCasePipe } from "@angular/common";
import { AngularFireModule } from "@angular/fire";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ClipboardModule } from "ngx-clipboard";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ImageCropperModule } from "ngx-image-cropper";
import { SwiperModule } from "swiper/angular";
import { AgGridModule } from "ag-grid-angular";
import { HighchartsChartModule } from "highcharts-angular";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxStripeModule } from "ngx-stripe";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

// Components
import { InputItemComponent } from "./components/input-item/input-item.component";
import { ButtonItemComponent } from "./components/button-item/button-item.component";
import { LoadingItemComponent } from "./components/loading-item/loading-item.component";
import { MessageItemComponent } from "./components/message-item/message-item.component";
import { ChipItemComponent } from "./components/chip-item/chip-item.component";
import { AdviceMessageItemComponent } from "./components/advice-message-item/advice-message-item.component";
import { CapsuleInputItemComponent } from "./components/capsule-input-item/capsule-input-item.component";
import { CreatePubModalComponent } from "./components/create-pub-modal/create-pub-modal.component";
import { StandardCampaignItemComponent } from "./components/standard-campaign-item/standard-campaign-item.component";
import { WidgetItemComponent } from "./components/widget-item/widget-item.component";
import { AcademyContactItemComponent } from "./components/academy-contact-item/academy-contact-item.component";
import { FilterItemComponent } from "./components/filter-item/filter-item.component";
import { FeatureDesignItemComponent } from "./components/feature-design-item/feature-design-item.component";
import { ModifyAcademyPubModalComponent } from "./components/modify-academy-pub-modal/modify-academy-pub-modal.component";
import { DesignCreativesItemComponent } from "./components/design-creatives-item/design-creatives-item.component";
import { SavedSponsorModalComponent } from "./components/saved-sponsor-modal/saved-sponsor-modal.component";
import { ContactusModalComponent } from "./components/contactus-modal/contactus-modal.component";
import { PixelModalComponent } from "./components/pixel-modal/pixel-modal.component";

// Services
import { UserService } from "./services/user.service";
import { StorageService } from "./services/storage.service";
import { AdvertiserService } from "./services/advertiser.service";
import { CampaignService } from "./services/campaign.service";
import { UniversityService } from "./services/university.service";
import { CenterService } from "./services/center.service";
import { StudyService } from "./services/study.service";
import { TargetService } from "./services/target.service";
import { SponsorSubjectService } from "./services/sponsor-subject.service";
import { BillingService } from "./services/billing.service";

// Guards
import { SessionGuard } from "./guards/session.guard";

// Pages
import { LoginComponent } from "./pages/login/login.component";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { CompleteProfileComponent } from "./pages/complete-profile/complete-profile.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { DashboardMainComponent } from "./pages/dashboard-main/dashboard-main.component";
import { CampaignDetailsComponent } from "./pages/campaign-details/campaign-details.component";
import { DashboardSettingsComponent } from "./pages/dashboard-settings/dashboard-settings.component";
import { DashboardCreateComponent } from "./pages/dashboard-create/dashboard-create.component";
import { DashboardGenerateSegmentationComponent } from "./pages/dashboard-generate-segmentation/dashboard-generate-segmentation.component";
import { DashboardPendingComponent } from "./pages/dashboard-pending/dashboard-pending.component";
import { RecoverPasswordComponent } from "./pages/recover-password/recover-password.component";
import { DashboardCropperComponent } from "./pages/dashboard-cropper/dashboard-cropper.component";
import { DashboardPublicsComponent } from "./pages/dashboard-publics/dashboard-publics.component";
import { DashboardAssociateComponent } from "./pages/dashboard-associate/dashboard-associate.component";
import { SponsorSubjectsComponent } from "./pages/landing/sponsor-subjects/sponsor-subjects.component";
import { LandingMainComponent } from "./pages/landing/landing-main/landing-main.component";
import { PurchasedSuccessComponent } from "./pages/landing/purchased-success/purchased-success.component";
import { PurchasedErrorComponent } from "./pages/landing/purchased-error/purchased-error.component";
import { DashboardAdminSponsorSubjectDetailComponent } from "./pages/dashboard-admin-sponsor-subject-detail/dashboard-admin-sponsor-subject-detail.component";
import { SponsorLanguagesComponent } from "./pages/landing/sponsor-languages/sponsor-languages.component";
import { CustomPaymentComponent } from "./pages/landing/custom-payment/custom-payment.component";
import { IndexComponent } from "./pages/index/index.component";
import { DashboardSponsorComponent } from "./pages/dashboard-sponsor/dashboard-sponsor.component";
import { DashboardSponsorSegmentationComponent } from "./pages/dashboard-sponsor-segmentation/dashboard-sponsor-segmentation.component";
import { DashboardSponsorDesignComponent } from "./pages/dashboard-sponsor-design/dashboard-sponsor-design.component";
import { DashboardSponsorCreativitiesComponent } from "./pages/dashboard-sponsor-creativities/dashboard-sponsor-creativities.component";
import { DashboardSponsorPaymentComponent } from "./pages/dashboard-sponsor-payment/dashboard-sponsor-payment.component";
import { DashboardAdminSponsorSubjectComponent } from "./pages/dashboard-admin-sponsor-subject/dashboard-admin-sponsor-subject.component";

// Pipes
import { TranslatePipe } from "./pipes/translate.pipe";
import { TimestampPipe } from "./pipes/timestamp.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { RoundPipe } from "./pipes/round.pipe";

// Environments
import { environment } from "../environments/environment";
import { DashboardUrlClickGenComponent } from "./pages/dashboard-url-click-gen/dashboard-url-click-gen.component";
import { DashboardSponsorReportComponent } from "./pages/dashboard-sponsor-report/dashboard-sponsor-report.component";
import { DashboardSponsorReportDetailsComponent } from "./pages/dashboard-sponsor-report-details/dashboard-sponsor-report-details.component";
import { CloseSponsorModalComponent } from "./components/close-sponsor-modal/close-sponsor-modal.component";
import { ResourceService } from "./services/resource.service";
import { DashboardPixelComponent } from "./pages/dashboard-pixel/dashboard-pixel.component";
import { AnalyticService } from "./services/analytic.service";
import { AgentComponent } from "./pages/landing/agent/agent.component";
import { HubspotService } from "./services/hubspot.service";
import { PixelService } from "./services/pixel.service";
import { DashboardCampaignDetailsComponent } from "./pages/dashboard-campaign-details/dashboard-campaign-details.component";
import { DashboardTakeoverComponent } from "./pages/dashboard-takeover/dashboard-takeover.component";
import { CustomPaymentUniqueComponent } from "./pages/landing/custom-payment-unique/custom-payment-unique.component";

@NgModule({
  declarations: [
    AppComponent,
    InputItemComponent,
    ButtonItemComponent,
    LoginComponent,
    LoadingItemComponent,
    MessageItemComponent,
    SignUpComponent,
    CompleteProfileComponent,
    DashboardComponent,
    DashboardMainComponent,
    CampaignDetailsComponent,
    TranslatePipe,
    TimestampPipe,
    SafePipe,
    RoundPipe,
    DashboardSettingsComponent,
    DashboardPendingComponent,
    DashboardCreateComponent,
    DashboardGenerateSegmentationComponent,
    ChipItemComponent,
    RecoverPasswordComponent,
    DashboardCropperComponent,
    DashboardPublicsComponent,
    DashboardAssociateComponent,
    AdviceMessageItemComponent,
    CapsuleInputItemComponent,
    SponsorSubjectsComponent,
    LandingMainComponent,
    PurchasedSuccessComponent,
    PurchasedErrorComponent,
    DashboardAdminSponsorSubjectDetailComponent,
    SponsorLanguagesComponent,
    CustomPaymentComponent,
    IndexComponent,
    DashboardUrlClickGenComponent,
    DashboardSponsorReportComponent,
    DashboardSponsorReportDetailsComponent,
    CreatePubModalComponent,
    StandardCampaignItemComponent,
    AcademyContactItemComponent,
    DashboardSponsorComponent,
    WidgetItemComponent,
    DashboardSponsorSegmentationComponent,
    FilterItemComponent,
    DashboardSponsorDesignComponent,
    FeatureDesignItemComponent,
    ModifyAcademyPubModalComponent,
    DashboardSponsorCreativitiesComponent,
    DesignCreativesItemComponent,
    DashboardSponsorPaymentComponent,
    CloseSponsorModalComponent,
    DashboardPixelComponent,
    SavedSponsorModalComponent,
    ContactusModalComponent,
    DashboardAdminSponsorSubjectComponent,
    AgentComponent,
    DashboardCampaignDetailsComponent,
    PixelModalComponent,
    DashboardTakeoverComponent,
    CustomPaymentUniqueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ClipboardModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ImageCropperModule,
    AutocompleteLibModule,
    SwiperModule,
    InfiniteScrollModule,
    SwiperModule,
    AgGridModule.withComponents([]),
    HighchartsChartModule,
    NgxSkeletonLoaderModule,
    NgxStripeModule.forRoot(
      "pk_test_51IRCuyCZDAhvQ6LXdIEjjD2mzmYHtZtFeZqenUg9Ny26Eu45jUVp7Gj7PUVq99i4lCGizaqjo28VkgqDqqjXiGfZ00HA2pwCgA"
    ),
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    UserService,
    HttpClient,
    StorageService,
    SessionGuard,
    TranslatePipe,
    TimestampPipe,
    SafePipe,
    RoundPipe,
    AdvertiserService,
    CampaignService,
    UniversityService,
    CenterService,
    StudyService,
    TargetService,
    SponsorSubjectService,
    ResourceService,
    AnalyticService,
    BillingService,
    HubspotService,
    PixelService,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
