import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ActivatedRoute } from '@angular/router';

// Services
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-sponsor-report-details',
  templateUrl: './dashboard-sponsor-report-details.component.html',
  styleUrls: ['./dashboard-sponsor-report-details.component.css']
})
export class DashboardSponsorReportDetailsComponent implements OnInit {

  id: number;
  widget: any;
  folderName: string;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptionsImpressions: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsClicks: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsFixedDocsImpressions: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  chartOptionsFixedDocsClicks: Highcharts.Options = {
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        }
      }
    },
    series: []
  };

  updateFlag = false;
  oneToOneFlag: boolean = true;
  isFirstLoad = true;

  columnDefs = [
    { headerName: 'Documento', field: 'name', sortable: true, filter: true, resizable: true, minWidth: 100},
    { headerName: 'Impresiones', field: 'impressions', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Previsualizaciones', field: 'previews', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Visualizaciones', field: 'views', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Descargas', field: 'downloads', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Clicks', field: 'clicks', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'CTR', field: 'ctr', sortable: true, resizable: true, minWidth: 100},
  ];

  rowData = [];

  columnDefsPubInDocs = [
    { headerName: 'Nombre', field: 'name', sortable: true, filter: true, resizable: true, minWidth: 100},
    { headerName: 'Impresiones', field: 'impressions', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Clicks', field: 'clicks', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'CTR', field: 'ctr', sortable: true, resizable: true, minWidth: 100},
  ];

  rowDataPubInDocs = [];

  columnDefsComponentDetails = [
    { headerName: 'Nombre', field: 'name', sortable: true, filter: true, resizable: true, minWidth: 100},
    { headerName: 'Impresiones', field: 'impressions', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'Clicks', field: 'clicks', sortable: true, resizable: true, minWidth: 100},
    { headerName: 'CTR', field: 'ctr', sortable: true, resizable: true, minWidth: 100},
  ];

  rowDataComponentDetails = [];

  firstReport: any;

  periodSelected = 7;
  period = {
    fromDate: null,
    toDate: null
  };

  check = 0;

  constructor(
    public sponsorSubjectservice: SponsorSubjectService,
    public route: ActivatedRoute,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { 
    this.route.params.subscribe((params) => {
      if (params) {
        if (params.id) {
          this.id = parseInt(params.id);      
        }
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Detalles del patrocinio | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/report/'+this.route.snapshot.paramMap.get("id"), 'Sponsorship details');
    this.analyticService.screenView('Sponsorship details');

    this.init();
  }

  init() {
    this.sponsorSubjectservice.getWidget(this.id).then(
      (response) => {
        this.widget = response;
        setTimeout(() => {
          this.sponsorSubjectservice.getFolderById(this.widget?.folderId).then((response) => {
            this.folderName=response?.name;
          })
        }, 150);
        this.continue();
      }
    ).catch(
      (error) => {
        this.widget = {
          id: this.id,
          name: 'Detalles del patrocinio'
        };

        this.continue();
      }
    );
  }

  continue() {
    this.sponsorSubjectservice.getWidgetReportDetails(this.id, this.period).then(
      (response) => {
        let impressionSerie: any = [];
        let clickSerie: any = [];
        let impressionFixedDocsSerie: any = [];
        let clickFixedDocsSerie: any = [];
        
        for (let i = 0; i < response.length; i++) {
          const splitted = response[i].date.split('-');
          for (let j = 0; j < splitted.length; j++) {
            splitted[j] = parseInt(splitted[j]);            
          }

          const date = Date.UTC(splitted[0], splitted[1] - 1, splitted[2]);

          var impItem: any = [
            date,
            response[i].impression
          ];

          var clickItem: any = [
            date,
            response[i].click
          ];

          var impFixedDocsItem: any = [
            date,
            response[i].impressionFixedDoc
          ];

          var clickFixedDocsItem: any = [
            date,
            response[i].clicksFixedDocs
          ];

          impressionSerie.push(impItem);
          clickSerie.push(clickItem);
          impressionFixedDocsSerie.push(impFixedDocsItem);
          clickFixedDocsSerie.push(clickFixedDocsItem);
        }

        this.chartOptionsImpressions.series.push({
          name: 'Impresiones',
          data: impressionSerie,
          type: null
        });

        this.chartOptionsClicks.series.push({
          name: 'Clicks',
          data: clickSerie,
          type: null
        });

        this.chartOptionsFixedDocsImpressions.series.push({
          name: 'Impresiones',
          data: impressionFixedDocsSerie,
          type: null
        });

        this.chartOptionsFixedDocsClicks.series.push({
          name: 'Clicks',
          data: clickFixedDocsSerie,
          type: null
        });

        this.firstReport = response;
        this.check = 1;

        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }

        if (!this.isFirstLoad) {
          this.updateFlag = true;
        }

        this.getWidgetDocList();
      }
    ).catch(
      (error) => {
        this.firstReport = [];
        this.getWidgetDocList();
      }
    );
  }

  getWidgetDocList() {
    this.sponsorSubjectservice.getWidgetDocList(this.id, this.period).then(
      (response) => {
        for (let i = 0; i < response.length; i++) {
          let ctr = 0;
          if (response[i].clicks > 0) {
            ctr = ((response[i].clicks / response[i].impressions) * 100);
          }        

          const rowItem = { 
            name: response[i].name,
            impressions: response[i].impressions,
            clicks: response[i].clicks,
            ctr: (Math.round(ctr * 100) / 100) + ' %',
            previews: response[i].previews,
            views: response[i].views,
            downloads: response[i].previews
          };
          
          this.rowData.push(rowItem);
        }

        this.check = 2;
        this.pubInDocs();
      }
    ).catch(
      (error) => {
        this.check = 2;
        this.pubInDocs();
      }
    );
  }

  pubInDocs() {
    this.sponsorSubjectservice.getWidgetPubInDoc(this.id, this.period).then(
      (response) => {
        let ctr = 0;
        if (response.clicks > 0) {
          ctr = ((response.clicks / response.impressions) * 100);
        }        

        const rowItem = { 
          name: 'Anuncios en los apuntes',
          impressions: response.impressions,
          clicks: response.clicks,
          ctr: (Math.round(ctr * 100) / 100) + ' %'
        };
        
        this.rowDataPubInDocs.push(rowItem);

        this.check = 3;
        this.widgetDetails();
      }
    ).catch(
      (error) => {
        this.check = 3;
        this.widgetDetails();
      }
    );
  }

  widgetDetails() {
    this.sponsorSubjectservice.getReportWidgetDetails(this.id, this.period).then(
      (response) => {
        for (let i = 0; i < response.clicks.length; i++) {
          let ctr = 0;
          if (response.clicks[i].clicks > 0) {
            ctr = ((response.clicks[i].clicks / response.impressions) * 100);
          }        
  
          const rowItem = { 
            name: response.clicks[i].name,
            impressions: response.impressions,
            clicks: response.clicks[i].clicks,
            ctr: (Math.round(ctr * 100) / 100) + ' %'
          };
          
          this.rowDataComponentDetails.push(rowItem);
        }

        this.check = 4;
      }
    ).catch(
      (error) => {
        this.check = 4;
      }
    );
  }

  setPeriod(period: number) {
    this.check = 0;

    this.chartOptionsImpressions.series = [];
    this.chartOptionsClicks.series = [];
    this.chartOptionsFixedDocsImpressions.series = [];
    this.chartOptionsFixedDocsClicks.series = [];

    this.firstReport = null;

    this.rowData = [];
    this.rowDataPubInDocs = [];
    this.rowDataComponentDetails = [];
    
    this.periodSelected = period;
    
    const today = new Date();
    const base = new Date();
    const final = base.setDate(base.getDate() - this.periodSelected);
    this.period.toDate = this.formatDate(today);
    this.period.fromDate = this.formatDate(final);

    this.init();
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  getTotal(dataType: string) {
    let output = 0;

    for (let i = 0; i < this.firstReport.length; i++) {
      output = output + this.firstReport[i][dataType];   
    }

    return output;
  }

  rowClicked(event: any) {
    //
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  goBack() {
    window.history.back();
  }
}
