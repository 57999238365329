<div class="body-container">
  <h1 class="wds-h1">Crear nueva campaña</h1>

  <div class="stepper-container">
    <div class="stepper-union">
      <div class="progress-bar" [ngClass]="{'step-2': step === 2, 'step-3': step === 3}"></div>
    </div>

    <div class="stepper-item" [ngClass]="{'checked': step >= 1, 'done': step > 1}">
      <div class="stp-icon step">
        1
      </div>
      <div class="stp-icon done">
        <i class="material-icons-outlined">
          done
        </i>
      </div>

      <div class="stp-text">
        Campaña
      </div>
    </div>

    <div class="stepper-item" [ngClass]="{'checked': step >= 2, 'done': step > 2}">
      <div class="stp-icon step">
        2
      </div>
      <div class="stp-icon done">
        <i class="material-icons-outlined">
          done
        </i>
      </div>

      <div class="stp-text">
        Conjunto de anuncios
      </div>
    </div>

    <div class="stepper-item" [ngClass]="{'checked': step >= 3, 'done': step > 3}">
      <div class="stp-icon step">
        3
      </div>
      <div class="stp-icon done">
        <i class="material-icons-outlined">
          done
        </i>
      </div>

      <div class="stp-text">
        Anuncios
      </div>
    </div>
  </div>

  <div class="inner-container">
    <div class="inner-left-side" [ngClass]="{'step-2': step === 2}">
      <div class="inner-slide slide-1" [hidden]="step != 1">
        <div class="inputs-block">
          <div class="item-input no-margin">
            <div class="labels">
              <label>Nombre de la campaña</label>
              <small>Dale un nombre a tu campaña para reconocerlas posteriormente.</small>
            </div>

            <app-input-item
              [(data)]="campaign.name"
              (dataChange)="onNameUpdate()"
              [label]="'Nombre para tu campaña'"
              [type]="'text'"
            ></app-input-item>
          </div>
        </div>

        <div class="inputs-block">
          <div class="item-input">
            <div class="labels">
              <label>Audiencia</label>
              <small></small>
            </div>

            <app-input-item
              [(data)]="campaign.target.name"
              (dataChange)="onTargetNameUpdate()"
              [label]="'Nombre de tu audiencia'"
              [type]="'text'"
            ></app-input-item>
          </div>

          <div class="item-input no-margin wds-ident-left">
            <div class="labels">
              <label>Localización</label>
              <small>¿En qué zona quieres ser visible?</small>
            </div>

            <div class="inner-autocomplete">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.city.disabled}" (click)="targetDisableChange('city')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Ciudad</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.city.disabled">
                <ng-autocomplete #cityAutocomplete
                  [data]="targetSearchData.city.results"
                  [(ngModel)]="targetSearchData.city.text"
                  [searchKeyword]="'name'"
                  (selected)='selectTarget($event, "city")'
                  (inputChanged)='searchTargetOptions($event, "city")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Ciudad'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.city.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let city of campaign.target.cityList" [text]="city.name" (click)="deselectTarget(city.id, 'city')"></app-chip-item>
              </div>
            </div>

            <div class="inner-autocomplete" [hidden]="true">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.zip.disabled}" (click)="targetDisableChange('zip')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Código postal</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.zip.disabled">
                <ng-autocomplete #zipAutocomplete
                  [data]="targetSearchData.zip.results"
                  [(ngModel)]="targetSearchData.zip.text"
                  [searchKeyword]="'name'"
                  (selected)='selectTarget($event, "zip")'
                  (inputChanged)='searchTargetOptions($event, "zip")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Código postal'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.zip.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let zip of campaign.target.zipList" [text]="zip.name" (click)="deselectTarget(zip.id, 'zip')"></app-chip-item>
              </div>
            </div>

            <div class="inner-autocomplete">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.university.disabled}" (click)="targetDisableChange('university')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Universidad</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.university.disabled">
                <ng-autocomplete #universityAutocomplete
                  [data]="targetSearchData.university.results"
                  [(ngModel)]="targetSearchData.university.text"
                  [searchKeyword]="'name'"
                  (inputChanged)='searchTargetOptions($event, "university")'
                  (selected)='selectTarget($event, "university")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Universidad'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.university.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let university of campaign.target.universityList" [text]="university.name" (click)="deselectTarget(university.id, 'university')"></app-chip-item>
              </div>
            </div>

            <div class="inner-autocomplete">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.center.disabled}" (click)="targetDisableChange('center')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Facultad</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.center.disabled">
                <ng-autocomplete #centerAutocomplete
                  [data]="targetSearchData.center.results"
                  [(ngModel)]="targetSearchData.center.text"
                  [searchKeyword]="'name'"
                  (inputChanged)='searchTargetOptions($event, "center")'
                  (selected)='selectTarget($event, "center")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Facultad'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.center.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let center of campaign.target.centerList" [text]="center.name" (click)="deselectTarget(center.id, 'center')"></app-chip-item>
              </div>
            </div>

            <div class="inner-autocomplete">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.study.disabled}" (click)="targetDisableChange('study')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Estudio</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.study.disabled">
                <ng-autocomplete #studyAutocomplete
                  [data]="targetSearchData.study.results"
                  [(ngModel)]="targetSearchData.study.text"
                  [searchKeyword]="'name'"
                  (inputChanged)='searchTargetOptions($event, "study")'
                  (selected)='selectTarget($event, "study")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Estudio'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.study.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let study of campaign.target.studyList" [text]="study.name" (click)="deselectTarget(study.id, 'study')"></app-chip-item>
              </div>
            </div>

            <div class="inner-autocomplete">
              <div class="check-container" [ngClass]="{'checked': !targetSearchData.course.disabled}" (click)="targetDisableChange('course')">
                <div class="square">
                  <i class="material-icons-outlined">check</i>
                </div>

                <div class="text">Curso</div>
              </div>

              <div class="ng-autocomplete" *ngIf="!targetSearchData.course.disabled">
                <ng-autocomplete #courseAutocomplete
                  [data]="targetSearchData.course.results"
                  [(ngModel)]="targetSearchData.course.text"
                  [searchKeyword]="'name'"
                  (inputChanged)='searchTargetOptions($event, "course")'
                  (selected)='selectTarget($event, "course")'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [placeHolder]="'Curso'"
                  [notFoundText]="'No hay resultados'"
                  [isLoading]="targetSearchData.course.loading"
                  [debounceTime]="400"
                ></ng-autocomplete>
              </div>

              <div class="chips-container">
                <app-chip-item *ngFor="let course of campaign.target.courseList" [text]="course.name" (click)="deselectTarget(course.id, 'course')"></app-chip-item>
              </div>
            </div>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>

            <div class="wds-margin-vrt">
              <app-advice-message-item
                [icon]="'info'"
                [title]="'¿Necesitas una segmentación más avanzada para tu campaña?'"
                [subtitle]="'Contacta con nuestro equipo en el teléfono 601 645 449'"
              ></app-advice-message-item>
            </div>
          </div>
        </div>

        <div class="inputs-block">
          <div class="item-input no-margin">
            <div class="labels">
              <label>Presupuesto y duración</label>
              <small>Selecciona un presupuesto y una duración para tu campaña.</small>
            </div>

            <div class="wds-margin-vrt wds-ident-left">
              <div class="inner-title">Presupuesto</div>

              <div class="inner-capsule-container">
                <app-capsule-input-item
                  [selectable]="true"
                  [options]="[{id: 0, name: 'Diario'}, {id: 1, name: 'Total'}]"
                  [(selected)]="campaign.customBudgetPeriodicity"
                  [(amount)]="campaign.customBudgetAmount"
                  (selectedChange)="onBudgetUpdate()"
                  (amountChange)="onBudgetUpdate()"
                  [type]="'number'"
                  [unity]="'EUR'"
                  [color]="'warning'"
                  [min]="campaign.customBudgetPeriodicity == 0 ? 5 : minimumTotalBudget"
                ></app-capsule-input-item>

                <div class="wds-ident-left" *ngIf="campaign.budget < minimumTotalBudget">
                  <app-advice-message-item
                    [icon]="'report_problem'"
                    [color]="'warning'"
                    [subtitle]="'El presupuesto mínimo diario ha de ser superior a 5€'"
                  ></app-advice-message-item>
                </div>
              </div>

              <div class="inner-title">Duración</div>

              <div class="inner-capsule-container duration">
                <app-capsule-input-item
                  [label]="'Fecha de inicio'"
                  [(amount)]="campaign.startDateString"
                  (amountChange)="onDateUpdate()"
                  [type]="'date'"
                ></app-capsule-input-item>

                <div class="wds-ident-left">
                  <app-capsule-input-item
                    [label]="'Fecha de finalización'"
                    [(amount)]="campaign.endDateString"
                    (amountChange)="onDateUpdate()"
                    [type]="'date'"
                  ></app-capsule-input-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="inner-slide slide-2" *ngIf="step === 2">
        <div class="inputs-block">
          <div class="item-input">
            <div class="labels">
              <label>Destino</label>
              <small>Introduce una URL de destino para tus anuncios.</small>
            </div>

            <app-input-item
              [(data)]="campaign.clickUrl"
              [label]="'URL de destino de los anuncios'"
              [type]="'text'"
              (dataChange)="onClickUrlChange()"
            ></app-input-item>
          </div>

          <div class="item-input no-margin">
            <div class="labels">
              <label>Imágenes</label>
              <small>Las imágenes que ilustran tu campaña.</small>
            </div>

            <div class="images-wrapper">
              <input class="hidden-input" type="file" #newCreativityInput (change)="onNewCreativity($event)" style="display: none;"/>
              <div class="add-action" (click)="openAddCreativityInput()">
                <i class="material-icons-outlined">add</i>
              </div>

              <ng-container *ngFor="let creative of campaign.creatives; let i = index">
                <div class="img-item" *ngIf="selectedAdUbication == creative.ubicationId" [ngClass]="{'active': i == selectedCreative}" (click)="onSelectCreativeChange(i);">
                  <div class="overlay">
                    <img [src]="creative.fileUrl">
                  </div>
  
                  <i class="material-icons-outlined">wallpaper</i>
                </div>
              </ng-container>
              
              <div class="img-item" *ngFor="let fakeCreative of [].constructor(creativesSlots[selectedAdUbication])" (click)="openAddCreativityInput()">
                  <i class="material-icons-outlined">wallpaper</i>
                </div>
            </div>

            <p class="image-advice">
              ¿No tienes diseñador? No te preocupes, sube 3 imágenes como material
              de apoyo para que nuestro equipo diseñe la campaña por ti en 48h.
              Si lo deseas puedes descargar nuestras plantillas en <a href="./../../../assets/templates/APUNTES - lateral-pdf-horizontal.psd" download="">horizontal</a> y <a href="./../../../assets/templates/APUNTES - lateral-pdf-vertical.psd" download="">vertical</a> y diseñar tu mismo
              los anuncios. Antes de comenzar la campaña un agente contactará contigo
              para verificar los anuncios. No te haremos ningún cargo hasta que verifiques
              los anuncios.
            </p>
          </div>
        </div>
      </div>

      <div class="inner-slide slide-3" [hidden]="step !== 3">
        <form action="" method="post" id="payment-form">
          <div class="form-row">
            <label for="card-element">
              Introduce tu método de pago:
            </label>
            <div id="card-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>

            <!-- Used to display form errors. -->
            <div id="card-errors" role="alert"></div>
          </div>

          <button (click)="submitPaymentForm()">Realizar pago</button>
        </form>
      </div>
    </div>

    <div class="inner-right-side" [ngClass]="{'step-2': step === 2}">
      <div *ngIf="step === 1 || step === 3" class="inputs-block">
        <div class="item-input no-margin">
          <div class="labels">
            <label>Alcance</label>
            <small>Opciones de alcance según tu segmentación.</small>
          </div>

          <div class="single-chart">
            <svg viewBox="0 0 36 36" class="circular-chart green">
              <path class="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
              <path class="circle" [attr.stroke-dasharray]="reachPercentage + ', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
              <text x="18" y="18" class="percentage">{{campaign.reach}}</text>
              <text x="18" y="22" class="percentage-2">Usuarios objetivos</text>
            </svg>
          </div>

          <div class="point-item">
            <div class="point"></div>
            <div class="text">~ <strong>{{campaign.impressions | round }}</strong> impresiones</div>
          </div>

          <div class="point-item">
            <div class="point"></div>
            <div class="text">{{campaign.budget}} €</div>
          </div>
        </div>
      </div>

      <i *ngIf="step === 1">* Datos estimados según tu selección en la audiencia y el presupuesto de tu campaña.</i>

      <div *ngIf="step === 2" class="inputs-block image-examples">
        <div class="item-input no-margin">
          <div class="labels">
            <label>Vista previa</label>
            <small>Ejemplo de como quedará tu campaña según el formato seleccionado.</small>
          </div>

          <div class="image-container" [ngClass]="creativityPreviewClass">
            <img [src]="selectedUbicationExample">
          </div>

          <p class="image-advice">Simulación formato A4 completo</p>
        </div>

        <div class="item-input no-margin examples-selector">
          <div class="labels">
            <label>Apuntes</label>
            <small></small>
          </div>

          <div class="images-wrapper gridder">
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 1}" (click)="onSelectedAdUbicationChange(1)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-1.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">A4</div>
            </div>

            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 2}" (click)="onSelectedAdUbicationChange(2)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-2.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Portada</div>
            </div>

            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 3}" (click)="onSelectedAdUbicationChange(3)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-3.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Lateral vertical</div>
            </div>

            <div class="image-with-title tail">
              <div class="img-item horizontal" [ngClass]="{'active': selectedAdUbication == 4}" (click)="onSelectedAdUbicationChange(4)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-4.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Lateral horizontal</div>
            </div>

            <div class="image-with-title tail" *ngIf="false">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 5}" (click)="onSelectedAdUbicationChange(5)">
                <div class="overlay">
                  <img src="https://4c448342d6996fb20913-fd1f9dc15ff616aa7fa94219cb721c9c.ssl.cf3.rackcdn.com/0f/25/498717_55b4cf70e36649f194e0f936ad173ef3.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Footer</div>
            </div>
          </div>

          <div class="labels">
            <label>Display</label>
            <small></small>
          </div>
          <div class="images-wrapper gridder">
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 6}" (click)="onSelectedAdUbicationChange(6)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-6.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Roba</div>
            </div>
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 7}" (click)="onSelectedAdUbicationChange(7)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-7.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Roba grande</div>
            </div>
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 8}" (click)="onSelectedAdUbicationChange(8)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-8.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Skin</div>
            </div>
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 9}" (click)="onSelectedAdUbicationChange(9)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-9.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Superbanner</div>
            </div>
            <div class="image-with-title tail">
              <div class="img-item" [ngClass]="{'active': selectedAdUbication == 10}" (click)="onSelectedAdUbicationChange(10)">
                <div class="overlay">
                  <img src="/assets/pub/example-ubication-10.jpg">
                </div>

                <i class="material-icons-outlined">wallpaper</i>
              </div>

              <div class="title">Megabanner</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="stepper-footer" [ngStyle]="step !== 1 ? {} : {'justify-content': 'flex-end'}">
    <app-button-item [ngStyle]="step !== 1 ? {} : {'display': 'none'}" (fireClick)="previousStep()" [text]="'Atrás'" [color]="'#111111'" [borderColor]="'#b6b6b6'" [outlined]="true"></app-button-item>
    <app-button-item (fireClick)="nextStep()" [text]="'Continuar'" [solid]="true" [disabled]="!stepFulfilled"></app-button-item>
  </div>
</div>
