<div class="container">
  <div class="overlay" (click)="close()"></div>
  <div class="modal-container">
    <app-button-item
      class="close"
      [title]="'Cerrar'"
      [onlyIcon]="true"
      [icon]="'close'"
      [color]="'#111111'"
      (fireClick)="close()"
    ></app-button-item>
    <div class="preview">
      <p>Previsualizar</p>
      <div *ngIf="dataWidget">
        <app-feature-design-item
          [title]="dataWidget.title"
          [description]="dataWidget.description"
          [linkName]="dataWidget.actionText"
          [image]="dataWidget.assetUrl"
          [edit]="false"
        ></app-feature-design-item>
      </div>
    </div>
    <div class="fill-container">
      <label class="input-element-label">Icono</label>
      <div [ngClass]="{uploadFileActive: dataWidget.imageChanged}" class="upload-file" (click)="openMediaInput('feature_asset')">
        <app-button-item
          [title]="'Añadir icono'"
          [onlyIcon]="true"
          [icon]="dataWidget.imageChanged ? 'task_alt' : 'add_circle_outline'"
          [color]="dataWidget.imageChanged ? '#0055FF' : '#888888'"
        ></app-button-item>
        <span>{{ dataWidget.imageChanged ? 'Añadido' : 'Añadir icono'}}</span>
      </div>
      <div class="input-element">
        <label class="input-element-label">Título 1</label>
        <input 
          placeholder="Los mejores precios" 
          [(ngModel)]="dataWidget.title" 
          type="text"
        />
      </div>
      <div class="input-element">
        <label class="input-element-label">Descripción</label>
        <textarea
          [(ngModel)]="dataWidget.description"
          placeholder="Este mes un 2x1 en tu matrícula"
          cols="30"
          rows="3"
        ></textarea>
      </div>
      <div class="input-element">
        <label class="input-element-label">Botón</label>
        <input placeholder="Ir" [(ngModel)]="dataWidget.actionText" type="text" />
      </div>
      <div class="input-element">
        <label class="input-element-label">Web</label>
        <input placeholder="https://www.wuolah.com/" [(ngModel)]="dataWidget.actionUrl" type="text" />
      </div>
    </div>
    <div class="save-container">
      <app-button-item
        class="remove"
        [title]="'Borrar'"
        [color]="'#0055ff'"
        [borderColor]="'#b6b6b6'"
        [text]="'Borrar'"
        (fireClick)="removeFeature(); dataWidget.imageChanged = false"
      ></app-button-item>
      <app-button-item
        [text]="'Guardar'"
        [background]="'#001540'"
        [solid]="true"
        [size]="'small'"
        (fireClick)="save()"
      >
      </app-button-item>
    </div>
  </div>
</div>

<input type="file" accept="image/*" #mediaInput (change)="handleFile($event); dataWidget.imageChanged = true" style="display: none;">
