<div class="chip-item" [ngClass]="{'selected': selected, 'for-add': addition}" (click)="toggle()">
  <div class="icon">
    <span *ngIf="selected && !addition" class="material-icons">
      check_circle_outline
    </span>

    <span *ngIf="!selected && !addition" class="material-icons">
      radio_button_unchecked
    </span>

    <span *ngIf="addition" class="material-icons">
      add_circle_outline
    </span>
  </div>

  <div class="text">{{text}}</div>
</div>
