<div class="body-container">
  <h1 class="wds-h1">Cortador de imágenes</h1>

  <div class="inner-container">
    <div class="left-side">
      <h5>Formato vertical</h5>

      <div *ngIf="!verticalName" (click)="openInput('vertical')" class="add-image-item">
        <div class="icon">
          <i class="material-icons-outlined">add_circle_outline</i>
        </div>

        <div class="text">Añadir creatividad</div>
      </div>

      <div *ngIf="verticalName" class="image-item">
        <div class="text" [title]="verticalName"> {{verticalName}}</div>

        <div (click)="showPreview('vertical')" class="icon">
          <i class="material-icons-outlined">visibility</i>
        </div>

        <div (click)="deleteImage('vertical')" class="icon">
          <i class="material-icons-outlined">delete</i>
        </div>
      </div>

      <h5>Formato horizontal</h5>

      <div *ngIf="!horizontalName" (click)="openInput('horizontal')" class="add-image-item">
        <div class="icon">
          <i class="material-icons-outlined">add_circle_outline</i>
        </div>

        <div class="text">Añadir creatividad</div>
      </div>

      <div *ngIf="horizontalName" (click)="showPreview('horizontal')" class="image-item">
        <div class="text" [title]="horizontalName">{{horizontalName}}</div>

        <div (click)="showPreview('horizontal')" class="icon">
          <i class="material-icons-outlined">visibility</i>
        </div>

        <div (click)="deleteImage('horizontal')" class="icon">
          <i class="material-icons-outlined">delete</i>
        </div>
      </div>

      <div class="button-download-container">
        <app-button-item [text]="'Descargar creatividades'" (fireClick)="downloadImages()" [disabled]=" !sideCroppedImage || !upperCroppedImage" [solid]="true"></app-button-item>
      </div>

      <div class="button-download-container" style="display: none;">
        <app-button-item [text]="'Nuevas creatividades'" [outlined]="true" (fireClick)="deleteUploads()" [disabled]="!sideCroppedImage || !upperCroppedImage"></app-button-item>
      </div>
    </div>

    <div class="right-side">
      <div *ngIf="previewSRC" class="preview-container" [ngClass]="{'horizontal': mode && mode === 'horizontal'}">
        <img [src]="previewSRC">
      </div>
    </div>
  </div>
</div>

<input class="cropper" type="file" style="visibility: hidden;" #verticalAttachments (change)="handleFileInput($event, 'vertical')" />
<input class="cropper" type="file" style="visibility: hidden;" #horizontalAttachments (change)="handleFileInput($event, 'horizontal')" />

<div class="custom-file" [ngClass]="{ cropper: toggle }">
  <div class="images-content">
    <div class="cropped-images">
      <img [src]="upperCroppedImage">
    </div>

    <div class="cropped-images">
      <img [src]="sideCroppedImage">
    </div>
  </div>

  <div [ngClass]="{ cropper: toggle }">
    <image-cropper
      [imageFile]="imageChangedEvent"
      [cropperStaticWidth]="upperCroppedWidth"
      [cropperStaticHeight]="upperCroppedHeight"
      [cropperMaxHeight]="upperCroppedHeight"
      [cropper]="upper"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'upper')"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>

  <div [ngClass]="{ cropper: toggle }">
    <image-cropper
      [imageFile]="imageChangedEvent_2"
      [cropper]="side"
      [cropperStaticWidth]="sideCroppedWidth"
      [cropperStaticHeight]="sideCroppedHeight"
      [cropperMaxWidth]="sideCroppedWidth"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'side')"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</div>
