import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-standard-campaign-item',
  templateUrl: './standard-campaign-item.component.html',
  styleUrls: ['./standard-campaign-item.component.css']
})
export class StandardCampaignItemComponent implements OnInit {

  @Input('title') title: string;
  @Input('description') description: string;
  @Input('image') image: string;
  @Input('backgroundColor') backgroundColor: string = '#cccccc';

  @Output() onSelect = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  selectItem() {
    this.onSelect.emit();
  }

}
