import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// Services
import { StorageService } from './services/storage.service';
import { UtilsService } from './services/utils.service';
import { TranslateService } from './translate.service';

// Libs
import { v4 as uuidv4 } from 'uuid';

// External
declare var _hsq : any;

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  loading = false;

  showMessage = false;
  message: any;

  showContactUs = false;
  interval: any;
  lastClick = false;

  environment = environment;

  constructor(
    public utilsService: UtilsService,
    private translateService: TranslateService,
    public storageService: StorageService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.environment.production) {
          _hsq.push(['setPath', val.url]);
        }
      }
    });

    const uuid = this.storageService.read('uuid');
    if (typeof uuid === 'undefined' || !uuid || uuid === '') {
      const uuid = uuidv4();
      this.storageService.write('uuid', uuid);
    }

    this.utilsService.getObservable().subscribe((result) => {
      if (result && result.name === "activete_loading") {
        if (!this.loading) {
          this.loading = true;
        }
      } else if (result && result.name === "close_loading") {
        if (this.loading) {
          this.loading = false;
        }
      } else if (result && result.name === "alert_message") {
        if (result.data) {
          this.message = result.data;
          this.showMessage = true;
        }
      }
    });
  }

  async ngOnInit() {
    this.translateService.init();
  }

  contactUs() {
    this.interval = setInterval(() => {
      let lost_popup_closed = this.storageService.read('lost_popup_closed');

      if (this.lastClick === false) {
        if (typeof lost_popup_closed !== 'undefined' && lost_popup_closed && lost_popup_closed !== 0) {
          this.showContactUs = false;
          clearInterval(this.interval);
        } else {
          this.showContactUs = true;
        }    
      }

      this.lastClick = false;
    
    }, 5000);
  }

  restart() {
    clearInterval(this.interval);
    this.lastClick = false;
    this.contactUs();
  }
}
