<div class="filter-container">
  <div class="input-search">
    <input
      [placeholder]="placeholder ? placeholder : 'Buscar...'"
      type="text"
      #input
      (input)="searchHandler($event)"
      debounce="500"
    />

    <app-button-item
      [onlyIcon]="true"
      [title]="'Buscar'"
      [icon]="'search'"
      [solid]="true"
      [background]="'#001540'"
      [color]="'#ffffff'"
      (fireClick)="search()"
    ></app-button-item>
  </div>

  <div class="result-search" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
    <ul>
      <li *ngFor="let object of objects" (click)="objectStatus(object); checkObject(object)">
        <div class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': checkObject(object)}">
          <i class="material-icons-outlined">done</i>
        </div>

        <span>{{ object?.name }}</span>
      </li>
    </ul>
  </div>

  <div class="save-search">
    <app-button-item
      [text]="'Borrar'"
      [color]="'#b6b6b6'"
      (fireClick)="input.value = ''; objects = []; 
      type == 'university' ? checkedObjects.university = null : ''; 
      type == 'center' ? checkedObjects.center = null : ''; 
      type == 'study' ? checkedObjects.study = null : ''; 
      type == 'course' ? checkedObjects.course = null : '';
      buttonClick.emit(); throwData()"
    ></app-button-item>

    <app-button-item
      [text]="'Filtrar'"
      [background]="'#0055FF'"
      [solid]="true"
      (fireClick)="buttonClick.emit(); throwData()"
    ></app-button-item>
  </div>
</div>
