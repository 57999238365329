<div class="body-container">
  <h1 class="wds-h1">Generador de segmentaciones</h1>
  <div class="inner-container">
    <div class="inner-left-side">
      <div class="item-input no-margin wds-ident-left">
        <small>Introduce los filtros y copia los Ids que necesites</small>
      
        <div class="inner-autocomplete">
          <div class="text">Ciudad</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #cityAutocomplete
              [data]="targetSearchData.city.results"
              [(ngModel)]="targetSearchData.city.text"
              [searchKeyword]="'name'"
              (selected)='selectTarget($event, "city")'
              (inputChanged)='searchTargetOptions($event, "city")'
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [placeHolder]="'Ciudad'"
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.city.loading"
              [debounceTime]="400"
            ></ng-autocomplete>
          </div>

          <div class="chips-container">
            <app-chip-item *ngFor="let city of campaign.target.cityList" [text]="city.name + ', Id = ' + city.id" (click)="deselectTarget(city.id, 'city')"></app-chip-item>
              <div class="public-label" *ngIf="campaign.target.cityList.length">
                <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('city')"
                  [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
              </div>
          </div>
        </div>

        <div class="inner-autocomplete" [hidden]="false">
          <div class="text">Tipo de Estudio</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #typeAutocomplete 
              [data]="targetSearchData.type.results" 
              [(ngModel)]="targetSearchData.type.text"
              [searchKeyword]="'name'" 
              (selected)='selectTarget($event, "type")'
              (inputChanged)='searchStudyTypeTargetOptions()' 
              (inputFocused)='searchStudyTypeTargetOptions()' 
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate" 
              [placeHolder]="'Tipo de Estudio'" 
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.type.loading" 
              [debounceTime]="400"></ng-autocomplete>
          </div>
        
          <div class="chips-container">
            <app-chip-item *ngFor="let type of campaign.target.typeList" [text]="type.name + ', Id = ' + type.id"
              (click)="deselectTarget(type.id, 'type')"></app-chip-item>
              <div class="public-label" *ngIf="campaign.target.typeList.length">
                <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('type')"
                  [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
              </div>
          </div>
        </div>

        <div class="inner-autocomplete" *ngIf="!targetSearchData.university.disabled">
          <div class="text">Universidad</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #universityAutocomplete
              [data]="targetSearchData.university.results"
              [(ngModel)]="targetSearchData.university.text"
              [searchKeyword]="'name'"
              (inputChanged)='searchTargetOptions($event, "university")'
              (selected)='selectTarget($event, "university")'
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [placeHolder]="'Universidad'"
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.university.loading"
              [debounceTime]="400"
            ></ng-autocomplete>
          </div>

          <div class="chips-container">
            <app-chip-item *ngFor="let university of campaign.target.universityList" [text]="university.name + ', Id = ' + university.id" (click)="deselectTarget(university.id, 'university')"></app-chip-item>
          <div class="public-label" *ngIf="campaign.target.universityList.length">
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('university')"
              [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          </div>
        </div>

        <div class="inner-autocomplete">
          <div class="text">Centro</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #centerAutocomplete
              [data]="targetSearchData.center.results"
              [(ngModel)]="targetSearchData.center.text"
              [searchKeyword]="'name'"
              (inputChanged)='searchTargetOptions($event, "center")'
              (selected)='selectTarget($event, "center")'
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [placeHolder]="'Centro'"
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.center.loading"
              [debounceTime]="400"
            ></ng-autocomplete>
          </div>

          <div class="chips-container">
            <app-chip-item *ngFor="let center of campaign.target.centerList" [text]="center.name + ', Id = ' + center.id" (click)="deselectTarget(center.id, 'center')"></app-chip-item>
          <div class="public-label" *ngIf="campaign.target.centerList.length">
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('center')"
              [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          </div>
        </div>

        <div class="inner-autocomplete">
          <div class="text">Estudio</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #studyAutocomplete
              [data]="targetSearchData.study.results"
              [(ngModel)]="targetSearchData.study.text"
              [searchKeyword]="'name'"
              (inputChanged)='searchTargetOptions($event, "study")'
              (selected)='selectTarget($event, "study")'
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [placeHolder]="'Estudio'"
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.study.loading"
              [debounceTime]="400"
            ></ng-autocomplete>
          </div>

          <div class="chips-container">
            <app-chip-item *ngFor="let study of campaign.target.studyList" [text]="study.name + ', Id = ' + study.id" (click)="deselectTarget(study.id, 'study')"></app-chip-item>
          <div class="public-label" *ngIf="campaign.target.studyList.length">
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('study')"
              [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          </div>
        </div>

        <div class="inner-autocomplete">
          <div class="text">Curso</div>
          <div class="ng-autocomplete">
            <ng-autocomplete #courseAutocomplete
              [data]="targetSearchData.course.results"
              [(ngModel)]="targetSearchData.course.text"
              [searchKeyword]="'name'"
              (inputChanged)='searchTargetOptions($event, "course")'
              (selected)='selectTarget($event, "course")'
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [placeHolder]="'Curso'"
              [notFoundText]="'No hay resultados'"
              [isLoading]="targetSearchData.course.loading"
              [debounceTime]="400"
            ></ng-autocomplete>
          </div>

          <div class="chips-container">
            <app-chip-item *ngFor="let course of campaign.target.courseList" [text]="course.name" (click)="deselectTarget(course.id, 'course')"></app-chip-item>
          <div class="public-label" *ngIf="campaign.target.courseList.length">
            <app-button-item [outlined]="true" [borderColor]="'#111111'" [color]="'#111111'" (fireClick)="copyPublic('course')"
              [onlyIcon]="true" [title]="'Copiar'" [icon]="'content_copy'"></app-button-item>
          </div>
          </div>
        </div>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
