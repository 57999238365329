import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

// Services
import { UtilsService } from 'src/app/services/utils.service';
import { CampaignService } from 'src/app/services/campaign.service';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-takeover',
  templateUrl: './dashboard-takeover.component.html',
  styleUrls: ['./dashboard-takeover.component.css']
})
export class DashboardTakeoverComponent implements OnInit {

  @ViewChild('file_rf_nav_logo') file_rf_nav_logo: ElementRef;
  @ViewChild('file_rf_widget_logo') file_rf_widget_logo: ElementRef;

  creativeId: number;
  params = {
    rf_nav_logo: null,
    rf_widget_logo: null,
    rf_widget_text: null,
    rf_trade_name: null
  };

  output = null;
  uploadCheck = 0;

  environment = environment;

  constructor(
    public utilsService: UtilsService,
    public campaignService: CampaignService,
    private clipboardService: ClipboardService,
  ) { }

  ngOnInit(): void { }

  onFileSelected(key, event) {
    if (event.target.files.length > 0) {
      this.params[key] = event.target.files[0];
    }
  }

  send() {
    this.utilsService.loading('open');

    const path = 'media/assets/takeover/' + this.creativeId;
    const name_rf_nav_logo = 'rf_nav_logo_' + this.creativeId + '.' + this.params.rf_nav_logo.name.split('.').pop();
    const name_rf_widget_logo = 'rf_widget_logo_' + this.creativeId + '.' + this.params.rf_widget_logo.name.split('.').pop();

    this.uploadImageToS3(path, name_rf_nav_logo, this.params.rf_nav_logo.type, this.params.rf_nav_logo);
    this.uploadImageToS3(path, name_rf_widget_logo, this.params.rf_widget_logo.type, this.params.rf_widget_logo);
  }

  uploadImageToS3(path, name, type, file) {
    const data = {
      type: type,
      fileName: name,
      path: path
    };

    this.campaignService.getsignedURLFromS3(data).then(
      (response) => {
        this.uploadImage(file, type, response.pictureUrl);
      }
    ).catch(
      (error) => {
        console.log(error);
      }
    );
  }

  async uploadImage(file, type, url) {
    try {
      await this.campaignService.uploadImageToS3(file, type, url);
      this.uploadCheck++;

      const path = 'media/assets/takeover/' + this.creativeId;
      const name_rf_nav_logo = 'rf_nav_logo_' + this.creativeId + '.' + this.params.rf_nav_logo.name.split('.').pop();
      const name_rf_widget_logo = 'rf_widget_logo_' + this.creativeId + '.' + this.params.rf_widget_logo.name.split('.').pop();

      if (this.uploadCheck === 2) {
        this.utilsService.loading('close');
        this.output = 'rf_nav_logo=' + this.environment.s3Buckets.public + '/' + path + '/' + name_rf_nav_logo + ',rf_widget_logo=' + 
        this.environment.s3Buckets.public + '/' + path + '/' + name_rf_widget_logo + ',rf_widget_text=' + 
        this.params.rf_widget_text + ',rf_trade_name=' + this.params.rf_trade_name;
      }
    } catch (error) {
      console.log(error);
    }
  }

  clear() {
    this.uploadCheck = 0;
    this.output = null;
    this.creativeId = null;
    this.params = {
      rf_nav_logo: null,
      rf_widget_logo: null,
      rf_widget_text: null,
      rf_trade_name: null
    };

    this.utilsService.presentAlertMessage({
      title: 'Formulario borrado.',
      description: 'Se han limpiado los datos del formulario.',
      duration: 4
    });
  }

  copy() {
    this.clipboardService.copy(this.output);

    this.utilsService.presentAlertMessage({
      title: 'Copiado.',
      description: 'Se han copiado los datos en el portapapeles.',
      duration: 4
    });
  }
}
