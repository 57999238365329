import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AdvertiserService } from '../../services/advertiser.service';
import { UtilsService } from '../../services/utils.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { AnalyticService } from 'src/app/services/analytic.service';

// Environments
import { environment } from '../../../environments/environment';
import { ResourceService } from 'src/app/services/resource.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit {

  @ViewChild("cityAutocomplete") cityAutocomplete: any;

  environment = environment;

  data: any;
  businessTypes = [];
  cities = [];

  uuid: string;

  constructor(
    public utilsService: UtilsService,
    public userService: UserService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    public advertiserService: AdvertiserService,
    public analyticService: AnalyticService,
    public resourceService: ResourceService,
    public titleService: Title
  ) {
    const user: any = this.storageService.read('userMe');
    let businessType: any = this.storageService.read('businessType');
    
    if (typeof businessType === 'undefined' || !businessType) {
      businessType = 'INITIAL';
    }

    this.data = {
      email: user.mail,
      firstname: null,
      mobilephone: null,
      name: null,
      businessType: businessType,
      cityId: -1,
    };

    this.businessTypes = this.utilsService.getBusinessTypes();
    this.uuid = this.storageService.read('uuid');
  }

  ngOnInit(): void { 
    this.titleService.setTitle('Completa tu perfil | Wuolads');
    this.analyticService.pageView(window.location.href, 'complete-profile', 'Complete profile');
    this.analyticService.screenView('Complete profile');

    this.analyticService.customEvent(
      'UP1_04_0_VISIT',
      {
        uuid: this.uuid,
        userId: null
      }
    );

    this.getCities();
  }

  getCities() {
    const data = {
      offset: 0,
      limit: 5000,
      types: 'city',
      countryId: 1,
      sort: 'name'
    };

    this.resourceService.getResources(data).then(
      (response) => {
        this.cities = response.results;
      }
    ).catch(
      () => this.cities = []
    );
  }

  checkDisabled() {
    return this.data?.email === null || 
    this.data?.firstname === null || 
    this.data?.mobilephone === null || 
    this.data?.name === null || 
    this.data?.cityId < 0;
  }

  completeProfile() {
    if (!this.data.firstname || this.data.firstname.length < 3 || this.data.firstname.length > 255) {
      this.utilsService.presentAlertMessage({
        title: 'Nombre incorrecto.',
        description: 'El nombre debe contener entre 3 y 255 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (!this.data.mobilephone || !this.utilsService.validatePhoneNumber(this.data.mobilephone)) {
      this.utilsService.presentAlertMessage({
        title: 'Teléfono incorrecto.',
        description: 'Debe introducir un número de teléfono válido',
        error: true,
        duration: 6
      });

      return false;
    }

    if (!this.data.name || this.data.name.length < 3 || this.data.name.length > 255) {
      this.utilsService.presentAlertMessage({
        title: 'Nombre incorrecto.',
        description: 'El nombre de la empresa debe contener entre 3 y 255 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (this.data.cityId < 0) {
      this.utilsService.presentAlertMessage({
        title: 'Elige tu ciudad.',
        description: 'Debes elegir una ciudad de la lista.',
        error: true,
        duration: 6
      });

      return false;
    }

    this.utilsService.loading('open');

    const data: any = {
      contactName: this.data.firstname,
      phoneNumber: this.data.mobilephone,
      name: this.data.name,
      cityId: parseInt(this.data.cityId)
    }

    if (this.data.businessType && this.data.businessType !== 'INITIAL') {
      data.businessType = this.data.businessType;
    }

    const user: any = this.storageService.read('userMe');

    this.advertiserService.completeProfile(data).then(
      async (response) => {

        const data = {
          step: 'COMPLETEPROFILE'
        };
        let notification: any = this.storageService.read("notification");
        if (!notification) {
          notification = {
            sponsor: null,
            campaign: null,
            completeProfile: 1,
            signUp: null,
          }
          this.userService.sendStepNotification(data);
        } else {
          if (notification?.completeProfile && notification?.completeProfile < 5) {
            this.userService.sendStepNotification(data);
            notification.completeProfile = notification?.completeProfile + 1;
          } else if (!notification?.completeProfile) {
            notification.completeProfile = 1;
            this.userService.sendStepNotification(data);
          }
        }
        this.storageService.write("notification", notification);

        this.router.navigate(['/dashboard'], { relativeTo: this.route }).then(()=>{
          this.utilsService.loading('close');
        });
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
    );
  }
}
