import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UtilsService } from "src/app/services/utils.service";
import { Router } from "@angular/router";

import { StorageService } from 'src/app/services/storage.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { Title } from "@angular/platform-browser";
import { UserService } from 'src/app/services/user.service';
import { SponsorSubjectService } from 'src/app/services/sponsor-subject.service';

interface button {
  name: string;
  value: string;
  color: string;
  backgroundColor: string;
  iconUrl: string;
  iconType: string;
  actionUrl: string;
  id?: number;
}

interface contact {
  name: string;
  value: string;
  color: string;
  backgroundColor: string;
  iconUrl: string;
  iconType: string;
  actionUrl: string;
  id?: number;
}

interface feature {
  title: string;
  description: string;
  color: string;
  backgroundColor: string;
  data: string;
  highlight: 1 | 0;
  assetUrl: string;
  actionText: string;
  actionUrl: string;
  id?: number;
}

interface generalInformation {
  actionText: string;
  actionUrl: string;
  backgroundUrl: string;
  centerId: number;
  course: number;
  description: string;
  folderId: number;
  logoUrl: string;
  name: string;
  primaryBgColor: string;
  primaryColor: string;
  secondaryBgColor: string;
  secondaryColor: string;
  status: 1 | 0;
  studyId: number;
  subtitle: string;
  title: string;
  userId: number;
}

@Component({
  selector: "app-dashboard-sponsor-design",
  templateUrl: "./dashboard-sponsor-design.component.html",
  styleUrls: ["./dashboard-sponsor-design.component.css"],
})
export class DashboardSponsorDesignComponent implements OnInit {
  @ViewChild("mediaInput", { static: true }) mediaInput: ElementRef;

  openModalAcademy = false;
  activeCheck = false;
  ifeature = -1;

  dataButton: button = {
    name: null,
    value: null,
    color: null,
    backgroundColor: null,
    iconUrl: null,
    iconType: null,
    actionUrl: null,
    id: null,
  };

  dataContact: contact = {
    name: null,
    value: null,
    color: null,
    backgroundColor: null,
    iconUrl: null,
    iconType: null,
    actionUrl: null,
    id: null,
  };

  dataFeature: feature = {
    title: null,
    description: null,
    color: null,
    backgroundColor: null,
    data: null,
    highlight: null,
    assetUrl: null,
    actionText: null,
    actionUrl: null,
    id: null,
  };

  dataGeneralInformation: generalInformation = {
    actionText: "Ir a la academia",
    actionUrl: null,
    backgroundUrl: null,
    centerId: null,
    course: null,
    description: null,
    folderId: null,
    logoUrl: null,
    name: null,
    primaryBgColor: null,
    primaryColor: null,
    secondaryBgColor: null,
    secondaryColor: null,
    status: null,
    studyId: null,
    subtitle: null,
    title: null,
    userId: null,
  };

  buttons = [
    (this.dataButton = {
      name: "web",
      value: "web",
      color: null,
      backgroundColor: null,
      iconUrl: null,
      iconType: null,
      actionUrl: null,
      id: null,
    }),
    (this.dataButton = {
      name: "whatsapp",
      value: "whatsapp",
      color: null,
      backgroundColor: null,
      iconUrl: null,
      iconType: null,
      actionUrl: null,
      id: null,
    }),
  ];

  contacts = [
    (this.dataContact = {
      name: "Teléfono:",
      value: null,
      color: null,
      backgroundColor: null,
      iconUrl: null,
      iconType: null,
      actionUrl: null,
      id: null,
    }),
    (this.dataContact = {
      name: "Email:",
      value: null,
      color: null,
      backgroundColor: null,
      iconUrl: null,
      iconType: null,
      actionUrl: null,
      id: null,
    }),
    (this.dataContact = {
      name: "Web:",
      value: null,
      color: null,
      backgroundColor: null,
      iconUrl: null,
      iconType: null,
      actionUrl: null,
      id: null,
    }),
  ];

  features = [
    (this.dataFeature = {
      title: "Un mes gratuito",
      description: "Apúntate ahora y consigue un mes gratis",
      color: null,
      backgroundColor: null,
      data: "Precios",
      highlight: null,
      assetUrl: "https://wuolah-web.s3.eu-west-1.amazonaws.com/wuolads/academy-feature-assets/pig_icon.png",
      actionText: "Saber más",
      actionUrl: null,
      id: 0,
    }),
    (this.dataFeature = {
      title: "Horarios flexibles",
      description:
        "Tenemos diferentes horarios disponibles para adaptarnos a tu disponibilidad",
      color: null,
      backgroundColor: null,
      data: "Horarios",
      highlight: null,
      assetUrl: "https://wuolah-web.s3.eu-west-1.amazonaws.com/wuolads/academy-feature-assets/watch_icon.png",
      actionText: "Saber más",
      actionUrl: null,
      id: 1,
    }),
    (this.dataFeature = {
      title: "Profesores expertos",
      description:
        "Nuestro profesorado está cualificado y entrenado en la materia",
      color: null,
      backgroundColor: null,
      data: "Profesorado/alumnado",
      highlight: null,
      assetUrl: "https://wuolah-web.s3.eu-west-1.amazonaws.com/wuolads/academy-feature-assets/teacher_icon.png",
      actionText: "Saber más",
      actionUrl: null,
      id: 2,
    }),
    (this.dataFeature = {
      title: "Equipos nuevos",
      description:
        "Utilizamos las herramientas y los métodos de enseñanza más novedosos",
      color: null,
      backgroundColor: null,
      data: "Tecnologías",
      highlight: null,
      assetUrl: "https://wuolah-web.s3.eu-west-1.amazonaws.com/wuolads/academy-feature-assets/laptop_icon.png",
      actionText: "Saber más",
      actionUrl: null,
      id: 3,
    }),
    (this.dataFeature = {
      title: "Certificados oficiales",
      description:
        "Tenemos el certificado oficial de centro de estudios de Google",
      color: null,
      backgroundColor: null,
      data: "Certificados/premios",
      highlight: null,
      assetUrl: "https://wuolah-web.s3.eu-west-1.amazonaws.com/wuolads/academy-feature-assets/certificate_icon.png",
      actionText: "Saber más",
      actionUrl: null,
      id: 4,
    }),
    (this.dataFeature = {
      title: "Título",
      description: "Descripción del contenido de la tarjeta",
      color: null,
      backgroundColor: null,
      data: 'Créalo tú',
      highlight: null,
      assetUrl: null,
      actionText: "Saber más",
      actionUrl: null,
      id: 5,
    }),
  ];

  iconCheckbox: string = "radio_button_unchecked";
  colorCheckbox: string = "#111111";

  @Output() onClose = new EventEmitter();
  @Output() onOpen = new EventEmitter();
  @Output() onModifyModal = new EventEmitter();

  file_aplication = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg"];

  previewSRC: any;

  uploadingMode: string;

  originals = {
    logo: "",
    background: "",
  };

  checkInputs = {
    inputName: this.dataGeneralInformation.name,
    inputTitle: this.dataGeneralInformation.title,
    // inputLogo: this.dataGeneralInformation.logoUrl,
    inputBackground: this.dataGeneralInformation.backgroundUrl,
    inputDescription: this.dataGeneralInformation.description,
    inputPhone: this.contacts[0].value,
    inputEmail: this.contacts[1].value,
    inputWeb: this.contacts[2].value,
    inputSubtitle: this.dataGeneralInformation.subtitle,
    inputFeature: null,
  };

  checkError = false;
  checkErrorPhone = false;
  checkErrorWeb = false;
  checkErrorMail = false;
  count = 0;
  
  uuid: string;
  userId: string;
  data: any;

  widgets = [];

  filters: any = {
    actionText: null,
    actionUrl: null,
    backgroundUrl: null,
    centerId: null,
    course: null,
    description: null,
    folderId: null,
    logoUrl: null,
    name: null,
    primaryBgColor: null,
    primaryColor: null,
    secondaryBgColor: null,
    secondaryColor: null,
    status: 0,
    studyId: null,
    subtitle: null,
    title: null,
    userId: null
  };

  signatures: any;

  config: any = [];

  reloadWidget: number = 0;

  constructor(private router: Router, 
    public utilsService: UtilsService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
    public userService: UserService,
    public sponsorSubjectService: SponsorSubjectService,
  ) {
    let checkStorageInformation = JSON.parse(
      localStorage.getItem("dataGeneralInformation")
    );

    if (checkStorageInformation) {
      this.dataGeneralInformation = checkStorageInformation;
      this.checkInputs.inputName = this.dataGeneralInformation.name;
      this.checkInputs.inputTitle = this.dataGeneralInformation.title;
      // this.checkInputs.inputLogo = this.dataGeneralInformation.logoUrl;
      this.checkInputs.inputBackground = this.dataGeneralInformation.backgroundUrl;
      this.checkInputs.inputDescription =
        this.dataGeneralInformation.description;
      this.checkInputs.inputSubtitle = this.dataGeneralInformation.subtitle;
    }

    this.utilsService.publish(
      "change_widget_general_data",
      this.dataGeneralInformation
    );

    let checkStorageButtons = JSON.parse(localStorage.getItem("buttons"));

    if (checkStorageButtons) {
      this.buttons = checkStorageButtons;
    }

    this.utilsService.publish("change_widget_buttons_data", this.buttons);

    let checkStorageContacts = JSON.parse(localStorage.getItem("contacts"));

    if (checkStorageContacts) {
      this.contacts = checkStorageContacts;
      this.checkInputs.inputPhone = this.contacts[0].value;
      this.checkInputs.inputEmail = this.contacts[1].value;
      this.checkInputs.inputWeb = this.contacts[2].value;
    }

    this.utilsService.publish("change_widget_contacts_data", this.contacts);

    let checkStorageFeatures = JSON.parse(localStorage.getItem("features"));

    if (checkStorageFeatures) {
      this.features = checkStorageFeatures;
    }

    this.userId = this.storageService.read('userId');
    this.uuid = this.storageService.read('uuid');

    this.utilsService.publish("change_widget_features_data", this.features);

    this.checkDisabled();

    this.data = this.storageService.read('advertiserMe');

  }

  checkDisabled(){
    this.testFeature();

    if (this.count == 0) {
      this.checkInputs.inputFeature = false;
    }
    
    return this.dataGeneralInformation.name == null || this.dataGeneralInformation.backgroundUrl == null || this.dataGeneralInformation.title == null || this.dataGeneralInformation.description == null || this.dataGeneralInformation.subtitle == null || this.contacts[0].value == null || this.contacts[1].value == null || this.contacts[2].value == null || this.checkInputs.inputFeature || this.checkInputs.inputFeature == null;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Diseño del patrocinio de asignatura | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/sponsor/subject/2', 'Subject sponsorship design');
    this.analyticService.screenView('Subject sponsorship design');

    const user: any = this.storageService.read('userMe');

    this.analyticService.customEvent(
      'UP2_03_1_VISIT',
      {
        uuid: this.uuid,
        userId: this.userId,
      }
    );

    this.checkInputs.inputFeature = null;

    let checkStorageInformation = JSON.parse(localStorage.getItem("signatures"));

    if (checkStorageInformation == null) {
      this.router.navigate(["dashboard/sponsor/subject/1"]);
    }

    this.signatures = this.storageService.read('signatures');

    this.widgets = this.storageService.read('widgets');

    if (!this.widgets || this.widgets == null || this.widgets.length < 1) {
      this.widgets = [];

      this.createWidgets().then((response) => {
        
        let data = response;
        this.storageService.write('widgets', data);
        
      });
    }
  }

  openMediaInput(mode: string) {
    this.uploadingMode = mode;
    this.mediaInput.nativeElement.click();
  }

  async handleFile(event) {
    let files: FileList = event.srcElement.files;

    if (!this.file_aplication.includes(files[0]?.type)) {
      if (files[0]?.type) {
        this.utilsService.presentAlertMessage({
          title: "La imagen debe ser PNG, JPG, JPEG o PJPEG",
        }); 
      }

      this.mediaInput.nativeElement.value = "";
    } else {
      if (files[0].size > 2000000) {
        this.utilsService.presentAlertMessage({
          title: "La imagen no puede ocupar más de 2 MB",
        });

        this.mediaInput.nativeElement.value = "";
      } else {
        // if (this.uploadingMode == "logo") {
        //   this.dataGeneralInformation.logoUrl = files[0].name;
        // } else if (this.uploadingMode == "background") {
        //   this.dataGeneralInformation.backgroundUrl = files[0].name;
        // }

        
        const data: any = {};
        const file = event.target.files[0];
        
        const lastDot = file.name.lastIndexOf('.');
        const extension = file.name.substring(lastDot + 1);;

        data[this.uploadingMode] = {
          extension: extension,
          contentType: file.type
        };
        if (this.widgets.length < 1) {
          
          await this.createWidgets().then(() => {
  
            setTimeout(() => {
  
              for (let i = 0; i < this.widgets.length; i++) {
              
                this.sponsorSubjectService.updateWidget(this.widgets[i].id, data).then(
                  (response) => {
                    if (response.logoUrl) {
                      this.uploadToS3(response.logoUrl, file.type, file).then(()=>{

                        this.dataGeneralInformation.logoUrl = response.logoUrl.split('?')[0];

                        this.utilsService.publish("change_widget_general_data", this.dataGeneralInformation);

                        this.reloadWidget += 1;
                        this.utilsService.publish("reload_widget", this.reloadWidget);
                      });
                    }else if(response.backgroundUrl){
                      this.uploadToS3(response.backgroundUrl, file.type, file).then(() => {
                        this.dataGeneralInformation.backgroundUrl = response.backgroundUrl.split('?')[0];
                        this.dataGeneralInformation.logoUrl = response.backgroundUrl.split('?')[0];

                        this.utilsService.publish("change_widget_general_data", this.dataGeneralInformation);

                        this.reloadWidget += 1;
                        this.utilsService.publish("reload_widget", this.reloadWidget);
                      });
                    }
        
                    this.widgets[i] = Object.assign({}, response.widget);
                    this.config[i] = Object.assign({}, response.widget);        
                    this.mediaInput.nativeElement.value = null;
        
                    this.utilsService.loading('close');
                  }
                ).catch(
                  (error) => {
                    this.utilsService.presentMessage('Ha ocurrido un error');
                    this.utilsService.loading('close');
                  }
                );
              }
            }, 500);
  
            
  
          });
        }else{
          for (let i = 0; i < this.widgets.length; i++) {
          
            this.sponsorSubjectService.updateWidget(this.widgets[i].id, data).then(
              (response) => {
                if (response.logoUrl) {
                  this.uploadToS3(response.logoUrl, file.type, file).then(() => {

                    this.dataGeneralInformation.logoUrl = response.logoUrl.split('?')[0];

                    this.utilsService.publish("change_widget_general_data", this.dataGeneralInformation);

                    this.reloadWidget += 1;
                    this.utilsService.publish("reload_widget", this.reloadWidget);
                  });
                }else if(response.backgroundUrl){
                  this.uploadToS3(response.backgroundUrl, file.type, file).then(() => {
                    this.dataGeneralInformation.backgroundUrl = response.backgroundUrl.split('?')[0];
                    this.dataGeneralInformation.logoUrl = response.backgroundUrl.split('?')[0];

                    this.utilsService.publish("change_widget_general_data", this.dataGeneralInformation);

                    this.reloadWidget += 1;
                    this.utilsService.publish("reload_widget", this.reloadWidget);
                  });
                }
    
                this.widgets[i] = Object.assign({}, response.widget);
                this.config[i] = Object.assign({}, response.widget);
    
                this.mediaInput.nativeElement.value = null;
    
                this.utilsService.loading('close');
              }
            ).catch(
              (error) => {
                this.utilsService.presentMessage('Ha ocurrido un error');
                this.utilsService.loading('close');
              }
            );
          }
        }
        
        this.storageService.write('widgets', this.widgets);

        // this.onSelectFile(event, this.uploadingMode);
      }
    }
  }

  async uploadToS3(url: string, type: any, file: any) {
    const response = await this.sponsorSubjectService.uploadToS3(url, type, file);
  }

  async createWidgets(){
          
    for (let i = 0; i < this.signatures.length; i++) {

      this.filters.folderId = parseInt(this.signatures[i].folderId);
      this.filters.centerId = parseInt(this.signatures[i].centerId);
      this.filters.course = parseInt(this.signatures[i].course);
      this.filters.studyId = parseInt(this.signatures[i].studyId);
      this.filters.userId = parseInt(this.userId);
      this.filters.name = this.userId;
      
      await this.sponsorSubjectService.createWidget(this.filters).then(
        (response) => {
          this.widgets.push(response);

          this.utilsService.loading('close');
        }
      ).catch(
        (error) => {
          this.widgets = [];
          
          this.utilsService.loading('close');
        }
      );
            
    }

    return this.widgets;
  }

  onSelectFile(event, mode) {
    // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.originals[mode] = event.target.result;
        this.previewSRC = event.target.result;
        if (this.uploadingMode === "logo") {
          // this.dataGeneralInformation.logoUrl = this.previewSRC;
        } else if (this.uploadingMode === "background") {
          this.dataGeneralInformation.backgroundUrl = this.previewSRC;
          this.dataGeneralInformation.logoUrl = this.previewSRC;
        }
      };
    }
  }

  modifyModal(i) {
    setTimeout(() => {
      this.onModifyModal.emit(i);
    }, 150);
  }

  testFeature() {
    
    let e = 0;

    for (let index = 0; index < this.features.length; index++) {
      if (this.features[index].highlight !== 1) {
        e++;
      }
    }
    if (e <= 5) {
      this.checkInputs.inputFeature = false;
    } else {
      this.checkInputs.inputFeature = true;
    }
  }

  checkInput() {
    // this.checkInputs.inputLogo = this.dataGeneralInformation.logoUrl;
    this.checkInputs.inputBackground = this.dataGeneralInformation.backgroundUrl;

    let e = 0;

    this.testFeature();

    if (this.checkInputs.inputFeature) {
      e = 1;
    }

    for (let i in this.checkInputs) {
      if (this.checkInputs[i] === null) {
        e++;
      }
    }

    if (e == 0) {
      this.checkError = false;
      if(!this.utilsService.validatePhoneNumber(this.checkInputs.inputPhone)){
        this.checkErrorPhone = true;
      }else{
        this.checkErrorPhone = false;
      }

      if (!this.utilsService.validateEmail(this.checkInputs.inputEmail)) {
        this.checkErrorMail = true;
      }else{
        this.checkErrorMail = false;
      }

      let web = this.checkInputs.inputWeb;
      if (!this.checkInputs.inputWeb?.includes("https://") && !this.checkInputs.inputWeb?.includes("http://")) {
        web = "https://" + this.checkInputs.inputWeb;
        this.checkInputs.inputWeb = web;
        this.buttons[0].actionUrl = web;
        this.contacts[2].actionUrl = web;
      }

      this.checkErrorWeb = false;

      if (!web?.match('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})')) {
        this.checkErrorWeb = true;
      }

    } else {
      this.checkError = true;
    }

    if (!this.checkError && !this.checkErrorMail && !this.checkErrorPhone && !this.checkErrorWeb) {
      this.goToCreativities();
    }
  }

  getWhatsappUrl(number?) {
    this.buttons[1].actionUrl = "https://api.whatsapp.com/send?phone=34" + number;
  }

  goToCreativities() {
    this.getWhatsappUrl(this.buttons[1].actionUrl);
    
    if (!this.checkError) {
      this.router.navigate(["dashboard/sponsor/subject/3"]);

      localStorage.setItem(
        "dataGeneralInformation",
        JSON.stringify(this.dataGeneralInformation)
      );

      localStorage.setItem("buttons", JSON.stringify(this.buttons));

      localStorage.setItem("contacts", JSON.stringify(this.contacts));

      localStorage.setItem("features", JSON.stringify(this.features));
    }
  }

  goToSegmentation() {
    this.getWhatsappUrl(this.buttons[1].actionUrl);

    this.router.navigate(["dashboard/sponsor/subject/1"]);

    localStorage.setItem(
      "dataGeneralInformation",
      JSON.stringify(this.dataGeneralInformation)
    );

    localStorage.setItem("buttons", JSON.stringify(this.buttons));

    localStorage.setItem("contacts", JSON.stringify(this.contacts));

    localStorage.setItem("features", JSON.stringify(this.features));
  }
}
