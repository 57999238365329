<div class="container">
  <div class="overlay" (click)="close()"></div>

  <div class="modal-container">
    <div class="close-modal">
      <app-button-item
       [title]="'Close'"
       [onlyIcon]="true"
       [icon]="'close'"
       [color]="'#111111'"
       (fireClick)="close()"
      ></app-button-item>
    </div>
    
    <p>¿Quieres guardar tu campaña en borradores?</p>
    
    <span>Si no guardas el proceso de tu campaña se perderán todos los cambios.</span>
    
    <div class="modal-buttons">
      <app-button-item
       [title]="'Sí'"
       [text]="'Sí'"
       [outlined]="true"
       [borderColor]="'#b6b6b6'"
       [color]="'#111111'"
       (click)="gotToDashboardSave()"
      ></app-button-item>

      <app-button-item
       [title]="'No'"
       [text]="'No'"
       [solid]="true" 
       (click)="gotToDashboard()"     
      ></app-button-item>

      <app-button-item
       [title]="'Cancelar'"
       [text]="'Cancelar'"
       [solid]="true"
       (fireClick)="close()"
       ></app-button-item>
    </div>
  </div>
</div>
