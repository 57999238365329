<div class="body-container">
  <h1 class="wds-h1">Generar takeover params</h1>

  <div class="form-container">
    <div class="item-input">
      <div class="labels">
        <label>Creative ID</label>
        <small>
          ID de la creatividad de Ad Manager
        </small>
      </div>
  
      <div class="input-container">
        <input [disabled]="output && output !== ''" [(ngModel)]="creativeId" type="number" maxlength="15">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Logo del nav</label>
        <small>
          Logo que aparece a la derecha del de Wuolah, las medidas deben ser 300x55px
        </small>
      </div>
  
      <div class="input-container">
        <input [disabled]="output && output !== ''" accept="image/jpg, image/png, image/jpeg" #file_rf_nav_logo (change)="onFileSelected('rf_nav_logo', $event)" type="file">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Logo del widget</label>
        <small>
          Logo que aparece encima del video, widget CTA, las medidas de la imagen deben ser 50x50px
        </small>
      </div>
  
      <div class="input-container">
        <input [disabled]="output && output !== ''" accept="image/jpg, image/png, image/jpeg" #file_rf_widget_logo (change)="onFileSelected('rf_widget_logo', $event)" type="file">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Texto del widget</label>
        <small>
          Texto que aparece encima del video, widget CTA, debe ser un texto de máximo 15 caracteres
        </small>
      </div>
  
      <div class="input-container">
        <input [disabled]="output && output !== ''" [(ngModel)]="params.rf_widget_text" type="text" maxlength="15">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Nombre de la marca</label>
        <small>
          Nombre comercial de la marca, esto no aparecerá en ningún sitio visualmente, debe ser un texto de máximo 30 caracteres
        </small>
      </div>
  
      <div class="input-container">
        <input [disabled]="output && output !== ''" [(ngModel)]="params.rf_trade_name" type="text" maxlength="30">
      </div>
    </div>

    <div *ngIf="output" class="output-container">
      {{output}} 

      <app-button-item [text]="'Copiar'" (click)="copy()" [disabled]="!output" [solid]="true"></app-button-item>
    </div>

    <div class="buttons-container">
      <app-button-item [text]="'Limpiar formulario'" (click)="clear()" [borderColor]="'#111111'" [color]="'#111111'" [outlined]="true"></app-button-item>
      <app-button-item [text]="'Generar parámetros'" (click)="send()" [disabled]="!params.rf_nav_logo || !params.rf_widget_logo || !params.rf_widget_text || !params.rf_trade_name || !creativeId || output" [solid]="true"></app-button-item>
    </div>
  </div>
</div>