<div class="design-container">
  <div class="form-container">
    <h4>Diseñar creatividades</h4>
    <p>
      Elige entre 2 opciones para la publicidad de tu academia: subir tus
      propias creatividades o diseñar las creatividades desde Wuolahads
    </p>
    <div class="forms-item">
      <div class="creativity">
        <app-design-creatives-item
          class="cardUpload"
          [ngClass]="{uploadActive: uploadCreativities === false}"
          [iconMaterial]="'edit'"
          [title]="'Elige uno de nuestros diseños'"
          [description]="
            'Rellena el siguiente formulario y agrega los diferentes componentes para crear tu creatividad.'"
          (click)="designCreativities(false)"
        ></app-design-creatives-item>
        <app-design-creatives-item
          class="cardUpload"
          [ngClass]="{uploadActive: uploadCreativities === true}"
          [title]="'Subir tus propias creatividades'"
          [iconMaterial]="'file_download'"
          [description]="
            'Descarga las plantillas .psd y agrega tus propios diseños a la publicidad'"
          (click)="designCreativities(true); dataUpload.portrait= null; dataUpload.landscape= null"
        ></app-design-creatives-item>
        <div class="design-later" (click)="designLater()">
          <span>Diseñar más tarde</span>
        </div>
      </div>
      <div *ngIf="uploadCreativities === false" class="inputs-form">
        <div class="fill-input">
          <div class="input-element specific-label">
            <label class="input-element-label"
              >Elige el tema/diseño que más te guste</label
            >
          </div>
        </div>
        <div class="choose-group">
          <div class="choose-item">
            <div
              (click)="chosenTheme(0)"
              class="checkbox-custom-selector"
              [ngClass]="{
                'checkbox-custom-selector-selected': theme[0] !== 0
              }"
            >
              <i class="material-icons-outlined">done</i>
            </div>
            <span>Índigo</span>
          </div>
          <div class="choose-item">
            <div
              (click)="chosenTheme(1)"
              class="checkbox-custom-selector"
              [ngClass]="{
                'checkbox-custom-selector-selected': theme[1] !== 0
              }"
            >
              <i class="material-icons-outlined">done</i>
            </div>
            <span>Mondrian</span>
          </div>
          <div class="choose-item">
            <div
              (click)="chosenTheme(2)"
              class="checkbox-custom-selector"
              [ngClass]="{
                'checkbox-custom-selector-selected': theme[2] !== 0
              }"
            >
              <i class="material-icons-outlined">done</i>
            </div>
            <span>Light</span>
          </div>
          <div class="choose-item">
            <div
              (click)="chosenTheme(3)"
              class="checkbox-custom-selector"
              [ngClass]="{
                'checkbox-custom-selector-selected': theme[3] !== 0
              }"
            >
              <i class="material-icons-outlined">done</i>
            </div>
            <span>Minimal</span>
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Título superior</label>
            <input
              placeholder="Introduce el título superior de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.topLine"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Título inferior</label>
            <input
              placeholder="Introduce el título inferior de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.bottomLine"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label"
              >Subtítulo/Información adicional</label
            >
            <input
              placeholder="Introduce el subtítulo/información adicional de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.subtitle"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Web</label>
            <input
              placeholder="Introduce la web de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.web"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Teléfono</label>
            <input
              placeholder="Introduce el teléfono de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.phone"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Botón</label>
            <input
              placeholder="Introduce el nombre del botón de tu anuncio"
              type="text"
              [(ngModel)]="creativityData.cta"
            />
          </div>
        </div>
        <div class="fill-input">
          <div class="input-element">
            <label class="input-element-label">Logo</label>

            <div
              class="upload-file"
              (click)="openMediaInput('logo')"
              [ngClass]="{
                uploadFileActive:
                  creativityData &&
                  creativityData.logo &&
                  creativityData.logo !== ''
              }"
            >
              <app-button-item
                [title]="'Añadir logo'"
                [onlyIcon]="true"
                [icon]="
                  creativityData &&
                  creativityData.logo &&
                  creativityData.logo !== ''
                    ? 'task_alt'
                    : 'add_circle_outline'
                "
                [color]="
                  creativityData &&
                  creativityData.logo &&
                  creativityData.logo !== ''
                    ? '#0055FF'
                    : '#888888'
                "
              ></app-button-item>

              <span>{{
                creativityData &&
                creativityData.logo &&
                creativityData.logo !== ""
                  ? "Añadido"
                  : "Añadir logo"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="uploadCreativities === true" class="upload-creativity">
        <div class="first-step">
          <span>Primer paso</span>
          <p>
            El primer paso es descargar las plantillas de los 2 formatos y
            diseñar en el espacio disponible tu publicidad
          </p>
          <app-button-item
            [title]="'Descargar las plantillas'"
            [icon]="'file_download'"
            [outlined]="true"
            [text]="'Descargar las plantillas'"
            [color]="'#001540'"
            (click)="downloadPSD()"
          ></app-button-item>
        </div>
        <div class="upload-items">
          <span class="creativiy">Creatividad</span>
          <div class="input-element">
            <div
              class="upload-file"
              (click)="openMediaInput('portrait')"
              [ngClass]="{
                uploadFileActive:
                  dataUpload &&
                  dataUpload.portrait &&
                  dataUpload.portrait !== ''
              }"
            >
              <app-button-item
                [title]="'Añadir formato vertical'"
                [onlyIcon]="true"
                [icon]="
                  dataUpload &&
                  dataUpload.portrait &&
                  dataUpload.portrait !== ''
                    ? 'task_alt'
                    : 'add_circle_outline'
                "
                [color]="
                  dataUpload &&
                  dataUpload.portrait &&
                  dataUpload.portrait !== ''
                    ? '#0055FF'
                    : '#888888'
                "
              ></app-button-item>
              <span>{{
                dataUpload && dataUpload.portrait && dataUpload.portrait !== ""
                  ? "Añadido"
                  : "Añadir formato vertical"
              }}</span>
              <!-- <input placeholder="javier@tuempresa.com" type="file"> -->
            </div>
          </div>
          <div class="input-element">
            <div
              class="upload-file"
              (click)="openMediaInput('landscape')"
              [ngClass]="{
                uploadFileActive:
                  dataUpload &&
                  dataUpload.landscape &&
                  dataUpload.landscape !== ''
              }"
            >
              <app-button-item
                [title]="'Añadir formato horizontal'"
                [onlyIcon]="true"
                [icon]="
                  dataUpload &&
                  dataUpload.landscape &&
                  dataUpload.landscape !== ''
                    ? 'task_alt'
                    : 'add_circle_outline'
                "
                [color]="
                  dataUpload &&
                  dataUpload.landscape &&
                  dataUpload.landscape !== ''
                    ? '#0055FF'
                    : '#888888'
                "
              ></app-button-item>
              <span>{{
                dataUpload &&
                dataUpload.landscape &&
                dataUpload.landscape !== ""
                  ? "Añadido"
                  : "Añadir formato horizontal"
              }}</span>
              <!-- <input placeholder="javier@tuempresa.com" type="file"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <app-button-item
      [title]="'Atrás'"
      [color]="'#111111'"
      [borderColor]="'#b6b6b6'"
      [text]="'Atrás'"
      [outlined]="true"
      (fireClick)="goToDesign()"
    ></app-button-item>
    <app-button-item
      [text]="'Siguiente'"
      [background]="'#001540'"
      [solid]="true"
      (fireClick)="uploadCreativities === true ? goToPayment('upload') : goToPayment('design')"
      [disabled]=" uploadCreativities === true ?
        dataUpload?.portrait === null || dataUpload?.landscape === null : creativityData?.topLine === null
        || creativityData?.bottomLine === null || creativityData?.subtitle === null || creativityData?.web === null
        || creativityData?.phone === null || creativityData?.cta === null || creativityData?.logo === null
      "
    >
    </app-button-item>
  </div>
</div>

<input type="file" accept="image/*" #mediaInput (change)="handleFile($event)" style="display: none;">
<a style="display: none;" #downloadTemplateHor href="./../../../assets/templates/APUNTES - lateral-pdf-horizontal.psd" download=""></a>
<a style="display: none;" #downloadTemplateVer href="./../../../assets/templates/APUNTES - lateral-pdf-vertical.psd" download=""></a>
    
<div class="custom-file">
  <div>
    <image-cropper
      [imageFile]="imageChangedEventPortrait"
      [cropperStaticWidth]="upperCroppedWidthPortrait"
      [cropperStaticHeight]="upperCroppedHeightPortrait"
      [cropperMaxHeight]="upperCroppedHeightPortrait"
      [cropper]="upperPortrait"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'upper','vertical')"
      (imageLoaded)="imageLoaded('vertical')"
      (cropperReady)="cropperReady($event,'vertical')"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>

  <div>
    <image-cropper
      [imageFile]="imageChangedEventPortrait_2"
      [cropper]="sidePortrait"
      [cropperStaticWidth]="sideCroppedWidthPortrait"
      [cropperStaticHeight]="sideCroppedHeightPortrait"
      [cropperMaxWidth]="sideCroppedWidthPortrait"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'side','vertical')"
      (imageLoaded)="imageLoaded('vertical')"
      (cropperReady)="cropperReady($event,'vertical')"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <div>
    <image-cropper
      [imageFile]="imageChangedEventLandscape"
      [cropperStaticWidth]="upperCroppedWidthLandscape"
      [cropperStaticHeight]="upperCroppedHeightLandscape"
      [cropperMaxHeight]="upperCroppedHeightLandscape"
      [cropper]="upperLandscape"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'upper','horizontal')"
      (imageLoaded)="imageLoaded('horizontal')"
      (cropperReady)="cropperReady($event,'horizontal')"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>

  <div>
    <image-cropper
      [imageFile]="imageChangedEventLandscape_2"
      [cropper]="sideLandscape"
      [cropperStaticWidth]="sideCroppedWidthLandscape"
      [cropperStaticHeight]="sideCroppedHeightLandscape"
      [cropperMaxWidth]="sideCroppedWidthLandscape"
      [style.display]="showCropper ? null : 'none'"
      format="jpeg"
      (imageCropped)="imageCropped($event, 'side', 'horizontal')"
      (imageLoaded)="imageLoaded('horizontal')"
      (cropperReady)="cropperReady($event, 'horizontal')"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</div>