import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class TargetService extends RequestService {
  getTargets() {
    return this.request(
      this.httpClient.get(this.environment.API + "/pub/advertiser/me", {
        headers: this.headers(),
      })
    );
  }

  createTarget(target: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser/me/target",
        target,
        { headers: this.headers() }
      )
    );
  }

  getSegmentations(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/browser", params),
        { headers: this.headers() }
      )
    );
  }

  searchTypeStudy(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/study/typeStudy", params),
        { headers: this.headers() }
      )
    );
  }
}
