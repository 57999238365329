<div class="payment-container">
  <div class="form-container">
    <h4>Pago</h4>
    <p>Introduce tus datos de facturación y revisa el resumen de tu pedido</p>
    <div class="forms-item">
      <div class="choose-payment">
        <div
          *ngFor="let paymentItem of paymentItems"
          [ngClass]="
            paymentItem.choosen
              ? 'choose-payment-item payment-chosen'
              : 'choose-payment-item'
          "
          class="choose-payment-item"
          (click)="chooseItem(paymentItem); throwPaymentData()"
        >
          <div *ngIf="paymentItem?.feature == 1" class="favourite">
            <div>
              <span class="material-icons"> star_border </span>
            </div>
          </div>
          <p>{{ paymentItem?.name }}</p>
          <div *ngIf="!paymentItem?.discount" class="line-through"><br></div>
          <span *ngIf="paymentItem?.discount" class="line-through"
            >{{ paymentItem?.price }}€</span
          >
          <span class="final-price">{{ getFinalPrice(paymentItem) }}€</span>
        </div>
      </div>
      <div *ngIf="!noCards" class="choose-card">
        <div class="choose-card-title">
          <span>Usar una tarjeta guardada </span>
          <app-button-item
            [title]="'Añadir nueva'"
            [color]="'#002778'"
            [borderColor]="'#002778'"
            [text]="'Añadir nueva'"
            [outlined]="true"
            (fireClick)="addNewCard = true"
          ></app-button-item>
        </div>

        <div class="choose-your-card"  (click)="setDefaultCard(card); checkCard(card)" *ngFor="let card of userPaymentMethod">
          <div class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': checkCard(card)}">
            <i class="material-icons-outlined">done</i>
          </div>
          <img src="{{selectImage(card?.card?.brand)}}" alt="logo">
          <span>xxxx xxxx xxxx {{card?.card?.last4}}</span>
        </div>
      </div>
      <div *ngIf="noCards || addNewCard" [ngClass]="{addNewCard: addNewCard}" class="inputs-form">
        <div *ngIf="addNewCard" class="newCardOverlay"  (click)="addNewCard = false"></div>
        <div [ngClass]="{addNewCardContainer: addNewCard}">
          <form>
            <div class="closeAddCard">
              <app-button-item
                *ngIf="addNewCard"
                [onlyIcon]="true"
                [title]="'Cerrar'"
                [icon]="'close'"
                [color]="'#111111'"
                (fireClick)="addNewCard = false"
              ></app-button-item>
            </div>

            <div class="fill-input">
              <div class="input-element">
                <label for="firstName" class="input-element-label">Nombre</label>
                <input name="firstName" placeholder="John" type="text" autocomplete="given-name" [(ngModel)]="user.name" />
              </div>
            </div>
            <div class="fill-input">
              <div class="input-element">
                <label for="lastName" class="input-element-label">Apellidos</label>
                <input name="lastName" placeholder="Doe" type="text" autocomplete="family-name" [(ngModel)]="user.surname" />
              </div>
            </div> 
            <div class="input-element">
              <label for="card" class="input-element-label">Tarjeta</label>
              <div class="stripe-card">
                <ngx-stripe-card
                  [options]="cardOptions"
                  [elementsOptions]="elementsOptions"
                ></ngx-stripe-card>
              </div>
            </div>
            <div class="check-method-payment" *ngIf="userPaymentMethod.length > 0" (click)="checkMethodStatus === false ? checkMethodStatus = true : checkMethodStatus = false">
              <div class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': checkMethodStatus == true}">
                <i class="material-icons-outlined">done</i>
              </div>
              <span>Guardar este método de pago como método predeterminado</span>
            </div>
            
            <div class="add-method-payment" *ngIf="userPaymentMethod.length > 0">
              <app-button-item
                [text]="'Añadir como método de pago'"
                [background]="'#001540'"
                [solid]="true"
                (fireClick)="createPaymentMethod()"
                ></app-button-item>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <app-button-item
      [text]="'Pagar'"
      [background]="'#001540'"
      [solid]="true"
      (fireClick)="pay()"
    >
    </app-button-item>
  </div>
</div>
