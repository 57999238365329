<div class="body-container">
  <h1 class="wds-h1">Configuración</h1>
  <div class="data-section">
    <h3 class="wds-h3">Datos del acceso</h3>
    <p class="wds-text">Administrar cuenta y datos de acceso.</p>
  
    <div class="item-input">
      <div class="labels">
        <label>Correo electrónico</label>
        <small>
          Indique el correo electrónico nuevo.
        </small>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="newMail" (keypress)="onKeypressEvent($event, 'access')" placeholder="example@domain.com....">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Contraseña</label>
        <small>
          Escriba la contraseña actual.
        </small>
      </div>
  
      <div class="input-container">
        <input class="input-password" type="password" [(ngModel)]="password" (keypress)="onKeypressEvent($event, 'access')" placeholder="******">
        <app-button-item class="button-password" (fireClick)="changePassword()" [solid]='true' [size]='"small"' [text]="'Cambiar'"></app-button-item>
      </div>
    </div>

    <div *ngIf="disabledchangePassword" class="item-input">
      <div class="labels">
        <small>
          Escriba la nueva contraseña.
        </small>
      </div>

      <div class="input-container">
        <input type="password" [(ngModel)]="newPassword" (keypress)="onKeypressEvent($event, 'access')" placeholder="Nueva contraseña">
      </div>
    </div>

    <div class="button-container">
      <app-button-item [text]="'Guardar'" [solid]="true" [disabled]="disabledAccessData" (fireClick)="saveAccountInfo()"></app-button-item>
    </div>

    <hr>

  </div>

  <div class="data-section">
    <h3 class="wds-h3">Perfil de cliente</h3>
    <p class="wds-text">Administrar la información de contacto.</p>
  
    <div class="item-input">
      <div class="labels">
        <label>Nombre y apellidos</label>
        <small>
          Indique el nombre completo de la persona de contacto.
        </small>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.contactName" (keypress)="onKeypressEvent($event, 'profile')" placeholder="Jhon Smith....">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Correo electrónico</label>
        <small>
          Correo electrónico de la persona de contacto.
        </small>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.contactMail" (keypress)="onKeypressEvent($event, 'profile')" placeholder="example@domain.com....">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Ciudad</label>
        <small>
          Ciudad donde se encuentra su negocio.
        </small>
      </div>
  
      <div class="input-container">
        <select [(ngModel)]="city.name" name="cityId" id="cityId">
          <option disabled name="'Selecciona tu ciudad'" [value]="-1">Selecciona tu ciudad</option>
          <option *ngFor="let city of cities" [value]="city?.id" >{{city?.name}}</option>
        </select>      
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Teléfono</label>
        <small>
          Teléfono de contacto
        </small>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.phoneNumber" pattern="(^\+?(6\d{2}|7[1-9]\d{1})\d{6})|^[0-9]{2,3}-? ?[0-9]{6,7}$"  (keypress)="onKeypressEvent($event, 'profile')" placeholder="+34">
      </div>
    </div>

    <div class="button-container">
      <app-button-item (fireClick)="saveContactInfo()" [text]="'Guardar'" [solid]="true" [disabled]="disabledProfileData"></app-button-item>
    </div>

    <hr>

  </div>

  <div class="data-section">
    <h3 class="wds-h3">Facturación</h3>
    <p class="wds-text">Administrar información fiscal.</p>
  
    <div class="item-input">
      <div class="labels">
        <label>Nombre de la empresa</label>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.name" (keypress)="onKeypressEvent($event, 'tax')" placeholder="Nombre de la empresa">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>NIF</label>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.nif" (keypress)="onKeypressEvent($event, 'tax')" placeholder="Número de identificación fiscal....">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Dirección 1</label>
      </div>
  
      <div class="input-container">
        <input  [(ngModel)]="advertiserMe.address1" (keypress)="onKeypressEvent($event, 'tax')" placeholder="'Avenida, Calle, Distrito...'">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Dirección 2</label>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.address2" (keypress)="onKeypressEvent($event, 'tax')" placeholder="Número, Bloque, Edificio, Escalera, Piso, Puerta...">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Código postal</label>
      </div>
  
      <div class="input-container">
        <input [(ngModel)]="advertiserMe.postalCode" (keypress)="onKeypressEvent($event, 'tax')" placeholder="41011...">
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>Provincia</label>
      </div>
  
      <div class="input-container">
        <select [(ngModel)]="advertiserMe.city" name="cityId" id="cityId">
          <option disabled name="'Selecciona tu provincia" [value]="-1">Selecciona tu provincia</option>
          <option *ngFor="let city of cities" [value]="city?.name" >{{city?.name}}</option>
        </select>
      </div>
    </div>
  
    <div class="item-input">
      <div class="labels">
        <label>País</label>
      </div>
  
      <div class="input-container">
        <select [(ngModel)]="advertiserMe.country" name="countryId" id="countryId">
          <option disabled name="'Selecciona tu país'" [value]="-1">Selecciona tu país</option>
          <option *ngFor="let country of countries" [value]="country?.name" >{{country?.name}}</option>
        </select>
      </div>
    </div>

    <div class="button-container">
      <app-button-item (fireClick)="saveTaxInfo()" [text]="'Guardar'" [solid]="true" [disabled]="disabledTaxData && !advertiserMe.country && !advertiserMe.city"></app-button-item>
    </div>

    <hr>

  </div>

  <div class="data-section">
    <h3 class="wds-h3">Ayuda</h3>
    <p class="wds-text">Contacta con nosotros.</p>
  
    <div class="item-input">
      <div class="labels">
        <label>Teléfono de contacto</label>
      </div>
  
      <div class="input-container">
        <input value="+34 645 610 987" disabled="true">
      </div>
    </div>

    <div class="item-input">
      <div class="labels">
        <label>Correo electrónico de contacto</label>
      </div>
  
      <div class="input-container">
        <input value="info@wuolah.com" disabled="true">
      </div>
    </div>
  </div>
</div>

