import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class PixelService extends RequestService {

  async getPixel() {
    return await this.request(this.httpClient.get(
      this.environment.API + '/pixel/me',
      { headers: this.headers() }
    ))
  }

  async getPixelViews(data: any) {
    return await this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pixel/views', data),
      { headers: this.headers() }
    ))
  }

  async getPixelSessions(data: any) {
    data.groupBy = null;
    return await this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pixel/sessions', data),
      { headers: this.headers() }
    ))
  }

  async getPixelVisitors() {
    return await this.request(this.httpClient.get(
      this.environment.API + '/pixel/currentVisitors',
      { headers: this.headers() }
    ))
  }

  async getOwnReferers() {
    return await this.request(this.httpClient.get(
      this.environment.API + '/pixel/ownReferers',
      { headers: this.headers() }
    ))
  }
}