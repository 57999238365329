import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-capsule-input-item',
  templateUrl: './capsule-input-item.component.html',
  styleUrls: ['./capsule-input-item.component.css']
})
export class CapsuleInputItemComponent implements OnInit {

  @Input('color') color: 'normal' | 'warning' | 'danger';
  @Input('selectable') selectable: boolean = false;
  @Input() selected: number;
  @Output() selectedChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() amount: number;
  @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();
  @Input('options') options: {id: number, name: string | number};
  @Input('label') label: string;
  @Input('type') type: string;
  @Input('unity') unity: string;
  @Input('max') max: number;
  @Input('min') min: number;

  constructor() { }

  ngOnInit(): void { }
  
  selectedOnChange(){
    this.selectedChange.emit(this.selected);
  }

  amountOnChange(){
    this.amountChange.emit(this.amount);
  }
}
