<div class="segmentation-container">
  <div class="container">
    <h4>Segmentar la búsqueda de asignatura</h4>
    
    <p>
      Aplica los filtros a las asignaturas para acotar la
      búsqueda.<sup (mouseover)="openRemember = true" (mouseout)="openRemember = false">(?)</sup>
    </p>

    <div *ngIf="openRemember" class="remember">
      <p>Recuerda</p>
      <span>Todas las asignaturas que selecciones para patrocinar tendrán el mismo widget y la misma publi en los apuntes</span>
      <div>
        <i class="material-icons-outlined">info</i>
      </div>
    </div>

    <div class="input-container">
      <div class="input-signature">
        <input
          #searchInput
          class="inputSearch"
          type="text"
          placeholder="Nombre de la asignatura"
          (input)="searchHandler($event)"
          debounce="500"
          [(ngModel)]="searchInputData"
        />

        <app-button-item
          (fireClick)="searchMode = 'folders'; searchHandler($event)"
          [text]="'Buscar'"
          [icon]="'search'"
          [background]="'#001540'"
          [size]="mobile ? 'small' : ''"
          [solid]="true"
        ></app-button-item>
      </div>
    </div>

    <div class="buttons-item">
      <div class="university-item" #zindex1>
        <app-button-item
          class="maxWidthButton"
          id="filter"
          [title]="filterData?.university?.name ? filterData?.university?.name : 'Universidad'"
          [color]="searchData?.universityId ? '#0055FF' : '#111111'"
          [text]="filterData?.university?.name ? filterData?.university?.name : 'Universidad'"
          [outlined]="true"
          [borderColor]="searchData?.universityId ? '#0055FF' : '#b6b6b6'"
          [size]="mobile ? 'small' : ''"
          (fireClick)="openFilter('0'); changeZ(1)"
        ></app-button-item>

        <div *ngIf="clicked[0]" class="filter-item-center">
          <app-filter-item
            [filters]="throwFilterData()"
            (buttonClick)="clicked[0] = false"
            style="display: block"
            [placeholder]="'Universidad'"
            [type]="'university'"
          ></app-filter-item>
        </div>
      </div>

      <div class="university-item" #zindex2>
        <app-button-item
          class="maxWidthButton"
          [title]="filterData?.center?.name ? filterData.center?.name : 'Centro'"
          [color]="searchData?.centerId ? '#0055FF' : '#111111'"
          [text]="filterData?.center?.name ? filterData.center?.name : 'Centro'"
          [outlined]="true"
          [borderColor]="searchData?.centerId ? '#0055FF' : '#b6b6b6'"
          [size]="mobile ? 'small' : ''"
          (fireClick)="openFilter('1'); changeZ(2)"
        ></app-button-item>

        
        <div *ngIf="clicked[1]" class="filter-item-center">
          <app-filter-item
            [filters]="throwFilterData()"
            (buttonClick)="clicked[1] = false"
            style="display: block"
            [placeholder]="'Centro'"
            [type]="'center'"
          ></app-filter-item>
        </div>
      </div>

      <div class="university-item" #zindex3>
        <app-button-item
          class="maxWidthButton"
          [title]="filterData?.study?.name ? filterData.study?.name : 'Grado'"
          [text]="filterData?.study?.name ? filterData.study?.name : 'Grado'"
          [outlined]="true"
          [borderColor]="searchData?.studyId ? '#0055FF' : '#b6b6b6'"
          [color]="searchData?.studyId ? '#0055FF' : '#111111'"
          [size]="mobile ? 'small' : ''"
          (fireClick)="openFilter('2'); changeZ(3)"
        ></app-button-item>

        <div *ngIf="clicked[2]" class="filter-item-center">
          <app-filter-item
            [filters]="throwFilterData()"
            (buttonClick)="clicked[2] = false"
            style="display: block"
            [placeholder]="'Grado'"
            [type]="'study'"
          ></app-filter-item>
        </div>
      </div>

      <div class="university-item" #zindex4>
        <app-button-item
          class="maxWidthButton"
          [title]="filterData?.course?.name ? filterData.course?.name : 'Curso'"
          [color]="searchData?.course ? '#0055FF' : '#111111'"
          [borderColor]="searchData?.course ? '#0055FF' : '#b6b6b6'"
          [text]="filterData?.course?.name ? filterData.course?.name : 'Curso'"
          [outlined]="true"
          [size]="mobile ? 'small' : ''"
          (fireClick)="openFilter('3'); changeZ(4)"
          [disabled]="!searchData?.studyId"
        ></app-button-item>

        <div *ngIf="clicked[3]" class="filter-item-center">
          <app-filter-item
            [filters]="throwFilterData()"
            (buttonClick)="clicked[3] = false"
            style="display: block"
            [placeholder]="'Curso'"
            [type]="'course'"
          ></app-filter-item>
        </div>
      </div>
    </div>

    <div class="search-container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
      <img *ngIf="!folders || folders?.length <= 0" src="./../../../assets/img/step-segmentacion.png" alt=""/>

      <div class="search-titles" *ngIf="folders && folders?.length > 0">
        <div (click)="checkAll(); throwEvent()" class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': allChecked}">
          <i class="material-icons-outlined">done</i>
        </div>

        <span>Asignatura</span>
        <span>Segmentación</span>
      </div>

      <div *ngIf="folders && folders?.length > 0" class="search-results">
        <ul>
          <li *ngFor="let folder of folders" (click)="folderStatus(folder); checkFolder(folder); throwEvent()" class="searchResultCheck">

            <div class="checkbox-custom-selector" [ngClass]="{'checkbox-custom-selector-selected': checkFolder(folder) || allChecked}">
              <i class="material-icons-outlined">done</i>
            </div>

            <span>{{ folder?.folder }}</span>
            <span>
              {{ folder?.study }}
              {{ folder?.center ? ', ' : ''  }}{{ folder?.center }}
              {{ folder?.university ? ', ' : ''  }}{{ folder?.university }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="footer-container">

    <app-button-item
      [text]="'Cancelar'"
      [color]="'#111111'"
      [borderColor]="'#b6b6b6'"
      [outlined]="true"
      (fireClick)="cancel()"
      *ngIf="!mobile"
    ></app-button-item>

    <app-button-item
      [text]="'Modo avanzado'"
      [solid]="true"
      [background]="'#eeeeee'"
      [color]="'#111111'"
      (fireClick)="goToDesign()"
      [disabled]="checkedFolders.length <= 0"
      [size]="mobile ? 'small': ''"
    ></app-button-item>

    <app-button-item
      [text]="'Patrocinar'"
      [background]="'#001540'"
      [solid]="true"
      (fireClick)="goToLanding()"
      [disabled]="checkedFolders.length <= 0"
      [size]="mobile ? 'small': ''"
    ></app-button-item>
  </div>
</div>
