import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd  } from '@angular/router';
import { Subject } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/auth";

// Services
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

// Pipes
import { TranslatePipe } from '../pipes/translate.pipe';

// Environments
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private cancelPendingRequests$ = new Subject<void>();

  environment = environment;

  public previousUrl: string;
  public currentUrl: string;

  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    public router: Router,
    public afAuth: AngularFireAuth,
    public translatePipe: TranslatePipe,
    public utilsService: UtilsService,
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  request(httpClientObservable) {
    return <any>new Promise(async (resolve, reject) => {
      let rslv = false;
      let timeout;

      timeout = setTimeout(() => {
        rslv = true;
        reject(null);
      }, 25000);

      httpClientObservable.subscribe(
        (response) => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (!rslv) {
            resolve(response);
          }
        },
        (error) => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (!rslv) {
            reject(error);
          }
        });
    });
  }

  headers() {
    const token =  this.storageService.read('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return headers;
  }

  headersAdmin() {
    const tokenAdmin =  this.storageService.read('tokenAdmin');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenAdmin);
    return headers;
  }

  s3Headers(contentType) {
    const headers = new HttpHeaders().set('ContentType', contentType);
    return headers;
  }

  serializeUrl(uri, params?) {
    var str = [];

    for (var p in params) {
      if (params.hasOwnProperty(p) && params[p] !== null) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    }

    if (str.length) {
      return uri + '?' + str.join("&");
    } else {
      return uri;
    }
  }

  cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }

  navigateToExit() {
    this.router.navigate(['/login']);
  }

  errorHandler(error: any, show: boolean) {
    let code: string = error && error.error && error.error.code ? error.error.code : 'UNKNOWNERR';

    let title = this.translatePipe.transform('error_messages.default_title') + ' (' + code + ')';
    let description = this.translatePipe.transform('error_messages.' + code + '_title');

    if (typeof title === 'undefined') {
      title = this.translatePipe.transform('error_messages.default_title') + ' (' + code + ')';
    }

    if (typeof description === 'undefined') {
      if (show == false) {
        description = title;
      } else {
        description = this.translatePipe.transform('error_messages.default_subtitle');
      }
    }

    if (error && error.status === 0){
      description = this.translatePipe.transform('global.no_internet_error');
    }

    let div = document.createElement("div");
    div.innerHTML = description;
    description = div.textContent || div.innerText || "";

    const message = {
      title: title,
      description: description,
      error: true,
      duration: 5
    };

    if (show) {
      this.utilsService.presentAlertMessage(message);
    } else {
      // Only send analytics event
    }
  }
}
