<div class="body-container">
  <h1 class="wds-h1">Generador de url para clicks</h1>

  <div class="inner-container">
    <textarea [(ngModel)]="click_url" placeholder="Introduce la URL destino" name="click_url" id="click_url" cols="30" rows="5"></textarea>

    <div *ngIf="output && output !== ''" class="output-container">
      {{output}}
    </div>

    <div class="buttons-container">
      <app-button-item [disabled]="!output" (fireClick)="copyOutput()" [text]="'Copiar resultado'" [color]="'#102754'" [outlined]="true"></app-button-item>
      <app-button-item [disabled]="!click_url" (fireClick)="generateURL()" [text]="'Generar URL'"  [background]="'#102754'" [color]="'#FFFFFF'" [solid]="true"></app-button-item>
    </div>
  </div>
</div>