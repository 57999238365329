import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable()

export class CampaignService extends RequestService {
  getCampaigns(userId: Number) {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/' + userId + '/campaign',
      { headers: this.headers() }
    ));
  }

  getCampaign(userId: Number, id: String) {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/' + userId + '/campaign/' + id,
      { headers: this.headers() }
    ));
  }

  getCampaignReach(userId: Number, campaignId: String) {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/' + userId + '/campaign/' + campaignId + '/reach',
      { headers: this.headers() }
    ));
  }

  getTargetReach(target: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pub/campaign/target/reach', {target:JSON.stringify(target)}),
      { headers: this.headers() }
    ));
  }

  getPendingCampaigns(userId: Number) {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/advertiser/' + userId + '/campaign/pending',
      { headers: this.headers() }
    ));
  }

  getAgentPendingCampaigns() {
    return this.request(this.httpClient.get(
      this.environment.API + '/pub/campaign/pending',
      { headers: this.headersAdmin() }
    ));
  }

  createCampaign(data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser/me/campaign",
        data,
        { headers: this.headers() })
    );
  }

  editCampaign(data: any) {
    if(!data.id) return this.createCampaign(data);
    return this.request(
      this.httpClient.put(
        this.environment.API + "/pub/advertiser/me/campaign/" + data.id,
        data,
        { headers: this.headers() })
    );
  }

  adminUpdate(userId: Number, campaignId: Number, data: any) {
    return this.request(
      this.httpClient.put(
        this.environment.API + "/pub/advertiser/" + userId + "/campaign/" + campaignId,
        data,
        { headers: this.headers() })
    );
  }

  prepareCreativityUpload(campaignId, data) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser/me/campaign/" + campaignId + "/creativity",
        data,
        { headers: this.headers() })
    );
  }

  getsignedURLFromS3(data) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/image",
        data,
        { headers: this.headers() })
    );
  }

  uploadImageToS3(file, type, url) {
    return this.request(
      this.httpClient.put(
        url,
        file,
        { headers: this.s3Headers(type) })
    );
  }

  uploadCreativityToS3(file, contentType, url) {
    let b: any = new Blob([file], { type: 'image/jpeg' });
    b.lastModifiedDate = new Date();
    let fileToProcess = <File>b;
    return this.request(
      this.httpClient.put(
        url,
        fileToProcess,
        { headers: this.s3Headers(contentType) })
    );
  }

  payCampaign(campaignId: Number, paymentId: Number, data: any) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/advertiser/me/campaign/" + campaignId + "/payment/" + paymentId + "/authorize",
        data,
        { headers: this.headers() })
    );
  }

  adminCapturePayment(campaignId: Number, paymentId: Number) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/campaign/" + campaignId + "/payment/" + paymentId + "/capture",
        {},
        { headers: this.headersAdmin() })
    );
  }

  createCreativity(data) {
    return this.request(
      this.httpClient.post(
        this.environment.API + "/pub/creative",
        data,
        { headers: this.headers() })
    );
  }

  getGeneralReport(advertiserId: any, orderId: any, data: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pub/advertiser/' + advertiserId + '/campaign/' + orderId + '/generalReport', data),
      { headers: this.headers() }
    ));
  }

  getReportByDay(advertiserId: any, orderId: any, data: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pub/advertiser/' + advertiserId + '/campaign/' + orderId + '/reportByDay', data),
      { headers: this.headers() }
    ));
  }

  getReportByUbication(advertiserId: any, orderId: any, data: any) {
    return this.request(this.httpClient.get(
      this.serializeUrl(this.environment.API + '/pub/advertiser/' + advertiserId + '/campaign/' + orderId + '/reportByUbication', data),
      { headers: this.headers() }
    ));
  }
}
