import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { CampaignService } from '../../services/campaign.service';
import { StorageService } from 'src/app/services/storage.service';
import { ClipboardService } from "ngx-clipboard";
import { UtilsService } from 'src/app/services/utils.service';
import { TimestampPipe } from 'src/app/pipes/timestamp.pipe';
import { AdvertiserService } from 'src/app/services/advertiser.service';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css']
})
export class CampaignDetailsComponent implements OnInit {
  campaignId: String;

  advertiserId: any;
  adminUserMe: any;
  userMe: any;
  campaign: any;
  campaignAdditionalInfo: any = {
    startDate: '',
    endDate: '',
    numOfflineUbications: 0,
    numOnlineUbications: 0,
    paymentStatusText: '',
    payment: null,
    campaignStatus: ''
  };
  offlineUbicationIds = [1, 2, 3, 4, 5]
  selectedAdUbication = 1;
  selectedAdUbicationCreatives = [];
  selectedUbicationCreative = null;

  campaignStatuses = [
    'CREATING_CAMPAIGN',
    'CREATING_CREATIVES',
    'CREATING_PAYMENTS',
    'PENDING',
    'READY',
    'ACTIVE',
    'PAUSED',
    'FINISHED'
  ];

  campaignStatusesMessages = {
    'CREATING_CAMPAIGN': 'Borrador',
    'CREATING_CREATIVES': 'Borrador',
    'CREATING_PAYMENTS': 'Borrador',
    'PENDING': 'Revisando',
    'READY': 'Preparada',
    'ACTIVE': 'Activa',
    'PAUSED': 'Pausada',
    'FINISHED': 'Finalizada'
  };

  paymentStatusMessages = {
    'PENDING': 'Pendiente de introducir un método de pago.',
    'AUTHORIZED': 'Pago autorizado. Se hará efectivo el cobro cuando comience la campaña.',
    'PAID': 'Pago realizado.',
    'REJECTED': 'Pago rechazado, por favor, actualice el método de pago.',
  }

  openPublic = false;
  publicSlide = false;

  openCreative = false;
  creativeSlide = false;

  advertiser: any;

  mobile = false;

  constructor(
    private clipboardService: ClipboardService,
    public utilsService: UtilsService,
    private route: ActivatedRoute,
    public campaignService: CampaignService,
    public storageService: StorageService,
    private timestampPipe: TimestampPipe,
    public advertiserService: AdvertiserService,
    ) { }

  async ngOnInit() {
    this.campaignId = this.route.snapshot.paramMap.get("id");
    this.advertiserId = this.route.snapshot.paramMap.get("advertiserId");
    this.adminUserMe = this.storageService.read("adminUserMe");
    this.userMe = this.storageService.read('userMe');
    this.campaign = await this.campaignService.getCampaign(this.advertiserId, this.campaignId);
    this.advertiser = await this.advertiserService.getAdvertiserData(this.advertiserId);

    this.updateCampaignAdditionalInfo();
    this.onSelectedAdUbicationChange(1);

    const screen = window.screen.width;
    if (screen < 767) {
      this.mobile = true;
    }
  }

  updateCampaignAdditionalInfo(){
    this.campaignAdditionalInfo.startDate = this.timestampPipe.transform(this.campaign.startDate);
    this.campaignAdditionalInfo.endDate = this.timestampPipe.transform(this.campaign.endDate);

    let offlineUbications = [];
    let onlineUbications = [];
    for(let i = 0; i < this.campaign.creatives.length; i++) {
      if(this.offlineUbicationIds.indexOf(this.campaign.creatives[i].ubicationId) != -1) {
        if(offlineUbications.indexOf(this.campaign.creatives[i].ubicationId) == -1) {
          offlineUbications.push(this.campaign.creatives[i].ubicationId);
        }
      } else {
        if(onlineUbications.indexOf(this.campaign.creatives[i].ubicationId) == -1) {
          onlineUbications.push(this.campaign.creatives[i].ubicationId);
        }
      }
    }

    this.campaignAdditionalInfo.numOfflineUbications = offlineUbications.length;
    this.campaignAdditionalInfo.numOnlineUbications = onlineUbications.length;

    if(this.campaign.payments.length) {
      this.campaignAdditionalInfo.payment = this.campaign.payments[this.campaign.payments.length - 1];
    }
    
  }

  async changeStatus() {
    if(!this.adminUserMe){
      return;
    }

    let currentIndex = this.campaignStatuses.indexOf(this.campaign.status);
    let newIndex = (((++currentIndex) - 3) % 5) + 3;
    let status = this.campaignStatuses[newIndex];

    this.utilsService.loading('open');
    try {
      await this.campaignService.adminUpdate(this.campaign.userId, this.campaign.id, {status: status});
      this.campaign.status = status;
      this.utilsService.loading('close');
    } catch(error) {
      this.utilsService.loading('close');
      this.campaignService.errorHandler(error, true);
      return;
    }
  }

  async capturePayment() {
    this.utilsService.loading('open');
    try {
      await this.campaignService.adminCapturePayment(this.campaign.id, this.campaignAdditionalInfo.payment.id);
      this.campaignAdditionalInfo.payment.status = 'PAID';
    } catch(error) {
      this.campaignService.errorHandler(error, true);
    }
    this.utilsService.loading('close');
  }

  toggleCreativeSlide(type?) {
    if (this.creativeSlide) {
      this.openCreative = false;

      setTimeout(() => {
        this.creativeSlide = false;
      }, 210);
    } else {
      this.creativeSlide = true;

      setTimeout(() => {
        this.openCreative = true;
        if(type == 'offline') this.onSelectedAdUbicationChange(1);
        if(type == 'online') this.onSelectedAdUbicationChange(6);
      }, 80);
    }
  }

  onSelectedAdUbicationChange(id) {
    this.selectedAdUbicationCreatives = this.campaign.creatives.filter(x => x.ubicationId == id);
    if(this.selectedAdUbicationCreatives.length) {
      this.onSelectedAdUbicationCreativeChange(this.selectedAdUbicationCreatives[0].fileUrl);
    }
    this.selectedAdUbication = id;
  }

  onSelectedAdUbicationCreativeChange(url) {
    this.selectedUbicationCreative = url;
  }

  togglePublicSlide() {
    if (this.publicSlide) {
      this.openPublic = false;

      setTimeout(() => {
        this.publicSlide = false;
      }, 210);
    } else {
      this.publicSlide = true;

      setTimeout(() => {
        this.openPublic = true;
      }, 80);
    }
  }

  copyPublic(type) {
    let copied = JSON.stringify(this.campaign.target[type + 'List'].map(x => x.id));
    copied = copied.substring(1, copied.length - 1);
    this.clipboardService.copy(copied);
    this.utilsService.presentAlertMessage({
      title: 'Copiado.',
      description: 'Se ha copiado el objeto en el portapapeles.',
      duration: 5
    });
  }
}
