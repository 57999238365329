import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

declare var Stripe: any;

// Services
import { UtilsService } from '../../../services/utils.service';
import { SponsorSubjectService } from '../../../services/sponsor-subject.service';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-payment-unique',
  templateUrl: './custom-payment-unique.component.html',
  styleUrls: ['./custom-payment-unique.component.css']
})
export class CustomPaymentUniqueComponent implements OnInit {

  loading = true;

  amount: number;
  product: string;
  email: string;

  environment = environment;

  constructor(
    public sponsorSubjectService: SponsorSubjectService,
    public utilsService: UtilsService,
    public route: ActivatedRoute,
    public router: Router,
  ) { 
    this.route.params.subscribe((params) => {
      if (params && params.amount) {
        this.amount = parseInt(params.amount);      
      }
      if (params && params.product) {
        this.product = params.product; 
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params && params.email) {
        this.email = params.email;   
      }
    });
  }
  
  ngOnInit(): void { 
    setTimeout(() => {
      this.redirectToStripe();
    }, 250);
  }

  redirectToStripe() {
    const stripe = Stripe(this.environment.wdsClientsStripeKey);

    const data = {
      email: this.email,
      productId: this.product,
      amount: this.amount,
      currency: "eur"
    };

    this.sponsorSubjectService.createCustomPaymentUnique(data).then(
      (response) => {
        const config: any = {
          sessionId: response.sessionId
        };
    
        stripe.redirectToCheckout(config).then((result) => {
          this.router.navigateByUrl('/landing/error');
        });
      }
    ).catch(
      (error) => {
        this.utilsService.presentMessage('Ha ocurrido un error');
        this.router.navigate(['/landing/error']);
      }
    );
  }
}