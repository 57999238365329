<div class="body-container">
  <div class="period-capsule">
    <app-button-item 
      (fireClick)="goBack()" 
      [onlyIcon]="true" 
      [title]="'Cerrar'" 
      [icon]="'arrow_back'"
      [background]="'#eeeeee'"
      [solid]="true"
      [color]="'#0055FF'"
    ></app-button-item>

    <ngx-skeleton-loader
      *ngIf="!widget"
      animation="progress"
      [theme]="{ 
        'border-radius': '8px',
        'height': '36px',
        'min-width': '150px',
        'background-color': '#eeeeee',
        'margin': '0'
      }"
    ></ngx-skeleton-loader>

    <h1 *ngIf="folderName" class="wds-h1">
      {{folderName}}
    </h1>

    <div class="period-capsule-item">
      <div (click)="setPeriod(7)" class="period-item" [ngClass]="{'selected': periodSelected === 7, 'disabled': check <= 0}">7 días</div>
      <div (click)="setPeriod(14)" class="period-item" [ngClass]="{'selected': periodSelected === 14, 'disabled': check <= 0}">14 días</div>
      <div (click)="setPeriod(30)" class="period-item" [ngClass]="{'selected': periodSelected === 30, 'disabled': check <= 0}">30 días</div>
    </div>
  </div>

  <div class="title-capsule separation">
    <h4 class="wds-h4">Datos generales</h4>
  </div>
  
  <div class="splitted-chart">
    <div class="side-chart">
      <div class="title-capsule">
        <h4 class="wds-h4">Impresiones</h4>
    
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '36px',
            'width': '100px',
            'background-color': '#eeeeee',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <div *ngIf="firstReport" class="total-capsule">
          Total: {{getTotal('impression')}}
        </div>
      </div>
  
      <div class="chart-container">
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '300px',
            'width': '100%',
            'background-color': '#f8f8f8',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <highcharts-chart 
          *ngIf="firstReport"
          [Highcharts]="Highcharts"
          [options]="chartOptionsImpressions"
          style="width: 100%; height: 300px; display: block;"
          [(update)]="updateFlag"
          [oneToOne]="oneToOneFlag"
        ></highcharts-chart>
      </div>
    </div>

    <div class="side-chart">
      <div class="title-capsule">
        <h4 class="wds-h4">Clicks</h4>

        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '36px',
            'width': '100px',
            'background-color': '#eeeeee',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <div *ngIf="firstReport" class="total-capsule">
          Total: {{getTotal('click')}}
        </div>
      </div>
  
      <div class="chart-container">
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '300px',
            'width': '100%',
            'background-color': '#f8f8f8',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <highcharts-chart 
          *ngIf="firstReport"
          [Highcharts]="Highcharts"
          [options]="chartOptionsClicks"
          style="width: 100%; height: 300px; display: block;"
          [(update)]="updateFlag"
          [oneToOne]="oneToOneFlag"
        ></highcharts-chart>
      </div>
    </div>
  </div>

  <div class="title-capsule separation">
    <h4 class="wds-h4">Datos contenido subido por la academia</h4>
  </div>
  
  <div class="splitted-chart">
    <div class="side-chart">
      <div class="title-capsule">
        <h4 class="wds-h4">Impresiones</h4>
    
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '36px',
            'width': '100px',
            'background-color': '#eeeeee',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <div *ngIf="firstReport" class="total-capsule">
          Total: {{getTotal('impressionFixedDoc')}}
        </div>
      </div>
  
      <div class="chart-container">
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '300px',
            'width': '100%',
            'background-color': '#f8f8f8',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <highcharts-chart 
          *ngIf="firstReport"
          [Highcharts]="Highcharts"
          [options]="chartOptionsFixedDocsImpressions"
          style="width: 100%; height: 300px; display: block;"
          [(update)]="updateFlag"
          [oneToOne]="oneToOneFlag"
        ></highcharts-chart>
      </div>
    </div>

    <div class="side-chart">
      <div class="title-capsule">
        <h4 class="wds-h4">Clicks</h4>

        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '36px',
            'width': '100px',
            'background-color': '#eeeeee',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <div *ngIf="firstReport" class="total-capsule">
          Total: {{getTotal('clicksFixedDocs')}}
        </div>
      </div>
  
      <div class="chart-container">
        <ngx-skeleton-loader
          *ngIf="!firstReport"
          animation="progress"
          [theme]="{ 
            'border-radius': '8px',
            'height': '300px',
            'width': '100%',
            'background-color': '#f8f8f8',
            'margin': '0'
          }"
        ></ngx-skeleton-loader>

        <highcharts-chart 
          *ngIf="firstReport"
          [Highcharts]="Highcharts"
          [options]="chartOptionsFixedDocsClicks"
          style="width: 100%; height: 300px; display: block;"
          [(update)]="updateFlag"
          [oneToOne]="oneToOneFlag"
        ></highcharts-chart>
      </div>
    </div>
  </div>

  <div class="title-capsule separation">
    <h4 class="wds-h4">Análisis contenido subido por la academia</h4>
  </div>

  <div class="table-container">
    <ngx-skeleton-loader
      *ngIf="check <= 1"
      animation="progress"
      [theme]="{ 
        'border-radius': '8px',
        'height': '300px',
        'width': '100%',
        'background-color': '#f8f8f8',
        'margin': '0'
      }"
    ></ngx-skeleton-loader>

    <ag-grid-angular
      *ngIf="check > 1"
      style="width: 100%;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [domLayout]="'autoHeight'"
      (firstDataRendered)="onFirstDataRendered($event)"
    ></ag-grid-angular>
  </div>

  <div class="title-capsule separation">
    <h4 class="wds-h4">Análisis publicidad en apuntes</h4>
  </div>

  <div class="table-container">
    <ngx-skeleton-loader
      *ngIf="check <= 2"
      animation="progress"
      [theme]="{ 
        'border-radius': '8px',
        'height': '100px',
        'width': '100%',
        'background-color': '#f8f8f8',
        'margin': '0'
      }"
    ></ngx-skeleton-loader>

    <ag-grid-angular
      *ngIf="check > 2"
      style="width: 100%;"
      class="ag-theme-alpine"
      [rowData]="rowDataPubInDocs"
      [domLayout]="'autoHeight'"
      [columnDefs]="columnDefsPubInDocs"
      (firstDataRendered)="onFirstDataRendered($event)"
    ></ag-grid-angular>
  </div>

  <div class="title-capsule separation">
    <h4 class="wds-h4">Análisis del perfil de tu academia</h4>
  </div>

  <div class="table-container">
    <ngx-skeleton-loader
      *ngIf="check <= 3"
      animation="progress"
      [theme]="{ 
        'border-radius': '8px',
        'height': '200px',
        'width': '100%',
        'background-color': '#f8f8f8',
        'margin': '0'
      }"
    ></ngx-skeleton-loader>

    <ag-grid-angular
      *ngIf="check > 3"
      style="width: 100%;"
      class="ag-theme-alpine"
      [rowData]="rowDataComponentDetails"
      [domLayout]="'autoHeight'"
      [columnDefs]="columnDefsComponentDetails"
      (firstDataRendered)="onFirstDataRendered($event)"
    ></ag-grid-angular>
  </div>
</div>