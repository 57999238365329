import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { UserService } from '../services/user.service';
import { StorageService } from '../services/storage.service';
import { AdvertiserService } from '../services/advertiser.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  isAStudent = null;

  constructor(
    private router: Router,
    public userService: UserService,
    public storageService: StorageService,
    public advertiserService: AdvertiserService,
  ) { 
    this.isAStudent = this.storageService.read('isStudent');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {
      const token = this.storageService.read('token');
      const redirect = this.storageService.read('redirect');

      if (typeof token === 'undefined' || !token || token === '') {

        this.storageService.clear();

        if (this.isAStudent !== null) {
          this.storageService.write('isStudent', this.isAStudent);
        }
  
        if (state && state.url && state.url !== '/login' && state.url !== '/sign-up' && state.url !== '/index') {
          this.router.navigate(['index']);
        }

        resolve(true);
      } else {
        this.userService.userMe().then(
          (response) => {
            this.storageService.write('userMe', response);
            this.storageService.write('userId', response.userId);
  
            window['dataLayer'].push({
              'userId' : response.userId
            });
  
            this.advertiserService.getAdvertiser().then(
              (response) => {
                this.storageService.write('advertiserMe', response);
  
                if (typeof redirect !== 'undefined' && redirect) {
                  if (state.url !== redirect) {
                    this.router.navigate([redirect]);
                  } else {
                    if (state.url.indexOf('dashboard') > -1) {
                      resolve(true);
                    } else {
                      this.router.navigate(['dashboard/main']);
                      resolve(true);
                    }
                  }
                } else {
                  if (state.url.indexOf('dashboard') > -1) {
                    resolve(true);
                  } else {
                    this.router.navigate(['dashboard/main']);
                    resolve(true);
                  }
                }
              }
            ).catch(
              () => {
                if (state && state.url && state.url !== '/complete-profile') {
                  this.router.navigate(['complete-profile']);
                }
  
                resolve(true);
              }
            );
          }
        ).catch(
          () => {
            if (state && state.url && state.url !== '/login' && state.url !== '/sign-up' && state.url !== '/index') {
              this.router.navigate(['index']);
            }
  
            resolve(true);
          }
        );
      }
    });
  }

}
