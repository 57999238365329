import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

declare var Stripe: any;

// Services
import { UtilsService } from '../../../services/utils.service';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sponsor-languages',
  templateUrl: './sponsor-languages.component.html',
  styleUrls: ['./sponsor-languages.component.css']
})
export class SponsorLanguagesComponent implements OnInit {

  loading = true;
  quantity: number = 1;
  email: string;
  type = 0;

  prices = {
    production: [
      'price_1IU6upCZDAhvQ6LX0nt7EmYt'
    ],
    development: [
      'price_1IU71aCZDAhvQ6LXyvNgH4ni'
    ]
  };

  environment = environment;

  constructor(
    public utilsService: UtilsService,
    public route: ActivatedRoute,
    public router: Router,
  ) { 
    this.route.params.subscribe((params) => {
      if (params && params.quantity) {
        this.quantity = parseInt(params.quantity);      
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params && params.email) {
        this.email = params.email;   
      }

      if (params && params.type) {
        this.type = parseInt(params.type);   
      }
    });
  }
  
  ngOnInit(): void { 
    setTimeout(() => {
      this.redirectToStripe();
    }, 250);
  }

  redirectToStripe() {
    const stripe = Stripe(this.environment.wdsStripeKey);
    let mode = 'development';

    if (this.environment.production) {
      mode = 'production';
    }

    const config: any = {
      lineItems: [{
        price: this.prices[mode][this.type],
        quantity: this.quantity,
      }],
      mode: 'subscription',
      successUrl: 'https://wuolads.com/landing/gracias',
      cancelUrl: 'https://wuolads.com/landing/error',
    };

    if (this.email && this.email !== '') {
      config.customerEmail = this.email;
    }

    stripe.redirectToCheckout(config).then( (result) => {
      this.router.navigateByUrl('/landing/error');
    });
  }

}
