import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// Services
import { AnalyticService } from 'src/app/services/analytic.service';
import { AdvertiserService } from '../../services/advertiser.service';
import { CampaignService } from '../../services/campaign.service';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-dashboard-associate',
  templateUrl: './dashboard-associate.component.html',
  styleUrls: ['./dashboard-associate.component.css']
})
export class DashboardAssociateComponent implements OnInit {
  advertisers = [];
  selectedAdvertiser: any;
  selectedAdvertiserCampaigns: any[] = [];
  selectedCampaign: any;

  newAdvertiserDfpId = '';
  newAgentId = '';
  newCampaignDfpId = '';
  newCampaignDashboardUrl = '';

  associateSlide = false;
  openSlide = false;
  step = 1;

  mobile = false;

  constructor(
    public advertiserService: AdvertiserService,
    public campaignService: CampaignService,
    public storageService: StorageService,
    public utilsService: UtilsService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { }
  
  async ngOnInit() {
    this.titleService.setTitle('Clientes asociados | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/associate', 'Associate clients');
    this.analyticService.screenView('Associate clients');

    this.advertisers = await this.advertiserService.getAdvertisers({limit: 15});

    window.innerWidth < 767 ? this.mobile = true : this.mobile = false;
  }

  toggleAssociateSlide(advertiser) {
    if (this.associateSlide) {
      this.openSlide = false;

      setTimeout(() => {
        this.associateSlide = false;
        this.selectAdvertiser(null);
      }, 210);
    } else {
      this.selectAdvertiser(advertiser);
      this.associateSlide = true;

      setTimeout(() => {
        this.openSlide = true;
      }, 80);
    }
  }
  async selectAdvertiser(advertiser) {
    if(advertiser){
      this.selectedAdvertiser = advertiser;
      if(advertiser.dfpId) this.newAdvertiserDfpId = advertiser.dfpId;

      this.advertiserService.getAdvertiserGoogleAdManager(this.newAdvertiserDfpId).then((response)=>{
        this.selectedAdvertiserCampaigns = response;
      });
    } else {
      this.selectedAdvertiser = null;
      this.newAdvertiserDfpId = '';
      this.selectedAdvertiserCampaigns = [];
    }
  }

  async selectCampaign(campaign) {
    this.selectedCampaign = campaign;
    if(campaign.dfpOrderId) this.newCampaignDfpId = campaign.dfpOrderId;
    if(campaign.dashboardUrl) this.newCampaignDashboardUrl = campaign.dashboardUrl;
    this.step = 2;
  }

  closeCampaignEdit(){
    this.step = 1;
    this.newCampaignDfpId = '';
    this.newCampaignDashboardUrl = '';
    this.selectedCampaign = null;
  }

  async editAdvertiser() {
    this.addAgentId();

    let data: any = { agentIds: this.selectedAdvertiser.agentIds };
    if (this.newAdvertiserDfpId) data.dfpId = this.newAdvertiserDfpId;
    this.selectedAdvertiser = await this.advertiserService.adminUpdate(this.selectedAdvertiser.userId, data);
    let advertiser = this.advertisers.find(x => x.userId == this.selectedAdvertiser.userId);
    this.advertisers[this.advertisers.indexOf(advertiser)] = this.selectedAdvertiser;
    this.toggleAssociateSlide(null);
  }

  async editCampaign(){
    let data: any = {};
    if(this.newCampaignDfpId.length) data.dfpOrderId = this.newCampaignDfpId;
    if(this.newCampaignDashboardUrl.length) data.dashboardUrl = this.newCampaignDashboardUrl;
    this.selectedCampaign = await this.campaignService.adminUpdate(this.selectedAdvertiser.userId, this.selectedCampaign.id, data);
    let campaign = this.selectedAdvertiserCampaigns.find(x => x.id == this.selectedCampaign.id);
    this.selectedAdvertiserCampaigns[this.selectedAdvertiserCampaigns.indexOf(campaign)] = this.selectedCampaign;
    this.closeCampaignEdit();
  }

  addAgentId() {
    if (this.selectedAdvertiser.agentIds == null) this.selectedAdvertiser.agentIds = [];
    if (this.newAgentId !== null && this.newAgentId !== '') {
      this.newAgentId.match('^[0-9]*$') ?
        this.selectedAdvertiser.agentIds.push(Number(this.newAgentId))
        :
        this.utilsService.presentAlertMessage({
          title: 'ID del agente incorrecto.',
          description: 'Por favor introduzca un ID que solo contenga números.',
          error: true,
          duration: 6
        });
    }
    this.newAgentId = '';
  }

  removeAgentId(agentId) {
    let index = this.selectedAdvertiser.agentIds.indexOf(agentId);
    this.selectedAdvertiser.agentIds.splice(index, 1);
  }

  async onScroll() {
    let res = await this.advertiserService.getAdvertisers({startAt: this.advertisers[this.advertisers.length-1]?.userId});
    this.advertisers = this.advertisers.concat(res);
  }
}
