import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable()
export class StudyService extends RequestService {
  heavySearchStudies(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/study/search", params),
        { headers: this.headers() }
      )
    );
  }

  searchStudies(params) {
    return this.request(
      this.httpClient.get(
        this.serializeUrl(this.environment.API + "/study", params),
        { headers: this.headers() }
      )
    );
  }
}
