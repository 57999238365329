import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})
export class MessageItemComponent implements OnInit {

  @Input('error') error: boolean = false;
  @Input('title') title: any;
  @Input('description') description: any;
  @Input('duration') duration: number = 3;

  @Output() closeAction = new EventEmitter();

  open = false;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.open = true;
    }, 200);

    setTimeout(() => {
      this.close();
    }, ((this.duration * 1000) + 200));
  }

  close() {
    this.open = false;

    setTimeout(() => {
      this.closeAction.emit(true);
    }, 200);
  }

}
