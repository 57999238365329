<div class="body-container">
  <h1 class="wds-h1">Avisos</h1>

  <div class="table-container">
    <div class="table-row table-header">
      <div class="table-column">Campaña</div>
      <div class="table-column">Estado</div>
      <div class="table-column">Fecha cobro</div>
      <div class="table-column"></div>
    </div>

    <div class="table-row" *ngFor="let campaign of campaigns">
        <div class="table-column">{{campaign.name}}</div>
        <div class="table-column">{{campaignStatusesMessages[campaign.status]}}</div>
        <div class="table-column" [ngClass]="{'elapsed': campaign.shownPayment && campaign.shownPayment.status == 'REJECTED'}">
          {{campaign.shownPayment ? campaign.shownPayment.shownDate : 'No hay cobros pendientes.'}}
        </div>
        <div class="table-column actions">
          <app-button-item *ngIf="campaign.editable" [routerLink]="['/dashboard', 'edit', campaign.id]" [onlyIcon]="true" [title]="'Editar'" [icon]="'edit'" [color]="'#0055FF'"></app-button-item>
        </div>
    </div>
  </div>
</div>