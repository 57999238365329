<div #mtfInput class="mdc-text-field mdc-text-field--outlined" [ngClass]="{'mdc-text-field--invalid': helpStatus && helpStatus === 'danger', 'with-margin': !helpText || helpText === '', 'no-margin': noMargin}">
  <input *ngIf="maxLength" (keydown.enter)="onKeyEnter()" [name]="name" class="mdc-text-field__input" id="tf-outlined-{{uuid}}" [disabled]="disabled" [maxLength]="maxLength" [(ngModel)]="data" (keyup)="emitData()" [pattern]="pattern" [type]="type" (blur)="onBlurEmit()">
  <input *ngIf="!maxLength" (keydown.enter)="onKeyEnter()"  [name]="name" class="mdc-text-field__input" id="tf-outlined-{{uuid}}" [disabled]="disabled" [(ngModel)]="data" (keyup)="emitData()" [type]="type" [pattern]="pattern" (blur)="onBlurEmit()">

  <div class="mdc-notched-outline">
    <div class="mdc-notched-outline__leading"></div>
    <div class="mdc-notched-outline__notch">
      <label for="tf-outlined-{{uuid}}" class="mdc-floating-label">{{label}}</label>
    </div>
    <div class="mdc-notched-outline__trailing"></div>
  </div>
</div>

<div *ngIf="(helpText && helpText !== '') || maxLength" class="mdc-text-field-helper-line" [ngClass]="{'with-margin': helpText && helpText !== ''}">
  <div *ngIf="helpText && helpText !== ''" class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" [ngClass]="{'mdc-text-field-helper-text--validation-msg': helpStatus && helpStatus === 'danger'}">{{helpText}}</div>

  <div *ngIf="maxLength && !data" class="mdc-text-field-character-counter" [ngClass]="{'mdc-text-field-helper-text--validation-msg': helpStatus && helpStatus === 'danger'}">0 / {{maxLength}}</div>
  <div *ngIf="maxLength && data" class="mdc-text-field-character-counter" [ngClass]="{'mdc-text-field-helper-text--validation-msg': helpStatus && helpStatus === 'danger'}">{{data.length}} / {{maxLength}}</div>
</div>
