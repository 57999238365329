import { Component, OnInit } from '@angular/core';
import * as sha512 from 'js-sha512';

// Services
import { UtilsService } from '../../services/utils.service';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';

// Environments
import { environment } from '../../../environments/environment';
import { AnalyticService } from 'src/app/services/analytic.service';
import { Title } from '@angular/platform-browser';

declare var gtag: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  environment = environment;

  credentials: {
    mail: string,
    password: string
  };

  uuid: string;

  constructor(
    public utilsService: UtilsService,
    public userService: UserService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticService: AnalyticService,
    public titleService: Title
  ) {
    this.credentials = {
      mail: null,
      password: null
    };

    this.uuid = this.storageService.read('uuid');
  }

  ngOnInit(): void { 
    this.titleService.setTitle('Regístrate | Wuolads');
    this.analyticService.pageView(window.location.href, 'sign-up', 'Sign up');
    this.analyticService.screenView('Sign up');

    this.analyticService.customEvent(
      'UP1_03_0_VISIT',
      {
        uuid: this.uuid,
        userId: null
      }
    );
  }

  signUp() {
    const data = {
      email: null,
      repeatEmail: null,
      password: null,
      language: 'es_ES',
      gdpr_mailing: 1,
      captcha: '03AGdBq25JSIiMnlEcN7F2BZuut1zljZdDMLZliRjzOOjPBlDTFtcTl3XgeT3SgG9wBILpPHaU8doCAlAI_rsjulTCtWyNDzXrzuZAdCOST3Vo61rj'
    };

    const hash = this.credentials.password ? sha512.sha512(this.credentials.password) : null;

    if (!this.credentials.mail || !this.utilsService.validateEmail(this.credentials.mail)) {
      this.utilsService.presentAlertMessage({
        title: 'Email incorrecto.',
        description: 'Necesitas un email válido para registrarte.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (!this.credentials.password || !this.utilsService.validatePassword(this.credentials.password)) {
      this.utilsService.presentAlertMessage({
        title: 'Contraseña incorrecta.',
        description: 'La contraseña debe contener entre 6 y 18 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }

    data.email = this.credentials.mail.trim();
    data.repeatEmail = this.credentials.mail.trim();
    data.password = hash;

    this.utilsService.loading('open');

    this.userService.singUp(data).then(
      async (response) => {
        this.storageService.write('token', response.accessToken);

        window['dataLayer'].push({
          'event' : 'sign-up'
        });

        gtag("event", "sign_up", {
          method: 'Email'
        });

        this.router.navigate(['/complete-profile'], { relativeTo: this.route }).then(()=>{
            this.utilsService.loading('close');
        });
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
    );
  }
}
