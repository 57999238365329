import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { UtilsService } from '../../services/utils.service';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { AnalyticService } from 'src/app/services/analytic.service';

// Environments
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';

declare var gtag: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  environment = environment;

  credentials: {
    mail: string,
    password: string
  };

  businessType = 'INITIAL';
  businessTypes = [];

  version = '0.0.0';
  uuid: string;

  createAccount = false;

  showRequestInit = false;
  showRequest = false;
  isStudent = null;

  constructor(
    public utilsService: UtilsService,
    public userService: UserService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticService: AnalyticService,
    public titleService: Title
  ) {
    this.credentials = {
      mail: null,
      password: null
    };

    this.storageService.read<any>('contactCount')
    this.businessTypes = this.utilsService.getBusinessTypes();
    this.version = this.utilsService.getVersion();

    this.uuid = this.storageService.read('uuid');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Anúnciate en Wuolah | Wuolads');
    this.analyticService.pageView(window.location.href, 'index', 'Home');
    this.analyticService.screenView('Home');
    
    this.analyticService.customEvent(
      'UP1_00_0_VISIT',
      {
        uuid: this.uuid,
        userId: null
      }
    );

    this.isStudent = this.storageService.read("isStudent");
    
    if (this.isStudent == null) {
      this.showRequestInit = true;

      setTimeout(() => {
        this.showRequest = true;
      }, 150);
    }
  }

  checkDisabled(){
    return this.credentials?.mail === null || this.credentials?.password === null || this.businessType === "INITIAL";
  }

  isAStudent(value: number) {
    this.showRequest = false;

    setTimeout(() => {
      this.showRequestInit = false;
      this.storageService.write('isStudent', value);
    }, 200);

    
  }

  signUp() {
    const data = {
      email: null,
      repeatEmail: null,
      password: null,
      language: 'es_ES',
      gdpr_mailing: 1,
      captcha: '03AGdBq25JSIiMnlEcN7F2BZuut1zljZdDMLZliRjzOOjPBlDTFtcTl3XgeT3SgG9wBILpPHaU8doCAlAI_rsjulTCtWyNDzXrzuZAdCOST3Vo61rj'
    };


    if (!this.credentials.mail || !this.utilsService.validateEmail(this.credentials.mail)) {
      this.utilsService.presentAlertMessage({
        title: 'Email incorrecto.',
        description: 'Necesitas un email válido para registrarte.',
        error: true,
        duration: 6
      });

      return false;
    }

    if (!this.credentials.password || !this.utilsService.validatePassword(this.credentials.password)) {
      this.utilsService.presentAlertMessage({
        title: 'Contraseña incorrecta.',
        description: 'La contraseña debe contener entre 6 y 18 carácteres.',
        error: true,
        duration: 6
      });

      return false;
    }

    data.email = this.credentials.mail.trim();
    data.repeatEmail = this.credentials.mail.trim();
    data.password = this.credentials.password;

    this.utilsService.loading('open');

    this.userService.singUp(data).then(
      async (response) => {
        this.storageService.write('token', response.accessToken);
        this.storageService.write('businessType', this.businessType);

        window['dataLayer'].push({
          'event' : 'sign-up'
        });

        gtag("event", "sign_up", {
          method: 'Email'
        });

        let notification: any = this.storageService.read("notification");
        const data = {
          step: 'SIGNUP'
        };
        if (!notification) {
          notification = {
            sponsor: null,
            campaign: null,
            completeProfile: null,
            signUp: 1,
          }
          await this.userService.sendStepNotification(data);
        } else {
          if (notification?.signUp && notification?.signUp < 5) {
            await this.userService.sendStepNotification(data);
            notification.signUp = notification?.signUp + 1;
          } else if (!notification?.signUp) {
            notification.signUp = 1;
            await this.userService.sendStepNotification(data);
          }
        }
        this.storageService.write("notification", notification);
        
        setTimeout(() => {
          this.router.navigate(['/complete-profile'], { relativeTo: this.route });
          this.utilsService.loading('close');  
        }, 200);
        
      }
    ).catch(
      (error) => {
        this.utilsService.loading('close');
        this.userService.errorHandler(error, true);
      }
    );
  }

  goToLogin() {    
    this.router.navigate(['/login']);
  }
}
