<nav>
  <div [routerLink]="'/index'" class="logo">
    <img src="https://www.wuolah.com/static/media/res/mark/logo.png" alt="Wuolads - Wuolah Ad Manager">
  </div>

  <h4>Ad Manager</h4>

  <app-button-item class="back-button" [routerLink]="'/index'" [onlyIcon]="true" [background]="'#FFFFFF'" [icon]="'arrow_back'" [color]="'#102754'" [text]="'Continuar'" [solid]="true"></app-button-item>
</nav>

<div class="container">
  <div class="inner-container">
    <div class="inputs-container">
      <h5 class="wds-h5">Inicia sesión con tu cuenta</h5>

      <div class="input-element">
        <label class="input-element-label">Email</label>
        <input placeholder="javier@tuempresa.com" type="email" [(ngModel)]="credentials.mail">
      </div>

      <div class="input-element">
        <label class="input-element-label">Contraseña</label>
        <input placeholder="********" type="password" [(ngModel)]="credentials.password">
      </div>
      
      <a [routerLink]="'/recover-password'">He olvidado mi contraseña</a>

      <app-button-item (fireClick)="login()" [background]="'#102754'" [color]="'#FFFFFF'" [text]="'Continuar'" [solid]="true"></app-button-item>
    </div>
  </div>

  <a class="outside" [routerLink]="'/index'">¿No tienes cuenta aún?<span>&nbsp; Regístrate</span></a>
</div>
