import { Component, OnInit } from "@angular/core";

// Services
import { CampaignService } from '../../services/campaign.service';
import { AdvertiserService } from '../../services/advertiser.service';
import { StorageService } from '../../services/storage.service';
import { AnalyticService } from "src/app/services/analytic.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard-pending",
  templateUrl: "./dashboard-pending.component.html",
  styleUrls: ["./dashboard-pending.component.css"],
})
export class DashboardPendingComponent implements OnInit {
  advertiserMe: any;
  campaigns: any[];

  campaignStatuses = [
    'CREATING_CAMPAIGN',
    'CREATING_CREATIVES',
    'CREATING_PAYMENTS',
    'PENDING',
    'READY',
    'ACTIVE',
    'PAUSED',
    'FINISHED'
  ];

  campaignStatusesMessages = {
    'CREATING_CAMPAIGN': 'Borrador',
    'CREATING_CREATIVES': 'Borrador',
    'CREATING_PAYMENTS': 'Borrador',
    'PENDING': 'Revisando',
    'READY': 'Preparada',
    'ACTIVE': 'Activa',
    'PAUSED': 'Pausada',
    'FINISHED': 'Finalizada'
  };


  constructor(
    public advertiserService: AdvertiserService,
    public campaignService: CampaignService,
    public storageService: StorageService,
    public analyticService: AnalyticService,
    public titleService: Title,
  ) { }
  
  async ngOnInit() {
    this.titleService.setTitle('Avisos de campaña | Wuolads');
    this.analyticService.pageView(window.location.href, 'dashboard/campaign/pending', 'Campaign notices');
    this.analyticService.screenView('Campaign notices');

    this.advertiserMe = this.storageService.read('advertiserMe');
    await this.loadCampaigns();
  }

  async loadCampaigns(){
    this.campaigns = await this.campaignService.getPendingCampaigns(this.advertiserMe.userId);
    for(var i = 0; i < this.campaigns.length; i++){
      console.log(this.campaigns[i]);
      this.campaigns[i].editable = this.campaignStatuses.indexOf(this.campaigns[i].status) <= 2;
      if(this.campaigns[i].payments && this.campaigns[i].payments.length) {
        this.campaigns[i].shownPayment = this.campaigns[i].payments[this.campaigns[i].payments.length - 1];
        this.campaigns[i].shownPayment.shownDate = (new Date(this.campaigns[i].shownPayment.dueDate)).toISOString().split('T')[0];
      }
    }
  }
}
